export const BasePaths = {
  PMS: "/pms",
  FINAL: "/finalassembly",
  JAR: "/jarassembly",
  OEM: "/OEM",
  MOULDING: "/moulding",
  SALESDISPATCH: "/salesdispatch",
  QUALITY: "/quality",
  WAREHOUSE: "/warehouse",
  RECONCILESTOCKATLOCATION: "/reconcile-stock-at-location",
  SERIALNUMBERTRACEABILITY: "/serial-number-traceability",
};

const pmsRoles = {
  [`${BasePaths.PMS}/d/`]: [
    "admin",
    "station_supervisor",
    "station_operator",
    "station_manager",
    "report_manager"
  ],
  [`${BasePaths.PMS}/plan/`]: [
    "admin",
    "station_supervisor",
    "station_manager",
  ],
  [`${BasePaths.PMS}/rotor/`]: [
    "admin",
    "station_supervisor",
    "station_manager",
  ],
  [`${BasePaths.PMS}/motor/`]: [
    "admin",
    "station_supervisor",
    "station_manager",
  ],
  [`${BasePaths.PMS}/stator/`]: [
    "admin",
    "station_supervisor",
    "station_manager",
  ],
  [`${BasePaths.PMS}/stator-test/`]: [
    "admin",
    "station_supervisor",
    "station_operator",
  ],
  [`${BasePaths.PMS}/rotor-test/`]: [
    "admin",
    "station_supervisor",
    "station_operator",
  ],
  [`${BasePaths.PMS}/motor-testing-report/`]: [
    "admin",
    "station_supervisor",
    "station_operator",
    "report_manager"
  ],
  [`${BasePaths.PMS}/report/`]: [
    "admin",
    "station_supervisor",
    "station_operator",
    "report_manager"
  ],
};

const finalAssemblyRoles = {
  [`${BasePaths.FINAL}/jar/`]: [
    "admin",
    "final_assembly_manager",
    "final_assembly_supervisor",
    "final_assembly_operator",
    "dispatch_head",
    "account_head",
    "report_manager"
  ],
  [`${BasePaths.FINAL}/plan/`]: [
    "admin",
    "final_assembly_manager",
    "final_assembly_supervisor",
    "dispatch_head",
    "account_head",
  ],
  [`${BasePaths.FINAL}/reports/`]: [
    "admin",
    "final_assembly_manager",
    "final_assembly_supervisor",
    "final_assembly_operator",
    "dispatch_head",
    "account_head",
    "report_manager"
  ],
};

const jarAssemblyRoles = {
  [`${BasePaths.JAR}/jar/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
    "report_manager"
  ],
  [`${BasePaths.JAR}/plan/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
  ],
  [`${BasePaths.JAR}/jar-test/`]: ["admin", "jar_assembly_supervisor"],
};

const OEMRoles = {
  [`${BasePaths.OEM}/oem/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
    "report_manager"
  ],
  [`${BasePaths.OEM}/plan/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
  ],
  [`${BasePaths.OEM}/stock-out/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "sales_head",
    "account_assistant",
    "sales_assistant",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
    "oem_dispatch",
  ],
  [`${BasePaths.OEM}/production-report/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
    "report_manager"
  ],
  [`${BasePaths.OEM}/dispatch-report/`]: [
    "admin",
    "jar_assembly_manager",
    "jar_assembly_supervisor",
    "report_manager"
  ],
};

const mouldingRoutes = {
  [`${BasePaths.MOULDING}/mould/`]: [
    "admin",
    "moulding_manager",
    "moulding_supervisor",
    "report_manager"
  ],
  [`${BasePaths.MOULDING}/plan/`]: [
    "admin",
    "moulding_manager",
    "moulding_supervisor",
  ],
  [`${BasePaths.MOULDING}/machine/`]: [
    "admin",
    "moulding_manager",
    "moulding_supervisor",
  ],
};

const salesRoutes = {
  [`${BasePaths.SALESDISPATCH}/create-sales-order`]: ["admin"],
  [`${BasePaths.SALESDISPATCH}/create-customer`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "sales_head",
    "account_assistant",
    "sales_assistant",
    "final_assembly_manager",
  ],
  [`${BasePaths.SALESDISPATCH}/d/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "sales_head",
    "account_assistant",
    "sales_assistant",
    "dispatcher",
    "final_assembly_manager",
  ],
  [`${BasePaths.SALESDISPATCH}/reports/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "sales_head",
    "account_assistant",
    "sales_assistant",
    "dispatcher",
    "final_assembly_manager",
    "report_manager"
  ],
  [`${BasePaths.SALESDISPATCH}/sales-order-product-report/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "sales_head",
    "account_assistant",
    "sales_assistant",
    "dispatcher",
    "final_assembly_manager",
    "report_manager"
  ],
};

const qualityRoutes = {
  [`${BasePaths.QUALITY}/reports/:report_name/`]: ["admin"],
  [`${BasePaths.QUALITY}/reports/incoming-quality-report/`]: ["admin"],
  [`${BasePaths.QUALITY}/reports/inward-inventory/`]: ["admin"],
  [`${BasePaths.QUALITY}/reports/outward-inventory/`]: ["admin"],
};

const warehouseRoutes = {
  [`${BasePaths.WAREHOUSE}/d/`]: ["admin", "account_head", "dispatch_head", "report_manager"],
  [`${BasePaths.WAREHOUSE}/stock_transfer/`]: [
    "admin",
    "account_head",
    "dispatch_head",
  ],
  [`${BasePaths.WAREHOUSE}/reports/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "report_manager"
  ],
  [`${BasePaths.WAREHOUSE}/search_box/`]: [
    "admin",
    "account_head",
    "dispatch_head",
    "report_manager"
  ],
};

export const ROUTES = {
  "/flowview": ["admin", "purchase_assistant", "purchase_manager"],
  "/reports": ["admin", "purchase_assistant", "purchase_manager"],
  "/myview": ["admin", "purchase_assistant", "purchase_manager"],
  ...pmsRoles,
  ...finalAssemblyRoles,
  ...jarAssemblyRoles,
  ...OEMRoles,
  ...mouldingRoutes,
  ...salesRoutes,
  ...qualityRoutes,
  ...warehouseRoutes,
};
