import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getProductType = (type) => {
  return axios
    .get(`/api/v1/product_types?type=${type}`)
    .then((response) => response)
    .catch((err) => err);
};

const getAllProductTypes = () => {
  return axios
    .get("/api/v1/product_types/all_products")
    .then((response) => response)
    .catch((err) => err);
};

const getColorCodes = () => {
  return axios
    .get("/api/v1/product_types/color_codes")
    .then((response) => response)
    .catch((err) => err);
};

export const productTypeService = {
  getProductType,
  getAllProductTypes,
  getColorCodes,
};
