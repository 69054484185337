import { oemDashboardConstant } from "../constants";

export function oemDashboard(
  state = {
    total_production: {},
    oem_dispatch: {},
    oem_production: [],
  },
  action
) {
  switch (action.type) {
    case oemDashboardConstant.GET_OEM_DASHBOARD_SUCCESS:
      return {
        ...state,
        total_production: action.data,
      };
    case oemDashboardConstant.GET_OEM_PRODUCTION_SUCCESS:
      return {
        ...state,
        oem_production: action.data,
      };

    case oemDashboardConstant.GET_OEM_DISPATCH_SUCCESS:
      return {
        ...state,
        oem_dispatch: action.data,
      };

    case oemDashboardConstant.GET_OEM_PRODUCTION_FAILURE:
      return {
        ...state,
        oem_production: [],
      };

    case oemDashboardConstant.GET_OEM_DISPATCH_FAILURE:
      return {
        ...state,
        oem_dispatch: {},
      };

    case oemDashboardConstant.GET_OEM_DASHBOARD_FAILURE:
    default:
      return state;
  }
}
