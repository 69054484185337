import React from "react";
import ExpandableRow from "./ExpandableRow";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
// import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

export const POTable = ({
  handleSelectAllClick,
  handleRowClick,
  isSelected,
  numSelected,
  vendorIndents,
  updateUnitSelected,
  poIndent = {},
  itemDetail = {},
}) => {
  // const rowCount = vendorIndents.length;
  const width = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <TableContainer component={Paper}>
      <Table aria-label="PO table" size={width ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Sr. No.</TableCell>
            <TableCell></TableCell>
            <TableCell>IDN No.</TableCell>
            <TableCell>Department</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Additional Item Description</TableCell>
            <TableCell>Busy Unit</TableCell>
            <TableCell padding="checkbox">
              Select
              {/* <Checkbox
                color="primary"
                size="small"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                disabled={vendorIndents[0].item.busy_item === null}
                onChange={(e) => {
                  handleSelectAllClick(
                    e,
                    vendorIndents[0].item.busy_gst,
                    vendorIndents
                  );
                }}
                inputProps={{ "aria-label": "select all indents" }}
              /> */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vendorIndents.map((indent, index) => {
            const isItemSelected = isSelected(
              indent.id,
              indent?.item?.busy_gst ?? undefined
            );
            return (
              <ExpandableRow
                key={indent.id}
                indent={indent}
                poIndent={poIndent}
                itemDetail={itemDetail}
                index={index}
                isItemSelected={isItemSelected}
                handleRowClick={handleRowClick}
                updateUnitSelected={updateUnitSelected}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default POTable;
