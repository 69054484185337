import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "25ch",
    margin: 10,
  },
}));

const VList = ({ po, vendors, handleChange, error }) => {
  const classes = useStyles();
  return (
    <>
      <FormControl
        error={error}
        variant="outlined"
        className={classes.formControl}
        size="small"
      >
        <InputLabel id="vendor-label">Vendor</InputLabel>
        <Select
          labelId="vendor-label"
          id="selected_vendor"
          name="selected_vendor"
          value={po.selected_vendor ?? ""}
          label="Vendor"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vendor:</em>
          </MenuItem>
          {vendors &&
            vendors.map((vendor, index) => (
              <MenuItem value={vendor.id} key={index}>
                {vendor.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default VList;
