import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { gridsActions, errorAndLoadingActions } from "../../actions";

// component import
import DropComment from "./DropComment";
import Grid from "./Grid";

// design import
import "./Flowview.css";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import BackdropLoader from "../Cards/BackdropLoader";

const useStyle = makeStyles(() => ({
  dInline: {
    display: "inline-block",
    marginRight: 5,
  },
  linkEllipises: {
    width: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stageContainer: {
    display: "flex",
    overflowX: "scroll",
    height: "calc(100vh - 140px)",
  },
  StgTitle: {
    fontSize: 18,
    color: "#4D4F5C",
    textTransform: "uppercase",
  },
  stageBox: {
    padding: 25,
  },
  darkBG: {
    background: "#F8F8F8",
  },
  lightBG: {
    background: "#fff",
  },
}));

const MyViewTracker = ({
  getSingleGrid,
  detailedGrid,
  onDrop,
  stopDropAlert,
  invalidDrop,
  user,
  openAlert,
  closeAlert,
}) => {
  const classes = useStyle();

  /* Drop comment related state and function */
  const [commentOpen, setCommentOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [dropData, setDropData] = useState({});
  const { flowId } = useParams();

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  const handleCommentSubmit = async () => {
    try {
      await onDrop(dropData, commentText);
      setCommentOpen(false);
      setCommentText("");
    } catch {
      stopDropAlert();
    }
  };

  const handleCommentTextChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleDrop = (dropDataInfo) => {
    setCommentOpen(true);
    setDropData(dropDataInfo);
  };

  /* Drop comment related state and function end here */

  useEffect(() => {
    async function fetchData() {
      try {
        if (flowId) await getSingleGrid(flowId);
      } catch (err) {
        openAlert("Error loading flow: ", err.message);
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId]);

  let myViewGrids = Object.assign({}, detailedGrid);

  if (detailedGrid.id) {
    for (let i = 0; i < detailedGrid.stages.length; i++) {
      let filteredSteps = [];
      for (let j = 0; j < detailedGrid.stages[i].steps.length; j++) {
        for (
          let k = 0;
          k < detailedGrid.stages[i].steps[j].workcentres.length;
          k++
        ) {
          if (
            detailedGrid.stages[i].steps[j].workcentres[k].accountable_id ===
            user.id
          ) {
            filteredSteps.push(detailedGrid.stages[i].steps[j]);
          }
        }
      }
      myViewGrids.stages[i].steps = filteredSteps;
    }
  }

  return myViewGrids.id ? (
    <Container maxWidth={false} className={classes.stageContainer}>
      <Grid
        grid={myViewGrids}
        handleDrop={handleDrop}
        invalidDrop={invalidDrop}
        stopDropAlert={stopDropAlert}
      />
      <DropComment
        commentOpen={commentOpen}
        commentText={commentText}
        handleCommentClose={handleCommentClose}
        handleCommentTextChange={handleCommentTextChange}
        handleCommentSubmit={handleCommentSubmit}
      />
    </Container>
  ) : (
    <BackdropLoader />
  );
};

const mapState = (state) => {
  const { detailedGrid } = state.grids;
  const { user } = state.user;
  return {
    detailedGrid,
    user,
  };
};

const dispatchAction = {
  getSingleGrid: gridsActions.getSingleGrid,
  onDrop: gridsActions.onDrop,
  stopDropAlert: gridsActions.stopDropAlert,
  invalidDrop: gridsActions.invalidDrop,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};

export default connect(mapState, dispatchAction)(MyViewTracker);
