import React, { useState } from "react";
import PropTypes from "prop-types";

import IndentDetails from "../TaskDetails/IndentDetails";
import ItemAccordian from "../TaskDetails/ItemAccordian";
import ConsignmentDetailsTable from "./ConsignmentDetailsTable";
import ManufacturingCompanyAccordian from "../TaskDetails/ManufacturingCompanyAccordian";

import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { indentFormActions } from "../../../actions";

const useStyles = makeStyles(() => ({
  truncate: {
    display: "-webkit-box",
    "-moz-box-orient": "vertical",
    "-webkit-box-orient": "vertical",
    "box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
  },
}));

export const IndentDescription = ({
  indent,
  indent_id = null,
  fetchIndentDetail,
  currDate,
  itemDetail,
  mcDetail,
  itemName,
  createConsignments,
  workcentre_name,
}) => {
  const [consignmentAlert, setConsignmentAlert] = useState(false);
  const classes = useStyles();

  const indentCloseAlert = () => {
    setConsignmentAlert(!consignmentAlert);
  };

  const handleIndentClose = (force_close = false) => {
    const body = {
      indent_id,
      create_sub_indent: !force_close,
      consignments: [],
      force_close,
      unit_price: 1,
    };
    createConsignments(body);
    indentCloseAlert();
    return;
  };

  return (
    <div>
      <Accordion
        className="mt-2 mb-3"
        TransitionProps={{ unmountOnExit: true }}
        onChange={async (e, expanded) => {
          if (!indent_id) return;
          if (!expanded) return;
          if (indent) return;
          try {
            await fetchIndentDetail(indent_id);
          } catch (err) {
            console.log("Error getting Manufacturing Vendor: ", err);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="small" />}
          aria-controls="indent-content"
          id={`indent-header-${indent?.id ?? indent_id}`}
        >
          <div className="d-flex flex-column w-100">
            <div className="d-md-flex justify-content-md-between w-100">
              <div className="indent-details-heading col-12 col-md-4 pl-0">
                <Typography className="pr-4" variant="body1">
                  Indent Details
                </Typography>
              </div>
              <div className="indent-details-number d-flex justify-content-between col-12 col-md-8 pl-0">
                <strong className="col-3 pl-0">Item Name</strong>
                <span className={classes.truncate}>
                  {itemName || indent?.item?.name}
                </span>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="d-block">
          {indent && (
            <>
              <div className="py-2 ">
                <div className="d-flex">
                  <IndentDetails indent={indent} />
                </div>
              </div>

              <ItemAccordian
                item={itemDetail[indent.item.id]}
                item_id={indent.item.id}
              />

              {indent.item_sku && indent.item_sku.manufacturing_company && (
                <ManufacturingCompanyAccordian
                  manufacturingCompany={
                    mcDetail[indent.item_sku.manufacturing_company.id]
                  }
                  manufacturingCompany_id={
                    indent.item_sku.manufacturing_company.id
                  }
                />
              )}

              <div className="indent-consignment__wrapper py-2">
                <div className="indent-consignment__header mb-3">
                  <Typography variant="h6">Consignment Details</Typography>
                </div>
                <div className="indentconsignment__table">
                  <ConsignmentDetailsTable
                    consignments={
                      indent.consignments ? indent.consignments : []
                    }
                    currDate={currDate}
                    workcentre_name = {workcentre_name}
                    indent_id = {indent_id}
                  />
                </div>
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Dialog open={consignmentAlert}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={indentCloseAlert}>
            Cancel
          </Button>
          <Tooltip title="This will force close the indent.">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleIndentClose(true)}
            >
              Force Close
            </Button>
          </Tooltip>

          <Tooltip title="This will create new indent.">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleIndentClose()}
            >
              Yes
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapState = (state) => {
  const { itemDetail, mcDetail } = state.indentForm;
  return {
    itemDetail,
    mcDetail,
  };
};

IndentDescription.propTypes = {
  indent: PropTypes.object,
  indent_id: PropTypes.number,
  currDate: PropTypes.string,
  itemDetail: PropTypes.object,
  itemName: PropTypes.string,
  mcDetail: PropTypes.object,
  fetchIndentDetail: PropTypes.func,
};

const dispatchAction = {
  fetchIndentDetail: indentFormActions.fetchIndentDetail,
  createConsignments: indentFormActions.createConsignments,
};

export default connect(mapState, dispatchAction)(IndentDescription);
