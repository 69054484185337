import { vehicleConstants } from "../constants";

export function vehicle(
  state = {
    all_vehicle: [],
    detailed_vehicle: {},
    stations: [],
  },
  action
) {
  switch (action.type) {
    case vehicleConstants.GET_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        all_vehicle: action.data,
      };

    case vehicleConstants.GET_DETAILED_VEHICLE_SUCCESS:
      return {
        ...state,
        detailed_vehicle: action.data,
      };

    case vehicleConstants.GET_DETAILED_VEHICLE_FAILURE:
    case vehicleConstants.GET_DETAILED_VEHICLE_REQUEST:
      return {
        ...state,
        detailed_vehicle: {},
      };

    case vehicleConstants.GET_VEHICLE_LIST_REQUEST:
    case vehicleConstants.GET_VEHICLE_LIST_FAILURE:
      return {
        ...state,
        all_vehicle: [],
      };

    case vehicleConstants.GET_STATIONS_SUCCESS:
      return {
        ...state,
        stations: action.data,
      };

    case vehicleConstants.GET_STATIONS_REQUEST:
    case vehicleConstants.GET_STATIONS_FAILURE:
      return {
        ...state,
        stations: [],
      };

    default:
      return state;
  }
}
