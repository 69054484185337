import { errorAndLoadingConstants, qualityConstants } from "../constants";
import { qualityService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getQualityReport = (report_name) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: qualityConstants.GET_QUALITY_REPORT_REQUEST,
    });
    await qualityService.getQualityReport(report_name).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: qualityConstants.GET_QUALITY_REPORT_SUCCESS,
            data: res.data.reports,
            report_name,
          });
        } else {
          const errMsg = requestFailError(res, "Error getting Report");
          dispatchFailure(dispatch, errMsg, {
            type: qualityConstants.GET_QUALITY_REPORT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: qualityConstants.GET_QUALITY_REPORT_FAILURE,
        });
      }
    );
  };
};

export const qualityActions = {
  getQualityReport,
};
