import React, { forwardRef } from "react";

// Design Import
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Alert } from "reactstrap";

const DropComment = forwardRef(
  (
    {
      commentOpen,
      commentText,
      handleCommentClose,
      handleCommentSubmit,
      handleCommentTextChange,
      error,
    },
    ref
  ) => {
    return (
      <>
        <Dialog
          open={commentOpen}
          fullWidth={true}
          aria-labelledby="on drop comment"
        >
          <DialogTitle>Comment</DialogTitle>
          <DialogContent>
            {error && <Alert color="danger">Comment text can't be blank</Alert>}
            <TextField
              multiline
              placeholder="Type you comment here"
              value={commentText}
              onChange={handleCommentTextChange}
              rows="8"
              fullWidth
              inputRef={ref}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCommentClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCommentSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default DropComment;
