export const stoConstants = {
  GET_PRODUCT_WITH_MASTER_CARTON_SUCCESS:
    "GET_PRODUCT_WITH_MASTER_CARTON_SUCCESS",
  GET_PRODUCT_WITH_MASTER_CARTON_FAILURE:
    "GET_PRODUCT_WITH_MASTER_CARTON_FAILURE",

  CREATE_STO_SUCCESS: "CREATE_STO_SUCCESS",
  CREATE_STO_FAILURE: "CREATE_STO_FAILURE",

  EDIT_STO_SUCCESS: "EDIT_STO_SUCCESS",
  EDIT_STO_FAILURE: "EDIT_STO_FAILURE",

  REJECT_STO_SUCCESS: "REJECT_STO_SUCCESS",
  REJECT_STO_FAILURE: "REJECT_STO_FAILURE",

  DELETE_STO_SUCCESS: "DELETE_STO_SUCCESS",
  DELETE_STO_FAILURE: "DELETE_STO_FAILURE",

  REVIVE_STO_SUCCESS: "REVIVE_STO_SUCCESS",
  REVIVE_STO_FAILURE: "REVIVE_STO_FAILURE",

  GET_CANCELLED_STO_SUCCESS: "GET_CANCELLED_STO_SUCCESS",
  GET_CANCELLED_STO_FAILURE: "GET_CANCELLED_STO_FAILURE",

  DETAILED_STO_SUCCESS: "DETAILED_STO_SUCCESS",
  DETAILED_STO_FAILURE: "DETAILED_STO_FAILURE",

  GET_STN_DETAIL_REQUEST: "GET_STN_DETAIL_REQUEST",
  GET_STN_DETAIL_SUCCESS: "GET_STN_DETAIL_SUCCESS",
  GET_STN_DETAIL_FAILURE: "GET_STN_DETAIL_FAILURE",

  SCAN_SERIAL_REQUEST: "SCAN_SERIAL_REQUEST",
  SCAN_SERIAL_SUCCESS: "SCAN_SERIAL_SUCCESS",
  SCAN_SERIAL_FAILURE: "SCAN_SERIAL_FAILURE",

  GET_STN_SCANS_REQUEST: "GET_STN_SCANS_REQUEST",
  GET_STN_SCANS_SUCCESS: "GET_STN_SCANS_SUCCESS",
  GET_STN_SCANS_FAILURE: "GET_STN_SCANS_FAILURE",

  STO_DASHBOARD_DATA_SUCCESS: "STO_DASHBOARD_DATA_SUCCESS",
  STO_DASHBOARD_DATA_FAILURE: "STO_DASHBOARD_DATA_FAILURE",

  GET_BOX_DETAIL_INITIAL: "GET_BOX_DETAIL_INITIAL",
  GET_BOX_DETAIL_SUCCESS: "GET_BOX_DETAIL_SUCCESS",
  GET_BOX_DETAIL_FAILURE: "GET_BOX_DETAIL_FAILURE",

  GET_STN_LIST_REQUEST: "GET_STN_LIST_REQUEST",
  GET_STN_LIST_SUCCESS: "GET_STN_LIST_SUCCESS",
  GET_STN_LIST_FAILURE: "GET_STN_LIST_FAILURE",

  GET_OEM_STN_LIST_REQUEST: "GET_OEM_STN_LIST_REQUEST",
  GET_OEM_STN_LIST_SUCCESS: "GET_OEM_STN_LIST_SUCCESS",
  GET_OEM_STN_LIST_FAILURE: "GET_OEM_STN_LIST_FAILURE",

  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",

  GET_SO_PRODUCT_REPORT_REQUEST: "GET_SO_PRODUCT_REPORT_REQUEST",
  GET_SO_PRODUCT_REPORT_SUCCESS: "GET_SO_PRODUCT_REPORT_SUCCESS",
  GET_SO_PRODUCT_REPORT_FAILURE: "GET_SO_PRODUCT_REPORT_FAILURE",

  VEHICLE_LOADED_REQUEST: "VEHICLE_LOADED_REQUEST",
  VEHICLE_LOADED_SUCCESS: "VEHICLE_LOADED_SUCCESS",
  VEHICLE_LOADED_FAILURE: "VEHICLE_LOADED_FAILURE",

  GET_INVENTORY_STORE_REQUEST: "GET_INVENTORY_STORE_REQUEST",
  GET_INVENTORY_STORE_SUCCESS: "GET_INVENTORY_STORE_SUCCESS",
  GET_INVENTORY_STORE_FAILURE: "GET_INVENTORY_STORE_FAILURE",
};
