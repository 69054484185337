import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const fetchPOVendors = (order_type) => {
  return axios
    .get(`/api/v1/indent_accepted_vendors/${order_type}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchIndentVendors = (vendor_id, order_type) => {
  return axios
    .get(`/api/v1/vendors/${vendor_id}/vendor_indents/${order_type}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchIndentDepartments = (vendor_id, department_id, order_type) => {
  return axios
    .get(`/api/v1/vendors/${vendor_id}/vendor_indents/${order_type}/department/${department_id}`)
    .then((response) => response)
    .catch((err) => err);
};

const createPO = (requestData) => {
  return axios
    .post(`/api/v1/purchase_orders`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const updateJob = (requestData, id) => {
  return axios
    .put("/api/v1/purchase_orders/" + id, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const rejectPO = (id) => {
  return axios
    .put(`/api/v1/purchase_orders/${id}/reject_po`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchBusyPurchaseTypes = () => {
  return axios
    .get("/api/v1/purchase_orders/busy_purchase_types")
    .then((response) => response)
    .catch((err) => err);
};
const fetchBusyITCEligibilities = () => {
  return axios
    .get("/api/v1/purchase_orders/busy_itc_eligibilities")
    .then((response) => response)
    .catch((err) => err);
};
const fetchBusyCurrencies = () => {
  return axios
    .get("/api/v1/purchase_orders/currencies")
    .then((response) => response)
    .catch((err) => err);
};
const fetchMaterialCentres = () => {
  return axios
    .get("/api/v1/material_centres")
    .then((response) => response)
    .catch((err) => err);
};
const fetchPoEmployeeByRole = () => {
  return axios
    .get(`/api/v1/employees?role=order_po`)
    .then((response) => response)
    .catch((err) => err);
};

export const poFormServices = {
  fetchPOVendors,
  fetchIndentVendors,
  fetchIndentDepartments,
  updateJob,
  createPO,
  rejectPO,
  fetchBusyPurchaseTypes,
  fetchBusyITCEligibilities,
  fetchBusyCurrencies,
  fetchMaterialCentres,
  fetchPoEmployeeByRole,
};
