import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import { useStyles } from "./Css";

export const CustomChip = ({ color, className, label, isSmall = false }) => {
  const classes = useStyles();
  return (
    <Chip
      className={clsx(
        color === "error"
          ? classes.CChiplabelError
          : color === "success"
          ? classes.CChiplabelSuccess
          : classes.CChiplabelWarning,
        className,
        isSmall && classes.chipSmall
      )}
      size="small"
      label={label}
      variant="outlined"
    />
  );
};

CustomChip.propTypes = {
  color: PropTypes.oneOf(["success", "error", "warning"]),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CustomChip;
