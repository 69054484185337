import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PMSAlerts from "../PMS/components/PMSAlerts";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { BasePaths } from "../utils";

const Plan = lazy(() => import("./pages/Plan"));
const JarAssembly = lazy(() => import("./pages/JarAssembly"));
const Dispatch = lazy(() => import("../SalesAndDispatch/pages/Dispatch"));
const DispatchList = lazy(() => import("./pages/DispatchList"));
const Report = lazy(() => import("./pages/Report"))

const baseRoute = BasePaths.FINAL;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const FinalAssemblyRoutes = () => {
  const classes = useStyles();
  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/plan/`}>
            <Plan />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/jar/`}>
            <JarAssembly />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/dispatch-list/`}>
            <DispatchList />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/dispatch/`}>
            <Dispatch />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/reports/`}>
            <Report />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlerts />
    </>
  );
};

export default FinalAssemblyRoutes;
