export const countryConstants = {
  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_SUCCESS",

  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_SUCCESS",

  GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAILURE: "GET_CITIES_SUCCESS",

  GET_DISTRICTS_REQUEST: "GET_DISTRICTS_REQUEST",
  GET_DISTRICTS_SUCCESS: "GET_DISTRICTS_SUCCESS",
  GET_DISTRICTS_FAILURE: "GET_DISTRICTS_SUCCESS",
};
