import React, { useState } from "react";

import ExportButton from "./ExportButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useStyles } from "./reportStyles";
import { formattedNumber } from "../../../utils";

function descendingComparator(a, b, orderBy) {
  if (
    ![
      "no_of_sku_procured",
      "serial_no",
      "ontime_delivery_score",
      "incomplete_csn",
    ].includes(orderBy)
  ) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  } else {
    let first, second;
    if (b[orderBy]) second = parseFloat(b[orderBy]);
    else second = 0;
    if (a[orderBy]) first = parseFloat(a[orderBy]);
    else first = 0;
    if (second < first) return -1;
    if (second > first) return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function VendorsReport({ reports, reportName, reportId }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHangler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    reports && (
      <Paper className={classes.reportBackground}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <div className={classes.ReportHeader}>
            <div className={classes.exportbtn}>
              <ExportButton id={reportId} />
            </div>
          </div>

          <Table
            className={classes.table}
            stickyHeader
            aria-label="Vendor report"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sortDirection={orderBy === "serial_no" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "serial_no"}
                    direction={orderBy === "serial_no" ? order : "asc"}
                    onClick={createSortHangler("serial_no")}
                  >
                    Serial No.
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">Vendor Name</TableCell>
                <TableCell
                  align="center"
                  sortDirection={
                    orderBy === "no_of_sku_procured" ? order : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === "no_of_sku_procured"}
                    direction={orderBy === "no_of_sku_procured" ? order : "asc"}
                    onClick={createSortHangler("no_of_sku_procured")}
                  >
                    Number of SKU Procured
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  sortDirection={
                    orderBy === "ontime_delivery_score" ? order : false
                  }
                >
                  <TableSortLabel
                    onClick={createSortHangler("ontime_delivery_score")}
                    active={orderBy === "ontime_delivery_score"}
                    direction={
                      orderBy === "ontime_delivery_score" ? order : "asc"
                    }
                  >
                    On Time Delivery Score
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  sortDirection={orderBy === "incomplete_csn" ? order : false}
                >
                  <TableSortLabel
                    onClick={createSortHangler("incomplete_csn")}
                    active={orderBy === "incomplete_csn"}
                    direction={orderBy === "incomplete_csn" ? order : "asc"}
                  >
                    % of incomplete CSN
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(reports, getComparator(order, orderBy)).map(
                (report) => (
                  <TableRow key={report.serial_no} data-testid="vendor-row">
                    <TableCell align="left" data-testid="table-body">
                      {report?.serial_no ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      data-testid="table-body"
                    >
                      {report?.vendor_name ?? "-"}
                    </TableCell>
                    <TableCell align="center" data-testid="table-body">
                      {formattedNumber(report?.no_of_sku_procured) ?? "-"}
                    </TableCell>
                    <TableCell align="center" data-testid="table-body">
                      {formattedNumber(report?.ontime_delivery_score, true) ??
                        "-"}
                    </TableCell>
                    <TableCell align="center" data-testid="table-body">
                      {report?.incomplete_csn ?? "-"}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  );
}
