import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const login = (username, password) => {
  let credentials = {}, user = {};
  user.email = username;
  user.password = password;

  credentials.user = user;

  return axios
    .post("/api/v1/users/sign_in", JSON.stringify(credentials), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const logout = () => {
  return axios
    .get("/api/v1/sign_out")
    .then((response) => response)
    .catch((err) => err);
};

const isLoggedIn = () => {
  return axios
    .get("/api/v1/users/logged_in")
    .then((response) => response)
    .catch((err) => err);
};

const checkLoggedIn = async () => {
  try {
    const {
      data: { user },
    } = await isLoggedIn();
    if (user.message) {
      throw new Error(user.message);
    }
    return {
      user: {
        isLoggedIn: true,
        user,
      },
    };
  } catch (error) {
    return {
      user: {
        isLoggedIn: false,
      },
    };
  }
};

export const userService = {
  login,
  logout,
  isLoggedIn,
  checkLoggedIn,
};
