import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PMSAlerts from "../PMS/components/PMSAlerts";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { BasePaths } from "../utils";

const Plan = lazy(() => import("./pages/Plan"));
const StockOut = lazy(() => import("./pages/StockOut"));
const OemInventory = lazy(() => import("./pages/OemInventory"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Report = lazy(() => import("./pages/ProductionReport"));
const DispatchReport = lazy(() => import("./pages/DispatchReport"));
const baseRoute = BasePaths.OEM;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const OEMRoutes = () => {
  const classes = useStyles();
  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/oem/`}>
            <Dashboard />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/plan/`}>
            <Plan />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/stock-out/`}>
            <StockOut />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/inventory/`}>
            <OemInventory />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/production-report/`}>
            <Report />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/dispatch-report/`}>
            <DispatchReport />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlerts />
    </>
  );
};

export default OEMRoutes;