import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import { indentFormActions, poActions, errorAndLoadingActions } from "../../../actions";
import { poFormConstants } from "../../../constants";
import VList from "./VList";
import DList from "./DList";
import POTable from "./POTable";
import CustomAlert from "../CustomAlert";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const elementGap = {
  primary: "30px",
  secondary: "16px",
};

const POStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    "& .MuiTextField-root": {
      width: "25ch",
    },
  },
  DialogMinWidth: {
    minWidth: "80%",
    padding: elementGap.primary,
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "90vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "80vh",
    },
  },
  Icon: {
    position: "fixed",
    right: 50,
    bottom: 50,
  },
  DbodyContain: {
    paddingTop: 0,
  },
  header: {
    borderBottom: "1px solid #F1F1F3",
    marginBottom: theme.spacing(5),
  },
  formControl: {
    marginLeft: 10,
    minWidth: 196,
    maxWidth: 196,
    marginBottom: theme.spacing(4),
    "& .MuiInput-formControl": {
      marginTop: 12,
    },
  },
}));

const initialPO = {
  selected_vendor: "",
  indent_ids: [],
  selected_department: "",
};

const initialBusyData = {
  busy_currency: "INR",
  busy_itc_eligibility: "",
  busy_purchase_type: "",
  material_centre: "",
  order_by: "",
  freight: "",
  packaging: "",
  remark: "",
};

const initialBusyError = {
  busy_currency: false,
  busy_itc_eligibility: false,
  busy_purchase_type: false,
  material_centre: false,
  order_by: false,
  freight: false,
  packaging: false,
  remark: false,
};
let vendorId;
function AddPo({
  createPO,
  vendors,
  departments,
  fetchPOVendors,
  fetchDepartments,
  vendorIndents,
  fetchIndentVendors,
  fetchIndentDepartments,
  openAlert,
  closeAlert,
  order_type,
  fetchBusyPurchaseTypes,
  fetchBusyITCEligibilities,
  fetchBusyCurrencies,
  fetchMaterialCentres,
  fetchPoEmployeeByRole,
  busy_purchase_types,
  busy_itc_eligibilities,
  busy_currencies,
  material_centres,
  po_users,
  isError,
  poIndent,
  itemDetail,
}) {
  const [open, setOpen] = useState(false);
  const [po, setPO] = useState(initialPO);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isPORequired, setIsPORequired] = useState(false);
  const [errors, setErrors] = useState({
    selected_vendor: false,
    selected_department: false,
    indent_ids: {
      isError: false,
      message: "",
    },
  });
  const [selectedRowMapCount, setSelectedRowMapCount] = useState(new Map());
  const [indentItemCount, setIndentItemCount] = useState(new Map());

  const [newPO, setNewPO] = useState(new Map());
  const [allNewPO, setAllNewPO] = useState(new Map());
  const [busyData, setBusyData] = useState(initialBusyData);
  const [busyError, setBusyError] = useState(initialBusyError);
  const [vIndent, setVIndent] = useState([]);

  const classes = POStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData(vendor_id, order_type) {
      await fetchIndentVendors(vendor_id, order_type);
    }
    if (po.selected_vendor) fetchData(po.selected_vendor, order_type);
  }, [po.selected_vendor, fetchIndentVendors, order_type]);

  useEffect(() => {
    async function fetchData(vendor_id, department_id, order_type) {
      await fetchIndentDepartments(vendor_id, department_id, order_type);
    }
    if(po.selected_department) fetchData(po.selected_vendor, po.selected_department, order_type)
  }, [po.selected_vendor, po.selected_department, fetchIndentDepartments, order_type]);

  useEffect(() => {
    (async () => {
      await fetchDepartments();
    })();
  }, [fetchDepartments]);

  useEffect(() => {
    (async () => {
      await fetchBusyPurchaseTypes();
    })();
  }, [fetchBusyPurchaseTypes]);

  useEffect(() => {
    (async () => {
      await fetchBusyITCEligibilities();
    })();
  }, [fetchBusyITCEligibilities]);

  useEffect(() => {
    (async () => {
      await fetchBusyCurrencies();
    })();
  }, [fetchBusyCurrencies]);

  useEffect(() => {
    (async () => {
      await fetchMaterialCentres();
    })();
  }, [fetchMaterialCentres]);

  useEffect(() => {
    (async () => {
      await fetchPoEmployeeByRole();
    })();
  }, [fetchPoEmployeeByRole]);

  const handleSelectAllClick = (event, value, allIndents) => {
    if (event.target.checked) {
      if (selectedRowMapCount.has(value) && selectedRowMapCount.size === 1) {
        setIndentItemCount((prevState) => {
          prevState.clear();
          return new Map(prevState);
        });

        setSelectedRowMapCount((prevState) => {
          prevState.clear();
          return new Map(prevState);
        });

        setNewPO((prevState) => {
          prevState.set("selected_vendor", "");
          prevState.set("indent_ids", []);
          return new Map(prevState);
        });

        setAllNewPO((prevState) => {
          prevState.set(value, newPO);
          return new Map(prevState);
        });

        setPO((prevState) => ({
          ...prevState,
          indent_ids: [],
        }));
        return;
      } else if (
        !selectedRowMapCount.has(value) &&
        selectedRowMapCount.size === 0
      ) {
        let similarItems = [],
          tempSelecteds = [];

        for (let i = 0; i < allIndents.length; i++) {
          if (!indentItemCount.has(allIndents[i].item.id)) {
            setIndentItemCount((prevState) => {
              prevState.set(allIndents[i].item.id, 1);
              return new Map(prevState);
            });
          }
          if (!similarItems.includes(allIndents[i].item.id)) {
            similarItems.push(allIndents[i].item.id);
            tempSelecteds.push(allIndents[i].id);
          }
        }

        setSelectedRowMapCount((prevState) => {
          prevState.set(value, tempSelecteds);
          return new Map(prevState);
        });
        const newSelecteds = tempSelecteds;

        setNewPO((prevState) => {
          prevState.set("selected_vendor", po.selected_vendor);
          prevState.set("indent_ids", newSelecteds);
          return new Map(prevState);
        });

        setAllNewPO((prevState) => {
          prevState.set(value, newPO);
          return new Map(prevState);
        });

        setPO((prevState) => ({
          ...prevState,
          indent_ids: newSelecteds,
        }));
        return;
      } else if (
        !selectedRowMapCount.has(value) &&
        selectedRowMapCount.size > 0
      )
        alert("You can select only one table at a time.");
    } else {
      setIndentItemCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });

      setSelectedRowMapCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });

      setNewPO((prevState) => {
        prevState.set("selected_vendor", "");
        prevState.set("indent_ids", []);
        return new Map(prevState);
      });

      setAllNewPO((prevState) => {
        prevState.set(value, newPO);
        return new Map(prevState);
      });

      setPO((prevState) => ({
        ...prevState,
        indent_ids: [],
      }));
    }
  };

  const isSelected = (id, gst_value) => {
    return allNewPO.get(gst_value) !== undefined &&
      allNewPO
        .get(gst_value)
        .get("indent_ids")
        .find((_) => _.id === id)
      ? true
      : false;
  };

  const setPOData = (event, id, gst_value, selectedUnit, additionalItemDescription) => {
    const selected =
      allNewPO.get(gst_value) !== undefined
        ? allNewPO.get(gst_value).get("indent_ids")
        : [];
    const selectedIndex = selected.findIndex((_) => _.id === id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        id,
        selected_unit: selectedUnit,
        additional_item_description: additionalItemDescription
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setNewPO((prevState) => {
      prevState.set("selected_vendor", po.selected_vendor);
      prevState.set("indent_ids", newSelected);
      return new Map(prevState);
    });

    setAllNewPO((prevState) => {
      prevState.set(gst_value, newPO);
      return new Map(prevState);
    });

    setPO((prevState) => ({
      ...prevState,
      indent_ids: newSelected,
    }));
  };

  const handleRowClick = (event, id, gst, indentItemId, selectedUnit, additionalItemDescription) => {
    if (selectedRowMapCount.has(gst)) {
      if (event.target.checked) {
        if (indentItemCount.get(indentItemId) === 1)
          alert("Multiple Indent Of Same Item cannot be selected.");
        else {
          setIndentItemCount((prevState) => {
            prevState.set(indentItemId, 1);
            return new Map(prevState);
          });
          setSelectedRowMapCount((prevState) => {
            prevState.set(gst, indentItemCount);
            return new Map(prevState);
          });
          setPOData(event, id, gst, selectedUnit, additionalItemDescription);
        }
      } else {
        setIndentItemCount((prevState) => {
          prevState.set(indentItemId, prevState.get(indentItemId) - 1);
          if (prevState.get(indentItemId) === 0) prevState.delete(indentItemId);
          return new Map(prevState);
        });

        setSelectedRowMapCount((prevState) => {
          prevState.set(gst, indentItemCount);
          if (prevState.get(gst).size === 0) prevState.clear();
          return new Map(prevState);
        });

        setPOData(event, id, gst, selectedUnit, additionalItemDescription);
      }
    } else if (selectedRowMapCount.size === 0) {
      setIndentItemCount((prevState) => {
        prevState.set(indentItemId, 1);
        return new Map(prevState);
      });
      setSelectedRowMapCount((prevState) => {
        prevState.set(gst, indentItemCount);
        return new Map(prevState);
      });
      setPOData(event, id, gst, selectedUnit, additionalItemDescription);
    } else if (!selectedRowMapCount.has(gst) && selectedRowMapCount.size > 0)
      alert("You can only select PO from same GST %.");
  };

  const handleClickOpen = () => {
    setOpen(true);
    async function fetchData(order_type) {
      await fetchPOVendors(order_type);
    }
    fetchData(order_type);
  };

  const handleClose = () => {
    setVIndent({});
    setOpen(false);
    setPO(initialPO);
    setBusyError(initialBusyError);
    setBusyData(initialBusyData);
    setErrors({
      selected_vendor: false,
      selected_department: false,
      indent_ids: {
        isError: false,
        message: "",
      },
    });
    setIndentItemCount((prevState) => {
      prevState.clear();
      return new Map(prevState);
    });
    setSelectedRowMapCount((prevState) => {
      prevState.clear();
      return new Map(prevState);
    });
    setNewPO((prevState) => {
      prevState.clear();
      return new Map(prevState);
    });

    setAllNewPO((prevState) => {
      prevState.clear();
      return new Map(prevState);
    });
    dispatch({ type: poFormConstants.GET_VENDOR_INDENTS_REQUEST });
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleConfirmSubmit = async () => {
    if (po.indent_ids.length < 1) {
      setErrors((prevState) => ({
        ...prevState,
        indent_ids: {
          isError: true,
          message: "Indent(s) not selected.",
        },
      }));
      handleConfirmClose();
      return;
    }

    for(let i = 0; i<po.indent_ids.length; i++){
      if (po.indent_ids[i].additional_item_description === "") {
        setErrors((prevState) => ({
          ...prevState,
          indent_ids: {
            isError: true,
            message: "Additional Item Description required for selected Indent(s).",
          },
        }));
        handleConfirmClose();
        return;
      }
    }

    if (po.selected_vendor && po.selected_department) {
      const reqBody = {
        purchase_order: {
          vendor_id: po.selected_vendor,
          indent_ids: po.indent_ids,
          busy_purchase_type:
            document.getElementById("busy-purchase-type").innerHTML,
          busy_itc_eligibility: document.getElementById("busy-itc-eligibility")
            .innerHTML,
          currency: document.getElementById("busy-currency").innerHTML,
          material_centre_id:
            document.getElementsByName("material_centre")[0].value,
          order_by_id: busyData.order_by,
          packaging_and_forwarding: document.getElementById("packaging").value,
          freight: document.getElementById("freight").value,
          remarks: busyData.remark,
        },
        order_type,
      };
      if (order_type === "local")
        reqBody.purchase_order.po_not_required = isPORequired;
      try {
        await createPO(reqBody, setOpen, setPO, initialPO);
        handleConfirmClose();
        handleClose();
        setIsPORequired(false);
        setErrors((prevState) => ({
          ...prevState,
          indent_ids: {
            isError: false,
            message: "",
          },
        }));
      } catch (err) {
        setOpen(true);
        openAlert(`Error getting SKU: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
        setErrors((prevState) => ({
          ...prevState,
          indent_ids: {
            isError: false,
            message: "",
          },
        }));
        handleConfirmClose();
      }
    }
  };

  const handleBusyChange = (e) => {
    e.persist();
    setBusyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setBusyError((ps) => ({
      ...ps,
      [e.target.name]: false,
    }));
  };

  const gstValuExtract = (s) => {
    let out = "";
    let digit_occur = false;

    for (let i = 0; i <= s.length; i++) {
      if (s[i] >= "0" && s[i] <= "9") {
        out += s[i];
        digit_occur = true;
      } else if (s[i] == ".") {
        if (digit_occur) {
          out += ".";
        }
      } else if (digit_occur) {
        break;
      }
    }
    return out;
  };

  const handleGstChange = (e) => {
    e.persist();
    setBusyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setBusyError((ps) => ({
      ...ps,
      [e.target.name]: false,
    }));
    const gstValue = gstValuExtract(e.target.value);

    const filterIndents = vendorIndents.filter(
      (data) => data[0].item.busy_item?.busy_gst == gstValue
    );
    setVIndent(filterIndents);
  };

  const validate = () => {
    let errorCount = 0;
    if (!po.selected_vendor) {
      errorCount++;
      setErrors((prevState) => ({
        ...prevState,
        selected_vendor: true,
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        selected_vendor: false,
      }));
    }
    if (!po.selected_department) {
      errorCount++;
      setErrors((prevState) => ({
        ...prevState,
        selected_department: true,
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        selected_department: false,
      }));
    }
    Object.keys(busyData).forEach((key) => {
      if (!busyData[key]) {
        errorCount++;
        setBusyError((ps) => ({
          ...ps,
          [key]: true,
        }));
      }
    });
    return errorCount > 0;
  };

  const handleSave = () => {
    if (validate()) return;
    setOpenConfirm(true);
  };

  const handleIsPORequired = useCallback(() => {
    setIsPORequired(!isPORequired);
  }, [isPORequired]);

  const handleChange = (event, name = "") => {  
    event.persist();
    if ("selected_vendor" === event.target.name) {
      vendorId = event.target.value;
      setPO(initialPO);
      setBusyData(initialBusyData);
      setVIndent({});
      setIndentItemCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      setSelectedRowMapCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      setNewPO((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });

      setAllNewPO((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      dispatch({ type: poFormConstants.GET_VENDOR_INDENTS_REQUEST });
      setPO({
        // ...po,
        indent_ids: [],
        [event.target.name]: event.target.value,
      });
    }
    if ("selected_department" === event.target.name) {
      setPO(initialPO);
      setBusyData(initialBusyData);
      setVIndent({});
      setIndentItemCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      setSelectedRowMapCount((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      setNewPO((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });

      setAllNewPO((prevState) => {
        prevState.clear();
        return new Map(prevState);
      });
      dispatch({ type: poFormConstants.GET_DEPARTMENT_INDENTS_REQUEST });
      setPO({
        // ...po,
        indent_ids: [],
        selected_vendor: vendorId,
        [event.target.name]: event.target.value,
      });
    }
  };

  const updateUnitSelected = (id, unit, gst_value) => {
    setNewPO((prevState) => {
      let tempIndent = prevState.get("indent_ids");
      tempIndent = tempIndent.map((_) =>
        _.id === id ? { ..._, selected_unit: unit } : _
      );
      prevState.set("indent_ids", tempIndent);
      return new Map(prevState);
    });

    setAllNewPO((prevState) => {
      prevState.set(gst_value, newPO);
      return new Map(prevState);
    });
    setPO((ps) => ({
      ...ps,
      indent_ids: ps.indent_ids.map((_) =>
        _.id === id ? { ..._, selected_unit: unit } : _
      ),
    }));
  };

  return (
    <div>
      <Fab
        className={classes.Icon}
        color="primary"
        onClick={handleClickOpen}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      <Dialog
        open={open}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.DialogMinWidth }}
      >
        <DialogTitle
          className={`p-0 d-flex justify-content-between align-items-center`}
          id="form-dialog-title"
          disableTypography={true}
        >
          <Typography variant="h5">Generate New PO</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="p-0">
          <div
            className={`d-flex justify-content-between align-items-center w-100 ${classes.header}`}
          >
            <Typography>All Indents</Typography>
            <Box display="flex">
              {order_type === "local" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPORequired}
                      onChange={handleIsPORequired}
                      name="isPORequired"
                      color="primary"
                      size="small"
                    />
                  }
                  label="PO Not Required by Vendor"
                />
              )}

              <VList
                po={po}
                vendors={vendors}
                handleChange={handleChange}
                error={errors.selected_vendor}
              />
              <DList
                po={po}
                departments={departments}
                handleChange={handleChange}
                error={errors.selected_department}
              />

            </Box>
          </div>
          <div className="po-table">
            {errors.indent_ids.isError && (
              <Box mb={2}>
                <Alert severity="error">{errors.indent_ids.message}</Alert>
              </Box>
            )}
            <h4>PO Details</h4>
            <FormControl
              required
              className={classes.formControl}
              error={busyError.material_centre}
              size="small"
              variant="outlined"
            >
              <InputLabel id="material-centre-select">
                Material Centre
              </InputLabel>
              <Select
                labelId="material-centre-select"
                id="material-centre"
                name="material_centre"
                value={busyData.material_centre}
                label="Material Centre"
                onChange={handleBusyChange}
              >
                {material_centres &&
                  material_centres.map((mat_cen) => (
                    <MenuItem
                      id="mat-centre-item"
                      key={mat_cen.id}
                      value={mat_cen.id}
                    >
                      {mat_cen.name}
                    </MenuItem>
                  ))}
              </Select>
              {isError && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              error={busyError.busy_purchase_type}
              size="small"
              variant="outlined"
            >
              <InputLabel id="busy-purchase-type-select">
                Busy Purchase Type
              </InputLabel>
              <Select
                labelId="busy-purchase-type-select"
                id="busy-purchase-type"
                name="busy_purchase_type"
                value={busyData.busy_purchase_type}
                label="Busy Purchase Type"
                onChange={handleGstChange}
              >
                {busy_purchase_types &&
                  busy_purchase_types.map((purchase_type) => (
                    <MenuItem key={purchase_type} value={purchase_type}>
                      {purchase_type}
                    </MenuItem>
                  ))}
              </Select>
              {isError && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              error={busyError.busy_itc_eligibility}
              size="small"
              variant="outlined"
            >
              <InputLabel id="busy-itc-eligibility-select">
                Busy ITC Eligibility
              </InputLabel>
              <Select
                labelId="busy-itc-eligibility-select"
                id="busy-itc-eligibility"
                name="busy_itc_eligibility"
                value={busyData.busy_itc_eligibility}
                label="Busy ITC Eligibility"
                onChange={handleBusyChange}
              >
                {busy_itc_eligibilities &&
                  busy_itc_eligibilities.map((busy_itc) => (
                    <MenuItem key={busy_itc} value={busy_itc}>
                      {busy_itc}
                    </MenuItem>
                  ))}
              </Select>
              {isError && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              error={busyError.busy_currency}
              size="small"
              variant="outlined"
            >
              <InputLabel id="busy-currency-select">Currency</InputLabel>
              <Select
                labelId="busy-currency-select"
                id="busy-currency"
                name="busy_currency"
                value={busyData.busy_currency}
                label="Currency"
                onChange={handleBusyChange}
              >
                {busy_currencies &&
                  busy_currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
              </Select>
              {isError && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              required
              className={classes.formControl}
              error={busyError.order_by}
              size="small"
              variant="outlined"
            >
              <InputLabel id="order-by-select">Order By</InputLabel>
              <Select
                labelId="order-by-select"
                id="order-by"
                name="order_by"
                value={busyData.order_by}
                label="Order By"
                onChange={handleBusyChange}
              >
                {po_users &&
                  po_users.map((po_user) => (
                    <MenuItem
                      id="order-by-item"
                      key={po_user.id}
                      value={po_user.id}
                    >
                      {po_user.firstname} {po_user.lastname}
                    </MenuItem>
                  ))}
              </Select>
              {isError && <FormHelperText>Required</FormHelperText>}
            </FormControl>
            <TextField
              name="freight"
              id="freight"
              label="Freight*"
              variant="outlined"
              error={busyError.freight}
              value={busyData.freight}
              className={classes.formControl}
              size="small"
              onChange={handleBusyChange}
            />

            <TextField
              name="packaging"
              id="packaging"
              label="Packaging and Forwarding*"
              variant="outlined"
              error={busyError.packaging}
              value={busyData.packaging}
              className={classes.formControl}
              size="small"
              onChange={handleBusyChange}
            />

            <TextField
              name="remark"
              id="remark"
              label="Remark"
              variant="outlined"
              error={busyError.remark}
              value={busyData.remark}
              className={classes.formControl}
              size="small"
              onChange={handleBusyChange}
            />

            <br />
            <br />
            {vIndent.length
              ? vIndent.map((data, index) => (
                  <div id={"div-id:" + index} key={"div-key:" + index}>
                    <Typography variant="h5" gutterBottom>
                      {!data[0].item || data[0].item.busy_item === null
                        ? "NO GST"
                        : "GST " + data[0].item.busy_item.busy_gst + "%"}
                    </Typography>
                    <POTable
                      handleSelectAllClick={handleSelectAllClick}
                      handleRowClick={handleRowClick}
                      isSelected={isSelected}
                      updateUnitSelected={updateUnitSelected}
                      numSelected={
                        data[0].item &&
                        allNewPO.get(data[0].item.busy_item) !== undefined
                          ? allNewPO
                              .get(data[0].item.busy_item.busy_gst)
                              .get("indent_ids").length
                          : 0
                      }
                      vendorIndents={data}
                      poIndent={poIndent}
                      itemDetail={itemDetail}
                    />
                    <br />
                    <br />
                  </div>
                ))
              : vIndent.length == 0
              ? ""
              : vendorIndents.map((data, index) => (
                  <div id={"div-id:" + index} key={"div-key:" + index}>
                    <Typography variant="h5" gutterBottom>
                      {!data[0].item || data[0].item.busy_item === null
                        ? "NO GST"
                        : "GST " + data[0].item.busy_item.busy_gst + "%"}
                    </Typography>
                    <POTable
                      handleSelectAllClick={handleSelectAllClick}
                      handleRowClick={handleRowClick}
                      isSelected={isSelected}
                      updateUnitSelected={updateUnitSelected}
                      numSelected={
                        data[0].item &&
                        allNewPO.get(data[0].item.busy_item) !== undefined
                          ? allNewPO
                              .get(data[0].item.busy_item.busy_gst)
                              .get("indent_ids").length
                          : 0
                      }
                      vendorIndents={data}
                      poIndent={poIndent}
                      itemDetail={itemDetail}
                    />
                    <br />
                    <br />
                  </div>
                ))}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            Generate PO
          </Button>
        </DialogActions>
        <CustomAlert
          isOpen={openConfirm}
          handleClose={handleConfirmClose}
          handleSubmit={handleConfirmSubmit}
        />
      </Dialog>
    </div>
  );
}

const mapState = (state) => {
  const {
    vendors,
    vendorIndents,
    busy_purchase_types,
    busy_itc_eligibilities,
    busy_currencies,
    material_centres,
    po_users,
  } = state.poForm;
  const { poIndent, itemDetail, departments} = state.indentForm;
  return {
    vendors,
    departments,
    vendorIndents,
    busy_purchase_types,
    busy_itc_eligibilities,
    busy_currencies,
    material_centres,
    po_users,
    poIndent,
    itemDetail,
  };
};

const dispatchAction = {  
  fetchPOVendors: poActions.fetchPOVendors,
  fetchDepartments: indentFormActions.fetchDepartments,
  fetchIndentVendors: poActions.fetchIndentVendors,
  fetchIndentDepartments: poActions.fetchIndentDepartments,
  fetchBusyPurchaseTypes: poActions.fetchBusyPurchaseTypes,
  fetchBusyITCEligibilities: poActions.fetchBusyITCEligibilities,
  fetchBusyCurrencies: poActions.fetchBusyCurrencies,
  fetchMaterialCentres: poActions.fetchMaterialCentres,
  fetchPoEmployeeByRole: poActions.fetchPoEmployeeByRole,
  createPO: poActions.createPO,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};
export default connect(mapState, dispatchAction)(AddPo);
