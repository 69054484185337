import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getProductsWithPackType = () => {
  return axios
    .get("/api/v1/stock_transfer_orders/products")
    .then((response) => response)
    .catch((err) => err);
};

const createSTO = (body) => {
  return axios
    .post("/api/v1/stock_transfer_orders", body)
    .then((res) => res)
    .catch((err) => err);
};

const editSTO = (id, body) => {
  return axios
    .put(`/api/v1/stock_transfer_orders/${id}`, body)
    .then((res) => res)
    .catch((err) => err);
};

const rejectSTO = (id) => {
  return axios
    .put(`/api/v1/stock_transfer_orders/${id}/reject_sto`)
    .then((res) => res)
    .catch((err) => err);
};

const deleteSTO = (id) => {
  return axios
    .put(`/api/v1/stock_transfer_orders/${id}/delete_sto`)
    .then((res) => res)
    .catch((err) => err);
};

const reviveSTO = (id) => {
  return axios
    .put(`/api/v1/stock_transfer_orders/${id}/revive_sto`)
    .then((res) => res)
    .catch((err) => err);
};

const getCancelledSTO = () => {
  return axios
    .get("/api/v1/stock_transfer_orders/cancelled_stock_transfer_order")
    .then((res) => res)
    .catch((err) => err);
};

const detailedSTO = (id) => {
  return axios
    .get(`/api/v1/stock_transfer_orders/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const getStnDetails = (id) => {
  return axios
    .get(`/api/v1/stock_transfer_notes/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const scanOut = (body, id) => {
  return axios
    .post(`/api/v1/stock_transfer_notes/${id}/scan/out`,{stock_transfer_note: body})
    .then((res) => res)
    .catch((err) => err);
};

const getStnScans = (body) => {
  return axios
    .get(`/api/v1/stock_transfer_notes/${body.id}/${body.page}`,{
      params: {
        oem_web: true,
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

export const getDashboardData = (date, warehouse_id, inventory_store_id) => {
  return axios
    .get(`/api/v1/warehouses/stock/${warehouse_id}?date=${date}&inventory_store_id=${inventory_store_id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getBoxDetails = (box_code, type) => {
  return axios
    .get(`/api/v1/search/box_detail/?code=${box_code}&type=${type}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getStnNumberList = (body) => {
  return axios
    .get("/api/v1/warehouses/stn_list",{
      params: {
        from_date: body.fromDate,
        to_date: body.toDate,
        status: body.status,
      }})
    .then((res) => res)
    .catch((err) => err);
};

export const getOEMStnList = (body) => {
  return axios
    .get("/api/v1/stock_transfer_notes",{
      params: {
        date: body.date,
        warehouse: body.warehouse,
        inventory_store_id: body.inventory_store_id,
        prodtype: body.prodtype,
      }})
    .then((res) => res)
    .catch((err) => err);
};

export const getReport = (body) => {
  return axios
    .post("/api/v1/sales_orders/sales_dispatch_report", body)
    .then((res) => res)
    .catch((err) => err);
};

export const getSOProductReport = (body) => {
  return axios
    .post("/api/v1/sales_orders/sales_order_product_report", body)
    .then((res) => res)
    .catch((err) => err);
};

const vehicleLoaded = (body) => {
  return axios
    .post("/api/v1/stn_scans", {stn_scan: body})
    .then((res) => res)
    .catch((err) => err);
};

const getInventoryStores = () => {
  return axios
    .get(`/api/v1/warehouses/all_inventory_stores`)
    .then((response) => response)
    .catch((err) => err);
};

export const stoService = {
  getProductsWithPackType,
  createSTO,
  editSTO,
  rejectSTO,
  deleteSTO,
  reviveSTO,
  getCancelledSTO,
  detailedSTO,
  getStnDetails,
  scanOut,
  getStnScans,
  getDashboardData,
  getBoxDetails,
  getStnNumberList,
  getOEMStnList,
  getReport,
  getSOProductReport,
  vehicleLoaded,
  getInventoryStores,
};
