import { errorAndLoadingConstants } from "../constants";

const initialState = {
  isLoading: false,
  loadCount: 0,
  popUp: {
    isOpen: false,
    message: "",
  },
  alert: {
    isOpen: false,
    message: "",
    type: "",
  },
  successAlert: {
    open: false,
    title: "",
    message: "",
  },
};

export const errorAndLoading = (state = initialState, action) => {
  switch (action.type) {
    case errorAndLoadingConstants.OPEN_ALERT:
      return {
        ...state,
        alert: {
          isOpen: true,
          message: action.message,
          type: action.alertType,
        },
      };

    case errorAndLoadingConstants.OPEN_POPUP:
      return {
        ...state,
        popUp: {
          isOpen: true,
          message: action.message,
        },
      };

    case errorAndLoadingConstants.CLOSE_ALERT:
      return {
        ...state,
        alert: {
          isOpen: false,
          message: "",
          type: "",
        },
      };

    case errorAndLoadingConstants.CLOSE_POPUP:
      return {
        ...state,
        popUp: {
          isOpen: false,
          message: "",
        },
      };

    case errorAndLoadingConstants.API_CALL_START:
      return {
        ...state,
        isLoading: true,
        loadCount: state.loadCount + 1,
      };

    case errorAndLoadingConstants.API_CALL_STOP:
      return {
        ...state,
        isLoading: false,
        loadCount: state.loadCount - 1,
      };

    case errorAndLoadingConstants.SHOW_SUCCESS:
      return {
        ...state,
        successAlert: {
          open: true,
          title: action.data.title,
          message: action.data.message,
        },
      };

    case errorAndLoadingConstants.CLOSE_SUCCESS:
      return {
        ...state,
        successAlert: {
          open: false,
          title: "",
          message: "",
        },
      };

    default:
      return state;
  }
};
