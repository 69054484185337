import { BasePaths } from "./RoutesConst";

export const navMenu = [
  {
    name: "Dashboard",
    url: "/reports",
  },
  // {
  //   name: "Purchase",
  //   url: "/flowview",
  // },
  {
    name: "Production Management System",
    child: [
      {
        name: "Jar Assembly",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.JAR}/jar`,
          },
          {
            name: "Plan",
            url: `${BasePaths.JAR}/plan`,
          },
          {
            name: "Jar Testing",
            url: `${BasePaths.JAR}/jar-test`,
          },
        ],
      },
      {
        name: "Final Assembly",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.FINAL}/jar`,
          },
          {
            name: "Plan",
            url: `${BasePaths.FINAL}/plan`,
          },
          {
            name: "Reports",
            url: `${BasePaths.FINAL}/reports`,
          },
        ],
      },
      {
        name: "OEM(White Labelled)",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.OEM}/oem`,
          },
          {
            name: "Plan",
            url: `${BasePaths.OEM}/plan`,
          },
          {
            name: "OEM Stock Outward",
            url: `${BasePaths.OEM}/stock-out`,
          },
          {
            name: "OEM Inventory",
            url: `${BasePaths.OEM}/inventory`,
          },
          {
            name: "Reports",
            child: [
              {
                name: "Production Report",
                url: `${BasePaths.OEM}/production-report`,
              },
              {
                name: "Dispatch Report",
                url: `${BasePaths.OEM}/dispatch-report`,
              },
            ]
            
          },
        ],
      },
      {
        name: "Motor Assembly",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.PMS}/d`,
          },
          {
            name: "Plan",
            url: `${BasePaths.PMS}/plan`,
          },
          {
            name: "Motor",
            url: `${BasePaths.PMS}/motor`,
          },
          {
            name: "Rotor",
            url: `${BasePaths.PMS}/rotor`,
          },
          {
            name: "Stator",
            url: `${BasePaths.PMS}/stator`,
          },
          {
            name: "Stator Testing",
            url: `${BasePaths.PMS}/stator-test`,
          },
          {
            name: "Rotor Testing",
            url: `${BasePaths.PMS}/rotor-test`,
          },
          {
            name: "Reports",
            child: [
              {
                name: "Motor Testing Report",
                url: `${BasePaths.PMS}/motor-testing-report`,
              },
              {
                name: "Downloadable Report",
                url: `${BasePaths.PMS}/report`,
              },
            ]
          }
        ],
      },
      {
        name: "Plastic Injection Moulding",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.MOULDING}/mould`,
          },
          {
            name: "Plan",
            url: `${BasePaths.MOULDING}/plan`,
          },
          {
            name: "Moulding Testing",
            url: `${BasePaths.MOULDING}/machine`,
          },
        ],
      },
    ],
  },
  {
    name: "Sales & Dispatch",
    child: [
      {
        name: "Dashboard",
        url: `${BasePaths.SALESDISPATCH}/d`,
      },
      {
        name: "Reports",
        child: [
          {
            name: "Sales Dispatch Report",
            url: `${BasePaths.SALESDISPATCH}/reports`,
          },
          {
            name: "Sales Dispatch Product Report",
            url: `${BasePaths.SALESDISPATCH}/sales-order-product-report`,
          },
        ]
        
      },
    ],
  },
  // {
  //   name: "Quality and Inventory",
  //   child: [
  //     {
  //       name: "Incoming Quality Report",
  //       url: `${BasePaths.QUALITY}/reports/incoming-quality-report`,
  //     },
  //     {
  //       name: "Inventory Report",
  //       url: `${BasePaths.QUALITY}/reports/inward-inventory`,
  //     },
  //   ],
  // },
  {
    name: "Warehouse",
    child: [
      {
        name: "Dashboard",
        url: `${BasePaths.WAREHOUSE}/d`,
      },
      {
        name: "Stock Transfer",
        child: [
          {
            name: "Dashboard",
            url: `${BasePaths.WAREHOUSE}/stock_transfer`,
          },
          {
            name: "Report",
            url: `${BasePaths.WAREHOUSE}/reports`,
          },
        ]
      },
      {
        name: "Search Box",
        url: `${BasePaths.WAREHOUSE}/search_box`,
      },
      {
        name: "Reconcile Stock at Location",
        child: [
          {
            name: "FG Store",
            url: `${BasePaths.RECONCILESTOCKATLOCATION}/store`,
          },
          {
            name: "Product Loaded on Vehicle",
            url: `${BasePaths.RECONCILESTOCKATLOCATION}/loaded-vehicle`,
          },
          {
            name: "Master Carton Packaging",
            url: `${BasePaths.RECONCILESTOCKATLOCATION}/master-carton`,
          },
        ],
      },
      {
        name: "Serial Number Traceability",
        child: [
          {
            name: "Summary Report",
            url: `${BasePaths.SERIALNUMBERTRACEABILITY}/summary-report`,
          },
          {
            name: "Detailed Report",
            url: `${BasePaths.SERIALNUMBERTRACEABILITY}/detailed-report`,
          },
        ],
      },
    ],
  },
];
