import { indentFormConstants, errorAndLoadingConstants } from "../constants";
import { indentFormServices } from "../services";
import { requestFailError } from "../utils";

// use this function to call reducer to handle alert
const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

const fetchDepartments = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchDepartments().then(
      (departments) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (departments.status === 200) {
          dispatch({
            type: indentFormConstants.GET_DEPARTMENT_SUCCESS,
            data: departments.data.departments,
          });
        } else {
          const errMsg = requestFailError(
            departments,
            "Error getting Departments"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_DEPARTMENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_DEPARTMENT_FAILURE,
        });
      }
    );
  };
};

const fetchItems = () => {
  return async (dispatch) => {
    dispatch({
      type: indentFormConstants.GET_ITEMS_REQUEST,
    });
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchItems().then(
      (items) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (items.status === 200) {
          dispatch({
            type: indentFormConstants.GET_ITEMS_SUCCESS,
            data: items.data.items,
          });
        } else {
          const errMsg = requestFailError(items, "Error getting Items");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_ITEMS_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatch({
          type: indentFormConstants.GET_ITEMS_FAILURE,
          data: error,
        });
      }
    );
  };
};

const fetchItemDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchItemDetails(id).then(
      (item) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (item.status === 200) {
          dispatch({
            type: indentFormConstants.GET_ITEM_DETAILS_SUCCESS,
            data: item.data.item,
          });
        } else {
          const errMsg = requestFailError(item, "Error getting Item Details");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_ITEM_DETAILS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_ITEM_DETAILS_FAILURE,
        });
      }
    );
  };
};

const fetchVendors = (item_id) => {
  return async (dispatch) => {
    dispatch({
      type: indentFormConstants.GET_VENDOR_REQUEST,
    });
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchVendors(item_id).then(
      (vendors) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vendors.status === 200) {
          dispatch({
            type: indentFormConstants.GET_VENDOR_SUCCESS,
            data: vendors.data.vendors,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatch({
          type: indentFormConstants.GET_VENDOR_FAILURE,
          data: error,
        });
      }
    );
  };
};

const fetchVendorDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchVendorDetails(id).then(
      (vendor) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vendor.status === 200) {
          dispatch({
            type: indentFormConstants.GET_VENDOR_DETAILS_SUCCESS,
            data: vendor.data.vendor,
          });
        } else {
          const errMsg = requestFailError(
            vendor,
            "Error getting vendor details"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_VENDOR_DETAILS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_VENDOR_DETAILS_FAILURE,
        });
      }
    );
  };
};

const fetchSkus = (item_id, vendor_id) => {
  return async (dispatch) => {
    dispatch({
      type: indentFormConstants.GET_SKU_REQUEST,
    });
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchSkus(item_id, vendor_id).then(
      (sku) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sku.status === 200) {
          dispatch({
            type: indentFormConstants.GET_SKU_SUCCESS,
            data: sku.data.item_skus,
          });
        } else {
          const errMsg = requestFailError(sku, "Error getting SKUs");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_SKU_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_SKU_FAILURE,
        });
      }
    );
  };
};

const fetchEmployee = () => {
  return async (dispatch) => {
    await indentFormServices.fetchEmployee().then(
      (employees) => {
        if (employees.status === 200) {
          dispatch({
            type: indentFormConstants.GET_EMPLOYEES_SUCCESS,
            data: employees.data.employees,
          });
        }
      },
      (err) => {
        dispatch({
          type: indentFormConstants.GET_EMPLOYEES_FAILURE,
          data: err,
        });
      }
    );
  };
};

const fetchEmployeeByRole = (role) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchEmployeeByRole(role).then(
      (employees) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (employees.status === 200) {
          dispatch({
            type: indentFormConstants.GET_EMPLOYEES_BY_ROLE_SUCCESS,
            data: employees.data.employees,
            role,
          });
        } else {
          const errMsg = requestFailError(
            employees,
            `Error getting employees for role: ${role}`
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_EMPLOYEES_BY_ROLE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_EMPLOYEES_BY_ROLE_FAILURE,
        });
      }
    );
  };
};

const saveIndent = (requestBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.saveIndent(requestBody).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.SAVE_INDENT_SUCCESS,
            data: indent.data.indents,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: `New Indent(s) created successfully. \n
             IDN Number: ${indent.data.indents[0].generated_idn_number}`,
          });
        } else {
          const errMsg = requestFailError(indent, "Cannot create indent");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.SAVE_INDENT_FAILURE,
          });
          throw new Error(errMsg);
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.SAVE_INDENT_FAILURE,
        });
      }
    );
  };
};

const updateIndent = (requestBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.updateIndent(requestBody, id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.UPDATE_INDENT_SUCCESS,
            data: indent.data.workcentre,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Indent successfully updated",
          });
        } else {
          const errMsg = requestFailError(indent, "Cannot update indent");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.UPDATE_INDENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.UPDATE_INDENT_FAILURE,
        });
      }
    );
  };
};

const assignSKU = (sku_id, order_type, indent_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.assignSKU(sku_id, order_type, indent_id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.ASSIGN_SKU_SUCCESS,
            data: indent.data.indents,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "SKU assigned successfully.",
          });
        } else {
          const errMsg = requestFailError(indent, "Cannot assign indent");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.ASSIGN_SKU_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.ASSIGN_SKU_FAILURE,
        });
      }
    );
  };
};

const getManufacturingCompanies = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.getManufacturingCompanies().then(
      (manufacturingCompanies) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (manufacturingCompanies.status === 200) {
          dispatch({
            type: indentFormConstants.GET_MANUFACTURING_COMPANIES_SUCCESS,
            data: manufacturingCompanies.data.manufacturing_companies,
          });
        } else {
          const errMsg = requestFailError(
            manufacturingCompanies,
            "Error getting Manufacturing Companies"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_MANUFACTURING_COMPANIES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_MANUFACTURING_COMPANIES_FAILURE,
        });
      }
    );
  };
};

const fetchManufacturingCompany = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchManufacturingCompany(id).then(
      (item) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (item.status === 200) {
          dispatch({
            type: indentFormConstants.GET_MANUFACTURING_COMPANY_SUCCESS,
            data: item.data.manufacturing_company,
          });
        } else {
          const errMsg = requestFailError(item, "Error getting Item Details");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_MANUFACTURING_COMPANY_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_MANUFACTURING_COMPANY_FAILURE,
        });
      }
    );
  };
};

const fetchIndentDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.fetchIndentDetail(id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.GET_PO_INDENT_SUCCESS,
            data: indent.data.indent,
          });
        } else {
          const errMsg = requestFailError(indent, "Error getting Item Details");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.GET_PO_INDENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.GET_PO_INDENT_FAILURE,
        });
      }
    );
  };
};

const unblockSKU = (item_sku_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.unblockSKU(item_sku_id).then(
      (sku) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sku.status === 200) {
          dispatch({
            type: indentFormConstants.UNBLOCK_SKU_SUCCESS,
            data: sku.data.item_sku,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.UNBLOCK_SKU_FAILURE,
        });
      }
    );
  };
};

const createSKU = (vendor_id, item_id, manufacturing_companies_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices
      .createSKU(vendor_id, item_id, manufacturing_companies_id)
      .then(
        (sku) => {
          dispatch({
            type: errorAndLoadingConstants.API_CALL_STOP,
          });
          if (sku.status === 200) {
            dispatch({
              type: indentFormConstants.CREATE_SKU_SUCCESS,
              data: sku.data.item_sku,
            });
            dispatch({
              type: errorAndLoadingConstants.OPEN_POPUP,
              message: "SKU created successfully.",
            });
          } else {
            const errMsg = requestFailError(sku, "Cannot create SKU");
            dispatchFailure(dispatch, errMsg, {
              type: indentFormConstants.CREATE_SKU_FAILURE,
            });
          }
        },
        (err) => {
          dispatch({
            type: errorAndLoadingConstants.API_CALL_STOP,
          });
          dispatchFailure(dispatch, err, {
            type: indentFormConstants.CREATE_SKU_FAILURE,
          });
        }
      );
  };
};

const createConsignments = (requestBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.createConsignments(requestBody).then(
      (consignments) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (consignments.status === 200) {
          dispatch({
            type: indentFormConstants.CREATE_CONSIGNMENTS_SUCCESS,
            data: consignments.data.consignments,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Consignment successfully created",
          });
        } else {
          const errMsg = requestFailError(
            consignments,
            "Cannot create consignment"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.CREATE_CONSIGNMENTS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.CREATE_CONSIGNMENTS_FAILURE,
        });
      }
    );
  };
};


const forceCloseConsignment = (requestBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.forceCloseConsignment(requestBody).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: indentFormConstants.CLOSE_CONSIGNMENT_SUCCESS,
            data: response.data,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Consignment Closed Successfully",
          });
        } else {
          const errMsg = requestFailError(
            response,
            "Cannot closed consignment"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.CLOSE_CONSIGNMENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.CLOSE_CONSIGNMENT_FAILURE,
        });
      }
    );
  };
};

const closeConsignment = (requestBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.closeConsignment(requestBody).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: indentFormConstants.CLOSE_CONSIGNMENT_SUCCESS,
            data: response.data,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: `New Indent created successfully. \n
             IDN Number: ${response.data.idn_number}`,
          });
        } else {
          const errMsg = requestFailError(
            response,
            "Cannot closed consignment"
          );
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.CLOSE_CONSIGNMENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.CLOSE_CONSIGNMENT_FAILURE,
        });
      }
    );
  };
};

const rejectIndent = (id, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.rejectIndent(id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.REJECT_INDENT_SUCCESS,
            data: indent.data.indents,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Indent successfully rejected.",
          });
        } else {
          const errMsg = requestFailError(indent);
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.REJECT_INDENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.REJECT_INDENT_FAILURE,
        });
      }
    );
  };
};

const holdIndent = (id, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.holdIndent(id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.HOLD_INDENT_SUCCESS,
            data: indent.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Indent put on hold successfully.",
          });
        } else {
          const errMsg = requestFailError(indent);
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.HOLD_INDENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.HOLD_INDENT_FAILURE,
        });
      }
    );
  };
};

const reviveIndent = (id, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.reviveIndent(id).then(
      (indent) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (indent.status === 200) {
          dispatch({
            type: indentFormConstants.REVIVE_INDENT_SUCCESS,
            data: indent.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Indent successfully Revived.",
          });
        } else {
          const errMsg = requestFailError(indent);
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.REVIVE_INDENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.REVIVE_INDENT_FAILURE,
        });
      }
    );
  };
};

const updateItem = (id, item_id, qty) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.updateItem(id, item_id, qty).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: indentFormConstants.UPDATE_ITEM_SUCCESS,
            data: response.data.indents,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Item successfully updated",
          });
        } else {
          const errMsg = requestFailError(response, "Error updating Item");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.UPDATE_ITEM_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.UPDATE_ITEM_FAILURE,
        });
      }
    );
  };
};

const saveUnitPrice = (id, unit_price) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await indentFormServices.saveUnitPrice(id, unit_price).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: indentFormConstants.UPDATE_UNIT_PRICE_SUCCESS,
            data: response.data.indents,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Unit Price successfully updated",
          });
        } else {
          const errMsg = requestFailError(response, "Error updating Price");
          dispatchFailure(dispatch, errMsg, {
            type: indentFormConstants.UPDATE_UNIT_PRICE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: indentFormConstants.UPDATE_UNIT_PRICE_FAILURE,
        });
      }
    );
  };
};

export const indentFormActions = {
  fetchDepartments,
  fetchItems,
  fetchItemDetails,
  fetchVendors,
  fetchSkus,
  fetchEmployee,
  saveIndent,
  updateIndent,
  assignSKU,
  getManufacturingCompanies,
  fetchManufacturingCompany,
  unblockSKU,
  createSKU,
  createConsignments,
  fetchEmployeeByRole,
  fetchVendorDetails,
  rejectIndent,
  holdIndent,
  fetchIndentDetail,
  reviveIndent,
  updateItem,
  saveUnitPrice,
  forceCloseConsignment,
  closeConsignment,
};
