import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const addComment = (reqBody) => {
  return axios
    .post("/api/v1/comments", JSON.stringify(reqBody), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const commentService = {
  addComment,
};
