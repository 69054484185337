import { stockConstants } from "../constants";

export const stock = (state = [], action) => {
  switch (action.type) {
    case stockConstants.GET_STOCK_SUCCESS:
      return action.data;

    case stockConstants.GET_STOCK_FAILURE:
      return [];

    default:
      return state;
  }
};
