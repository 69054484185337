import React, { lazy } from "react";
import RoutesWrapper from "../components/RoutesWrapper";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { BasePaths } from "../utils";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const CreateCustomer = lazy(() => import("./pages/CreateCustomer"));
const Report = lazy(() => import("./pages/Report"))
const SOProductReport = lazy(() => import("./pages/SOProductReport"))

const baseRoute = BasePaths.SALESDISPATCH;

const SalesAndDispatchRoutes = () => {
  return (
    <RoutesWrapper>
      <ProtectedRoutes path={`${baseRoute}/create-customer`}>
        <CreateCustomer />
      </ProtectedRoutes>
      <ProtectedRoutes path={`${baseRoute}/d/`}>
        <Dashboard />
      </ProtectedRoutes>
      <ProtectedRoutes path={`${baseRoute}/reports/`}>
        <Report />
      </ProtectedRoutes>
      <ProtectedRoutes path={`${baseRoute}/sales-order-product-report/`}>
        <SOProductReport />
      </ProtectedRoutes>
    </RoutesWrapper>
  );
};

export default SalesAndDispatchRoutes;
