import axios from "axios";
import { saveAs } from "file-saver";
import { requestFailError } from "../utils";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getReport = (reportName) => {
  return axios
    .get(`/api/v1/reports/${reportName}`)
    .then((response) => response)
    .catch((err) => err);
};

const downloadReport = (id) => {
  return axios
    .get(`/api/v1/reports/export_${id}`, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      const blog = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const contentDisposition = response.headers["content-disposition"];
      let filename = "report";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          filename = fileNameMatch[1];
        }
      }
      saveAs(blog, filename);
    })
    .catch((err) => {
      throw new Error(requestFailError(err));
    });
};

export const reportService = {
  downloadReport,
  getReport,
};
