import { indentFormConstants } from "../constants";

export function indentForm(
  state = {
    departments: [],
    items: [],
    vendors: [],
    skus: [],
    employees: {
      allEmployees: [],
      fill_requisition: [],
      approve_requisition: [],
      final_approve_requisition: [],
      receive_requisition: [],
    },
    manufacturingCompanies: [],
    itemDetail: {},
    vendorDetail: {},
    mcDetail: {},
    poIndent: {},
  },
  action
) {
  switch (action.type) {
    case indentFormConstants.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: action.data,
      };

    case indentFormConstants.GET_ITEMS_REQUEST:
      return {
        ...state,
        items: [],
      };

    case indentFormConstants.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.data,
      };

    case indentFormConstants.GET_VENDOR_REQUEST:
      return {
        ...state,
        vendors: [],
      };

    case indentFormConstants.GET_VENDOR_SUCCESS:
      return {
        ...state,
        vendors: action.data,
      };

    case indentFormConstants.GET_SKU_REQUEST:
      return {
        ...state,
        skus: [],
      };

    case indentFormConstants.GET_SKU_SUCCESS:
      return {
        ...state,
        skus: action.data,
      };

    case indentFormConstants.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: {
          ...state.employees,
          allEmployees: action.data,
        },
      };

    case indentFormConstants.GET_EMPLOYEES_BY_ROLE_SUCCESS:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.role]: action.data,
        },
      };

    case indentFormConstants.GET_MANUFACTURING_COMPANIES_SUCCESS:
      return {
        ...state,
        manufacturingCompanies: action.data,
      };

    case indentFormConstants.CREATE_SKU_SUCCESS:
      return {
        ...state,
        skus: [action.data, ...state.skus],
      };

    case indentFormConstants.UNBLOCK_SKU_SUCCESS:
      return {
        ...state,
        skus: state.skus.map((sku) =>
          sku.id === action.data.id ? action.data : sku
        ),
      };

    case indentFormConstants.GET_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          [action.data.id]: action.data,
        },
      };

    case indentFormConstants.GET_VENDOR_DETAILS_SUCCESS:
      return {
        ...state,
        vendorDetail: {
          ...state.vendorDetail,
          [action.data.id]: action.data,
        },
      };

    case indentFormConstants.GET_MANUFACTURING_COMPANY_SUCCESS:
      return {
        ...state,
        mcDetail: {
          ...state.mcDetail,
          [action.data.id]: action.data,
        },
      };

    case indentFormConstants.GET_PO_INDENT_SUCCESS:
      return {
        ...state,
        poIndent: {
          ...state.poIndent,
          [action.data.id]: action.data,
        },
      };

    case indentFormConstants.CLOSE_CONSIGNMENT_SUCCESS:
      if(action.data.final_consignments_status)
        setTimeout(() => {window.location.reload()}, 2000);
      const indent_id = action.data.indent_id;
      return {
        ...state,
        poIndent: { 
          ...state.poIndent,
          [indent_id] : {
            ...state.poIndent[indent_id],
            consignments : state.poIndent[indent_id].consignments.map((consignment)=>
              consignment.id === action.data.consignment.id
              ?  action.data.consignment
              : consignment
            )
          }
        }                 
      };

    case indentFormConstants.UNBLOCK_SKU_FAILURE:
    case indentFormConstants.CREATE_SKU_FAILURE:
    case indentFormConstants.CLOSE_CONSIGNMENT_FAILURE:
    case indentFormConstants.GET_MANUFACTURING_COMPANIES_FAILURE:
    case indentFormConstants.GET_EMPLOYEES_FAILURE:
    case indentFormConstants.GET_SKU_FAILURE:
    case indentFormConstants.GET_VENDOR_FAILURE:
    case indentFormConstants.GET_ITEMS_FAILURE:
    case indentFormConstants.GET_DEPARTMENT_FAILURE:
    case indentFormConstants.GET_EMPLOYEES_BY_ROLE_FAILURE:
    case indentFormConstants.GET_ITEM_DETAILS_FAILURE:
    case indentFormConstants.GET_VENDOR_DETAILS_FAILURE:
    case indentFormConstants.GET_MANUFACTURING_COMPANY_FAILURE:
    case indentFormConstants.GET_PO_INDENT_FAILURE:
      return state;

    default:
      return state;
  }
}
