import { countryConstants } from "../constants/country.constants";

export function countryData(
  state = {
    countries: [],
    states: [],
    cities: [],
    districts: [],
  },
  action
) {
  switch (action.type) {
    case countryConstants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data,
      };

    case countryConstants.GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.data,
      };

    case countryConstants.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.data,
      };

    case countryConstants.GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.data,
      };

    case countryConstants.GET_COUNTRIES_REQUEST:
    case countryConstants.GET_STATES_REQUEST:
    case countryConstants.GET_CITIES_REQUEST:
    case countryConstants.GET_DISTRICTS_REQUEST:
    case countryConstants.GET_COUNTRIES_FAILURE:
    case countryConstants.GET_STATES_FAILURE:
    case countryConstants.GET_CITIES_FAILURE:
    case countryConstants.GET_DISTRICTS_FAILURE:
    default:
      return state;
  }
}
