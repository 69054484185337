import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PMSAlerts from "../../PMS/components/PMSAlerts";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const RoutesWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          {children}
        </Suspense>
      </Switch>
      <PMSAlerts />
    </>
  );
};

export default RoutesWrapper;
