export default {
  head: {
    backgroundColor: "#e1e2e7",
    color: "#A3A6B4",
    fontSize: 14,
  },
  body: {
    fontSize: 14,
  },
  sizeSmall: {
    padding: 6,
  },
};
