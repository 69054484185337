import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const sendAssemblyTest = (body) => {
  return axios
    .post(`/api/v1/rotor_stator_testings`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getPlanByType = (type, date) => {
  return axios
    .post(
      "/api/v1/plans/get_plan_by_date_and_type",
      JSON.stringify({
        type,
        date,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response)
    .catch((err) => err);
};

const getFailureReasonType = (type) => {
  return axios
    .get(`/api/v1/failure_reasons?type=${type}`)
    .then((response) => response)
    .catch((err) => err);
};

export const assemblyTestingService = {
  sendAssemblyTest,
  getPlanByType,
  getFailureReasonType,
};
