export default {
  primary: {
    main: "#295EB2",
  },
  success: {
    main: "#0B9F55",
  },
  error: {
    main: "#FF6565",
  },
  pms: {
    gray: "#4D4F5C",
    gray1: "#F5F6FA",
  },
  blue: {
    main: "#E5F2FF",
  },
  divider: "#F1F1F3",
};
