import { makeStyles } from "@material-ui/core/styles";
const useStyle = makeStyles(() => ({
  dInline: {
    display: "inline-block",
    marginRight: 5,
  },
  linkEllipises: {
    width: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stageContainer: {
    display: "flex",
    overflowX: "scroll",
    height: "calc(100vh - 140px)",
  },
  StgTitle: {
    fontSize: 15,
    color: "#4D4F5C",
    textTransform: "uppercase",
  },
  stageBox: {
    padding: 15,
  },
  darkBG: {
    background: "#F8F8F8",
  },
  lightBG: {
    background: "#fff",
  },
}));

export default useStyle;
