import { errorAndLoadingConstants, vehicleConstants } from "../constants";
import { vehicleService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const saveVehicle = (requestData) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: vehicleConstants.SAVE_VEHICLE_REQUEST,
    });
    await vehicleService.saveVehicle(requestData).then(
      (vehicle) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vehicle.status === 200) {
          dispatch({
            type: vehicleConstants.SAVE_VEHICLE_SUCCESS,
            data: vehicle.data.vehicle_information,
          });
          if (vehicle?.data?.vehicle_information?.vehicle_number) {
            dispatch({
              type: errorAndLoadingConstants.OPEN_POPUP,
              message: `Your Vehicle number: ${
                vehicle?.data?.vehicle_information?.vehicle_number ?? ""
              } added`,
            });
          }
        } else {
          const errMsg = requestFailError(vehicle, "Error Creating Vechile");
          dispatchFailure(dispatch, errMsg, {
            type: vehicleConstants.SAVE_VEHICLE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vehicleConstants.SAVE_VEHICLE_FAILURE,
        });
      }
    );
  };
};

const getAllVehicle = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: vehicleConstants.GET_VEHICLE_LIST_REQUEST,
    });
    await vehicleService.getAllVehicle().then(
      (vehicles) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vehicles.status === 200) {
          dispatch({
            type: vehicleConstants.GET_VEHICLE_LIST_SUCCESS,
            data: vehicles.data.vehicles,
          });
        } else {
          const errMsg = requestFailError(
            vehicles,
            "Error getting Vehicle List"
          );
          dispatchFailure(dispatch, errMsg, {
            type: vehicleConstants.GET_VEHICLE_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vehicleConstants.GET_VEHICLE_LIST_FAILURE,
        });
      }
    );
  };
};

const getDetailedVehicle = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: vehicleConstants.GET_DETAILED_VEHICLE_REQUEST,
    });
    await vehicleService.getDetailedVehicle(id).then(
      (vehicle) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vehicle.status === 200) {
          dispatch({
            type: vehicleConstants.GET_DETAILED_VEHICLE_SUCCESS,
            data: vehicle.data.vehicle_information,
          });
        } else {
          const errMsg = requestFailError(
            vehicle,
            "Error getting Vehicle Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: vehicleConstants.GET_DETAILED_VEHICLE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vehicleConstants.GET_DETAILED_VEHICLE_FAILURE,
        });
      }
    );
  };
};

const getStations = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: vehicleConstants.GET_STATIONS_REQUEST,
    });
    await vehicleService.getStations().then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: vehicleConstants.GET_STATIONS_SUCCESS,
            data: res.data.station_ids,
          });
        } else {
          const errMsg = requestFailError(res, "Error getting Station List");
          dispatchFailure(dispatch, errMsg, {
            type: vehicleConstants.GET_STATIONS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vehicleConstants.GET_STATIONS_FAILURE,
        });
      }
    );
  };
};

export const vehicleActions = {
  saveVehicle,
  getAllVehicle,
  getDetailedVehicle,
  getStations,
};
