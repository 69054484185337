export const indentFormConstants = {
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_FAILURE: "GET_DEPARTMENT_FAILURE",

  GET_ITEMS_REQUEST: "GET_ITEMS_REQUEST",
  GET_ITEMS_SUCCESS: "GET_ITEMS_SUCCESS",
  GET_ITEMS_FAILURE: "GET_ITEMS_FAILURE",

  GET_ITEM_DETAILS_SUCCESS: "GET_ITEM_DETAILS_SUCCESS",
  GET_ITEM_DETAILS_FAILURE: "GET_ITEM_DETAILS_FAILURE",

  GET_VENDOR_REQUEST: "GET_VENDOR_REQUEST",
  GET_VENDOR_SUCCESS: "GET_VENDOR_SUCCESS",
  GET_VENDOR_FAILURE: "GET_VENDOR_FAILURE",

  GET_VENDOR_DETAILS_SUCCESS: "GET_VENDOR_DETAILS_SUCCESS",
  GET_VENDOR_DETAILS_FAILURE: "GET_VENDOR_DETAILS_FAILURE",

  GET_SKU_REQUEST: "GET_SKU_REQUEST",
  GET_SKU_SUCCESS: "GET_SKU_SUCCESS",
  GET_SKU_FAILURE: "GET_SKU_FAILURE",

  GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
  GET_EMPLOYEES_FAILURE: "GET_EMPLOYEES_FAILURE",

  GET_EMPLOYEES_BY_ROLE_SUCCESS: "GET_EMPLOYEES_BY_ROLE_SUCCESS",
  GET_EMPLOYEES_BY_ROLE_FAILURE: "GET_EMPLOYEES_BY_ROLE_FAILURE",

  SAVE_INDENT_SUCCESS: "SAVE_INDENT_SUCCESS",
  SAVE_INDENT_FAILURE: "SAVE_INDENT_FAILURE",

  UPDATE_INDENT_SUCCESS: "UPDATE_INDENT_SUCCESS",
  UPDATE_INDENT_FAILURE: "UPDATE_INDENT_FAILURE",

  ASSIGN_SKU_SUCCESS: "ASSIGN_SKU_SUCCESS",
  ASSIGN_SKU_FAILURE: "ASSIGN_SKU_FAILURE",

  GET_MANUFACTURING_COMPANIES_SUCCESS: "GET_MANUFACTURING_COMPANIES_SUCCESS",
  GET_MANUFACTURING_COMPANIES_FAILURE: "GET_MANUFACTURING_COMPANIES_FAILURE",

  GET_MANUFACTURING_COMPANY_SUCCESS: "GET_MANUFACTURING_COMPANY_SUCCESS",
  GET_MANUFACTURING_COMPANY_FAILURE: "GET_MANUFACTURING_COMPANY_FAILURE",

  GET_PO_INDENT_SUCCESS: "GET_PO_INDENT_SUCCESS",
  GET_PO_INDENT_FAILURE: "GET_PO_INDENT_FAILURE",

  CREATE_SKU_SUCCESS: "CREATE_SKU_SUCCESS",
  CREATE_SKU_FAILURE: "CREATE_SKU_FAILURE",

  UNBLOCK_SKU_SUCCESS: "UNBLOCK_SKU_SUCCESS",
  UNBLOCK_SKU_FAILURE: "UNBLOCK_SKU_FAILURE",

  CREATE_CONSIGNMENTS_SUCCESS: "CREATE_CONSIGNMENTS_SUCCESS",
  CREATE_CONSIGNMENTS_FAILURE: "CREATE_CONSIGNMENTS_FAILURE",

  CLOSE_CONSIGNMENT_SUCCESS: "CLOSE_CONSIGNMENT_SUCCESS",
  CLOSE_CONSIGNMENT_FAILURE: "CLOSE_CONSIGNMENT_FAILURE",

  REJECT_INDENT_SUCCESS: "REJECT_INDENT_SUCCESS",
  REJECT_INDENT_FAILURE: "REJECT_INDENT_FAILURE",

  HOLD_INDENT_SUCCESS: "HOLD_INDENT_SUCCESS",
  HOLD_INDENT_FAILURE: "HOLD_INDENT_FAILURE",

  REVIVE_INDENT_SUCCESS: "REVIVE_INDENT_SUCCESS",
  REVIVE_INDENT_FAILURE: "REVIVE_INDENT_FAILURE",

  UPDATE_ITEM_SUCCESS: "UPDATE_ITEM_SUCCESS",
  UPDATE_ITEM_FAILURE: "UPDATE_ITEM_FAILURE",

  UPDATE_UNIT_PRICE_SUCCESS: "UPDATE_UNIT_PRICE_SUCCESS",
  UPDATE_UNIT_PRICE_FAILURE: "UPDATE_UNIT_PRICE_FAILURE",
};
