import { userConstants } from "../constants";

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return state;

    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccessful: true,
      };

    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginSuccessful: false,
      };

    case userConstants.LOGGED_IN_REQUEST:
      return state;

    case userConstants.LOGGED_IN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.user.user,
      };

    case userConstants.LOGGED_IN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
      };

    case userConstants.LOGOUT:
      return {
        isLoggedIn: false,
      };

    default:
      return state;
  }
}
