import { assemblyListConstant } from "../constants";

export function assemblyList(state = {}, action) {
  switch (action.type) {
    case assemblyListConstant.GET_ASSEMBLYLIST_REQUEST:
    case assemblyListConstant.GET_ASSEMBLYLIST_FAILURE:
      return {};

    case assemblyListConstant.EDIT_ASSEMBLYLIST_SUCCESS:
    case assemblyListConstant.GET_ASSEMBLYLIST_SUCCESS:
      const workstation = action.data.assembly.reduce(
        (acc, station) => [...acc, ...station.stations],
        []
      );
      return { ...action.data, workstation };

    default:
      return state;
  }
}
