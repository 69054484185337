export const customerConstants = {
  SAVE_CUSTOMER_REQUEST: "SAVE_CUSTOMER_REQUEST",
  SAVE_CUSTOMER_SUCCESS: "SAVE_CUSTOMER_SUCCESS",
  SAVE_CUSTOMER_FAILURE: "SAVE_CUSTOMER_FAILURE",

  GET_SALES_CUSTOMER_REQUEST: "GET_SALES_CUSTOMER_REQUEST",
  GET_SALES_CUSTOMER_SUCCESS: "GET_SALES_CUSTOMER_SUCCESS",
  GET_SALES_CUSTOMER_FAILURE: "GET_SALES_CUSTOMER_FAILURE",

  GET_DETAILED_CUSTOMER_REQUEST: "GET_DETAILED_CUSTOMER_REQUEST",
  GET_DETAILED_CUSTOMER_SUCCESS: "GET_DETAILED_CUSTOMER_SUCCESS",
  GET_DETAILED_CUSTOMER_FAILURE: "GET_DETAILED_CUSTOMER_FAILURE",
};
