import React from "react";

import Cards from "../Cards/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import useStyle from "./Style";

const Grid = ({
  grid,
  handleDrop,
  invalidDrop,
  stopDropAlert,
  indentSearch,
}) => {
  const classes = useStyle();

  let bottleNeckStep = null,
    maxJobs = 0,
    prevTat = 0;
  if (grid.id) {
    for (let i = 0; i < grid.stages.length; i++) {
      for (let j = 0; j < grid.stages[i].steps.length; j++) {
        for (let k = 0; k < grid.stages[i].steps[j].workcentres.length; k++) {
          if (grid.stages[i].steps[j].workcentres[k].jobs.length > maxJobs) {
            bottleNeckStep = grid.stages[i].steps[j].id;
            maxJobs = grid.stages[i].steps[j].workcentres[k].jobs.length;
            prevTat = grid.stages[i].steps[j].workcentres[k].tat_in_mins;
          } else if (
            grid.stages[i].steps[j].workcentres[k].jobs.length === maxJobs
          ) {
            if (grid.stages[i].steps[j].workcentres[k].tat_in_mins > prevTat) {
              bottleNeckStep = grid.stages[i].steps[j].id;
              prevTat = grid.stages[i].steps[j].workcentres[k].tat_in_mins;
            }
          }
        }
      }
    }
  }

  return (
    grid.id && (
      <>
        {grid.stages.map(
          (stage, index) =>
            stage.steps.length > 0 && (
              <Box
                className={clsx(
                  classes.stageBox,
                  index % 2 === 0 ? classes.lightBG : classes.darkBG
                )}
                p={1}
                key={stage.id}
              >
                <Typography
                  className={classes.StgTitle}
                  title={stage.name ? stage.name : "Stage Name"}
                >
                  {stage.name ? stage.name : "Name"}
                </Typography>
                <Cards
                  steps={stage.steps}
                  stage_id={stage.id}
                  stage_index={index}
                  handleDrop={handleDrop}
                  invalidDrop={invalidDrop}
                  stopDropAlert={stopDropAlert}
                  bottleNeckStep={bottleNeckStep}
                  indentSearch={indentSearch}
                />
              </Box>
            )
        )}
      </>
    )
  );
};

export default Grid;
