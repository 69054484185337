import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./Css";

const SubTaskTopography = ({ subtitle1, subtitle2 }) => {
  const classes = useStyles();
  return (
    <div className={classes.CTasks}>
      <Typography className={classes.subtitle1} variant="subtitle1">
        {subtitle1}
      </Typography>
      <Typography className={classes.subtitle2} variant="subtitle2">
        {subtitle2}
      </Typography>
    </div>
  );
};

export default SubTaskTopography;
