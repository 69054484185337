import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./containers/Login/Login";
import ChangePassword from "./containers/Login/ChangePassword"; // eslint-disable-line
import RequestResetPasswd from "./containers/Login/RequestResetPasswd"; // eslint-disable-line
import Navigation from "./containers/Navigation/Navigation";
import FlowView from "./containers/FlowView/FlowView";
import Reports from "./containers/Cards/Reports/Reports";
import MyView from "./containers/FlowView/MyView";
import PMSRoutes from "./PMS/PMSRoutes";
import { AuthRoutes, ProtectedRoutes } from "./ProtectedRoutes";
import FinalAssemblyRoutes from "./FinalAssembly/FinalAssemblyRoutes";
import JarAssemblyRoutes from "./JarAssembly/JarAssemblyRoutes";
import MouldingRoutes from "./Moulding/MouldingRoutes";
import OEMRoutes from "./OEM/OEMRoutes";
import SalesAndDispatchRoutes from "./SalesAndDispatch/SalesAndDispatchRoutes";
import QualityRoutes from "./QualityAndInventory/QualityRoutes";
import WarehouseRoutes from "./Warehouse/WarehouseRoutes";
import ReconcileStockAtLocationRoutes from "./ReconcileStockAtLocation/ReconcileStockAtLocationRoutes";
import SerialNumberTraceabilityRoutes from "./SerialNumberTraceability/SerialNumberTraceabilityRoutes";
import { BasePaths } from "./utils";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <AuthRoutes path="/login" exact>
        <Login />
      </AuthRoutes>
      {/* Uncomment this code enable change password and resert password routes */}

      {/* <Route path="/change_password" exact>
        <ChangePassword />
      </Route>
      <Route path="/request_reset_passwd" exact>
        <RequestResetPasswd />
      </Route> */}
      {/* <ProtectedRoutes path="/flowview">
        <Navigation children={FlowView} showChild={false} />
      </ProtectedRoutes> */}
      <ProtectedRoutes path="/reports" exact>
        <Navigation children={Reports} showChild={false} />
      </ProtectedRoutes>
      {/* <ProtectedRoutes path="/myview">
        <Navigation children={MyView} showChild={false} />
      </ProtectedRoutes> */}
      <Navigation showChild={true}>
        <Route path="/pms">
          <PMSRoutes />
        </Route>
        <Route path="/finalassembly">
          <FinalAssemblyRoutes />
        </Route>
        <Route path="/jarassembly">
          <JarAssemblyRoutes />
        </Route>
        <Route path="/OEM">
          <OEMRoutes />
        </Route>
        <Route path="/moulding">
          <MouldingRoutes />
        </Route>

        <Route path={BasePaths.SALESDISPATCH}>
          <SalesAndDispatchRoutes />
        </Route>
        <Route path={BasePaths.QUALITY}>
          <QualityRoutes />
        </Route>
        <Route path={BasePaths.WAREHOUSE}>
          <WarehouseRoutes />
        </Route>
        <Route path={BasePaths.RECONCILESTOCKATLOCATION}>
          <ReconcileStockAtLocationRoutes />
        </Route>
        <Route path={BasePaths.SERIALNUMBERTRACEABILITY}>
          <SerialNumberTraceabilityRoutes />
        </Route>
      </Navigation>
    </Switch>
  );
}

const mapState = (state) => {
  const isLoggedIn = state.user.isLoggedIn;
  return { isLoggedIn };
};

export default connect(mapState, null)(Routes);
