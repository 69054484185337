import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PMSAlert from "../PMS/components/PMSAlerts";
import { BasePaths } from "../utils";

const IncomingQualityReport = lazy(() =>
  import("./pages/IncomingQualityReport")
);

const baseRoute = BasePaths.QUALITY;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PMSRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/reports/:report_name/`}>
            <IncomingQualityReport />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlert />
    </>
  );
};

export default PMSRoutes;
