import React, { memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

import Tasks from "./Tasks";
import CustomChip from "./CustomChip";
import StepTopography from "./StepTopography";
import { humanizeFormat } from "../../utils/humanizeFormat";

import { useStyles } from "./Css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { downloadAction } from "../../actions";
import { formattedNumber } from "../../utils";

const StepsContainer = ({
  step,
  step_index,
  handleDrop,
  stage_index,
  stage_id,
  invalidDrop,
  stopDropAlert,
  isBottleneck,
  indentSearch,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 200,
    })
  );

  const [, drop] = useDrop({
    accept: "JOB",
    drop: (item) => {
      if (step.workcentres.length === 0) {
        invalidDrop("Sorry, cannot move this job");
        stopDropAlert();
        return;
      }
      if (item.from_step_id === step.id) return;
      if (!step.workcentres[0].accountable_id) return;
      if (
        ["wc1", "wc2"].includes(item.from_workcentre_name) &&
        step.workcentres[0].name !== "wc1" &&
        item.noVendor === true
      ) {
        invalidDrop("Cannot move without assigning vendor");
        stopDropAlert();
        return;
      }
      if (
        ["wc1", "wc2", "wc3", "wc4", "wc5", "wc6"].includes(
          item.from_workcentre_name
        ) &&
        !["wc1", "wc2", "wc3", "wc4", "wc5", "wc6"].includes(
          step.workcentres[0].name
        ) &&
        item.noConsignments === true
      ) {
        invalidDrop("Cannot move without adding consignments");
        stopDropAlert();
        return;
      }
      handleDrop({
        job_id: item.job_id,
        from_step_id: item.from_step_id,
        from_step_index: item.from_step_index,
        to_step_id: step.id,
        to_step_index: step_index,
        from_stage_id: item.from_stage_id,
        from_stage_index: item.from_stage_index,
        to_stage_id: stage_id,
        to_stage_index: stage_index,
        from_workcentre_id: item.from_workcentre_id,
        from_workcentre_index: item.from_workcentre_index,
        to_workcentre_id: step.workcentres[0].id,
        to_workcentre_index: 0,
        accountable_id: step.workcentres[0].accountable_id,
      });
    },
  });

  const downloadReport = () => {
    const wc_name = step?.workcentres?.[0]?.name;
    const report_type =
      wc_name === "wc1"
        ? "export_indent"
        : wc_name === "wc11"
        ? "purchase"
        : "";
    dispatch(
      downloadAction.downloadFile(`/api/v1/reports/${report_type}_report`)
    );
  };

  let delayedTask = 0;

  for (let i = 0; i < step.workcentres.length; i++) {
    for (let j = 0; j < step.workcentres[i].jobs.length; j++) {
      if (step.workcentres[i].jobs[j].status === "delayed") delayedTask++;
    }
  }

  let tat =
    step.workcentres[0].tat_in_mins ||
    step.workcentres[0].tat_relative_mins ||
    null;

  let is_tat_relative = step.workcentres[0].tat_relative_mins ? true : false;

  let jobs = step.workcentres[0]
    ? step.workcentres[0].jobs.sort(
        (a, b) => new Date(b.complete_on) - new Date(a.complete_on)
      )
    : [];

  jobs = jobs.filter((job) =>
    indentSearch.isVisible ? job.isVisible && true : true
  );

  return (
    <Card
      ref={drop}
      className={
        (classes.root,
        isBottleneck ? classes.stepBottleneck : classes.stepcardcontent)
      }
      elevation={0}
    >
      <CardContent className={classes.CHtop}>
        <Grid container>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6">Step {step.position}</Typography>
            <Grid container align="center" spacing={2} className="w-auto">
              <Grid item>
                <CustomChip
                  color="error"
                  label={`${formattedNumber(delayedTask)} Task Delayed`}
                  className={classes.cardChip}
                />
              </Grid>
              <Grid item>
                <CustomChip
                  color="success"
                  label={
                    step.workcentres[0] &&
                    `Total Tasks: ${formattedNumber(
                      step.workcentres[0].jobs.length
                    )}`
                  }
                  className={classes.cardChip}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className=" mt-3">
            <StepTopography body1={step.name} body2="Task" />
          </Grid>
          <Grid item xs={12}>
            <StepTopography
              body1={`${is_tat_relative ? "Lead - " : ""}${humanizeFormat(
                tat * 60000
              )}`}
              body2="Time"
            />
          </Grid>
          {["wc1", "wc11"].includes(step?.workcentres?.[0]?.name) ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadReport}
              >
                Download Report
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <div className="flex-fill">
        {}
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={jobs.length}
              rowRenderer={({ key, index, style, parent }) => {
                const job = jobs[index];
                return (
                  <CellMeasurer
                    key={job.id}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    <div style={style}>
                      <Tasks
                        job={job}
                        workcentre_id={step.workcentres[0].id}
                        workcentre_index={0}
                        workcentre_name={step.workcentres[0].name}
                        accountable_id={step.workcentres[0].accountable_id}
                        step_id={step.id}
                        step_index={step_index}
                        stage_id={stage_id}
                        stage_index={stage_index}
                        // checklists={step.workcentres[0].checklists}
                        checklists={[]}
                      />
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    </Card>
  );
};

const shouldRerender = (prevProps, nextProps) => {
  if (prevProps.indentSearch.isVisible !== nextProps.indentSearch.isVisible)
    return false;
  if (prevProps.step === nextProps.step) return true;
  return false;
};

export default memo(StepsContainer, shouldRerender);
