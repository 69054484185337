import React, { useMemo, useRef, useState } from "react";
import { indentNumberWithPart } from "../../../utils";
import IndentDetails from "../TaskDetails/IndentDetails";
import ItemAccordian from "../TaskDetails/ItemAccordian";
import ManufacturingCompanyAccordian from "../TaskDetails/ManufacturingCompanyAccordian";
import ViewConsignmentTable from "../TaskDetails/ViewConsignmentTable";

import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { indentFormActions } from "../../../actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
  return {
    selected: {
      backgroundColor: "#ffffff !important",
    },
    toggleButton: {
      outline: "none !important",
      minWidth: "9ch !important",
      maxWidth: "9ch !important",
    },
    toggleSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "#ffffff !important",
    },
    error: {
      background: "rgba(0, 0, 0, 0.04)",
    },
  };
});

const ExpandableRow = ({
  indent,
  poIndent,
  itemDetail,
  isItemSelected,
  handleRowClick,
  index,
  updateUnitSelected,
  fetchIndentDetail,
}) => {
  const [open, setOpen] = useState(false);
  const [unitSelected, setUnitSelected] = useState(0);
  const [additionalItemDescription, setAdditionalItemDescription] = useState(indent.additional_item_description ? indent.additional_item_description : "");
  const classes = useStyles();
  const poIndentDetail = useRef(poIndent[indent.id] ?? {});

  const handleChange = (event, newUnit) => {
    if (isItemSelected) {
      updateUnitSelected(indent.id, +newUnit, indent.item.busy_gst);
    }
    setUnitSelected(+newUnit);
  };

  const handleAdditionalItemDescription = (event) => {
    setAdditionalItemDescription(event.target.value);
  }

  useMemo(() => {
    poIndentDetail.current = poIndent[indent.id];
  }, [poIndent, indent.id]);

  return (
    <>
      <TableRow
        key={indent.id}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        classes={{
          selected: classes.selected,
        }}
        className={!indent?.item?.busy_item ? classes.error : ""}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={async () => {
              if (!poIndent[indent.id] && !open)
                await fetchIndentDetail(indent.id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scrope="row">{indentNumberWithPart(indent)}</TableCell>
        <TableCell>
          {indent.department && indent.department.name ? indent.department.name : "-"}
        </TableCell>
        <TableCell>
          {indent.item && indent.item.name ? indent.item.name : "-"}
        </TableCell>
        <TableCell>
          <TextField
            name="additional_item_description"
            variant="outlined"
            error={!additionalItemDescription}
            value={additionalItemDescription}
            size="small"
            onChange={handleAdditionalItemDescription}
            disabled ={isItemSelected}
          />
        </TableCell>
        <TableCell>
          {indent?.item?.busy_item ? (
            <ToggleButtonGroup
              value={unitSelected}
              onChange={handleChange}
              exclusive
              aria-label="Unit Select"
              size="small"
            >
              <ToggleButton
                value={0}
                aria-label="Busy Unit"
                className={classes.toggleButton}
                classes={{ selected: classes.toggleSelected }}
              >
                {indent.item.busy_item?.busy_unit ?? ""}
              </ToggleButton>
              {indent.item.busy_item?.busy_alternate_unit && (
                <ToggleButton
                  value={1}
                  aria-label="Alternate Unit"
                  className={classes.toggleButton}
                  classes={{ selected: classes.toggleSelected }}
                >
                  {indent.item.busy_item?.busy_alternate_unit ?? ""}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          ) : (
            "Please Link Busy Item"
          )}
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            size="small"
            checked={isItemSelected}
            disabled={!indent?.item?.busy_item}
            onChange={(e) =>
              handleRowClick(
                e,
                indent.id,
                indent.item.busy_gst,
                indent.item.id,
                unitSelected,
                additionalItemDescription,
              )
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={open ? "" : "py-0"} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {poIndentDetail.current?.id && (
              <>
                <IndentDetails indent={poIndentDetail.current} />
                {indent.item && (
                  <ItemAccordian
                    item_id={indent?.item?.id ?? null}
                    item={itemDetail[indent?.item?.id] || null}
                  />
                )}
                {poIndentDetail?.current?.consignments?.length && (
                  <ViewConsignmentTable
                    consignments={poIndentDetail?.current?.consignments ?? []}
                  />
                )}
                {poIndentDetail.current.item_sku &&
                  poIndentDetail.current.item_sku.manufacturing_company && (
                    <ManufacturingCompanyAccordian
                      manufacturingCompany={
                        poIndentDetail.current.item_sku.manufacturing_company
                      }
                    />
                  )}
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const dispatchAction = {
  fetchIndentDetail: indentFormActions.fetchIndentDetail,
};

export default connect(null, dispatchAction)(ExpandableRow);
