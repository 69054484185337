import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getQRHistoryReport = (qrCode, page) => {
    return axios
      .get(`/api/v1/reports/motor_testing_history`,{
        params: {
          qr_code: qrCode,
          page: page
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response)
      .catch((err) => err);
};

const getTestingHistoryReport = (testingReportDate, selectedTestingStation, motor_type, page) => {
  if(selectedTestingStation === "Consolidated"){
    return axios
    .get(`/api/v1/reports/motor_testing_history`,{
      params: {
        testing_date: testingReportDate,
        motor_type_id: motor_type,
        page: page
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
  }
  return axios
    .get(`/api/v1/reports/motor_testing_history`,{
      params: {
        testing_date: testingReportDate,
				station_id: selectedTestingStation,
        motor_type_id: motor_type,
        page: page,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getProductionDetailReport = (reqBody) => {
  return axios
  .get(`/api/v1/assembly/production_detail_report`,{
    params: {...reqBody},
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((response) => response)
  .catch((err) => err);
};

const getOemProductionReport = (reqBody) => {
  return axios
  .get(`/api/v1/oems/production_detail_report`,{
    params: {...reqBody},
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((response) => response)
  .catch((err) => err);
};

const getStockTransferReport = (reqBody) => {
  return axios
  .get(`/api/v1/warehouses/stock_transfer_report`,{
    params: {...reqBody},
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((response) => response)
  .catch((err) => err);
};

export const historyReportService = {
  getQRHistoryReport,
  getTestingHistoryReport,
  getProductionDetailReport,
  getOemProductionReport,
  getStockTransferReport,
};