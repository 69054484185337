import { errorAndLoadingConstants, oemDashboardConstant } from "../constants";
import { oemDashboardService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getOemDashboard = (body) => async (dispatch) => {
  dispatch({
    type: errorAndLoadingConstants.API_CALL_START,
  });

  await oemDashboardService.getOemDashboard(body).then(
    (plan) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (plan.status === 200) {
        dispatch({
          type: oemDashboardConstant.GET_OEM_DASHBOARD_SUCCESS,
          data: plan.data,
        });
      } else {
        const errMsg = requestFailError(plan);
        dispatchFailure(dispatch, errMsg, {
          type: oemDashboardConstant.GET_OEM_DASHBOARD_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: oemDashboardConstant.GET_OEM_DASHBOARD_FAILURE,
      });
    }
  );
};


const getOemProduction = (type, date) => async (dispatch) => {
  dispatch({
    type: errorAndLoadingConstants.API_CALL_START,
  });

  await oemDashboardService.getOemProduction(type, date).then(
    (response) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (response.status === 200) {
        dispatch({
          type: oemDashboardConstant.GET_OEM_PRODUCTION_SUCCESS,
          data: response.data.oem_production,
        });
      } else {
        const errMsg = requestFailError(response);
        dispatchFailure(dispatch, errMsg, {
          type: oemDashboardConstant.GET_OEM_PRODUCTION_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: oemDashboardConstant.GET_OEM_PRODUCTION_FAILURE,
      });
    }
  );
};

const getOemDispatch = (body) => async (dispatch) => {
  dispatch({
    type: errorAndLoadingConstants.API_CALL_START,
  });

  await oemDashboardService.getOemDispatch(body).then(
    (response) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (response.status === 200) {
        dispatch({
          type: oemDashboardConstant.GET_OEM_DISPATCH_SUCCESS,
          data: response.data,
        });
      } else {
        const errMsg = requestFailError(response);
        dispatchFailure(dispatch, errMsg, {
          type: oemDashboardConstant.GET_OEM_DISPATCH_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: oemDashboardConstant.GET_OEM_DISPATCH_FAILURE,
      });
    }
  );
};

export const oemDashboardActions = {
  getOemDashboard,
  getOemProduction,
  getOemDispatch,
};
