import React from "react";
import Flow from "./Flow";

const MyView = () => {
  return (
    <>
      <Flow />
    </>
  );
};

export default MyView;
