import React from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
}));

export default function FormDatePicker({
  handleChange,
  name,
  selectedDate,
  label,
  id,
  error,
  size,
  readOnly,
  fixWidth = true,
}) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
      <KeyboardDateTimePicker
        error={error}
        inputVariant="outlined"
        id={id}
        value={selectedDate}
        label={label}
        onChange={(moment) => handleChange(moment, name, id)}
        className={!fixWidth ? "" : classes.root}
        format="DD/MM/YYYY hh:mm:ss a"
        size={size}
        inputProps={{ readOnly }}
        fullWidth={!fixWidth}
      />
    </MuiPickersUtilsProvider>
  );
}
