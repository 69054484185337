import { errorAndLoadingConstants, currPlanConstant } from "../constants";
import { currPlanService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

// const getCurrPlan = (body) => async (dispatch) => {
//   dispatch({
//     type: errorAndLoadingConstants.API_CALL_START,
//   });

//   await currPlanService.getCurrPlan(body).then(
//     (plan) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       if (plan.status === 200) {
//         dispatch({
//           type: currPlanConstant.GET_CURR_PLAN_SUCCESS,
//           data: plan.data,
//         });
//       } else {
//         const errMsg = requestFailError(plan);
//         dispatchFailure(dispatch, errMsg, {
//           type: currPlanConstant.GET_CURR_PLAN_FAILURE,
//         });
//       }
//     },
//     (err) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       dispatchFailure(dispatch, err, {
//         type: currPlanConstant.GET_CURR_PLAN_FAILURE,
//       });
//     }
//   );
// };

const getCurrPlanStatus = (type, date) => async (dispatch) => {
  dispatch({
    type: errorAndLoadingConstants.API_CALL_START,
  });

  await currPlanService.getCurrPlanStatus(type, date).then(
    (plans) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (plans.status === 200) {
        dispatch({
          type: currPlanConstant.GET_CURR_PLAN_STATUS_SUCCESS,
          data: plans.data.plan_testing_status,
        });
      } else {
        const errMsg = requestFailError(plans);
        dispatchFailure(dispatch, errMsg, {
          type: currPlanConstant.GET_CURR_PLAN_STATUS_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: currPlanConstant.GET_CURR_PLAN_STATUS_FAILURE,
      });
    }
  );
};

// const getTotalProduction = (body) => async (dispatch) => {
//   dispatch({
//     type: errorAndLoadingConstants.API_CALL_START,
//   });

//   await currPlanService.getTotalProduction(body).then(
//     (response) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       if (response.status === 200) {
//         dispatch({
//           type: currPlanConstant.GET_TOTAL_PRODUCTION_SUCCESS,
//           data: response.data,
//         });
//       } else {
//         const errMsg = requestFailError(response);
//         dispatchFailure(dispatch, errMsg, {
//           type: currPlanConstant.GET_TOTAL_PRODUCTION_FAILURE,
//         });
//       }
//     },
//     (err) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       dispatchFailure(dispatch, err, {
//         type: currPlanConstant.GET_TOTAL_PRODUCTION_FAILURE,
//       });
//     }
//   );
// };

// const getPlanDispatch = (body) => async (dispatch) => {
//   dispatch({
//     type: errorAndLoadingConstants.API_CALL_START,
//   });

//   await currPlanService.getPlanDispatch(body).then(
//     (response) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       if (response.status === 200) {
//         dispatch({
//           type: currPlanConstant.GET_PLAN_DISPATCH_SUCCESS,
//           data: response.data,
//         });
//       } else {
//         const errMsg = requestFailError(response);
//         dispatchFailure(dispatch, errMsg, {
//           type: currPlanConstant.GET_PLAN_DISPATCH_FAILURE,
//         });
//       }
//     },
//     (err) => {
//       dispatch({
//         type: errorAndLoadingConstants.API_CALL_STOP,
//       });
//       dispatchFailure(dispatch, err, {
//         type: currPlanConstant.GET_PLAN_DISPATCH_FAILURE,
//       });
//     }
//   );
// };

const getFinalAssemblyDashboardData = (body) => async (dispatch) => {
  if (body.autoRefresh)
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

  await currPlanService.getCurrPlan(body).then(
    (plan) => {
      if (plan.status === 200) {
        dispatch({
          type: currPlanConstant.GET_CURR_PLAN_SUCCESS,
          data: plan.data,
        });
      } else {
        const errMsg = requestFailError(plan);
        dispatchFailure(dispatch, errMsg, {
          type: currPlanConstant.GET_CURR_PLAN_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: currPlanConstant.GET_CURR_PLAN_FAILURE,
      });
    }
  );

  await currPlanService.getTotalProduction(body).then(
    (response) => {
      if (response.status === 200) {
        dispatch({
          type: currPlanConstant.GET_TOTAL_PRODUCTION_SUCCESS,
          data: response.data,
        });
      } else {
        const errMsg = requestFailError(response);
        dispatchFailure(dispatch, errMsg, {
          type: currPlanConstant.GET_TOTAL_PRODUCTION_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: currPlanConstant.GET_TOTAL_PRODUCTION_FAILURE,
      });
    }
  );

  await currPlanService.getPlanDispatch(body).then(
    (response) => {
      if (body.autoRefresh)
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
      if (response.status === 200) {
        dispatch({
          type: currPlanConstant.GET_PLAN_DISPATCH_SUCCESS,
          data: response.data,
        });
      } else {
        const errMsg = requestFailError(response);
        dispatchFailure(dispatch, errMsg, {
          type: currPlanConstant.GET_PLAN_DISPATCH_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: currPlanConstant.GET_PLAN_DISPATCH_FAILURE,
      });
    }
  );
};

export const currPlanActions = {
  // getCurrPlan,
  getCurrPlanStatus,
  // getTotalProduction,
  // getPlanDispatch,
  getFinalAssemblyDashboardData,
};
