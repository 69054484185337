export default {
  root: {
    color: "white",
    width: "28px",
    height: "28px",
    border: "1px solid #295EB280",
    borderRadius: "50%",
  },
  text: {
    fill: "#295EB2",
  },
};
