export const vehicleConstants = {
  SAVE_VEHICLE_REQUEST: "SAVE_VEHICLE_REQUEST",
  SAVE_VEHICLE_SUCCESS: "SAVE_VEHICLE_SUCCESS",
  SAVE_VEHICLE_FAILURE: "SAVE_VEHICLE_FAILURE",

  GET_VEHICLE_LIST_REQUEST: "GET_VEHICLE_LIST_REQUEST",
  GET_VEHICLE_LIST_SUCCESS: "GET_VEHICLE_LIST_SUCCESS",
  GET_VEHICLE_LIST_FAILURE: "GET_VEHICLE_LIST_FAILURE",

  GET_DETAILED_VEHICLE_REQUEST: "GET_DETAILED_VEHICLE_REQUEST",
  GET_DETAILED_VEHICLE_SUCCESS: "GET_DETAILED_VEHICLE_SUCCESS",
  GET_DETAILED_VEHICLE_FAILURE: "GET_DETAILED_VEHICLE_FAILURE",

  GET_STATIONS_REQUEST: "GET_STATIONS_REQUEST",
  GET_STATIONS_SUCCESS: "GET_STATIONS_SUCCESS",
  GET_STATIONS_FAILURE: "GET_STATIONS_FAILURE",
};
