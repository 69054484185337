import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const fetchVendor = () => {
  return axios
    .get("/api/v1/vendors")
    .then((response) => response)
    .catch((err) => err);
};

const fetchOEMVendor = () => {
  return axios
    .get("/api/v1/oems/oem_vendors")
    .then((response) => response)
    .catch((err) => err);
};

export const vendorService = {
  fetchVendor,
  fetchOEMVendor,
};
