export const formattedNumber = (
  num,
  showFraction = false,
  isCurrency = false
) => {
  const intNum = Math.round(num * 100) / 100;
  let options = {};

  if (isNaN(intNum)) return null;

  if (showFraction) {
    options = {
      ...options,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
  }

  if (isCurrency) {
    options = {
      ...options,
      style: "currency",
      currency: "INR",
    };
  }

  return intNum.toLocaleString("en-IN", options);
};
