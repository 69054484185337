import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const saveChecklist = (requestBody) => {
  return axios
    .post(
      "/api/v1/checklist_responses/save_checklist_responses",
      JSON.stringify(requestBody),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response)
    .catch((err) => err);
};

export const checklistService = {
  saveChecklist,
};
