import { errorAndLoadingConstants, mouldingConstants } from "../constants";
import { mouldingService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const updateMachineStatus = (id, requestData) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await mouldingService.updateMachineStatus(id, requestData).then(
      (data) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (data.status === 200) {
          dispatch({
            type: mouldingConstants.SAVE_MACHINE_STATUS_SUCCESS,
            data: data.data,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: "Machine Status successfully updated.",
            },
          });
        } else {
          const errMsg = requestFailError(data);
          dispatchFailure(dispatch, errMsg, {
            type: mouldingConstants.SAVE_MACHINE_STATUS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: mouldingConstants.SAVE_MACHINE_STATUS_FAILURE,
        });
      }
    );
  };
};

const mouldingDashboard = (body) => async (dispatch) => {
  dispatch({
    type: errorAndLoadingConstants.API_CALL_START,
  });

  await mouldingService.mouldingDashboard(body).then(
    (plan) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (plan.status === 200) {
        dispatch({
          type: mouldingConstants.GET_MOULDING_DASHBOARD_SUCCESS,
          data: plan.data,
        });
      } else {
        const errMsg = requestFailError(plan);
        dispatchFailure(dispatch, errMsg, {
          type: mouldingConstants.GET_MOULDING_DASHBOARD_FAILURE,
        });
      }
    },
    (err) => {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, err, {
        type: mouldingConstants.GET_MOULDING_DASHBOARD_FAILURE,
      });
    }
  );
};

const getMachineCount = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await mouldingService.getMachineCount().then(
      (data) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (data.status === 200) {
          dispatch({
            type: mouldingConstants.GET_MACHINE_COUNT_SUCCESS,
            data: data.data,
          });
        } else {
          const errMsg = requestFailError(data);
          dispatchFailure(dispatch, errMsg, {
            type: mouldingConstants.GET_MACHINE_COUNT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: mouldingConstants.GET_MACHINE_COUNT_FAILURE,
        });
      }
    );
  };
};

export const mouldingActions = {
  updateMachineStatus,
  mouldingDashboard,
  getMachineCount,
};
