import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isStringValid } from "../../../utils";
import UploadedFileLink from "../UploadedFileLink";
import StepTopography from "../StepTopography";
import { indentFormActions } from "../../../actions";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export const VendorAccordion = ({
  vendor,
  vendor_id = null,
  fetchVendorDetails = {},
}) => {
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      onChange={async (e, expanded) => {
        if (!vendor_id) return;
        if (!expanded) return;
        if (vendor) return;
        try {
          await fetchVendorDetails(vendor_id);
        } catch (err) {
          console.log("Error Getting Vendor: ", err);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="vendor-content"
        id={`vendor-header-${vendor?.id ?? vendor_id}`}
      >
        <Typography>Vendor Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {vendor && (
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2="Vendor Name"
                body1={isStringValid(vendor.name) ? vendor.name : "-"}
                reverse={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2=" Vendor Short Name"
                body1={
                  isStringValid(vendor.short_name) ? vendor.short_name : "-"
                }
                reverse={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2="Code"
                body1={isStringValid(vendor.code) ? vendor.code : "-"}
                reverse={true}
                wordBreak={{ body1: true }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2="GSTIN"
                body1={isStringValid(vendor.gstin) ? vendor.gstin : "-"}
                reverse={true}
                wordBreak={{ body1: true }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2="Website"
                body1={isStringValid(vendor.website) ? vendor.website : "-"}
                reverse={true}
                wordBreak={{ body1: true }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <StepTopography
                body2="Address"
                body1={
                  vendor.address
                    ? `${
                        isStringValid(vendor.address.area)
                          ? vendor.address.area
                          : "-"
                      } ${
                        vendor.address.city &&
                        isStringValid(vendor.address.city.name)
                          ? vendor.address.city.name
                          : ""
                      } ${
                        vendor.address.state &&
                        isStringValid(vendor.address.state.name)
                          ? vendor.address.state.name
                          : ""
                      } ${
                        vendor.address.country &&
                        isStringValid(vendor.address.country.name)
                          ? vendor.address.country.name
                          : ""
                      } ${
                        isStringValid(vendor.address.zipcode)
                          ? vendor.address.zipcode
                          : ""
                      }`
                    : ""
                }
                reverse={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <StepTopography
                body2="Description"
                body1={
                  isStringValid(vendor.description) ? vendor.description : "-"
                }
                reverse={true}
                wordBreak={{ body1: true }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Import Export Code
              </Typography>
              <StepTopography
                body2="Code"
                body1={
                  vendor.import_export_code &&
                  isStringValid(vendor.import_export_code.code)
                    ? vendor.import_export_code.code
                    : "-"
                }
                reverse={true}
              />
              <StepTopography
                body2="Type"
                body1={
                  vendor.import_export_code &&
                  isStringValid(vendor.import_export_code.import_export_type)
                    ? vendor.import_export_code.import_export_type
                    : "-"
                }
                reverse={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Files
              </Typography>
              {vendor.files &&
                vendor.files.map((file) => (
                  <UploadedFileLink key={file.id} file={file} />
                ))}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Payment Terms
              </Typography>
              <StepTopography
                body2="Name"
                body1={
                  vendor.payment_term && isStringValid(vendor.payment_term.name)
                    ? vendor.payment_term.name
                    : "-"
                }
                reverse={true}
              />
              <StepTopography
                body2="Description"
                body1={
                  vendor.payment_term &&
                  isStringValid(vendor.payment_term.description)
                    ? vendor.payment_term.description
                    : "-"
                }
                reverse={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Vendor Type
              </Typography>
              <StepTopography
                body2="Name"
                body1={
                  vendor.vendor_type && isStringValid(vendor.vendor_type.name)
                    ? vendor.vendor_type.name
                    : "-"
                }
                reverse={true}
              />
              <StepTopography
                body2="Description"
                body1={
                  vendor.vendor_type &&
                  isStringValid(vendor.vendor_type.description)
                    ? vendor.vendor_type.description
                    : "-"
                }
                reverse={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="mb-3" variant="body2">
                Contacts
              </Typography>
              <Grid container spacing={3}>
                {vendor.contacts &&
                  vendor.contacts.map((contact) => (
                    <Grid item key={contact.id} xs={12} sm={6} md={4} lg={3}>
                      <StepTopography
                        body2="Name"
                        body1={isStringValid(contact.name) ? contact.name : "-"}
                        reverse={true}
                      />
                      <StepTopography
                        body2="Email"
                        body1={
                          isStringValid(contact.email) ? contact.email : "-"
                        }
                        reverse={true}
                        wordBreak={{ body1: true }}
                      />
                      <StepTopography
                        body2="Phone No."
                        body1={
                          isStringValid(contact.phone) ? contact.phone : ""
                        }
                        reverse={true}
                        wordBreak={{ body1: true }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

VendorAccordion.propTypes = {
  vendor: PropTypes.object,
  vendor_id: PropTypes.number,
  fetchVendorDetails: PropTypes.func,
};

const dispatchAction = {
  fetchVendorDetails: indentFormActions.fetchVendorDetails,
};

export default connect(null, dispatchAction)(VendorAccordion);
