import {
  gridsConstants,
  jobConstant,
  checklistConstants,
  poFormConstants,
  indentFormConstants,
  salesOrderConstants,
  stoConstants,
  lotOfferConstants,
} from "../constants";

import { CORE_TYPE_MAP } from "../utils";

const initialSearch = {
  query: {
    search_term: "",
    item_id: null,
    vendor_id: null,
    department_id: null,
    search_date: null
  },
  isVisible: false,
};

const search = {
  number: "",
  isVisible: false,
};

const initialState = {
  grids: [],
  detailedGrid: {},
  wcMap: {},
  dragAlert: {
    isOpen: false,
    message: "",
  },
  alert: {
    isOpen: false,
    message: "",
    type: "",
  },
  popUP: {
    isOpen: false,
    message: "",
  },
  indentSearch: initialSearch,
  search,
};

export function grids(state = initialState, action) {
  switch (action.type) {
    case gridsConstants.CLEAR_DETAILED_GRID:
      return {
        ...state,
        detailedGrid: {},
      };

    case gridsConstants.GRIDS_REQUEST:
      return {
        ...state,
        grids: [],
      };

    case gridsConstants.GRIDS_SUCCESS:
      return {
        ...state,
        grids: action.grids,
      };

    case gridsConstants.SINGLE_GRID_REQUEST:
      return {
        ...state,
        detailedGrid: {},
      };

    case gridsConstants.SINGLE_GRID_SUCCESS:
      let wcs = {};
      action.grid.stages.forEach((stage) => {
        stage.steps.forEach((step) => {
          step.workcentres.forEach((wc) => {
            wcs[wc.id] = {
              step_id: wc.step_id,
              stage_id: wc.stage_id,
              workcentre_id: wc.id,
            };
          });
        });
      });
      return {
        ...state,
        wcMap: wcs,
        detailedGrid: action.grid,
      };

    case gridsConstants.DROP_JOB:
      return dropJob(state, action.data);

    case gridsConstants.DROP_FAIL:
      return {
        ...state,
        dragAlert: {
          message: action.data,
          isOpen: true,
        },
      };

    case gridsConstants.DROP_ALERT_STOP:
      return {
        ...state,
        dragAlert: {
          ...state.dragAlert,
          isOpen: false,
        },
      };

    case jobConstant.JOB_ACTION_SUCCESS:
    case indentFormConstants.HOLD_INDENT_SUCCESS:
    case indentFormConstants.REVIVE_INDENT_SUCCESS:
      return routeJob(state, action.data, action.extraPayload);

    case indentFormConstants.REJECT_INDENT_SUCCESS:
      return routeJob(state, action.data[0], action.extraPayload);

    case jobConstant.JOB_ACTION_SUCCESS_NO_WC:
      return routeJobNoWc(state, action.data, action.extraPayload);

    case jobConstant.UPLOAD_FILE_SUCCESS:
      return uploadFile(state, action.data, action.extraPayload);

    case jobConstant.GET_JOB_SUCCESS:
      return updateCO(state, action.job, action.extraPayload);
    
    case lotOfferConstants.SCAN_LO_BOX_CODE_SUCCESS:
    case lotOfferConstants.EDIT_LO_SUCCESS:
    case lotOfferConstants.APPROVE_LO_DISPATCH_SUCCESS:
      return updateLO(state, action.data, action.extraPayload);
      
    case lotOfferConstants.DELETE_LO_SCANNING_LOG_SUCCESS:
      return deleteLoLog(state, action.extraPayload);

    case lotOfferConstants.UPDATE_LO_SCANNING_LOG_SUCCESS:
      return updateLoLog(state, action.data, action.extraPayload);

    case jobConstant.GET_PO_JOB_SUCCESS:
      return updateCO(state, action.purchase_order, action.extraPayload);

    case checklistConstants.CHECKLIST_SUCCESS:
      return saveChecklist(state, action.data, action.extraPayload);

    case gridsConstants.STOP_ERROR:
      return {
        ...state,
        alert: {
          isOpen: false,
          message: "",
          type: "",
        },
      };

    case gridsConstants.CLOSE_POPUP:
      return {
        ...state,
        popUP: {
          isOpen: false,
          message: "",
        },
      };

    case poFormConstants.PO_UPDATE_SUCCESS:
      return updatePO(state, action.data);

    case poFormConstants.PO_UPDATE_FAILURE:
      return {
        ...state,
        UpdateSuccessful: false,
      };

    case indentFormConstants.SAVE_INDENT_SUCCESS:
    case salesOrderConstants.CREATE_SALES_ORDER_SUCCESS:
    case stoConstants.CREATE_STO_SUCCESS:
    case lotOfferConstants.CREATE_LO_SUCCESS:
      return saveJob(state, action.data);
    case salesOrderConstants.UPDATE_SALES_ORDER_SUCCESS:
    case salesOrderConstants.UPDATE_MDN_SUCCESS:
      return updateJob(state, action.data);

    case indentFormConstants.UPDATE_INDENT_SUCCESS:
      return updateIndent(state, action.data);

    case indentFormConstants.ASSIGN_SKU_SUCCESS:
      return assignSKU(state, action.data);

    case poFormConstants.CREATE_PO_SUCCESS:
      return createPO(state, action.data);

    case poFormConstants.REJECT_PO_SUCCESS:
    case salesOrderConstants.REJECT_MDN_SUCCESS:
    case salesOrderConstants.REJECT_SALES_ORDER_SUCCESS:
    case salesOrderConstants.FORCE_CLOSE_MDN_SUCCESS:
    case stoConstants.REJECT_STO_SUCCESS:
    case lotOfferConstants.REJECT_LO_SUCCESS:
      return rejectJob(state, action.data, action.extraPayload);

    case indentFormConstants.CREATE_CONSIGNMENTS_SUCCESS:
      return createConsignments(state, action.data, action.message);

    case gridsConstants.SET_INDENT_SEARCH:
      return {
        ...state,
        indentSearch: { query: { ...action.data }, isVisible: true },
        detailedGrid: searchUtil(state.detailedGrid, action.data),
      };

    case gridsConstants.RESET_INDENT_SEARCH:
      return {
        ...state,
        indentSearch: initialSearch,
      };

    case gridsConstants.SET_SEARCH:
      return {
        ...state,
        search: {
          number: action?.data?.number ?? "",
          isVisible: true,
        },
        detailedGrid: searchGridJob(state.detailedGrid, action.data),
      };
    case gridsConstants.RESET_SEARCH:
      return {
        ...state,
        search,
      };

    case salesOrderConstants.CREATE_MDN_SUCCESS:
      return createMDN(state, action.data, action.so, action.workcentre_id);

    case salesOrderConstants.CONFIRM_PAYMENT_SUCCESS:
    case salesOrderConstants.ORDER_APPROVAL_SUCCESS:
    case salesOrderConstants.CREATE_COURIER_SUCCESS:
    case salesOrderConstants.CREATE_BILTI_SUCCESS:
    case salesOrderConstants.ACKNOWLEDGE_MDN_SUCCESS:
    case salesOrderConstants.CREATE_EWAY_BILL_SUCCESS:
    case salesOrderConstants.MDN_RECEIVE_SUCCESS:
    case salesOrderConstants.EDIT_MDN_SUCCESS:
    case salesOrderConstants.EDIT_CUSTOMER_SUCCESS:
    case stoConstants.EDIT_STO_SUCCESS:
    case indentFormConstants.UPDATE_ITEM_SUCCESS:
    case indentFormConstants.UPDATE_UNIT_PRICE_SUCCESS:
      return replaceCO(state, action.data);

    case indentFormConstants.CREATE_CONSIGNMENTS_FAILURE:
    case gridsConstants.GRIDS_FAILURE:
    case gridsConstants.SINGLE_GRID_FAILURE:
    case jobConstant.JOB_ACTION_REQUEST:
    case jobConstant.JOB_ACTION_FAILURE:
    case jobConstant.UPLOAD_FILE_REQUEST:
    case jobConstant.UPLOAD_FILE_FAILURE:
    case jobConstant.GET_JOB_REQUEST:
    case jobConstant.GET_JOB_FAILURE:
    case checklistConstants.CHECKLIST_REQUEST:
    case checklistConstants.CHECKLIST_FAILURE:
    case poFormConstants.PO_UPDATE_REQUEST:
    case indentFormConstants.SAVE_INDENT_FAILURE:
    case indentFormConstants.UPDATE_INDENT_FAILURE:
    case indentFormConstants.ASSIGN_SKU_FAILURE:
    case indentFormConstants.REVIVE_INDENT_FAILURE:
    case indentFormConstants.REJECT_INDENT_FAILURE:
    case poFormConstants.CREATE_PO_FAILURE:
    case poFormConstants.REJECT_PO_FAILURE:
    case jobConstant.GET_PO_JOB_REQUEST:
    case jobConstant.GET_PO_JOB_FAILURE:
    case salesOrderConstants.CONFIRM_PAYMENT_FAILURE:
    case salesOrderConstants.CONFIRM_PAYMENT_REQUEST:
    case salesOrderConstants.ORDER_APPROVAL_FAILURE:
    case salesOrderConstants.ORDER_APPROVAL_REQUEST:
    case salesOrderConstants.CREATE_COURIER_FAILURE:
    case salesOrderConstants.CREATE_BILTI_FAILURE:
    case salesOrderConstants.ACKNOWLEDGE_MDN_FAILURE:
    case salesOrderConstants.CREATE_EWAY_BILL_FAILURE:
    case salesOrderConstants.MDN_RECEIVE_FAILURE:
    case salesOrderConstants.CREATE_MDN_FAILURE:
    case salesOrderConstants.REJECT_MDN_FAILURE:
    case salesOrderConstants.FORCE_CLOSE_MDN_FAILURE:
    case salesOrderConstants.REJECT_SALES_ORDER_FAILURE:
    case salesOrderConstants.EDIT_MDN_FAILURE:
    case salesOrderConstants.EDIT_CUSTOMER_FAILURE:
    case stoConstants.EDIT_STO_FAILURE:
    case lotOfferConstants.EDIT_LO_FAILURE:
    case lotOfferConstants.APPROVE_LO_DISPATCH_FAILURE:
    case lotOfferConstants.SCAN_LO_BOX_CODE_FAILURE:
    case lotOfferConstants.DELETE_LO_SCANNING_LOG_FAILURE:
    case indentFormConstants.UPDATE_ITEM_FAILURE:
    case indentFormConstants.UPDATE_UNIT_PRICE_FAILURE:
    default:
      return state;
  }
}

/************************ utility function for gird reducers ****************** */

// this method is to find job given it's step_id, stage_id, workcentre_id, job_id.

const findAndReplaceJob = (
  state,
  job,
  stage_id,
  step_id,
  workcentre_id,
  job_id
) => {
  let jb = job;
  state.stages = state.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs : wc.jobs.map((job)=>
                          job.id === jb.id
                          ?  job.isVisible
                          ? {...jb, isVisible: true}
                          :  jb
                          : job)
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return state;
};

// this function is responsible for droping a job

const dropJob = (state, data) => {
  let newState = Object.assign({}, state.detailedGrid);

  const { job_id, from_step_id, from_stage_id, from_workcentre_id } =
    data.dropData;

  const indent = data.response;

  const { stage_id, step_id, workcentre_id } =
    state.wcMap[indent.workcentre_id];

  // remove job object from the step where we want to move from.
  newState = {
    ...newState,
    stages: newState.stages.map((stage) =>
      stage.id === from_stage_id
        ? {
            ...stage,
            steps: stage.steps.map((step) =>
              step.id === from_step_id
                ? {
                    ...step,
                    workcentres: step.workcentres.map((wc) =>
                      wc.id === from_workcentre_id
                        ? {
                            ...wc,
                            jobs: wc.jobs.filter((job) => job.id !== job_id),
                          }
                        : wc
                    ),
                  }
                : step
            ),
          }
        : stage
    ),
  };

  // add job object to the step where we want to add to.
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [indent, ...wc.jobs],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

// utility function to replace old workcentre from response one
// this function recieve two argments:
// state -> detailedGrid(or newState used for below functions)
// eslint-disable-next-line
const replaceWorkcentre = (state, workcentre) => {
  const { id, stage_id, step_id } = workcentre;

  state.stages = state.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === id ? workcentre : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );
  return state;
};

// this function is responsible for action after user select yes or no in taskdetails.
const routeJob = (state, indent, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  let isVisible = null;

  newState.stages = newState.stages.map((stage) =>
    stage.id === extraPayload.stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === extraPayload.step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === extraPayload.workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.filter((job) => {
                            if (job.id === extraPayload.job_id)
                              isVisible = true;
                            return job.id !== extraPayload.job_id;
                          }),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  if (!state?.wcMap[indent?.workcentre_id])
    return {
      ...state,
      detailedGrid: newState,
    };

  const { stage_id, step_id, workcentre_id } =
    state.wcMap[indent.workcentre_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [
                            isVisible ? { ...indent, isVisible: true } : indent,
                            ...wc.jobs,
                          ],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const routeJobNoWc = (state, data, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);

  newState.stages = newState.stages.map((stage) =>
    stage.id === extraPayload.stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === extraPayload.step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === extraPayload.workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.filter(
                            (job) => job.id !== extraPayload.job_id
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    alert: {
      isOpen: true,
      message: data,
      type: "success",
    },
    detailedGrid: newState,
  };
};

// this function is responsible for adding workcentre after checklist action is done
const saveChecklist = (state, workcentre, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { stage_id, step_id, workcentre_id, job_id } = extraPayload;
  newState = findAndReplaceJob(
    newState,
    workcentre,
    stage_id,
    step_id,
    workcentre_id,
    job_id
  );
  // newState = replaceWorkcentre(newState, workcentre);
  return {
    ...state,
    detailedGrid: newState,
  };
};

const uploadFile = (
  state,
  job,
  { job_id, stage_id, step_id, workcentre_id }
) => {
  let newState = Object.assign({}, state.detailedGrid);
  newState = findAndReplaceJob(
    newState,
    job,
    stage_id,
    step_id,
    workcentre_id,
    job_id
  );
  // newState = replaceWorkcentre(newState, workcentre);
  return {
    ...state,
    detailedGrid: newState,
  };
};

const updatePO = (state, workcentre, message) => {
  let newState = Object.assign({}, state.detailedGrid);
  newState = replaceWorkcentre(newState, workcentre);
  return {
    ...state,
    detailedGrid: newState,
    popUP: {
      isOpen: true,
      message,
    },
  };
};

const saveJob = (state, job) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { step_id, stage_id, workcentre_id } =
    state.wcMap[job[0].workcentre_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [...job, ...wc.jobs],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );
  return {
    ...state,
    detailedGrid: newState,
  };
};

const updateJob = (state, newJob) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { step_id, stage_id, workcentre_id } =
    state.wcMap[newJob[0].workcentre_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.id === newJob[0].id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...newJob[0].core_objectable,
                                  },
                                }
                              : job
                            ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );
  return {
    ...state,
    detailedGrid: newState,
  };
};

const updateIndent = (state, indent) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { step_id, stage_id, workcentre_id } =
    state.wcMap[indent.workcentre_id];
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [indent, ...wc.jobs],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );
  return {
    ...state,
    detailedGrid: newState,
  };
};

const assignSKU = (state, indents) => {
  let newState = Object.assign({}, state.detailedGrid);
  indents.forEach((indent) => {
    const { step_id, stage_id, workcentre_id } =
      state.wcMap[indent.workcentre_id];

    newState.stages = newState.stages.map((stage) =>
      stage.id === stage_id
        ? {
            ...stage,
            steps: stage.steps.map((step) =>
              step.id === step_id
                ? {
                    ...step,
                    workcentres: step.workcentres.map((wc) =>
                      wc.id === workcentre_id
                        ? {
                            ...wc,
                            jobs: wc.jobs.map((job) =>
                              job.id === indent.id
                                ? job.isVisible
                                  ? { ...indent, isVisible: true }
                                  : indent
                                : job
                            ),
                          }
                        : wc
                    ),
                  }
                : step
            ),
          }
        : stage
    );
  });
  return {
    ...state,
    detailedGrid: newState,
  };
};

const createPO = (state, po) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { step_id, stage_id, workcentre_id } = state.wcMap[po[0].workcentre_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [...po, ...wc.jobs],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const createConsignments = (state, indents) => {
  let originalIndentId;
  let flag = false;
  if(indents.length === 2 && indents[0].core_objectable.part_number === 1 ){
    originalIndentId = indents[0].id;
    indents[0].id = indents[0].parent_indent;
    flag = true;
  }
  let newState = Object.assign({}, state.detailedGrid);

  indents.forEach((indent) => {
    const { step_id, stage_id, workcentre_id } =
      state.wcMap[indent.workcentre_id];

    newState.stages = newState.stages.map((stage) =>
      stage.id === stage_id
        ? {
            ...stage,
            steps: stage.steps.map((step) =>
              step.id === step_id
                ? {
                    ...step,
                    workcentres: step.workcentres.map((wc) =>
                      wc.id === workcentre_id
                        ? {
                            ...wc,
                            jobs: wc.jobs.map((job) =>{
                              if(job.id === indent.id){
                                if(flag === true){
                                  indent.id = originalIndentId;
                                  flag = false; 
                                }
                                return job.isVisible ? { ...indent, isVisible: true } : indent;
                              }
                              else return job;
                            }),
                          }
                        : wc
                    ),
                  }
                : step
            ),
          }
        : stage
    );
  });

  return {
    ...state,
    detailedGrid: newState,
  };
};

const rejectJob = (state, job, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  newState.stages = newState.stages.map((stage) =>
    stage.id === extraPayload.stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === extraPayload.step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === extraPayload.workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.filter(
                            (job) => job.id !== extraPayload.job_id
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );
  return {
    ...state,
    detailedGrid: newState,
  };
};

const updateCO = (state, newJob, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { job_id, workcentre_id } = extraPayload;
  const { stage_id, step_id } = state.wcMap[workcentre_id];
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.id === job_id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...job.core_objectable,
                                    ...newJob,
                                  },
                                }
                              : job
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const updateLO = (state, newLO, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { job_id, workcentre_id } = extraPayload;
  const { stage_id, step_id } = state.wcMap[workcentre_id];
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.id === job_id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...newLO
                                  },
                                }
                              : job
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const deleteLoLog = (state, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { lo_scanning_log_id, job_id, workcentre_id } = extraPayload;
  const { stage_id, step_id } = state.wcMap[workcentre_id];
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.id === job_id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...job.core_objectable,
                                    lo_scanning_logs: [
                                      ...job.core_objectable.lo_scanning_logs.filter((_) => _.id !== lo_scanning_log_id),
                                      
                                    ],
                                  },
                                }
                              : job
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const updateLoLog = (state, lo_scanning_log, extraPayload) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { lo_scanning_log_id, job_id, workcentre_id } = extraPayload;
  const { stage_id, step_id } = state.wcMap[workcentre_id];
  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.id === job_id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...job.core_objectable,
                                    lo_scanning_logs: [
                                      ...job.core_objectable.lo_scanning_logs.filter((_) => _.id !== lo_scanning_log_id),
                                      lo_scanning_log
                                    ],
                                  },
                                }
                              : job
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};

const searchUtil = (grids, query) => {
  return {
    ...grids,
    stages: grids.stages.map((stage) => ({
      ...stage,
      steps: stage.steps.map((step) => ({
        ...step,
        workcentres: step.workcentres.map((wc) => ({
          ...wc,
          jobs: wc.jobs.map((job) =>
            (!!query.search_term &&
              (job.core_objectable.requisition_slip_number
                ? job.core_objectable.requisition_slip_number ===
                  query.search_term
                : job.core_objectable.number &&
                  job.core_objectable.number
                    .toLowerCase()
                    .includes(query.search_term.toLowerCase()))) ||
            (!!query.item_id &&
              job.core_objectable.item &&
              job.core_objectable.item.id === query.item_id) ||
            (!!query.department_id &&
              job.core_objectable.department &&
              job.core_objectable.department.id === query.department_id) ||
            (!!query.vendor_id &&
              job.core_objectable.vendor &&
              job.core_objectable.vendor.id === query.vendor_id) ||
            (!!query.search_date &&
              job.complete_on.substring(0,10) === query.search_date)
              ? { ...job, isVisible: true }
              : { ...job, isVisible: false }
          ),
        })),
      })),
    })),
  };
};

const handleJobSearch = (job, query) => {
  const number_key = CORE_TYPE_MAP[job.core_objectable_type];

  if (!query || !number_key) return { ...job };

  if (
    job?.core_objectable?.[number_key]
      ?.toLowerCase()
      ?.includes(query.number.toLowerCase())
  ) {
    return {
      ...job,
      isVisible: true,
    };
  }

  return {
    ...job,
    isVisible: false,
  };
};

const searchGridJob = (grids, query) => {
  return {
    ...grids,
    stages: grids.stages.map((stage) => ({
      ...stage,
      steps: stage.steps.map((step) => ({
        ...step,
        workcentres: step.workcentres.map((wc) => ({
          ...wc,
          jobs: wc.jobs.map((job) => handleJobSearch(job, query)),
        })),
      })),
    })),
  };
};

const replaceCO = (state, sales_order) => {
  let newState = Object.assign({}, state.detailedGrid);
  sales_order.forEach((so) => {
    const { step_id, stage_id, workcentre_id } = state.wcMap[so.workcentre_id];

    newState.stages = newState.stages.map((stage) =>
      stage.id === stage_id
        ? {
            ...stage,
            steps: stage.steps.map((step) =>
              step.id === step_id
                ? {
                    ...step,
                    workcentres: step.workcentres.map((wc) =>
                      wc.id === workcentre_id
                        ? {
                            ...wc,
                            jobs: wc.jobs.map((job) =>
                              job.id === so.id
                                ? job.isVisible
                                  ? { ...so, isVisible: true }
                                  : so
                                : job
                            ),
                          }
                        : wc
                    ),
                  }
                : step
            ),
          }
        : stage
    );
  });
  return {
    ...state,
    detailedGrid: newState,
  };
};

const createMDN = (state, data, so_id, wc_id) => {
  let newState = Object.assign({}, state.detailedGrid);
  const { step_id, stage_id, workcentre_id } =
    state.wcMap[data[0].workcentre_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === stage_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === step_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === workcentre_id
                      ? {
                          ...wc,
                          jobs: [...data, ...wc.jobs],
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  const {
    step_id: ns_id,
    stage_id: nst_id,
    workcentre_id: nwc_id,
  } = state.wcMap[wc_id];

  newState.stages = newState.stages.map((stage) =>
    stage.id === nst_id
      ? {
          ...stage,
          steps: stage.steps.map((step) =>
            step.id === ns_id
              ? {
                  ...step,
                  workcentres: step.workcentres.map((wc) =>
                    wc.id === nwc_id
                      ? {
                          ...wc,
                          jobs: wc.jobs.map((job) =>
                            job.core_objectable.id === so_id
                              ? {
                                  ...job,
                                  core_objectable: {
                                    ...job.core_objectable,
                                    mdn_done: true,
                                  },
                                }
                              : job
                          ),
                        }
                      : wc
                  ),
                }
              : step
          ),
        }
      : stage
  );

  return {
    ...state,
    detailedGrid: newState,
  };
};
