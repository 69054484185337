import { commentConstants, errorAndLoadingConstants } from "../constants";
import { commentService } from "../services";
import { requestFailError } from "../utils";

const addComment = (reqBody, job_id) => {
  return async (dispatch) => {
    const dispatchFailure = (err) => {
      dispatch({
        type: commentConstants.SUBMIT_COMMENT_FAILURE,
      });
      dispatch({
        type: errorAndLoadingConstants.OPEN_ALERT,
        message: err,
        alertType: "danger",
      });
      setTimeout(() => {
        dispatch({
          type: errorAndLoadingConstants.CLOSE_ALERT,
        });
      }, 5000);
    };

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await commentService.addComment(reqBody).then(
      (comment) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (comment.status === 200) {
          dispatch({
            type: commentConstants.SUBMIT_COMMENT_SUCCESS,
            comment: comment.data.comment,
            job_id,
          });
        } else {
          const errMsg = requestFailError(comment);
          dispatchFailure(errMsg);
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(err);
      }
    );
  };
};

const storeComments = (comments, job_id) => {
  return (dispatch) => {
    dispatch({
      type: commentConstants.STORE_COMMENTS,
      comments,
      job_id,
    });
  };
};

export const commentActions = {
  addComment,
  storeComments,
};
