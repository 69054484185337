import { errorAndLoadingConstants, productTypesConstants } from "../constants";
import { productTypeService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getProductType = (type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await productTypeService.getProductType(type).then(
      (productType) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (productType.status === 200) {
          dispatch({
            type: productTypesConstants.PRODUCT_TYPE_SUCCESS,
            data: {
              type,
              product_type: productType.data.product_types,
            },
          });
        } else {
          const errMsg = requestFailError(productType, "Error getting Product");
          dispatchFailure(dispatch, errMsg, {
            type: productTypesConstants.PRODUCT_TYPE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: productTypesConstants.PRODUCT_TYPE_FAILURE,
        });
      }
    );
  };
};

export const getAllProductTypes = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await productTypeService.getAllProductTypes().then(
      (productType) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (productType.status === 200) {
          dispatch({
            type: productTypesConstants.GET_ALL_PRODUCT_TYPE_SUCCESS,
            data: productType.data.product_types,
          });
        } else {
          const errMsg = requestFailError(productType, "Error getting Product");
          dispatchFailure(dispatch, errMsg, {
            type: productTypesConstants.GET_ALL_PRODUCT_TYPE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: productTypesConstants.GET_ALL_PRODUCT_TYPE_FAILURE,
        });
      }
    );
  };
};

export const getColorCodes = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await productTypeService.getColorCodes().then(
      (colorCodes) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (colorCodes.status === 200) {
          dispatch({
            type: productTypesConstants.GET_COLOR_CODES_SUCCESS,
            data: colorCodes.data.color_codes,
          });
        } else {
          const errMsg = requestFailError(colorCodes, "Error getting Product");
          dispatchFailure(dispatch, errMsg, {
            type: productTypesConstants.GET_COLOR_CODES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: productTypesConstants.GET_ALL_PRODUCT_TYPE_FAILURE,
        });
      }
    );
  };
};

export const productTypeActions = {
  getProductType,
  getAllProductTypes,
  getColorCodes,
};
