import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PMSAlert from "../PMS/components/PMSAlerts";
import { BasePaths } from "../utils";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const StockTransfer = lazy(() => import("./pages/StockTransfer"));
const SearchBox = lazy(() => import("./pages/SearchBox"));
const Report = lazy(() => import("./pages/Report"));

const baseRoute = BasePaths.WAREHOUSE;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const WarehouseRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/d/`}>
            <Dashboard />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/stock_transfer/`}>
            <StockTransfer />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/reports/`}>
            <Report />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/search_box/`}>
            <SearchBox />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlert />
    </>
  );
};

export default WarehouseRoutes;
