import React from "react";
import moment from "moment";
import { formattedNumber, isStringValid } from "../../../utils";
import StepTopography from "../StepTopography";
import UploadedFileLink from "../UploadedFileLink";

import Typography from "@material-ui/core/Typography";

export const IndentDetails = ({ indent }) => {
  return (
    <div className="d-flex flex-wrap px-md-3">
      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              isStringValid(indent.requisition_slip_number)
                ? indent.requisition_slip_number
                : "-"
            }
            body2="Requisition Slip No"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.department && isStringValid(indent.department.name)
                ? indent.department.name
                : "-"
            }
            body2="Department"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.item && isStringValid(indent.item.name)
                ? indent.item.name
                : "-"
            }
            body2="Item"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.item && isStringValid(indent.item.code)
                ? indent.item.code
                : "-"
            }
            body2="Item Code"
            reverse={true}
          />
        </div>
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.item &&
              indent.item.unit &&
              isStringValid(indent.item.unit.name)
                ? indent.item.unit.name
                : "-"
            }
            body2="Unit"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.required_date
                ? moment(indent.required_date).local().format("DD/MM/YYYY")
                : "-"
            }
            body2="Required Date"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requested_quantity
                ? formattedNumber(indent.requested_quantity)
                : "-"
            }
            body2="Requested Qty"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.approved_quantity
                ? formattedNumber(indent.approved_quantity)
                : "-"
            }
            body2="Approved Qty"
            reverse={true}
          />
        </div>
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_filled_by
                ? `${indent.requisition_filled_by.firstname} ${indent.requisition_filled_by.lastname}`
                : "-"
            }
            body2="Filled By"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_filled_time
                ? moment(indent.requisition_filled_time)
                    .local()
                    .format("DD/MM/YYYY hh:mm A")
                : "-"
            }
            body2="Filled Time"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_approved_by
                ? `${indent.requisition_approved_by.firstname} ${indent.requisition_approved_by.lastname}`
                : "-"
            }
            body2="Approved By"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_approved_time
                ? moment(indent.requisition_approved_time)
                    .local()
                    .format("DD/MM/YYYY hh:mm A")
                : "-"
            }
            body2="Approved Time"
            reverse={true}
          />
        </div>
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_final_approved_by
                ? `${indent.requisition_final_approved_by.firstname} ${indent.requisition_final_approved_by.lastname}`
                : "-"
            }
            body2="Final Approved By"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_final_approved_time
                ? moment(indent.requisition_final_approved_time)
                    .local()
                    .format("DD/MM/YYYY hh:mm A")
                : "-"
            }
            body2="Final Approved Time"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_received_by
                ? `${indent.requisition_received_by.firstname} ${indent.requisition_received_by.lastname}`
                : "-"
            }
            body2="Received By"
            reverse={true}
          />
        </div>
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              indent.requisition_received_time
                ? moment(indent.requisition_final_approved_time)
                    .local()
                    .format("DD/MM/YYYY hh:mm A")
                : "-"
            }
            body2="Received Time"
            reverse={true}
          />
        </div>
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-25 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={isStringValid(indent.order_type) ? indent.order_type : "-"}
            body2="Order Type"
            reverse={true}
          />
        </div>
        {indent.item_sku && !indent.item_sku.manufacturing_company && (
          <div className="w-25 px-md-2 py-2 py-md-0">
            <StepTopography
              body1={"-"}
              body2="Manufacturing Company"
              reverse={true}
            />
          </div>
        )}
        {indent.item_sku && (
          <div className="w-25 px-md-2 py-2 py-md-0">
            <StepTopography
              body1={
                isStringValid(indent.item_sku.sku_code)
                  ? indent.item_sku.sku_code
                  : "-"
              }
              body2="SKU Code"
              reverse={true}
              wordBreak={{ body1: true }}
            />
          </div>
        )}
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={isStringValid(indent.remarks) ? indent.remarks : "-"}
            body2="Remarks"
            reverse={true}
          />
        </div>
      </div>

      <div className="d-md-flex w-100 mb-4">
        <div className="w-100 px-md-2 py-2 py-md-0">
          <StepTopography
            body1={
              isStringValid(indent.additional_item_description)
                ? indent.additional_item_description
                : "-"
            }
            body2="Additional Item Description"
            reverse={true}
          />
        </div>
      </div>

      {indent.files && indent.files.length > 0 && (
        <div className="d-flex w-100">
          <div className="w-100">
            <Typography variant="h6">Attachment:</Typography>
            {indent.files.map((file) => (
              <UploadedFileLink file={file} key={file.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IndentDetails;
