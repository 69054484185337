import { reportConstants, errorAndLoadingConstants } from "../constants";
import { reportService } from "../services";
import { requestFailError } from "../utils";

const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

const getReport = (reportName) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: reportConstants.REPORT_REQUEST,
      data: reportName,
    });
    await reportService.getReport(reportName).then(
      (report) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (report.status === 200) {
          dispatch({
            type: reportConstants.REPORT_SUCCESS,
            data: report.data,
          });
        } else {
          const errMsg = requestFailError(report);
          dispatchFailure(dispatch, errMsg, {
            type: reportConstants.REPORT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: reportConstants.REPORT_FAILURE,
        });
      }
    );
  };
};

const downloadReport = (reportName) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await reportService.downloadReport(reportName).then(
      () => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
      },
      (err) => {
        const errMsg = requestFailError(err);
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, errMsg, {
          type: null,
        });
      }
    );
  };
};

export const reportActions = {
  getReport,
  downloadReport,
};
