import { reportConstants } from "../constants";

const inititalState = {
  reports: [
    {
      id: "steps_report",
      name: "Steps Report",
      report: [],
    },
    {
      id: "employees_report",
      name: "Employees Report",
      report: [],
    },
    {
      id: "vendors_report",
      name: "Vendors Report",
      report: [],
    },
  ],
};

export const reports = (state = inititalState, action) => {
  switch (action.type) {
    case reportConstants.REPORT_REQUEST:
      return {
        ...state,
        reports: state.reports.map((report) =>
          report.id === action.data ? { ...report, report: [] } : report
        ),
      };

    case reportConstants.REPORT_SUCCESS:
      return {
        ...state,
        reports: saveReport(state.reports, action.data),
      };

    case reportConstants.REPORT_FAILURE:
    default:
      return state;
  }
};

const saveReport = (state, data) => {
  let newState = [...state];
  const reportName = Object.keys(data)[0];
  const report = data[reportName].map((report, index) => ({
    ...report,
    serial_no: index + 1,
  }));
  newState = newState.map((rpt) =>
    rpt.id === reportName ? { ...rpt, report } : rpt
  );
  return newState;
};
