export const poFormConstants = {
  GET_VENDOR_REQUEST: "GET_VENDOR_REQUEST",
  GET_VENDOR_SUCCESS: "GET_VENDOR_SUCCESS",
  GET_VENDOR_FAILURE: "GET_VENDOR_FAILURE",

  GET_VENDOR_INDENTS_REQUEST: "GET_VENDOR_INDENTS_REQUEST",
  GET_VENDOR_INDENTS_SUCCESS: "GET_VENDOR_INDENTS_SUCCESS",
  GET_VENDOR_INDENTS_FAILURE: "GET_VENDOR_INDENTS_FAILURE",

  GET_DEPARTMENT_INDENTS_REQUEST: "GET_DEPARTMENT_INDENTS_REQUEST",
  GET_DEPARTMENT_INDENTS_SUCCESS: "GET_DEPARTMENT_INDENTS_SUCCESS",
  GET_DEPARTMENT_INDENTS_FAILURE: "GET_DEPARTMENT_INDENTS_FAILURE",

  PO_UPDATE_REQUEST: "PO_UPDATE_REQUEST",
  PO_UPDATE_SUCCESS: "PO_UPDATE_SUCCESS",
  PO_UPDATE_FAILURE: "PO_UPDATE_FAILURE",

  CREATE_PO_SUCCESS: "CREATE_PO_SUCCESS",
  CREATE_PO_FAILURE: "CREATE_PO_FAILURE",

  REJECT_PO_SUCCESS: "REJECT_PO_SUCCESS",
  REJECT_PO_FAILURE: "REJECT_PO_FAILURE",

  GET_PURCHASE_TYPE_SUCCESS: "GET_PURCHASE_TYPE_SUCCESS",
  GET_PURCHASE_TYPE_FAILURE: "GET_PURCHASE_TYPE_FAILURE",

  GET_CURRENCIES_SUCCESS: "GET_CURRENCIES_SUCCESS",
  GET_CURRENCIES_FAILURE: "GET_CURRENCIES_FAILURE",

  GET_ITC_ELIGIBILITIES_SUCCESS: "GET_ITC_ELIGIBILITIES_SUCCESS",
  GET_ITC_ELIGIBILITIES_FAILURE: "GET_ITC_ELIGIBILITIES_FAILURE",

  GET_MATERIAL_CENTER_SUCCESS: "GET_MATERIAL_CENTER_SUCCESS",
  GET_MATERIAL_CENTER_FAILURE: "GET_MATERIAL_CENTER_FAILURE",

  GET_PO_USERS_SUCCESS: "GET_PO_USERS_SUCCESS",
  GET_PO_USERS_FAILURE: "GET_PO_USERS_FAILURE",
};
