import React from "react";
import CheckboxSelect from "./CheckboxSelect";

export default function Checklist({ checklists, selectedItem, handleChange }) {
  return (
    <div className="checklists">
      {checklists.map((cl) => (
        <CheckboxSelect
          key={cl.checklist.id}
          name={cl.checklist.name}
          options={cl.checklist.checklist_options}
          selectedItem={selectedItem}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
}
