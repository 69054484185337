import React from "react";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { useStyles } from "./Css";
import StepsContainer from "./StepsContainer";

export default function RecipeReviewCard({
  steps,
  handleDrop,
  stage_id,
  stage_index,
  stopDropAlert,
  invalidDrop,
  bottleNeckStep,
  indentSearch,
}) {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={`${classes.stageContainer} p-0`}>
      <Grid container spacing={2} wrap="nowrap">
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            <Grid item xs={12} md={6} lg={4} className={classes.cardMinWidth}>
              <StepsContainer
                step={step}
                step_index={index}
                handleDrop={handleDrop}
                stage_id={stage_id}
                stage_index={stage_index}
                invalidDrop={invalidDrop}
                stopDropAlert={stopDropAlert}
                indentSearch={indentSearch}
                isBottleneck={step.id === bottleNeckStep ? true : false}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
}
