import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  authorName: {
    fontSize: "9px",
    color: "#4D4F5C",
    opacity: "50%",
  },
  commentText: {
    fontSize: "11px",
  },
  connectorLine: {
    borderColor: "#808495",
  },
  connecotrVertical: {
    padding: 0,
    marginLeft: 3,
  },
  connectorLineVertical: {
    borderLeftWidth: 2,
  },
}));

const stepLabelStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    position: "relative",
    "&::before": {
      content: '""',
      width: 2,
      height: "100%",
      display: "block",
      position: "absolute",
      background: "#808495",
      top: 9,
      left: 3,
    },
  },
});

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#43425D",
    display: "flex",
    height: 9,
  },
  circle: {
    width: 9,
    height: 9,
    borderRadius: "50%",
    border: "2px solid #43425D",
  },
});

const StepIcon = () => {
  const classes = useQontoStepIconStyles();
  return (
    <div className={classes.root}>
      <div className={classes.circle} />
    </div>
  );
};

function ActiveSteps({ prevComments }) {
  const classes = useStyles();
  const stepLabelClasses = stepLabelStyles();

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={-1}
        orientation="vertical"
        connector={
          <StepConnector
            classes={{
              line: classes.connectorLine,
              lineVertical: classes.connectorLineVertical,
              vertical: classes.connecotrVertical,
            }}
          />
        }
      >
        {prevComments
          ? prevComments.map((comment, index) => (
              <Step key={comment.id}>
                <StepLabel
                  className={stepLabelClasses.root}
                  StepIconComponent={StepIcon}
                >
                  <div className={classes.MuiStepLabel}>
                    <div className={classes.commentText}>
                      {comment.comment_text}
                    </div>
                    <div className={classes.authorName}>
                      {comment.author_name}{" "}
                      {moment(comment.created_at)
                        .local()
                        .format("DD/MM/YYYY hh:mm A")}
                    </div>
                  </div>
                </StepLabel>
              </Step>
            ))
          : ""}
      </Stepper>
    </div>
  );
}

export default ActiveSteps;
