import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getAssemblyList = (type) => {
  return axios
    .get(`/api/v1/assembly/${type}_assembly`)
    .then((response) => response)
    .catch((err) => err);
};

const editAssemblyList = (requestData) => {
  return axios
    .post(`/api/v1/assembly/assign_worker`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const assemblyListService = {
  getAssemblyList,
  editAssemblyList,
};
