import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getDate = () => {
  return axios
    .get("/api/v1/purchase_orders/currentdate_time")
    .then((response) => response)
    .catch((err) => err);
};

export const dateServices = {
  getDate,
};
