import { dateConstants } from "../constants";

export const date = (state = "", action) => {
  switch (action.type) {
    case dateConstants.GET_DATE_REQUEST:
      return "";

    case dateConstants.GET_DATE_SUCCESS:
      return action.data;

    default:
      return state;
  }
};
