import { errorAndLoadingConstants, auditToolConstant } from "../constants";
import { auditToolService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const resetAuditData = () => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.RESET_AUDIT_DATA,
    });
  }
};

const getAuditList = (body) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.getAuditList(body).then(
      (audit_list) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (audit_list.status === 200) {
          dispatch({
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_SUCCESS,
            data: audit_list.data.audit_list,
          });
        } else {
          const errMsg = requestFailError(audit_list);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_FAILURE,
        });
      }
    );
  };
};

const getAuditDetails = (body) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.getAuditDetails(body).then(
      (audit_details) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (audit_details.status === 200) {
          dispatch({
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_SUCCESS,
            data: audit_details.data.audit_details,
          });
        } else {
          const errMsg = requestFailError(audit_details);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_FAILURE,
        });
      }
    );
  };
};

const completeAuditScanning = (body) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.COMPLETE_AUDIT_SCANNING_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.completeAuditScanning(body).then(
      (audit_details) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (audit_details.status === 200) {
          dispatch({
            type: auditToolConstant.COMPLETE_AUDIT_SCANNING_SUCCESS,
            data: audit_details.data.audit_tool,
          });
        } else {
          const errMsg = requestFailError(audit_details);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.COMPLETE_AUDIT_SCANNING_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_FAILURE,
        });
      }
    );
  };
};

const getWarehouseAudit = (body) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.GET_WAREHOUSE_AUDIT_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.getWarehouseAudit(body).then(
      (warehouse_audit) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (warehouse_audit.status === 200) {
          dispatch({
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_SUCCESS,
            data: warehouse_audit.data,
          });
        } else {
          const errMsg = requestFailError(warehouse_audit);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.GET_WAREHOUSE_AUDIT_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_WAREHOUSE_FG_AUDIT_FAILURE,
        });
      }
    );
  };
};

const getDispatchStations = (warehouseId) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.GET_DISPATCH_STATION_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.getDispatchStations(warehouseId).then(
      (dispatch_stations) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (dispatch_stations.status === 200) {
          dispatch({
            type: auditToolConstant.GET_DISPATCH_STATION_SUCCESS,
            data: dispatch_stations.data.dispatch_stations,
          });
        } else {
          const errMsg = requestFailError(dispatch_stations);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.GET_DISPATCH_STATION_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_DISPATCH_STATION_FAILURE,
        });
      }
    );
  };
};

const getBRList = (date, station_id) => {
  return async (dispatch) => {
    dispatch({
      type: auditToolConstant.GET_BR_LIST_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await auditToolService.getBRList(date, station_id).then(
      (br_list) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (br_list.status === 200) {
          dispatch({
            type: auditToolConstant.GET_BR_LIST_SUCCESS,
            data: br_list.data.br_list,
          });
        } else {
          const errMsg = requestFailError(br_list);
          dispatchFailure(dispatch, errMsg, {
            type: auditToolConstant.GET_BR_LIST_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: auditToolConstant.GET_BR_LIST_FAILURE,
        });
      }
    );
  };
};

export const auditToolActions = {
  resetAuditData,
  getAuditList,
  getAuditDetails,
  completeAuditScanning,
  getWarehouseAudit,
  getDispatchStations,
  getBRList,
};
