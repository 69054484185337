import { createMuiTheme } from "@material-ui/core";

import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";

const theme = createMuiTheme({
  spacing: 4,
  palette,
  typography,
  overrides,
  breakpoints,
  pms: {
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  props: {
    MuiDialog: {
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
    },
  },
});

export default theme;
