import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import { userService } from "./services";
import configureStore from "./store";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";

const renderApp = (preloadedState) => {
  const store = configureStore(preloadedState);

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
};

(async () => renderApp(await userService.checkLoggedIn()))();

serviceWorker.unregister();
