export const userConstants = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    LOGOUT: 'USER_LOGOUT',

    LOGGED_IN_REQUEST: 'USER_LOGGED_ID_REQUEST',
    LOGGED_IN_SUCCESS: 'USER_LOGGED_ID_SUCCESS',
    LOGGED_IN_FAILURE: 'USER_LOGGED_ID_FAILURE',
}