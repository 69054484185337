import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  flowSelect: {
    border: "1px solid #80849580",
    borderRadius: "4px",
    minWidth: 142,
    width: "fit-content",
    "&::before": {
      display: "none",
    },
    marginBottom: "10px",
  },
  selectEmpty: {
    paddingLeft: "10px",
  },
  link: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
      color: "inherit",
    },
  },
}));

const FlowViewDropDown = ({ grids, handleChange, selectedFlow }) => {
  const classes = useStyles();
  const location = useLocation();
  const isMyView = location.pathname.search("myview") !== -1;
  const flowId = useRef("");
  flowId.current = parseInt(location.pathname.split("/").slice(-1)[0]);

  useEffect(() => {
    if (flowId.current) {
      flowId.current =
        flowId.current > 0 && flowId.current <= 4 ? flowId.current : "";
      handleChange(flowId.current);
    } else {
      handleChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId.current]);

  return (
    <div className={classes.flowSelect}>
      <Select displayEmpty value={selectedFlow} className={classes.selectEmpty}>
        <MenuItem value="">
          <em>Select Flow:</em>
        </MenuItem>
        {grids.map((grid) => (
          <MenuItem
            component={Link}
            to={`/${isMyView ? "myview" : "flowview"}/${grid.id}`}
            key={grid.id}
            value={grid.id}
            className={classes.link}
          >
            {grid.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default FlowViewDropDown;
