import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ExportButton from "./ExportButton";
import { useStyles } from "./reportStyles";
import { humanizeFormat } from "../../../utils/humanizeFormat";
import { formattedNumber } from "../../../utils";

function descendingComparator(a, b, orderBy) {
  if (!["avg_delay_time_in_mins", "serial_no"].includes(orderBy)) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  } else {
    let first, second;
    if (b[orderBy]) second = parseFloat(b[orderBy]);
    else second = 0;
    if (a[orderBy]) first = parseFloat(a[orderBy]);
    else first = 0;
    if (second < first) return -1;
    if (second > first) return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EmployeeReport({ reports, reportName, reportId }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHangler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Paper className={classes.reportBackground}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <div className={classes.ReportHeader}>
          <div className={classes.exportbtn}>
            <ExportButton id={reportId} />
          </div>
        </div>

        <Table
          className={classes.table}
          stickyHeader
          aria-label="Employee Report"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sortDirection={orderBy === "serial_no" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "serial_no"}
                  direction={orderBy === "serial_no" ? order : "asc"}
                  onClick={createSortHangler("serial_no")}
                >
                  Serial No.
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel>Employee Name</TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sortDirection={
                  orderBy === "completed_jobs_count" ? order : false
                }
              >
                <TableSortLabel
                  active={orderBy === "completed_jobs_count"}
                  direction={orderBy === "completed_jobs_count" ? order : "asc"}
                  onClick={createSortHangler("completed_jobs_count")}
                >
                  No. of Task Done
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sortDirection={
                  orderBy === "in_progress_jobs_count" ? order : false
                }
              >
                <TableSortLabel
                  onClick={createSortHangler("in_progress_jobs_count")}
                  active={orderBy === "in_progress_jobs_count"}
                  direction={
                    orderBy === "in_progress_jobs_count" ? order : "asc"
                  }
                >
                  No. of Task Inline
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sortDirection={orderBy === "delayed_jobs_count" ? order : false}
              >
                <TableSortLabel
                  onClick={createSortHangler("delayed_jobs_count")}
                  active={orderBy === "delayed_jobs_count"}
                  direction={orderBy === "delayed_jobs_count" ? order : "asc"}
                >
                  No. of Delayed Task
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sortDirection={
                  orderBy === "avg_delay_time_in_mins" ? order : false
                }
              >
                <TableSortLabel
                  onClick={createSortHangler("avg_delay_time_in_mins")}
                  active={orderBy === "avg_delay_time_in_mins"}
                  direction={
                    orderBy === "avg_delay_time_in_mins" ? order : "asc"
                  }
                >
                  Average Delay Time
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(reports, getComparator(order, orderBy)).map(
              (report) => (
                <TableRow key={report.id}>
                  <TableCell align="left">{report?.serial_no ?? "-"}</TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {report.employee_full_name
                      ? report.employee_full_name
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {report.completed_jobs_count
                      ? formattedNumber(report.completed_jobs_count)
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {report.in_progress_jobs_count
                      ? formattedNumber(report.in_progress_jobs_count)
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {report.delayed_jobs_count
                      ? formattedNumber(report.delayed_jobs_count)
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {report.avg_delay_time_in_mins
                      ? humanizeFormat(report.avg_delay_time_in_mins * 60000)
                      : "-"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
