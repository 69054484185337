import { assemblyTestingConstants } from "../constants";

export function assemblyTesting(
  state = {
    stator: {},
    rotor: {},
    jar: {},
    plan: {},
    failureResons: [],
  },
  action
) {
  switch (action.type) {
    case assemblyTestingConstants.SEND_ASSEMBLY_TESTING_SUCCESS:
    case assemblyTestingConstants.GET_DETAILED_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          ...action.data,
        },
      };

    case assemblyTestingConstants.GET_ASSEMBLY_PLAN_SUCCESS:
      return {
        ...state,
        [action.extra]: action.data,
      };

    case assemblyTestingConstants.GET_ASSEMBLY_PLAN_FAILURE:
      return {
        ...state,
        [action.extra]: {},
      };

    case assemblyTestingConstants.GET_FAILURE_REASON_BY_TYPE_SUCCESS:
      return {
        ...state,
        failureResons: action.data.failure_reasons,
      };

    case assemblyTestingConstants.GET_DETAILED_PLAN_FAILURE:
    case assemblyTestingConstants.SEND_ASSEMBLY_TESTING_FAILURE:
    default:
      return state;
  }
}
