import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";

import {
  jobActions,
  vendorActions,
  checklistActions,
  indentFormActions,
  errorAndLoadingActions,
} from "../../../actions";
import {
  indentNumberWithPart,
  getStepNameByWC,
  getLastJobLog,
  numCheck,
  formattedNumber,
} from "../../../utils";
import { useStyles } from "../Css";
import SKUTable from "./SKUTable";
import Checklist from "../Checklist";
import UploadedFiles from "../UploadedFiles";
import CreateSkuForm from "./CreateSkuForm";
import CardsCommentDialogue from "../CardsDialogue/CardsCommentDialogue";
import StepTopography from "../StepTopography";
import CustomAlert from "../CustomAlert";
import IndentDetails from "./IndentDetails";
import VendorAccordion from "./VendorAccordion";
import ItemAccordian from "./ItemAccordian";
import ManufacturingCompanyAccordian from "./ManufacturingCompanyAccordian";
import ListboxComponent from "../ListboxComponent";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DialogActions from "@material-ui/core/DialogActions";
import ConsignmentContainer from "./ConsignmentContainer";
import { green } from "@material-ui/core/colors";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const elementGap = {
  primary: "30px",
  secondary: "16px",
};

const TaskDetailsStyles = makeStyles((theme) => ({
  formControl: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
  uniformWidth: {
    width: 210,
  },
  DialogMinWidth: {
    minWidth: "90%",
    padding: [
      [theme.spacing(5), theme.spacing(6), theme.spacing(4), theme.spacing(6)],
    ],
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "95vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "80vh",
    },
  },
  indentSection: {
    minWidth: "100%",
    padding: elementGap.secondary,
    paddingBottom: 0,
    overflowY: "scroll",
    boxShadow: "0px 2px 6px #0000000A",
    marginRight: theme.spacing(2.5),
    "& h4": {
      fontSize: 15,
      borderBottom: "3px solid #F1F1F3",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 667,
    },
  },
  vendorSection: {
    minWidth: "100%",
    maxWidth: "100%",
    padding: elementGap.secondary,
    paddingBottom: 0,
    overflowY: "scroll",
    boxShadow: "0px 2px 6px #0000000A",
    marginRight: theme.spacing(2.5),
    "& h4": {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 595,
      maxWidth: 595,
    },
  },
  checklistSection: {
    minWidth: "100%",
    padding: elementGap.secondary,
    paddingBottom: 0,
    overflowY: "scroll",
    boxShadow: "0px 2px 6px #0000000A",
    marginRight: theme.spacing(2.5),
    "& h4": {
      fontSize: 15,
      borderBottom: "1px solid #F1F1F3",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 337,
    },
  },
  DTitle: {
    padding: 0,
    flex: 1,
  },
  DHead: {
    display: "flex",
    alignItems: "baseline",
    paddingBottom: elementGap.secondary,
    borderBottom: "1px solid #F1F1F3",
  },
  itemDropdown: {
    width: "64ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
  truncate: {
    display: "-webkit-box",
    "-moz-box-orient": "vertical",
    "-webkit-box-orient": "vertical",
    "box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const getState = (arr, id) => {
  id = parseInt(id);
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) {
      return arr[i].state;
    }
  }
  return undefined;
};

export function TasksDetails({
  job,
  getJob,
  routeJob,
  stage_id,
  step_id,
  workcentre_id,
  workcentre_name,
  checklists,
  saveChecklist,
  uploadFile,
  vendors,
  allVendors,
  fetchItemVendors,
  assignSKU,
  skus,
  fetchSkus,
  unblockSKU,
  openAlert,
  closeAlert,
  fetchAllVendor,
  fetchVendorDetails,
  vendorDetail,
  rejectIndent,
  holdIndent,
  itemDetail,
  mcDetail,
  reviveIndent,
  fetchItems,
  items = [],
  updateItem,
}) {
  const classes = useStyles();
  const classes2 = TaskDetailsStyles();
  const [showAllVendors, setShowAllVendors] = useState(false);
  const [checklistSelect, setChecklistSelect] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedItem, setSelectedItem] = useState(job?.core_objectable?.item);
  const [itemQty, setItemQty] = useState(job?.core_objectable?.balance ?? 0);
  const [selectedSKU, setSelectedSKU] = useState("");
  const [orderType, setOrderType] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    validateAlert: {
      assignSku: false,
    },
    confirmAlert: {
      assignSku: false,
      withoutChecklist: false,
      rejectIndent: false,
      holdIndent: false,
      reviveIndent: false,
    },
    warningAlert: {
      assignSku: false,
      selectVendor: false,
      addConsignment: false,
    },
  });
  const allVendorRef = useRef(null);
  const co = job.core_objectable;
  const balance = co?.balance ?? 0;

  const fetchData = useCallback(
    async (item_id) => {
      await fetchItemVendors(item_id);
    },
    [fetchItemVendors]
  );

  useEffect(() => {
    async function fetchSKU(item_id, vendor_id) {
      try {
        await fetchSkus(item_id, vendor_id);
      } catch (err) {
        openAlert(`Error getting SKU: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    setOrderType("");
    if (selectedVendor) fetchSKU(co.item_id, selectedVendor.id);
  }, [fetchSkus, selectedVendor, openAlert, closeAlert, co.item_id]);

  useEffect(() => {
    (async () => {
      if (showAllVendors) {
        await fetchAllVendor();
        allVendorRef.current = true;
      } else {
        if (allVendorRef.current) {
          fetchData(co.item_id);
        }
      }
    })();
  }, [showAllVendors, fetchAllVendor, co.item_id, fetchData]);

  useEffect(() => {
    (async () => {
      if (selectedVendor) await fetchVendorDetails(selectedVendor.id);
    })();
  }, [selectedVendor, fetchVendorDetails]);

  useMemo(() => {
    setItemQty(balance);
  }, [balance]);

  const handleClickOpen = () => {
    async function fetchJob(co_id, job_id, workcentre_id) {
      try {
        await getJob(co_id, job_id, workcentre_id);
      } catch (err) {
        console.log("Get Job error: ", err);
      }
    }
    fetchJob(job.core_objectable.id, job.id, workcentre_id);
    setOpen(true);
    if (!co.item_sku) fetchData(co.item_id);
  };

  const assignSKUValidateAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      validateAlert: {
        ...prevState.validateAlert,
        assignSku: false,
      },
    }));
  };

  const assignSKUConfirmAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      confirmAlert: {
        ...prevState.confirmAlert,
        assignSku: false,
      },
    }));
  };

  const assignSKUWarningAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      warningAlert: {
        ...prevState.warningAlert,
        assignSku: false,
      },
    }));
  };

  const withoutChecklistConfirmAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      confirmAlert: {
        ...prevState.confirmAlert,
        withoutChecklist: false,
      },
    }));
  };

  const selectVendorAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      warningAlert: {
        ...prevState.warningAlert,
        selectVendor: false,
      },
    }));
  };

  const addConsignmentAlertClose = () => {
    setAlert((prevState) => ({
      ...prevState,
      warningAlert: {
        ...prevState.warningAlert,
        addConsignment: false,
      },
    }));
  };

  const handleChecklistChange = (event) => {
    event.persist();
    setChecklistSelect((prevState) => {
      let newState = [...prevState];
      if (event.target.checked) newState.push(+event.target.value);
      else {
        let index = newState.indexOf(+event.target.value);
        newState.splice(index, 1);
      }
      return newState;
    });
  };

  const handleSKUChange = (event) => {
    setSelectedSKU(event.target.value);
  };

  const handleShowVendors = () => {
    setShowAllVendors((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
    setChecklistSelect([]);
    setSelectedVendor(null);
    setFilesToUpload(null);
    setShowAllVendors(false);
    setSelectedItem(job?.core_objectable?.item ?? null);
  };

  const handleFileUploadChange = (event) => {
    event.persist();
    setErrorMsg("")
    setFilesToUpload(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if(filesToUpload){
      const MAX_FILE_SIZE = 8192 // 8MB

      const fileSizeKiloBytes = filesToUpload.size / 1024

      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        setErrorMsg("File size is greater than maximum limit(8 MB)");
        setIsSuccess(false);
        return;
      }
      setErrorMsg("")
      setIsSuccess(true)

      var formData = new FormData();
      formData.append("file", filesToUpload);
      try {
        await uploadFile(formData, job.id, step_id, stage_id, workcentre_id);
        setFilesToUpload(null);
      } catch (err) {
        openAlert(`Error Uploading File: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    
  };

  const handleAssignSKU = () => {
    const state = getState(skus, selectedSKU);
    if (state !== "approved") {
      setAlert((prevState) => ({
        ...prevState,
        warningAlert: {
          ...prevState.warningAlert,
          assignSku: true,
        },
      }));
      return;
    }
    if (!selectedSKU || !orderType) {
      setAlert((prevState) => ({
        ...prevState,
        validateAlert: {
          ...prevState.validateAlert,
          assignSku: true,
        },
      }));
      return;
    }
    setAlert((prevState) => ({
      ...prevState,
      confirmAlert: {
        ...prevState.confirmAlert,
        assignSku: true,
      },
    }));
  };

  const assignSKUConfirmAlertSubmit = async () => {
    try {
      await assignSKU(selectedSKU, orderType, co.id);
      setAlert({
        ...alert,
        confirmAlert: {
          ...alert.confirmAlert,
          assignSku: false,
        },
      });
    } catch (err) {
      openAlert(`Error assigning SKU: ${err.message}`, "danger");
      setTimeout(() => {
        closeAlert();
      }, 5000);
    }
  };

  const handleJobDone = async () => {
    const job_id = job.id;
    const last_job_log = getLastJobLog(job.job_logs);
    const last_job_log_id = last_job_log.id;
    const job_action = "yes";
    const last_job_log_state = last_job_log.state;

    const extraPayload = {
      stage_id,
      step_id,
      workcentre_id,
      job_id,
    };

    const routeJobRequestBody = {
      job_id,
      last_job_log_id,
      job_action,
      last_job_log_state,
    };

    const checklistRequestBody = {
      job_id,
      stage_id,
      step_id,
      workcentre_id,
      last_job_log_id,
      last_job_log_state,
      option_ids: checklistSelect,
    };

    if (workcentre_name === "wc2" && !co.vendor) {
      setAlert((prevState) => ({
        ...prevState,
        warningAlert: {
          ...prevState.warningAlert,
          selectVendor: true,
        },
      }));
      return;
    }

    if (
      workcentre_name === "wc6" &&
      (!co.consignments || co.consignments.length < 1)
    ) {
      setAlert((prevState) => ({
        ...prevState,
        warningAlert: {
          ...prevState.warningAlert,
          addConsignment: true,
        },
      }));
      return;
    }

    if (checklists.length > 0 && checklistSelect.length === 0) {
      setAlert((prevState) => ({
        ...prevState,
        confirmAlert: {
          ...prevState.confirmAlert,
          withoutChecklist: true,
        },
      }));
    } else if (checklists.length === 0) {
      if (last_job_log_state) {
        try {
          await routeJob(routeJobRequestBody, extraPayload);
        } catch (error) {
          openAlert(`${error.message}`, "danger");
          setTimeout(() => {
            closeAlert();
          }, 5000);
        }
      }
    } else {
      if (last_job_log_state) {
        try {
          await saveChecklist(checklistRequestBody);
          await routeJob(routeJobRequestBody, extraPayload);
        } catch (error) {
          openAlert(`${error.message}`, "danger");
          setTimeout(() => {
            closeAlert();
          }, 5000);
        }
      }
    }
  };

  const withoutChecklistConfirmAlertSubmit = async () => {
    const job_id = job.id;
    const last_job_log_id = getLastJobLog(job.job_logs);
    const job_action = "yes";
    const last_job_log_state = job.job_logs.find(
      (jl) => jl.id === last_job_log_id
    ).state;

    const routeJobRequestBody = {
      job_id,
      last_job_log_id,
      job_action,
      last_job_log_state,
    };

    const extraPayload = {
      stage_id,
      step_id,
      workcentre_id,
      job_id,
    };

    if (last_job_log_state) {
      await routeJob(routeJobRequestBody, extraPayload);
    }
  };

  const rejectIndentConfirmAlertClose = () => {
    setAlert({
      ...alert,
      confirmAlert: {
        ...alert.confirmAlert,
        rejectIndent: false,
      },
    });
  };

  const holdIndentConfirmAlertClose = () => {
    setAlert({
      ...alert,
      confirmAlert: {
        ...alert.confirmAlert,
        holdIndent: false,
      },
    });
  };

  const reviveIndentConfirmAlertClose = () => {
    setAlert({
      ...alert,
      confirmAlert: {
        ...alert.confirmAlert,
        reviveIndent: false,
      },
    });
  };

  const handleJobReject = async () => {
    try {
      await rejectIndent(co.id, {
        stage_id,
        step_id,
        workcentre_id,
        job_id: job.id,
      });
      rejectIndentConfirmAlertClose();
    } catch (error) {
      openAlert(`${error.message}`, "danger");
      setTimeout(() => {
        closeAlert();
      }, 5000);
    }
  };

  const handleHoldIndent = async () => {
    try {
      await holdIndent(co.id, {
        stage_id,
        step_id,
        workcentre_id,
        job_id: job.id,
      });
      holdIndentConfirmAlertClose();
    } catch (error) {
      openAlert(`${error.message}`, "danger");
      setTimeout(() => {
        closeAlert();
      }, 5000);
    }
  };

  const handleRevive = async () => {
    await reviveIndent(co.id, {
      stage_id,
      step_id,
      workcentre_id,
      job_id: job.id,
    });

    reviveIndentConfirmAlertClose();
  };

  const handleQtyChange = (e) => {
    e.persist();
    const { value } = e.target;
    setItemQty(numCheck(itemQty, +value));
  };

  const getItemList = async () => {
    await fetchItems();
  };
  const handleSaveItem = async () => {
    await updateItem(co.id, selectedItem.id, itemQty);
  };

  const submitBtnText = getStepNameByWC(workcentre_name);

  return (
    <div>
      <Button
        className={classes.VdBtn}
        size="small"
        onClick={handleClickOpen}
        color="primary"
      >
        <VisibilityOutlinedIcon fontSize="small" className="pr-1" /> View
        Details
      </Button>
      {open && (
        <Dialog
          open={open}
          maxWidth="md"
          aria-labelledby="indent-update-title"
          hideBackdrop={true}
          classes={{ paper: classes2.DialogMinWidth }}
        >
          <div className={classes2.DHead}>
            <DialogTitle className={classes2.DTitle} id="indent-update-title">
              Indent Description
            </DialogTitle>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent className="p-0 d-md-flex">
            <div className={classes2.indentSection}>
              <h4 className="pb-3 mb-4">
                IDN Number
                <span className="ml-4 ml-md-5 font-weight-normal">
                  {indentNumberWithPart(co)}
                </span>
              </h4>
              <IndentDetails indent={co} />
              <ItemAccordian
                item={itemDetail[selectedItem?.id ?? null]}
                item_id={selectedItem?.id ?? null}
              />
            </div>
            <div className={classes2.vendorSection}>
              <h4 className="pb-3">
                Balance Quantity to Order:{" "}
                <span className="font-weight-normal">
                  {co.balance
                    ? `${formattedNumber(co.balance)} ${
                        co?.item?.unit ? co?.item?.unit?.name : ""
                      }`
                    : `0 ${co?.item?.unit ? co?.item?.unit?.name : ""}`}
                </span>
              </h4>
              <div className="mb-2">
                <Autocomplete
                  id="selected_item"
                  options={items}
                  value={selectedItem}
                  getOptionLabel={(option) => option.name}
                  ListboxComponent={ListboxComponent}
                  className="d-inline-block"
                  disabled={
                    ["wc3", "wc4", "wc6", "wc7"].includes(workcentre_name) ||
                    job?.workcentre_id === 29
                      ? true
                      : false
                  }
                  onOpen={getItemList}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Item"
                      variant="outlined"
                      className={classes2.itemDropdown}
                      size="small"
                    />
                  )}
                  renderOption={(option) => (
                    <Tooltip title={option.name} position="bottom">
                      <Typography
                        id="selected_item-text"
                        className={classes2.truncate}
                      >
                        {option.name}
                      </Typography>
                    </Tooltip>
                  )}
                />
                <TextField
                  variant="outlined"
                  value={itemQty}
                  onChange={handleQtyChange}
                  label="Quantity"
                  size="small"
                  className={classes2.itemDropdown}
                  InputLabelProps={{ shrink: true }}
                  disabled={["wc3", "wc4", "wc6", "wc7"].includes(workcentre_name) || job?.workcentre_id === 29 ? true : false}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveItem}
                    disabled={
                      !selectedItem?.id ||
                      (selectedItem?.id === co.item.id && itemQty === balance)
                    }
                  >
                    Save Item
                  </Button>
                </div>
              </div>
              <div className="mb-2 d-md-flex align-items-center">
                <Autocomplete
                  id="selected_vendor"
                  options={showAllVendors ? allVendors : vendors}
                  value={co.item_sku ? co.vendor : selectedVendor}
                  getOptionLabel={(option) => option.name}
                  ListboxComponent={ListboxComponent}
                  className="d-inline-block"
                  disabled={co.item_sku ? true : false}
                  onChange={(event, newValue) => {
                    setSelectedVendor(newValue);
                    setSelectedSKU("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor"
                      variant="outlined"
                      className={classes2.formControl}
                      size="small"
                    />
                  )}
                  renderOption={(option) => (
                    <Tooltip title={option.name} position="bottom">
                      <Typography id="selected_vendor-text" noWrap>
                        {option.name}
                      </Typography>
                    </Tooltip>
                  )}
                />
                {!co.item_sku && (
                  <div className="ml-md-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={handleShowVendors}
                          checked={showAllVendors}
                          size="small"
                        />
                      }
                      label="Show All Vendors"
                    />
                  </div>
                )}
              </div>
              {!co.item_sku && (
                <FormControl
                  variant="outlined"
                  className={classes2.formControl}
                  size="small"
                >
                  <InputLabel id="order_type-label">Order Type</InputLabel>
                  <Select
                    labelId="order_type-label"
                    id="order_type"
                    name="order_type"
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                    label="Order Type"
                  >
                    <MenuItem value="">
                      <em>Select Order Type</em>
                    </MenuItem>
                    <MenuItem value="local">Local</MenuItem>
                    <MenuItem value="import">Import</MenuItem>
                  </Select>
                </FormControl>
              )}
              {co.item_sku && (
                <div>
                  <div className="mb-2 d-flex">
                    <StepTopography
                      body1={co.item_sku.sku_code}
                      body2="SKU Code"
                    />
                    <StepTopography
                      body1={
                        (co.item_sku.manufacturing_company &&
                          co.item_sku.manufacturing_company.name) ||
                        "-"
                      }
                      body2="Manufacturing Company"
                    />
                  </div>
                </div>
              )}
              {selectedVendor && vendorDetail[selectedVendor.id] ? (
                <VendorAccordion vendor={vendorDetail[selectedVendor.id]} />
              ) : (
                co.item_sku &&
                co.vendor && (
                  <VendorAccordion
                    vendor={vendorDetail[co?.vendor?.id]}
                    vendor_id={co?.vendor?.id}
                  />
                )
              )}
              {co.item_sku && co.item_sku.manufacturing_company ? (
                <ManufacturingCompanyAccordian
                  manufacturingCompany={
                    mcDetail[co.item_sku.manufacturing_company.id]
                  }
                  manufacturingCompany_id={co.item_sku.manufacturing_company.id}
                />
              ) : (
                ""
              )}
              {!co.item_sku && selectedVendor && skus && (
                <div className="mb-2">
                  <CreateSkuForm
                    item_id={co.item_id}
                    vendor_id={selectedVendor.id}
                  />
                  <SKUTable
                    skus={skus}
                    selectedSKU={selectedSKU}
                    handleChange={handleSKUChange}
                    unblockSKU={unblockSKU}
                  />
                  <div className="text-right my-3">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAssignSKU}
                    >
                      Assign SKU
                    </Button>
                  </div>
                </div>
              )}
              {co.item_sku && (
                <ConsignmentContainer
                  co={co}
                  workcentre_name={workcentre_name}
                />
              )}
            </div>
            <div className={classes2.checklistSection}>
              <h4 className="pb-3 mb-4">Checklist</h4>
              <div className="mb-2">
                <div className={classes.CTasks}>
                  {checklists.length > 0 ? (
                    <Checklist
                      checklists={checklists}
                      selectedItem={checklistSelect}
                      handleChange={handleChecklistChange}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mb-2">
                <div className={classes.CTasks}>
                  <UploadedFiles
                    files={co?.checklist_files || []}
                    handleChange={handleFileUploadChange}
                    handleFileUpload={handleFileUpload}
                    uploadedFiles={filesToUpload}
                    isSuccess={isSuccess}
                    errorMsg={errorMsg}
                    disable={job?.workcentre_id === 29 ? true : false}
                  />
                </div>
              </div>

              <div className="mb-2">
                <div className={classes.CTasks}>
                  <CardsCommentDialogue
                    comments={co?.comments || []}
                    job_id={job.id}
                    disable={job?.workcentre_id === 29 ? true : false}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {["wc3", "wc4"].includes(workcentre_name) && (
              <ThemeProvider theme={theme}>
                <Button
                  onClick={() =>
                    setAlert({
                      ...alert,
                      confirmAlert: {
                        ...alert.confirmAlert,
                        reviveIndent: true,
                      },
                    })
                  }
                  variant="contained"
                  color="primary"
                >
                  Revive
                </Button>
              </ThemeProvider>
            )}
            {["wc1", "wc2", "wc5", "wc6"].includes(workcentre_name) && (
              <Button
                onClick={() =>
                  setAlert({
                    ...alert,
                    confirmAlert: {
                      ...alert.confirmAlert,
                      holdIndent: true,
                    },
                  })
                }
                variant="contained"
                color="secondary"
              >
                Hold
              </Button>
            )}
            {submitBtnText && !["wc3", "wc4"].includes(workcentre_name) && (
              <Button
                onClick={handleJobDone}
                variant="contained"
                color="primary"
                className="ml-3"
              >
                {submitBtnText}
              </Button>
            )}

            {!["wc3", "wc4"].includes(workcentre_name) && (
              <Button
                onClick={() =>
                  setAlert({
                    ...alert,
                    confirmAlert: {
                      ...alert.confirmAlert,
                      rejectIndent: true,
                    },
                  })
                }
                variant="contained"
                color="secondary"
                className="ml-3"
                disabled={job?.workcentre_id === 29 ? true : false}
              >
                Reject
              </Button>
            )}
          </DialogActions>
          <CustomAlert
            isOpen={alert.validateAlert.assignSku}
            message="SKU and Order Type are Mandatory"
            handleClose={assignSKUValidateAlertClose}
            handleSubmit={assignSKUValidateAlertClose}
            hideCancelButton={true}
          />
          <CustomAlert
            isOpen={alert.confirmAlert.assignSku}
            message="Are you sure you want to assign the SKU?"
            handleClose={assignSKUConfirmAlertClose}
            handleSubmit={assignSKUConfirmAlertSubmit}
          />
          <CustomAlert
            isOpen={alert.confirmAlert.withoutChecklist}
            message="Are you sure you want to continue without filling checklist?"
            handleClose={withoutChecklistConfirmAlertClose}
            handleSubmit={withoutChecklistConfirmAlertSubmit}
          />
          <CustomAlert
            isOpen={alert.warningAlert.assignSku}
            handleClose={assignSKUWarningAlertClose}
            handleSubmit={assignSKUWarningAlertClose}
            hideCancelButton={true}
            message="Only approved SKUs can be assigned."
          />
          <CustomAlert
            isOpen={alert.warningAlert.selectVendor}
            message="Please select a vendor."
            handleClose={selectVendorAlertClose}
            handleSubmit={selectVendorAlertClose}
            hideCancelButton={true}
          />
          <CustomAlert
            isOpen={alert.warningAlert.addConsignment}
            message="Please add consignments."
            handleClose={addConsignmentAlertClose}
            handleSubmit={addConsignmentAlertClose}
            hideCancelButton={true}
          />
          <CustomAlert
            isOpen={alert.confirmAlert.rejectIndent}
            message="Are you sure you want to reject the indent?"
            handleClose={rejectIndentConfirmAlertClose}
            handleSubmit={handleJobReject}
          />
          <CustomAlert
            isOpen={alert.confirmAlert.holdIndent}
            message="Are you sure you want to put the indent on hold?"
            handleClose={holdIndentConfirmAlertClose}
            handleSubmit={handleHoldIndent}
          />
          <CustomAlert
            isOpen={alert.confirmAlert.reviveIndent}
            message="Are you sure you want to revive the indent."
            handleClose={reviveIndentConfirmAlertClose}
            handleSubmit={handleRevive}
          />
        </Dialog>
      )}
    </div>
  );
}

const mapState = (state) => {
  const { vendors, skus, vendorDetail, itemDetail, mcDetail, items } =
    state.indentForm;
  const { allVendors } = state.allVendors;
  return {
    vendors,
    skus,
    allVendors,
    vendorDetail,
    itemDetail,
    mcDetail,
    items,
  };
};

const dispatchAction = {
  routeJob: jobActions.routeJob,
  fetchAllVendor: vendorActions.fetchVendor,
  saveChecklist: checklistActions.saveChecklist,
  uploadFile: jobActions.uploadFile,
  getJob: jobActions.getJob,
  updateIndent: indentFormActions.updateIndent,
  fetchItemVendors: indentFormActions.fetchVendors,
  assignSKU: indentFormActions.assignSKU,
  fetchSkus: indentFormActions.fetchSkus,
  unblockSKU: indentFormActions.unblockSKU,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
  fetchVendorDetails: indentFormActions.fetchVendorDetails,
  rejectIndent: indentFormActions.rejectIndent,
  holdIndent: indentFormActions.holdIndent,
  reviveIndent: indentFormActions.reviveIndent,
  fetchItems: indentFormActions.fetchItems,
  updateItem: indentFormActions.updateItem,
};

export default connect(mapState, dispatchAction)(TasksDetails);
