import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  body1: {
    fontSize: 12,
    color: "#4D4F5C",
    fontWeight: "600",
    opacity: 0.9,
    [theme.breakpoints.up(1640)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  body2: {
    fontSize: 10,
    color: "#4D4F5C",
    opacity: 0.5,
    [theme.breakpoints.up(1640)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 8,
    },
  },
  stBig1: {
    fontSize: [theme.typography.pxToRem(20)],
    [theme.breakpoints.down("md")]: {
      fontSize: [theme.typography.pxToRem(18)],
    },
  },
  stBig2: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  stMedium1: {
    fontSize: theme.typography.pxToRem(16),
    color: "#060606",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  stMedium2: {
    fontSize: theme.typography.pxToRem(12),
    color: "#4D4F5C",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  subtitle1: {
    fontSize: 10,
    fontWeight: "600",
  },
  subtitle2: {
    fontSize: 9,
    fontWeight: "400",
    color: "#4D4F5C",
    opacity: "0.5",
    letterSpacing: "0.29",
  },
  TStatus: {
    display: "flex",
    alignItems: "center",
  },
  stepcardcontent: {
    display: "flex",
    flexDirection: "column",
    background: "#F5F6FA",
    marginTop: "16px",
    height: "100%",
  },
  ChipContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  CHtop: {
    display: "flex",
    padding: [
      [
        theme.spacing(2),
        theme.spacing(2.5),
        theme.spacing(2),
        theme.spacing(4),
      ],
      "!important",
    ],
  },
  CHeadContain: {
    minHeight: 75,
  },
  cardChip: {
    fontSize: 8,
    height: 20,
    borderRadius: 14,
  },
  CChiplabelError: {
    border: "none",
    background: "rgb(255, 101, 101 ,0.1)",
    "& > *": {
      color: "rgb(194, 13, 13)",
    },
  },
  CChiplabelSuccess: {
    border: "none",
    background: "rgba(11, 159, 85, 0.1)",
    "& > *": {
      color: "rgb(18, 104, 61)",
    },
  },
  CChiplabelWarning: {
    marginLeft: "auto",
    border: "none",
    background: "rgb(255, 192, 106)",
    "& > *": {
      color: "white",
    },
  },
  CardContainer: {
    boxShadow: "0px 2px 6px #0000001A",
    border: "1px solid #F5F6FA",
    marginBottom: "12px",
    background: "#FFFFFF",
  },
  CTasks: {
    color: "#4D4F5C",
    flex: "1",
    marginBottom: theme.spacing(1),
  },
  CVendor: {
    color: "#4D4F5C",
    flex: "1",
    marginTop: 10,
    marginBottom: 10,
  },
  VName: {
    marginBottom: 10,
  },
  TaskBtn: {
    flex: "1",
    width: "50px",
    height: "30px",
    background: "#295EB2",
    border: "1px solid #0000001A",
    padding: "inherit",
    fontSize: "14px",
  },
  CommentBtn: {
    width: "80%",
    height: "24px",
    padding: "inherit",
    fontSize: "10px",
    border: "1px solid #295EB2",
    color: "#295EB2",
  },
  DoneBtn: {
    width: "150px",
    height: "30px",
    fontSize: "12px",
    background: "#295EB2",
  },
  SubmitBtn: {
    width: "104px",
    height: "24px",
    fontSize: "10px",
    background: "#295EB2",
    color: "white",
    padding: "0",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  expand: {
    marginLeft: "auto",
    marginTop: "auto",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  TaskStatusContainer: {
    display: "flex",
    flexDirection: "column",
  },
  TaskStatus: {
    marginLeft: "auto",
  },
  TDetailsToggleBtn: {
    height: "20",
  },
  TaskButton: {
    height: "20px",
    padding: "inherit",
    width: "30px",
    minWidth: "0px",
    textTransform: "capitalize",
    "&:focus": {
      background: "#295EB2",
      color: "white",
    },
  },
  CommentBox: {
    padding: theme.spacing(2.5),
    margin: [[theme.spacing(2.5), 0]],
    border: "1px dashed #E9E9F0",
    borderRadius: 4,
  },
  CommentInput: {
    "&::placeholder": {
      color: "#A4AFB7",
      marginLeft: "10px",
    },
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
    fontWeight: "400",
    fontSize: "13px",
  },
  cardMinWidth: {
    minWidth: "270px",
    maxWidth: "320px",
  },
  taskCount: {
    padding: "10px",
  },
  postCommentBtn: {
    fontSize: 10,
    textTransform: "none",
  },
  dFlex: {
    display: "flex",
  },
  align_item_center: {
    alignItem: "center",
  },
  buttonGroupWidth: {
    width: "80%",
  },
  ml_auto: {
    marginLeft: "auto",
  },
  divider: {
    height: "0.9980354309082031px",
    background: "#F1F1F3 0% 0% no-repeat padding-box",
  },
  TDtitle: {
    paddingBottom: 0,
    flex: 1,
    paddingLeft: 0,
  },
  TDHead: {
    display: "flex",
    alignItems: "baseline",
    padding: 25,
    paddingBottom: 0,
  },
  stepBottleneck: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #FF6565",
    marginTop: "16px",
    background: "#F5F6FA",
    height: "100%",
  },
  VdBtn: {
    marginTop: theme.spacing(4),
    fontSize: 9,
    textTransform: "none",
  },
  DCommentActions: {
    display: "block",
  },
  BtnContain: {
    display: "flex",
    justifyContent: "flex-end",
  },
  indentCardPadding: {
    padding: [[theme.spacing(2), theme.spacing(2.5)], "!important"],
  },
  stageContainer: {
    height: "calc(100% - 10px)",
    overflow: "hidden",
    "& > div": {
      height: "inherit",
    },
  },
  chipSmall: {
    fontSize: 8,
    margin: [[2.5, 5]],
  },
}));
