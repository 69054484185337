import { customerConstants, errorAndLoadingConstants } from "../constants";
import { customerService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const saveCustomer = (requestData) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: customerConstants.SAVE_CUSTOMER_REQUEST,
    });
    await customerService.saveCustomer(requestData).then(
      (customer) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (customer.status === 200) {
          dispatch({
            type: customerConstants.SAVE_CUSTOMER_SUCCESS,
            data: customer.data,
          });
        } else {
          const errMsg = requestFailError(
            customer,
            "Error getting Customer Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: customerConstants.SAVE_CUSTOMER_FAILURE,
          });
          throw new Error(errMsg);
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: customerConstants.SAVE_CUSTOMER_FAILURE,
        });
        throw new Error(err);
      }
    );
  };
};

const getSalesCustomer = (customer_type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: customerConstants.GET_SALES_CUSTOMER_REQUEST,
    });
    await customerService.getSalesCustomer(customer_type).then(
      (customers) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (customers.status === 200) {
          dispatch({
            type: customerConstants.GET_SALES_CUSTOMER_SUCCESS,
            data: customers.data.sale_customers,
          });
        } else {
          const errMsg = requestFailError(
            customers,
            "Error getting Customer Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: customerConstants.GET_SALES_CUSTOMER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: customerConstants.GET_SALES_CUSTOMER_FAILURE,
        });
      }
    );
  };
};

const getDetailedCustomer = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: customerConstants.GET_DETAILED_CUSTOMER_REQUEST,
    });
    await customerService.getDetailedCustomer(id).then(
      (customer) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (customer.status === 200) {
          dispatch({
            type: customerConstants.GET_DETAILED_CUSTOMER_SUCCESS,
            data: customer.data.sale_customer,
          });
        } else {
          const errMsg = requestFailError(
            customer,
            "Error getting Customer Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: customerConstants.GET_DETAILED_CUSTOMER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: customerConstants.GET_DETAILED_CUSTOMER_FAILURE,
        });
      }
    );
  };
};

export const customerActions = {
  saveCustomer,
  getSalesCustomer,
  getDetailedCustomer,
};
