import React, {useState} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import CustomChip from "../CustomChip";
import { formattedNumber, removeUnderscoreFromString } from "../../../utils";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import { indentFormActions } from "../../../actions";
import { connect } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";

const colorCode = (state, scheduled_date, currDate) => {
  if (state === "rejected") return "error";
  if (state === "delivered") return "success";
  if (state === "open") {
    if (new Date(scheduled_date) <= new Date(currDate)) return "error";
    return "success";
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    "& .MuiTextField-root": {
      margin: 10,
      width: "25ch",
    },
  },
  DialogMinWidth: {
    minWidth: "20%",
    padding: [
      [theme.spacing(4), theme.spacing(12), theme.spacing(4), theme.spacing(5)],
    ],
    height: "25vh",
    [theme.breakpoints.up("md")]: {
      height: "25vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "25vh",
    },
  },
}));

export const ConsignmentDetailsTable = ({ consignments, currDate, workcentre_name, indent_id, closeConsignment,forceCloseConsignment}) => {

  const [consignmentAlert, setConsignmentAlert] = useState(false);

  const [forceIndent, setForceIndent] = useState(false);

  const [forceClose, setForceClose] = useState(false);

  const [consignmentId, setConsignmentId] = useState(null);

  const [comment, setComment] = useState("");

  const classes = useStyles();

  const handleChange = (event) => {
    setComment(event.target.value);
  }
  
  const ConsignmentCloseAlert = (id) => {
    setForceClose(false);
    setForceIndent(false);
    setConsignmentAlert(!consignmentAlert)
    setConsignmentId(id);
  }

  const deliveredCount = consignments.reduce(
    (acc, curr) => acc + curr.quantity - curr.balance_quantity,
    0
  );

  const handleClickForceClose = () => {
    setForceClose(true);
  };

  const handleClickForceIndent = () => {
    setForceIndent(true);
  };

  const handleForceClose  = async() =>{
    if(comment){
      const requestBody = {
        comment: comment,
        indent_id,
        consignment_id : consignmentId,
      }
    
      await forceCloseConsignment(requestBody);
      ConsignmentCloseAlert(null);
      setForceClose(false);
      setComment("");
    }
  }

  const handleClose = async() =>{
    if(comment){
      const requestBody = {
        comment: comment,
        indent_id,
        consignment_id : consignmentId,
      }

      await closeConsignment(requestBody);
      ConsignmentCloseAlert(null);
      setForceIndent(false);
      setComment("");
    }
  }

  const getRound = (num) => {
    return Math.round(num * 100)/100;
  }

  const DISCOUNT_TYPE = {
    percentage: 0,
    amount: 1,
  };
  
  const getDiscountType = (discount_type, isName) => {
    if ([null, undefined, ""].includes(discount_type)) return null;
    if (isName) {
      return DISCOUNT_TYPE[discount_type];
    }
    return +discount_type;
  };

  const calculateDiscount = (consignment, isName) => {
    let discountedPrice = 0;
    const type = getDiscountType(consignment?.discount_type, isName);
    if (type === 1) {
      discountedPrice = getRound(+(consignment?.amount)) - getRound(+(consignment?.discount ?? 0));
    }

    if (type === 0) {
      discountedPrice = getRound(+(consignment?.amount)) - getRound(getRound(+(consignment?.amount)) * (getRound(+(consignment?.discount ?? 0)) / 100));
    }

    return getRound(discountedPrice);
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="Indent Consignment Table">
          <TableHead>
            <TableRow>
              <TableCell>Consignment No.</TableCell>
              <TableCell>Quantity Ordered</TableCell>
              <TableCell>Scheduled Delivery Date and Time</TableCell>
              <TableCell>Actual Delivery Date and Time</TableCell>
              <TableCell>Quantity Received</TableCell>
              <TableCell>Balance Quantity</TableCell>
              <TableCell>Discount Type</TableCell>
              <TableCell>List Price</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Status</TableCell>
              { workcentre_name === "wc11" && 
              <TableCell>Action</TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>
            {consignments
              .sort(
                (a, b) =>
                  new Date(a.scheduled_date) - new Date(b.scheduled_date)
              )
              .map((consignment) => {
                const {
                  id,
                  number,
                  quantity,
                  scheduled_date,
                  balance_quantity,
                  state,
                  last_delivered_date,
                  discount_type,
                  amount,
                  discounted_amount,
                  remarks,
                  comment,
                  consignment_closed_status,
                } = consignment;
                const price = calculateDiscount(consignment, true);
                return (
                  <TableRow key={id}>
                    <TableCell>{number}</TableCell>
                    <TableCell>{formattedNumber(quantity, true)}</TableCell>
                    <TableCell>
                      {scheduled_date
                        ? moment(scheduled_date)  
                            .local()
                            .format("DD/MM/YYYY hh:mm:ss a")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {last_delivered_date
                        ? moment(last_delivered_date)
                            .local()
                            .format("DD/MM/YYYY hh:mm:ss a")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {formattedNumber(quantity - balance_quantity, true)}
                    </TableCell>
                    <TableCell>{formattedNumber(balance_quantity, true)}</TableCell>
                    <TableCell>{discount_type}</TableCell>
                    <TableCell>{formattedNumber(amount, true, true)}</TableCell>
                    <TableCell>{formattedNumber(price, true, true)}</TableCell>
                    <TableCell>{formattedNumber(discounted_amount, true, true)}</TableCell>
                    <TableCell>{remarks}</TableCell>
                    <TableCell>{comment}</TableCell>
                    <TableCell>
                      <CustomChip
                        color={colorCode(state, scheduled_date, currDate)}
                        label={removeUnderscoreFromString(state)}
                      />
                    </TableCell>
                    { workcentre_name === "wc11" && <TableCell>
                    <Box className="d-flex w-100 justify-content-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          ()=> ConsignmentCloseAlert(id)
                        }
                        disabled = {state ==='closed' || consignment_closed_status === true }
                      >
                        Close Consignment
                      </Button>
                    </Box>
                    </TableCell>
                  }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography align="right" className="my-2">
        Total Delivered Quantity: {formattedNumber(deliveredCount, true)}
      </Typography>

      <Dialog open={consignmentAlert}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" 
            onClick={()=> ConsignmentCloseAlert(null)}
          >
            Cancel
          </Button>
          <Tooltip title="This will force close the Consignment.">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClickForceClose()}
            >
              Force Close
            </Button>
          </Tooltip>
          <Dialog
            open={forceClose}
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.DialogMinWidth }}
          >
            <div className="pl-5">
              <DialogTitle id="form-dialog-title">
                Add Comments
              </DialogTitle>
            </div>
            <DialogContent className="pl-5">
              <form noValidate autoComplete="off">
                <TextField
                  error={!comment}
                  name="comment"
                  label="Comments"
                  variant="outlined"
                  value={comment}
                  onChange={handleChange}
                  size="medium"
                  required
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                className="mr-3"
                onClick={()=> ConsignmentCloseAlert(null)}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleForceClose} variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Tooltip title="This will create new indent.">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClickForceIndent()}
            >
              Create New Indent
            </Button>
          </Tooltip>
            <Dialog
              open={forceIndent}
              maxWidth="sm"
              aria-labelledby="form-dialog-title"
              classes={{ paper: classes.DialogMinWidth }}
            >
              <div className="pl-5">
                <DialogTitle id="form-dialog-title">
                  Add Comments
                </DialogTitle>
              </div>
              <DialogContent className="pl-5">
                <form noValidate autoComplete="off">
                <TextField
                  error={!comment}
                  name="comment"
                  label="Comments"
                  variant="outlined"
                  value={comment}
                  onChange={handleChange}
                  size="medium"
                  required
                />
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  className="mr-3"
                  onClick={()=> ConsignmentCloseAlert(null)}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
        </DialogActions>
      </Dialog>

    </>
  );
};

ConsignmentDetailsTable.propTypes = {
  consignments: PropTypes.array.isRequired,
  currDate: PropTypes.string,
};

const dispatchAction = {
  closeConsignment: indentFormActions.closeConsignment,
  forceCloseConsignment: indentFormActions.forceCloseConsignment,
}

export default connect(null,dispatchAction)(ConsignmentDetailsTable);
