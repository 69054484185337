export const planListConstant = {
  GET_PLANLIST_REQUEST: "GET_PLANLIST_REQUEST",
  GET_PLANLIST_SUCCESS: "GET_PLANLIST_SUCCESS",
  GET_PLANLIST_FAILURE: "GET_PLANLIST_FAILURE",

  CREATE_PLAN_REQUEST: "CREATE_PLAN_REQUEST",
  CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS",
  CREATE_PLAN_FAILURE: "CREATE_PLAN_FAILURE",

  EDIT_PLAN_REQUEST: "EDIT_PLAN_REQUEST",
  EDIT_PLAN_SUCCESS: "EDIT_PLAN_SUCCESS",
  EDIT_PLAN_FAILURE: "EDIT_PLAN_FAILURE",

  DELETE_PLAN_REQUEST: "DELETE_PLAN_REQUEST",
  DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
  DELETE_PLAN_FAILURE: "DELETE_PLAN_FAILURE",

  GET_MOULDING_DATA_SUCCESS: "GET_MOULDING_DATA_SUCCESS",
  GET_MOULDING_DATA_FAILURE: "GET_MOULDING_DATA_FAILURE",

  GET_MOTOR_TYPE_SUCCESS: "GET_MOTOR_TYPE_SUCCESS",
  GET_MOTOR_TYPE_FAILURE: "GET_MOTOR_TYPE_FAILURE",

  GET_PRODUCTION_FLOW_SUCCESS: "GET_PRODUCTION_FLOW_SUCCESS",
  GET_PRODUCTION_FLOW_FAILURE: "GET_PRODUCTION_FLOW_FAILURE",
};
