export const mouldingConstants = {
  SAVE_MACHINE_STATUS_SUCCESS: "SAVE_MACHINE_STATUS_SUCCESS",
  SAVE_MACHINE_STATUS_FAILURE: "SAVE_MACHINE_STATUS_FAILURE",

  GET_MOULDING_DASHBOARD_SUCCESS: "GET_MOULDING_DASHBOARD_SUCCESS",
  GET_MOULDING_DASHBOARD_FAILURE: "GET_MOULDING_DASHBOARD_FAILURE",

  GET_MACHINE_COUNT_SUCCESS: "GET_MACHINE_COUNT_SUCCESS",
  GET_MACHINE_COUNT_FAILURE: "GET_MACHINE_COUNT_FAILURE",
};
