import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getPlanList = (requestData) => {
  return axios
    .post(`/api/v1/plans/get_plan_by_plan_type`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const createPlan = (requestData) => {
  return axios
    .post(`/api/v1/plans`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const editPlan = (requestData, id) => {
  return axios
    .put(`/api/v1/plans/${id}`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const deletePlan = (id) => {
  return axios
    .delete(`/api/v1/plans/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const getMoudlingData = () => {
  return axios
    .get("/api/v1/plans/load_machine_component_user")
    .then((response) => response)
    .catch((err) => err);
};

const getMotorType = () => {
  return axios
    .get("/api/v2/motor_serial/get_motor_type_web")
    .then((response) => response)
    .catch((err) => err);
};

const createPlanJarFinal = (type, requestData) => {
  return axios
    .post(`/api/v1/plans/create_plan/${type}`, JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getProductionFlow = () => {
  return axios
    .get("/api/v1/production_flows")
    .then((response) => response)
    .catch((err) => err);
};

export const planListService = {
  getPlanList,
  createPlan,
  editPlan,
  deletePlan,
  getMoudlingData,
  getMotorType,
  createPlanJarFinal,
  getProductionFlow,
};
