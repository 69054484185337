import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const createLO = (body) => {
  return axios
    .post("/api/v1/lot_offers", body)
    .then((res) => res)
    .catch((err) => err);
};

const scanLOSerial = (id, body) => {
  return axios
    .post(`/api/v1/lot_offer/${id}/scan_serial`, body)
    .then((res) => res)
    .catch((err) => err);
};

const editLO = (id, body) => {
  return axios
    .put(`/api/v1/lot_offers/${id}`, body)
    .then((res) => res)
    .catch((err) => err);
};

const approveLoDispatch = (body) => {
  return axios
    .put("/api/v1/lot_offers/approve_dispatch", body)
    .then((res) => res)
    .catch((err) => err);
};

const rejectLO = (id) => {
  return axios
    .put(`/api/v1/lot_offer/${id}/reject_lo`)
    .then((res) => res)
    .catch((err) => err);
};

const deleteLO = (id) => {
  return axios
    .put(`/api/v1/lot_offer/${id}/delete_lo`)
    .then((res) => res)
    .catch((err) => err);
};

const reviveLO = (id) => {
  return axios
    .put(`/api/v1/lot_offer/${id}/revive_lo`)
    .then((res) => res)
    .catch((err) => err);
};

const getLoDetails = (id) => {
  return axios
    .get(`/api/v1/lot_offers/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const scanLOBoxCode = (body) => {
  return axios
    .post("/api/v1/lo_scanning_logs", body)
    .then((res) => res)
    .catch((err) => err);
};

const deleteLoScaningLog = (id) => {
  return axios
    .delete(`/api/v1/lo_scanning_logs/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const updateLoScaningLog = (id, body) => {
  return axios
    .put(`/api/v1/lo_scanning_logs/${id}`, body)
    .then((res) => res)
    .catch((err) => err);
};

export const lotOfferService = {
  createLO,
  scanLOBoxCode,
  editLO,
  approveLoDispatch,
  deleteLoScaningLog,
  updateLoScaningLog,
  rejectLO,
  deleteLO,
  reviveLO,
  getLoDetails,
};
