import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Button, Label, Form} from "reactstrap";
import {TextField as MaterialTextField} from "@material-ui/core"
import logo from "../../assets/sujatalogo.png";
import back from "../../assets/arrow_back-24px.svg"
function RequestResetPasswd(props){
    const [email, setEmail] = useState("");
    
    function validateForm() {
        return email.length > 0;
    }

    function handleSubmit(event) {
    event.preventDefault();
    }
    return (
    <div className="Gcontainer">
    <div className="CLoginRequestResetPasswd container">
        <div className="CLoginRequestResetPasswdForm">
            <div className="CLoginLogo">
                <Link to="/login"><img src={logo} className="img-fluid CLoginRequestResetPasswdLogo" alt="logo"/></Link>
            </div>
            <Label className="CLoginRequestResetPasswdLabel"> Reset Password</Label>
            <Form onSubmit={handleSubmit}>
                <div className="CLoginRequestResetPasswdEmailDiv">
                    <MaterialTextField className="CLoginFormgroup"
                        type="email" 
                        label="Email ID*" 
                        variant="outlined"
                        placeholder="Email ID"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <Button block disabled={!validateForm()} color="primary" type="submit" className="CLoginRequestResetPasswdProceedBtn">
                    PROCEED
                </Button>
                <Link to="/login"><img src={back} alt=""/>Back</Link>
            </Form>
        </div>
    </div>
    </div>
    );
}
export default RequestResetPasswd;
