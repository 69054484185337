import { historyReportConstants } from "../constants";

const inititalState = {
  historyReportData:[],
  production_detail_report:[],
  stock_transfer_report:[],
  total: 0
};

export const historyReport = (state = inititalState, action) => {
  switch (action.type) {
    case historyReportConstants.RESET_TESTING_DATA:
      return {
        historyReportData: []
      };
    case historyReportConstants.HISTORY_REQUEST:
    case historyReportConstants.PRODUCTION_DETAIL_REQUEST:
    case historyReportConstants.OEM_PRODUCTION_REQUEST:
    case historyReportConstants.STOCK_TRANSFER_REPORT_REQUEST:
      return {
        ...state,
      };

    case historyReportConstants.HISTORY_SUCCESS:
      return {
        ...state,
        historyReportData: action.data.motor_testing_history_report,
        total: action.data.total
      };
    case historyReportConstants.PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        production_detail_report: action.data.production_detail,
        total: action.data.total
      };
    case historyReportConstants.OEM_PRODUCTION_SUCCESS:
      return {
        ...state,
        production_detail_report: action.data.production_detail,
        total: action.data.total
      };
    case historyReportConstants.STOCK_TRANSFER_REPORT_SUCCESS:
      return {
        ...state,
        stock_transfer_report: action.data.stock_transfer_report,
        total: action.data.total
      };
    case historyReportConstants.HISTORY_FAILURE:
    case historyReportConstants.PRODUCTION_DETAIL_FAILURE:
      case historyReportConstants.OEM_PRODUCTION_FAILURE:
    case historyReportConstants.STOCK_TRANSFER_REPORT_FAILURE:
    default:
      return state;
  }
};
