import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PMSAlerts from "../PMS/components/PMSAlerts";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { BasePaths } from "../utils";
const Plan = lazy(() => import("./pages/Plan"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Operator = lazy(() => import("./pages/Operator"));

const baseRoute = BasePaths.MOULDING;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MouldingRoutes = () => {
  const classes = useStyles();
  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/mould/`}>
            <Dashboard />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/plan/`}>
            <Plan />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/machine/`}>
            <Operator />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlerts />
    </>
  );
};

export default MouldingRoutes;
