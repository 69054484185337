import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { indentFormActions, errorAndLoadingActions } from "../../../actions";
import CustomAlert from "../CustomAlert";
import ListboxComponent from "../ListboxComponent";
import ManufacturingCompanyAccordian from "./ManufacturingCompanyAccordian";

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
}));

export const CreateSkuForm = ({
  vendor_id,
  item_id,
  manufacturingCompanies,
  getManufacturingCompanies,
  createSKU,
  openAlert,
  closeAlert,
}) => {
  const classes = useStyles();
  const [
    selectedManufacturingCompany,
    setSelectedManufacturingCompany,
  ] = useState(null);
  const [openCreateSku, setOpenCreateSku] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);

  useMemo(() => {
    setSelectedManufacturingCompany(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor_id]);

  useEffect(() => {
    (async () => {
      if (openCreateSku) await getManufacturingCompanies();
    })();
  }, [openCreateSku, getManufacturingCompanies]);

  const handleCreateSkuOpen = (event) => {
    setOpenCreateSku(true);
  };

  const handleCreateSkuClose = (event) => {
    setOpenCreateSku(false);
    setSelectedManufacturingCompany(null);
  };

  const handleConfirmAlertSubmit = async () => {
    try {
      let manufacturingCompanies_id = "";
      if (selectedManufacturingCompany)
        manufacturingCompanies_id = selectedManufacturingCompany.id;
      await createSKU(vendor_id, item_id, manufacturingCompanies_id);
      setOpenCreateSku(false);
      setConfirmAlert(false);
      setSelectedManufacturingCompany(null);
    } catch (err) {
      const message = `${err.name}: ${err.message}`;
      openAlert(message, "danger");
      setTimeout(() => {
        closeAlert();
      }, 5000);
      setOpenCreateSku(false);
      setConfirmAlert(false);
      setSelectedManufacturingCompany(null);
    }
  };

  const handleConfirmAlertClose = () => {
    setConfirmAlert(false);
  };

  const handleCreateSku = () => {
    setConfirmAlert(true);
  };

  return (
    <>
      {!openCreateSku && (
        <div className="text-right my-3">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateSkuOpen}
          >
            Create SKU
          </Button>
        </div>
      )}
      {openCreateSku && (
        <Card className="my-3">
          <CardHeader title="Create SKU" className="p-2" />
          <CardContent className="p-2">
            <Box>
              <Autocomplete
                id="selected_manufacturing_company"
                options={manufacturingCompanies}
                value={selectedManufacturingCompany}
                getOptionLabel={(option) => option.name}
                className="d-inline-block"
                ListboxComponent={ListboxComponent}
                onChange={(event, newValue) => {
                  setSelectedManufacturingCompany(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Manufacturing Company"
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  />
                )}
                renderOption={(option) => (
                  <Tooltip title={option.name} position="bottom">
                    <Typography id="selected_vendor-text" noWrap>
                      {option.name}
                    </Typography>
                  </Tooltip>
                )}
              />
              {selectedManufacturingCompany && (
                <ManufacturingCompanyAccordian
                  manufacturingCompany={selectedManufacturingCompany}
                />
              )}
              <div className="text-right my-3">
                <Button
                  variant="outlined"
                  color="primary"
                  className="mr-3"
                  onClick={handleCreateSkuClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateSku}
                >
                  Create SKU
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      )}
      <CustomAlert
        isOpen={confirmAlert}
        handleSubmit={handleConfirmAlertSubmit}
        handleClose={handleConfirmAlertClose}
        message="Are you sure you want to create the SKU?"
      />
    </>
  );
};

const mapState = (state) => {
  const { manufacturingCompanies } = state.indentForm;
  return {
    manufacturingCompanies,
  };
};

const dispatchAction = {
  createSKU: indentFormActions.createSKU,
  getManufacturingCompanies: indentFormActions.getManufacturingCompanies,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};

export default connect(mapState, dispatchAction)(CreateSkuForm);
