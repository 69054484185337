import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getAuditList = (body) => {
  return axios
    .get("/api/v1/audit_tools/fetch_audit_list", {
      params: {
        ...body
      }
    })
    .then((response) => response)
    .catch((err) => err);
};

const getAuditDetails = (body) => {
  return axios
    .get("/api/v1/audit_tools/fetch_audit_details", {
      params: {
        ...body
      }
    })
    .then((response) => response)
    .catch((err) => err);
};

const completeAuditScanning = (body) => {
  return axios
    .put(`/api/v1/audit_tools/audit_tool_mark_complete?audit_id=${body.audit_id}`)
    .then((response) => response)
    .catch((err) => err);
};


const getWarehouseAudit = (body) => {
  return axios
    .get("/api/v1/audit_tools/warehouse_audit", {
      params: {
        ...body
      }
    })
    .then((response) => response)
    .catch((err) => err);
};

const getDispatchStations = (warehouseId) => {
  return axios
    .get("/api/v1/audit_tools/warehouse_dispatch_stations", {
      params: {
        warehouse_id: warehouseId
      }
    })
    .then((response) => response)
    .catch((err) => err);
};

const getBRList = (date, station_id) => {
  return axios
    .get("/api/v1/audit_tools/br_list", {
      params: {
        date: date,
        outward_station_id: station_id
      }
    })
    .then((response) => response)
    .catch((err) => err);
};

export const auditToolService = {
  getAuditList,
  getAuditDetails,
  completeAuditScanning,
  getWarehouseAudit,
  getDispatchStations,
  getBRList,
};
