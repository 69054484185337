import React from "react";
import FormDateTimePicker from "../FormDateTimePicker";
import UploadButton from "../UploadButton";
import ListboxComponent from "../ListboxComponent";
import ItemAccordian from "../TaskDetails/ItemAccordian";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { indentFormActions } from "../../../actions";
import MultipleItems from "./MultipleItems";
import { Box, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
  textareaField: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "78ch !important",
    },
  },
  indentDetailsContainer: {
    padding: [[0, theme.spacing(4)]],
    marginRight: theme.spacing(2.5),
    boxShadow: "0px 2px 6px #0000000A",
    "& h4": {
      fontWeight: "normal",
      fontSize: 18,
    },
  },
  itemDetailsContainer: {
    padding: [[0, theme.spacing(4)]],
    margin: [[theme.spacing(4), theme.spacing(2.5), theme.spacing(4), 0]],
    boxShadow: "0px 2px 6px #0000000A",
    minWidth: "100%",
    "& h4": {
      fontWeight: "normal",
      fontSize: 18,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 560,
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up(1640)]: {
      minWidth: 670,
    },
  },
  approvalContainer: {
    padding: [[0, theme.spacing(4)]],
    marginRight: theme.spacing(2.5),
    boxShadow: "0px 2px 6px #0000000A",
    [theme.breakpoints.up("md")]: {
      "& $formControl": {
        marginRight: "20px !important",
      },
    },
    "& h4": {
      fontWeight: "normal",
      fontSize: 18,
    },
  },
  itemControl: {
    width: "64ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.down("sm")]: {
      width: "30ch !important",
    },
    [theme.breakpoints.up(1640)]: {
      width: "79ch !important",
    },
  },
  truncate: {
    display: "-webkit-box",
    "-moz-box-orient": "vertical",
    "-webkit-box-orient": "vertical",
    "box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
  },
  itemOption: {
    "&:after": {
      content: '""',
      display: "block",
      height: 1,
      background: theme.palette.divider,
      width: "calc(100% - 32px)",
      position: "absolute",
      bottom: 0,
    },
  },
}));

const AddIndentFields = ({
  handleChange,
  indent,
  indentSearchable,
  handleAutoCompleteChange,
  handleFileUploadChange,
  isSuccess,
  errorMsg,
  departments,
  items,
  itemDetail,
  employees,
  errors,
  fetchEmployeeByRole,
  uploadedFiles,
  handleAddMultiItem,
  multiItems,
  handleMultiItemChange,
  handleMultiItemAutoCompleteChange,
  removeItem,
  selected_items,
  multiItemErrors,
  isDuplicate,
  fetchItemsList,
}) => {
  const classes = useStyles();

  const getEmployeeByRole = async (role) => {
    await fetchEmployeeByRole(role);
  };

  return (
    <div className="d-md-flex">
      <div className={classes.indentDetailsContainer}>
        <h4>Indent Details</h4>
        <TextField
          error={
            errors.requisition_slip_number && !indent.requisition_slip_number
          }
          name="requisition_slip_number"
          label="Requisition Slip No."
          variant="outlined"
          type="number"
          value={indent.requisition_slip_number}
          onChange={handleChange}
          className={classes.formControl}
          size="small"
        />
        <Autocomplete
          id="selected_department"
          options={departments}
          value={indentSearchable.selected_department}
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          className="d-inline-block"
          onChange={(event, newValue) =>
            handleAutoCompleteChange(event, newValue)
          }
          closeIcon={<CloseIcon size="small" id="selected_department" />}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                errors.selected_department &&
                !indentSearchable.selected_department
              }
              label="Department"
              variant="outlined"
              className={classes.formControl}
              size="small"
            />
          )}
          renderOption={(option) => (
            <Tooltip title={option.name} position="bottom">
              <Typography id="selected_department" noWrap>
                {option.name}
              </Typography>
            </Tooltip>
          )}
        />
      </div>

      <div className={classes.itemDetailsContainer}>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={fetchItemsList}
            disabled={!indentSearchable.selected_department}
          >
            Refresh Items
          </Button>
        </Box>
        <h4>Item Details</h4>
        <MultipleItems
          items={items}
          indentSearchable={indentSearchable}
          handleAutoCompleteChange={handleAutoCompleteChange}
          errors={errors}
          classes={classes}
          handleChange={handleChange}
          handleAddMultiItem={handleAddMultiItem}
          multiItems={multiItems}
          handleMultiItemChange={handleMultiItemChange}
          handleMultiItemAutoCompleteChange={handleMultiItemAutoCompleteChange}
          removeItem={removeItem}
          selected_items={selected_items}
          multiItemErrors={multiItemErrors}
          isDuplicate={isDuplicate}
        />
        {indentSearchable.selected_item && itemDetail.id && (
          <ItemAccordian item={itemDetail} />
        )}
      </div>

      <div className={classes.approvalContainer}>
        <h4>Approvals</h4>
        <div className="d-md-flex flex-md-wrap">
          <div className="d-md-flex">
            <Autocomplete
              id="requisition_filled_by"
              name="requisition_filled_by"
              options={employees["fill_requisition"]}
              ListboxComponent={ListboxComponent}
              value={indentSearchable.requisition_filled_by}
              className="d-inline-block"
              disableListWrap
              onOpen={() => {
                getEmployeeByRole("fill_requisition");
              }}
              closeIcon={<CloseIcon size="small" id="requisition_filled_by" />}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              onChange={(event, newValue) =>
                handleAutoCompleteChange(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    errors.requisition_filled_by &&
                    !indentSearchable.requisition_filled_by
                  }
                  label="Filled By"
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                />
              )}
              renderOption={(option) => (
                <Tooltip
                  title={`${option.firstname} ${option.lastname}`}
                  position="bottom"
                >
                  <Typography
                    id="requisition_filled_by-text"
                    noWrap
                  >{`${option.firstname} ${option.lastname}`}</Typography>
                </Tooltip>
              )}
            />
            <FormDateTimePicker
              selectedDate={indent.requisition_filled_time}
              name="requisition_filled_time"
              label="Filled Time"
              handleChange={handleChange}
              readOnly={true}
              error={
                errors.requisition_filled_time &&
                !indent.requisition_filled_time
              }
              size="small"
            />
          </div>
          <div className="d-md-flex">
            <Autocomplete
              id="requisition_approved_by"
              options={employees["approve_requisition"]}
              ListboxComponent={ListboxComponent}
              value={indentSearchable.requisition_approved_by}
              className="d-inline-block"
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              onOpen={() => {
                getEmployeeByRole("approve_requisition");
              }}
              closeIcon={
                <CloseIcon size="small" id="requisition_approved_by" />
              }
              onChange={(event, newValue) =>
                handleAutoCompleteChange(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approved By"
                  error={
                    errors.requisition_approved_by &&
                    !indentSearchable.requisition_approved_by
                  }
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                />
              )}
              renderOption={(option) => (
                <Tooltip
                  title={`${option.firstname} ${option.lastname}`}
                  position="bottom"
                >
                  <Typography
                    id="requisition_approved_by-text"
                    noWrap
                  >{`${option.firstname} ${option.lastname}`}</Typography>
                </Tooltip>
              )}
            />
            <FormDateTimePicker
              selectedDate={indent.requisition_approved_time}
              name="requisition_approved_time"
              label="Approved Time"
              handleChange={handleChange}
              readOnly={true}
              error={
                errors.requisition_approved_time &&
                !indent.requisition_approved_time
              }
              size="small"
            />
          </div>
          <div className="d-md-flex">
            <Autocomplete
              id="requisition_final_approved_by"
              options={employees["final_approve_requisition"]}
              value={indentSearchable.requisition_final_approved_by}
              ListboxComponent={ListboxComponent}
              className="d-inline-block"
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              onOpen={() => {
                getEmployeeByRole("final_approve_requisition");
              }}
              closeIcon={
                <CloseIcon size="small" id="requisition_final_approved_by" />
              }
              onChange={(event, newValue) =>
                handleAutoCompleteChange(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    errors.requisition_final_approved_by &&
                    !indentSearchable.requisition_final_approved_by
                  }
                  label="Finally Approved By"
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                />
              )}
              renderOption={(option) => (
                <Tooltip
                  title={`${option.firstname} ${option.lastname}`}
                  position="bottom"
                >
                  <Typography
                    id="requisition_final_approved_by-text"
                    noWrap
                  >{`${option.firstname} ${option.lastname}`}</Typography>
                </Tooltip>
              )}
            />
            <FormDateTimePicker
              selectedDate={indent.requisition_final_approved_time}
              name="requisition_final_approved_time"
              label="Final Approved Time"
              handleChange={handleChange}
              readOnly={true}
              error={
                errors.requisition_final_approved_time &&
                !indent.requisition_final_approved_time
              }
              size="small"
            />
          </div>
          <div className="d-md-flex">
            <Autocomplete
              id="requisition_received_by"
              options={employees["receive_requisition"]}
              ListboxComponent={ListboxComponent}
              value={indentSearchable.requisition_received_by}
              className="d-inline-block"
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              onOpen={() => {
                getEmployeeByRole("receive_requisition");
              }}
              closeIcon={
                <CloseIcon size="small" id="requisition_received_by" />
              }
              onChange={(event, newValue) =>
                handleAutoCompleteChange(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Received By"
                  error={
                    errors.requisition_received_by &&
                    !indentSearchable.requisition_received_by
                  }
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                />
              )}
              renderOption={(option) => (
                <Tooltip
                  title={`${option.firstname} ${option.lastname}`}
                  position="bottom"
                >
                  <Typography
                    id="requisition_received_by-text"
                    noWrap
                  >{`${option.firstname} ${option.lastname}`}</Typography>
                </Tooltip>
              )}
            />

            <FormDateTimePicker
              selectedDate={indent.requisition_received_time}
              name="requisition_received_time"
              label="Received Time"
              handleChange={handleChange}
              readOnly={true}
              error={
                errors.requisition_received_time &&
                !indent.requisition_received_time
              }
              size="small"
            />
          </div>
          <div className="d-flex">
            <TextField
              name="remarks"
              label="Remarks"
              variant="outlined"
              multiline
              rows="4"
              value={indent.remarks}
              onChange={handleChange}
              className={classes.textareaField}
              size="small"
            />
          </div>
          <div className="d-flex w-100">
            <UploadButton
              isSuccess={isSuccess}
              errorMsg={errorMsg}
              handleChange={handleFileUploadChange}
              multiple={false}
              uploadedFiles={uploadedFiles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { employees } = state.indentForm;
  return { employees };
};

const dispatchAction = {
  fetchEmployeeByRole: indentFormActions.fetchEmployeeByRole,
};
export default connect(mapState, dispatchAction)(AddIndentFields);
