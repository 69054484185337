import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getStocks = () => {
  return axios
    .get("/api/v1/stocks")
    .then((response) => response)
    .catch((err) => err);
};

export const stockService = {
  getStocks,
};
