import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const saveCustomer = (requestData) => {
  return axios
    .post("/api/v1/sale_customers", requestData, {})
    .then((response) => response)
    .catch((err) => err);
};

const getSalesCustomer = (customer_type = "all") => {
  return axios
    .get(
      `/api/v1/sale_customers/getAllCustomers${
        customer_type && `/${customer_type}`
      }`
    )
    .then((response) => response)
    .catch((err) => err);
};

const getDetailedCustomer = (id) => {
  return axios
    .get(`/api/v1/sale_customers/getCustomer/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

export const customerService = {
  saveCustomer,
  getSalesCustomer,
  getDetailedCustomer,
};
