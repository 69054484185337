import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ActiveSteps from "./ActiveSteps";
import { useStyles } from "../Css";
import { connect } from "react-redux";
import { commentActions } from "../../../actions";
import { Typography } from "@material-ui/core";

function CardsCommentDialogue({
  comments,
  addComment,
  storeComments,
  prevComments,
  job_id,
  user_id,
  disable,
}) {
  const [comment, setCommnt] = useState("");
  const classes = useStyles();

  const handleChange = (event) => {
    setCommnt(event.target.value);
  };

  useEffect(() => {
    if (!prevComments) storeComments(comments, job_id);
    else {
      prevComments.length > comments.length
        ? storeComments(prevComments, job_id)
        : storeComments(comments, job_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, prevComments]);

  const handleSubmit = async () => {
    if (!comment) {
      alert("Please enter a comment.");
      return;
    }
    let reqBody = {
      comment: {
        job_id: job_id,
        comment_text: comment,
        employee_id: user_id,
      },
    };
    try {
      await addComment(reqBody, job_id);
      setCommnt("");
    } catch (error) {
      // log error
    }
  };

  return (
    <div className={classes.commentContainer}>
      <Typography variant="body1">Post/View comment</Typography>
      <div className={classes.CommentBox}>
        <TextField
          multiline
          placeholder="Type your comment here"
          value={comment}
          onChange={handleChange}
          rows="6"
          InputProps={{ className: classes.CommentInput }}
          fullWidth
        />
        <div className={classes.BtnContain}>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
            disabled={disable}
          >
            Submit
          </Button>
        </div>
      </div>

      <div>Previous comments</div>
      <div>
        <ActiveSteps
          job_id={job_id}
          prevComments={prevComments ? prevComments : []}
        />
      </div>
    </div>
  );
}

const mapState = (state, ownProps) => {
  const prevComments = state.comments.comments[ownProps.job_id]
    ? state.comments.comments[ownProps.job_id]
    : undefined;
  const user_id = state.user.user.id;
  return {
    user_id,
    prevComments,
  };
};

const dispatchAction = {
  addComment: commentActions.addComment,
  storeComments: commentActions.storeComments,
};

export default connect(mapState, dispatchAction)(CardsCommentDialogue);
