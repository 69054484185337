import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PMSAlert from "../PMS/components/PMSAlerts";
import { BasePaths } from "../utils";

const Store = lazy(() => import("./pages/Store"));
const LoadedVehicle = lazy(() => import("./pages/LoadedVehicle"));
const MasterCarton = lazy(() => import("./pages/MasterCarton"));
const baseRoute = BasePaths.RECONCILESTOCKATLOCATION;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ReconcileStockAtLocationRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/store/`}>
            <Store />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/loaded-vehicle/`}>
            <LoadedVehicle />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/master-carton/`}>
            <MasterCarton />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlert />
    </>
  );
};

export default ReconcileStockAtLocationRoutes;
