import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getEmployeeList = (type) => {
  return axios
    .get(`/api/v1/assembly/worker_past_station_list?type=${type}`)
    .then((response) => response)
    .catch((err) => err);
};

export const employeeListService = {
  getEmployeeList,
};
