export default {
  root: {
    fontSize: 13,
    padding: "4px 22px",
    minWidth: 88,
  },
  outlined: {
    padding: "4px 22px",
  },
};
