import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { reportActions, errorAndLoadingActions } from "../../../actions";
import StepsReport from "./StepsReport";
import EmployeesReport from "./EmployeeReport";
import BackdropLoader from "../BackdropLoader";
import VendorsReport from "./VendorsReport";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles({
  root: {
    marginTop: "15px",
    padding: "15px",
  },
});

export function Reports({
  reports,
  getStepsReport,
  getEmployeesReport,
  getReport,
  openAlert,
  closeAlert,
  isLoading,
  allAlert,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const classes = useStyles();

  const switchTab = async (tab, reportName) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      try {
        await getReport(reportName);
      } catch (err) {
        openAlert(`Error loading Report: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getReport("steps_report");
      } catch (err) {
        openAlert(`Error loading Step Report: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Nav tabs>
          {reports &&
            reports.map((report, index) => (
              <NavItem key={report.id}>
                <NavLink
                  title={report.name}
                  className={classnames({ active: activeTab === index + 1 })}
                  onClick={() => {
                    switchTab(index + 1, report.id);
                  }}
                >
                  {report.name}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
        <TabContent className="flow-content" activeTab={activeTab}>
          {reports &&
            reports.map((report, index) => (
              <TabPane tabId={index + 1} key={report.id}>
                <Container fluid>
                  <Row>
                    <Col sm="12" className="px-0">
                      {report.id === "steps_report" && (
                        <StepsReport
                          reports={report.report}
                          reportName={report.name}
                          reportId={report.id}
                        />
                      )}
                      {report.id === "employees_report" && (
                        <EmployeesReport
                          reports={report.report}
                          reportName={report.name}
                          reportId={report.id}
                        />
                      )}
                      {report.id === "vendors_report" && (
                        <VendorsReport
                          reports={report.report}
                          reportName={report.name}
                          reportId={report.id}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </TabPane>
            ))}
        </TabContent>
      </div>
      {allAlert && allAlert.isOpen ? (
        <Snackbar
          open={allAlert.isOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert color={allAlert.type}>{allAlert.message}</Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {isLoading && <BackdropLoader />}
    </>
  );
}

const mapState = (state) => {
  const { reports } = state.reports;
  const { isLoading } = state.errorAndLoading;
  const allAlert = state.errorAndLoading.alert;
  return {
    reports,
    isLoading,
    allAlert,
  };
};

const dispatchAction = {
  getStepsReport: reportActions.getStepsReport,
  getEmployeesReport: reportActions.getEmployeesReport,
  getReport: reportActions.getReport,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};

export default connect(mapState, dispatchAction)(Reports);
