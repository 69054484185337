export const pmsDBConstants = {
  DASHBOARD_DATA_REQUEST: "DASHBOARD_DATA_REQUEST",
  DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
  DASHBOARD_DATA_FAILURE: "DASHBOARD_DATA_FAILURE",
  RESET_PMS_DB: "RESET_PMS_DB",
  MOTOR_STATION_REQUEST: "MOTOR_STATION_REQUEST",
  GET_MOTOR_STATION_SUCCESS: "GET_MOTOR_STATION_SUCCESS",
  GET_MOTOR_STATION_FAILURE: "GET_MOTOR_STATION_FAILURE",
  GET_PLAN_DATA_SUCCESS: "GET_PLAN_DATA_SUCCESS",
  GET_PLAN_DATA_FAILURE: "GET_PLAN_DATA_FAILURE",
  PMS_DB_DATA_SUCCESS: "PMS_DB_DATA_SUCCESS",
  PMS_DB_DATA_FAILURE: "PMS_DB_DATA_FAILURE",
  GET_MOTOR_SCAN_TIME_SUCCESS: "GET_MOTOR_SCAN_TIME_SUCCESS",
  GET_MOTOR_SCAN_TIME_FAILURE: "GET_MOTOR_SCAN_TIME_FAILURE",
};
