import React, { useState } from "react";
import { reportService } from "../../../services";
import BackdropLoader from "../BackdropLoader";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    "&:hover": {
      color: "#ffffff",
    },
  },
}));

export default function ExportButton({ id, ...rest }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();

  const handleClick = async (id) => {
    try {
      setLoading(true);
      await reportService.downloadReport(id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SaveIcon size="small" />}
        onClick={(event) => handleClick(id)}
        {...rest}
        size="small"
      >
        Export
      </Button>
      {loading && <BackdropLoader />}
      {error && (
        <Snackbar
          open={error ? true : false}
          autoHideDuration={5000}
          onClose={() => setError("")}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      )}
    </div>
  );
}
