import { currPlanConstant } from "../constants";

export function currPlan(
  state = {
    plans: [],
    plan_status: {},
    total_production: {},
    plan_dispatch: {},
  },
  action
) {
  switch (action.type) {
    case currPlanConstant.GET_CURR_PLAN_SUCCESS:
      return {
        ...state,
        plans: action.data.plans,
      };

    case currPlanConstant.GET_CURR_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        plan_status: {
          ...action.data.reduce(
            (acc, plan) => ({
              ...acc,
              [plan.plan.id]: {
                ...plan,
              },
            }),
            {}
          ),
        },
      };

    case currPlanConstant.GET_CURR_PLAN_STATUS_FAILURE:
      return {
        ...state,
        plan_status: {},
      };

    case currPlanConstant.GET_TOTAL_PRODUCTION_SUCCESS:
      return {
        ...state,
        total_production: action.data,
      };

    case currPlanConstant.GET_PLAN_DISPATCH_SUCCESS:
      return {
        ...state,
        plan_dispatch: action.data,
      };

    case currPlanConstant.GET_TOTAL_PRODUCTION_FAILURE:
      return {
        ...state,
        total_production: [],
      };

    case currPlanConstant.GET_PLAN_DISPATCH_FAILURE:
      return {
        ...state,
        plan_dispatch: {},
      };

    case currPlanConstant.GET_CURR_PLAN_FAILURE:
    default:
      return state;
  }
}
