import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { userActions } from "../../actions";
import { connect } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";

function Profile({ logout, user }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async (event) => {
    handleClose(event);
    try {
      await logout();
      history.push("/login");
    } catch (err) {
      // log error
      console.log(err);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Hidden lgDown>
          {user ? user.firstname : ""}{" "}
          <img
            className="CNavigationProfileMenuIcon"
            src={require("./arrow_back_ios-24px.svg")}
            alt="down arrow"
          />
        </Hidden>
        {user &&
          (user.avatar == null ? (
            <Avatar>{user.firstname[0] + user.lastname[0]}</Avatar>
          ) : (
            <Avatar
              src={user.avatar}
              alt="avatar"
              style={{ width: 48, height: 48 }}
            />
          ))}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function mapState(state) {
  const user = state.user.user;

  return {
    user,
  };
}

const actionCreator = {
  logout: userActions.logout,
};

export default connect(mapState, actionCreator)(Profile);
