import { useSelector } from "react-redux";
// import { ROUTES } from "./RoutesConst";

const useRoles = (path = "/flowview") => {
  const user = useSelector((state) => state?.user?.user);
  if (!user?.id) return false;
  if (!user?.role) return false;
  let access = user?.permission.find((_) => _.page_route === path);
  return access?.view_page;
  // return user.role.some((role) => ROUTES[path]?.includes(role.name));
};

export default useRoles;
