import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "25ch",
    margin: 10,
  },
}));

const DList = ({ po, departments, handleChange, error }) => {
  const classes = useStyles();
  return (
    <>
      <FormControl
        error={error}
        variant="outlined"
        className={classes.formControl}
        size="small"
        disabled={po.selected_vendor ? false : true}
      >
        <InputLabel id="department-label">Department</InputLabel>
        <Select
          labelId="department-label"
          id="selected_department"
          name="selected_department"
          value={po.selected_department ?? ""}
          label="Department"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Department:</em>
          </MenuItem>
          {departments &&
            departments.map((department, index) => (
              <MenuItem value={department.id} key={index}>
                {department.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DList;
