import { gridsConstants, errorAndLoadingConstants } from "../constants";
import { gridsService } from "../services";
import { requestFailError } from "../utils";

// use this function to call reducer to handle alert
const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

const getGrids = (type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: gridsConstants.GRIDS_REQUEST,
    });
    await gridsService.getGrids(type).then(
      (grids) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (grids.status === 200) {
          dispatch({
            type: gridsConstants.GRIDS_SUCCESS,
            grids: grids.data.grids,
          });
        } else {
          const errMsg = requestFailError(grids, "Error loading flows");
          dispatchFailure(dispatch, errMsg, {
            type: gridsConstants.GRIDS_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: gridsConstants.GRIDS_FAILURE,
        });
      }
    );
  };
};

const getSingleGrid = (id, date_range) => {
  return async (dispatch) => {
    dispatch({
      type: gridsConstants.SINGLE_GRID_REQUEST,
    });
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await gridsService.getSingleGrid(id, date_range).then(
      (grid) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (grid.status === 200) {
          dispatch({
            type: gridsConstants.SINGLE_GRID_SUCCESS,
            grid: grid.data.grid,
          });
        } else {
          const errMsg = requestFailError(grid, "Error loading flow");
          dispatchFailure(dispatch, errMsg, {
            type: gridsConstants.SINGLE_GRID_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: gridsConstants.SINGLE_GRID_FAILURE,
        });
      }
    );
  };
};

const onDrop = (dropData, remark_text) => {
  const { job_id, to_workcentre_id, accountable_id } = dropData;
  const requestData = {
    job_log: {
      job_id,
      workcentre_id: to_workcentre_id,
      accountable_id,
    },
    remark_text,
  };
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await gridsService.onDrop(requestData).then(
      (user) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (user.status === 200) {
          dispatch({
            type: gridsConstants.DROP_JOB,
            data: { dropData, remark_text, response: user.data.job_log },
          });
        } else {
          dispatch({
            type: gridsConstants.DROP_FAIL,
            data: "Sorry, cannot move this job",
          });
          setTimeout(() => {
            dispatch({
              type: gridsConstants.DROP_ALERT_STOP,
            });
          }, 1500);
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatch({
          type: gridsConstants.DROP_FAIL,
          data: "Sorry, cannot move this job",
        });
        setTimeout(() => {
          dispatch({
            type: gridsConstants.DROP_ALERT_STOP,
          });
        }, 1500);
      }
    );
  };
};

const invalidDrop = (message = "") => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.DROP_FAIL,
      data: message,
    });
    setTimeout(() => {
      dispatch({
        type: gridsConstants.DROP_ALERT_STOP,
      });
    }, 1500);
  };
};

const stopDropAlert = () => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch({
        type: gridsConstants.DROP_ALERT_STOP,
      });
    }, 1500);
  };
};

const setSearch = (data) => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.SET_INDENT_SEARCH,
      data,
    });
  };
};

const resetSearch = () => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.RESET_INDENT_SEARCH,
    });
  };
};

const setGridSearch = (data) => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.SET_SEARCH,
      data,
    });
  };
};

const resetGridSearch = () => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.RESET_SEARCH,
    });
  };
};

const clearGrid = () => {
  return (dispatch) => {
    dispatch({
      type: gridsConstants.CLEAR_DETAILED_GRID,
    });
    dispatch({
      type: gridsConstants.GRIDS_REQUEST,
    });
  };
};

export const gridsActions = {
  getGrids,
  getSingleGrid,
  onDrop,
  stopDropAlert,
  invalidDrop,
  setSearch,
  resetSearch,
  setGridSearch,
  resetGridSearch,
  clearGrid,
};
