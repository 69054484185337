import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const fetchDepartments = () => {
  return axios
    .get("/api/v1/departments")
    .then((response) => response)
    .catch((err) => err);
};

const fetchItems = () => {
  return axios
    .get("/api/v1/items")
    .then((response) => response)
    .catch((err) => err);
};

const fetchItemDetails = (id) => {
  return axios
    .get(`/api/v1/items/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchVendors = (item_id) => {
  return axios
    .get(`/api/v1/vendors?item_id=${item_id}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchVendorDetails = (id) => {
  return axios
    .get(`/api/v1/vendors/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchSkus = (item_id, vendor_id) => {
  return axios
    .get(`/api/v1/item_skus/find_sku?item_id=${item_id}&vendor_id=${vendor_id}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchEmployee = () => {
  return axios
    .get("/api/v1/employees")
    .then((response) => response)
    .catch((err) => err);
};

const fetchEmployeeByRole = (role) => {
  return axios
    .get(`/api/v1/employees?role=${role}`)
    .then((response) => response)
    .catch((err) => err);
};

const saveIndent = (requestBody) => {
  return axios
    .post("/api/v1/indents", requestBody, {})
    .then((response) => response)
    .catch((err) => err);
};

const updateIndent = (requestBody, id) => {
  return axios
    .put(`/api/v1/indents/${id}`, JSON.stringify(requestBody), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const assignSKU = (sku_id, order_type, indent_id) => {
  return axios
    .put(
      `/api/v1/indents/${indent_id}?indent[item_sku_id]=${sku_id}&indent[order_type]=${order_type}`
    )
    .then((response) => response)
    .catch((err) => err);
};

const getManufacturingCompanies = () => {
  return axios
    .get(`/api/v1/manufacturing_companies`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchManufacturingCompany = (id) => {
  return axios
    .get(`/api/v1/manufacturing_companies/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const fetchIndentDetail = (id) => {
  return axios
    .get(`/api/v1/indents/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const unblockSKU = (item_sku_id) => {
  return axios
    .put(`/api/v1/item_skus/${item_sku_id}/request_unblock`)
    .then((response) => response)
    .catch((err) => err);
};

const createSKU = (vendor_id, item_id, manufacturing_company_id) => {
  return axios
    .post(
      `/api/v1/item_skus?item_sku[vendor_id]=${vendor_id}&item_sku[item_id]=${item_id}${
        manufacturing_company_id &&
        `&item_sku[manufacturing_company_id]=${manufacturing_company_id}`
      }`
    )
    .then((response) => response)
    .catch((err) => err);
};

const createConsignments = (requestBody) => {
  return axios
    .post(`/api/v1/consignments/`, JSON.stringify(requestBody), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const closeConsignment = (requestBody) => {
  return axios
   .post('/api/v1/consignments/new_indent', JSON.stringify(requestBody),{
    headers: {
      "Content-Type": "application/json",
    },
   })
   .then((response)=> response)
   .catch((err)=>err);
}

const forceCloseConsignment = (requestBody) => {
  return axios
   .put('/api/v1/consignments/closed_consignment', JSON.stringify(requestBody),{
    headers: {
      "Content-Type": "application/json",
    },
   })
   .then((response)=> response)
   .catch((err)=>err);
}

const rejectIndent = (id) => {
  return axios
    .put(`/api/v1/indents/${id}/reject_indent`)
    .then((response) => response)
    .catch((err) => err);
};

const holdIndent = (id) => {
  return axios
    .put(`/api/v1/indents/${id}/hold_indent`)
    .then((response) => response)
    .catch((err) => err);
};

const reviveIndent = (id) => {
  return axios
    .put(`/api/v1/indents/${id}/revive_indent`)
    .then((response) => response)
    .catch((err) => err);
};

const updateItem = (id, item_id, qty) => {
  return axios
    .put(`/api/v1/indents/${id}/update/item/${item_id}/${qty}`)
    .then((response) => response)
    .catch((err) => err);
};

const saveUnitPrice = (id, unit_price) => {
  return axios
    .put(`/api/v1/indents/${id}/update/price/${unit_price}`)
    .then((response) => response)
    .catch((err) => err);
};

export const indentFormServices = {
  fetchDepartments,
  fetchItems,
  fetchItemDetails,
  fetchVendors,
  fetchSkus,
  fetchEmployee,
  saveIndent,
  updateIndent,
  assignSKU,
  getManufacturingCompanies,
  fetchManufacturingCompany,
  unblockSKU,
  createSKU,
  createConsignments,
  forceCloseConsignment,
  closeConsignment,
  fetchEmployeeByRole,
  fetchVendorDetails,
  rejectIndent,
  fetchIndentDetail,
  holdIndent,
  reviveIndent,
  updateItem,
  saveUnitPrice,
};
