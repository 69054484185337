import { customerConstants } from "../constants";

export function customer(
  state = {
    sales_customer: [],
    detailed_customer: {},
  },
  action
) {
  switch (action.type) {
    case customerConstants.GET_SALES_CUSTOMER_SUCCESS:
      return {
        ...state,
        sales_customer: action.data,
      };
    case customerConstants.GET_DETAILED_CUSTOMER_SUCCESS:
      return {
        ...state,
        detailed_customer: action.data,
      };

    case customerConstants.GET_SALES_CUSTOMER_FAILURE:
    case customerConstants.GET_SALES_CUSTOMER_REQUEST:
    case customerConstants.GET_DETAILED_CUSTOMER_FAILURE:
    case customerConstants.GET_DETAILED_CUSTOMER_REQUEST:
    default:
      return state;
  }
}
