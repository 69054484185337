export const DISCOUNT_TYPE = {
  0: "Percentage",
  1: "Amount",
};

export const CORE_TYPE_MAP = {
  SalesOrder: "so_number",
  MaterialDispatchNote: "mdn_number",
  Dispatch: "br_code",
  Invoice: "eway_invoice_number",
  StockTransferOrder: "sto_number",
  StnScan: "cr_code",
};
