import React, { useEffect, useRef } from "react";
import ViewConsignmentTable from "./ViewConsignmentTable";
import AddConsignmentTable from "./AddConsignmentTable";

const ConsignmentContainer = ({ co, workcentre_name }) => {
  const isInitial = useRef(true);

  useEffect(() => {
    isInitial.current = false;
  }, []);

  return (
    <div className="mb-2 mt-4">
      {co.consignments.length > 0 ? (
        <ViewConsignmentTable consignments={co.consignments} />
      ) : (
        workcentre_name === "wc6" && (
          <AddConsignmentTable
            indent_id={co.id}
            balance={co.balance}
            unit={co.item && co.item.unit ? co.item.unit.name : ""}
          />
        )
      )}
    </div>
  );
};

export default ConsignmentContainer;
