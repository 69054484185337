export const historyReportConstants = {
  RESET_TESTING_DATA: "RESET_TESTING_DATA",

  HISTORY_REQUEST: "HISTORY_REQUEST",
  HISTORY_SUCCESS: "HISTORY_SUCCESS",
  HISTORY_FAILURE: "HISTORY_FAILURE",

  PRODUCTION_DETAIL_REQUEST: "PRODUCTION_DETAIL_REQUEST",
  PRODUCTION_DETAIL_SUCCESS: "PRODUCTION_DETAIL_SUCCESS",
  PRODUCTION_DETAIL_FAILURE: "PRODUCTION_DETAIL_FAILURE",

  OEM_PRODUCTION_REQUEST: "OEM_PRODUCTION_REQUEST",
  OEM_PRODUCTION_SUCCESS: "OEM_PRODUCTION_SUCCESS",
  OEM_PRODUCTION_FAILURE: "OEM_PRODUCTION_FAILURE",

  STOCK_TRANSFER_REPORT_REQUEST: "STOCK_TRANSFER_REPORT_REQUEST",
  STOCK_TRANSFER_REPORT_SUCCESS: "STOCK_TRANSFER_REPORT_SUCCESS",
  STOCK_TRANSFER_REPORT_FAILURE: "STOCK_TRANSFER_REPORT_FAILURE",
};
