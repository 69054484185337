import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getCurrPlan = (body) => {
  return axios
    .post("/api/v1/plans/get_plan_by_date_and_type", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getCurrPlanStatus = (type, date) => {
  return axios
    .get(
      `/api/v1/rotor_stator_testings/get_record_by_type?testing_type=${type}&testing_date=${date}`
    )
    .then((response) => response)
    .catch((err) => err);
};

const getTotalProduction = (body) => {
  return axios
    .post("/api/v1/plans/get_total_by_date_and_type", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getPlanDispatch = (body) => {
  return axios
    .post("/api/v1/dispatches/product_code_wise_count", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const currPlanService = {
  getCurrPlan,
  getCurrPlanStatus,
  getTotalProduction,
  getPlanDispatch,
};
