export const currPlanConstant = {
  GET_CURR_PLAN_SUCCESS: "GET_CURR_PLAN_SUCCESS",
  GET_CURR_PLAN_FAILURE: "GET_CURR_PLAN_FAILURE",

  GET_CURR_PLAN_STATUS_SUCCESS: "GET_CURR_PLAN_STATUS_SUCCESS",
  GET_CURR_PLAN_STATUS_FAILURE: "GET_CURR_PLAN_STATUS_FAILURE",

  GET_TOTAL_PRODUCTION_SUCCESS: "GET_TOTAL_PRODUCTION_SUCCESS",
  GET_TOTAL_PRODUCTION_FAILURE: "GET_TOTAL_PRODUCTION_FAILURE",

  GET_PLAN_DISPATCH_SUCCESS: "GET_PLAN_DISPATCH_SUCCESS",
  GET_PLAN_DISPATCH_FAILURE: "GET_PLAN_DISPATCH_FAILURE",
};
