import { historyReportConstants, errorAndLoadingConstants } from "../constants";
import { historyReportService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getQRHistoryReport = (qrCode, page) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: historyReportConstants.HISTORY_REQUEST
    });
    await historyReportService.getQRHistoryReport(qrCode, page).then(
      (history) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (history.status === 200) {
          dispatch({
            type: historyReportConstants.HISTORY_SUCCESS,
            data: history.data,
          });
        } else {
          const errMsg = requestFailError(history);
          dispatchFailure(dispatch, errMsg, {
            type: historyReportConstants.HISTORY_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: historyReportConstants.HISTORY_FAILURE,
        });
      }
    );
  };
};

const getTestingHistoryReport = (testingReportDate, selectedTestingStation, motorType, page) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: historyReportConstants.HISTORY_REQUEST
    });
    await historyReportService.getTestingHistoryReport(testingReportDate, selectedTestingStation, motorType, page).then(
      (history) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (history.status === 200) {
          dispatch({
            type: historyReportConstants.HISTORY_SUCCESS,
            data: history.data,
          });
        } else {
          const errMsg = requestFailError(history);
          dispatchFailure(dispatch, errMsg, {
            type: historyReportConstants.HISTORY_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: historyReportConstants.HISTORY_FAILURE,
        });
      }
    );
  };
};

const getProductionDetailReport = (reqBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: historyReportConstants.PRODUCTION_DETAIL_REQUEST
    });
    await historyReportService.getProductionDetailReport(reqBody).then(
      (history) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (history.status === 200) {
          dispatch({
            type: historyReportConstants.PRODUCTION_DETAIL_SUCCESS,
            data: history.data,
          });
        } else {
          const errMsg = requestFailError(history);
          dispatchFailure(dispatch, errMsg, {
            type: historyReportConstants.PRODUCTION_DETAIL_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: historyReportConstants.PRODUCTION_DETAIL_FAILURE,
        });
      }
    );
  };
};

const getOemProductionReport = (reqBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: historyReportConstants.OEM_PRODUCTION_REQUEST
    });
    await historyReportService.getOemProductionReport(reqBody).then(
      (history) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (history.status === 200) {
          dispatch({
            type: historyReportConstants.OEM_PRODUCTION_SUCCESS,
            data: history.data,
          });
        } else {
          const errMsg = requestFailError(history);
          dispatchFailure(dispatch, errMsg, {
            type: historyReportConstants.OEM_PRODUCTION_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: historyReportConstants.OEM_PRODUCTION_FAILURE,
        });
      }
    );
  };
};

const getStockTransferReport = (reqBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: historyReportConstants.STOCK_TRANSFER_REPORT_REQUEST
    });
    await historyReportService.getStockTransferReport(reqBody).then(
      (history) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (history.status === 200) {
          dispatch({
            type: historyReportConstants.STOCK_TRANSFER_REPORT_SUCCESS,
            data: history.data,
          });
        } else {
          const errMsg = requestFailError(history);
          dispatchFailure(dispatch, errMsg, {
            type: historyReportConstants.STOCK_TRANSFER_REPORT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: historyReportConstants.STOCK_TRANSFER_REPORT_FAILURE,
        });
      }
    );
  };
};

const resetTestingData = () => {
  return async (dispatch) => {
    dispatch({
      type: historyReportConstants.RESET_TESTING_DATA,
    });
  }
};

export const historyReportAction = {
  getQRHistoryReport,
  getTestingHistoryReport,
  getProductionDetailReport,
  getOemProductionReport,
  getStockTransferReport,
  resetTestingData,
};
