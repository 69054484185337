import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Button, Label, Form} from "reactstrap";
import {TextField as MaterialTextField} from "@material-ui/core"
import logo from "../../assets/sujatalogo.png";
function ChangePassword(props){
    const [confirm_password, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    
    function validateForm() {
        return confirm_password.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    return (
    <div className="Gcontainer">
    <div className="CLoginChangePassword container">
        <div className="CLoginChangePasswordForm">
            <div className="CLoginLogo">
                <Link to="/login"><img src={logo} className="img-fluid CLoginChangePasswordLogo" alt="logo"/></Link>
            </div>
            <Label className="CLoginChangePasswordLabel"> Reset Password</Label>
            <Form onSubmit={handleSubmit}>
                <div className="CLoginPasswordDiv">
                    <MaterialTextField className="CLoginFormgroup"
                        type="password" 
                        label="New Password" 
                        variant="outlined"
                        placeholder="New Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className="CLoginConfPassDiv">
                    <MaterialTextField className="CLoginFormgroup"
                        type="password"
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        variant="outlined"
                        value={confirm_password}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="CLoginResetPasswordLink">
                        <Link to="/request_reset_passwd">Change Password</Link>
                </div>
                <Button block disabled={!validateForm()} color="primary" type="submit">
                    SAVE PASSWORD
                </Button>
            </Form>
        </div>
    </div>
    </div>
    );
}
export default ChangePassword;
