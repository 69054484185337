import axios from "axios";
import { saveAs } from "file-saver";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const saveJob = (requestData) => {
  return axios
    .post("/api/v1/purchase_orders", JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const routeJob = (requestData) => {
  return axios
    .post("/api/v1/jobs/route_job", JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const moveLoJob = (requestData) => {
  return axios
    .put("/api/v1/lot_offers/move_to_next_step", requestData)
    .then((res) => res)
    .catch((err) => err);
};

const rejectLoJob = (requestData) => {
  return axios
    .put("/api/v1/lot_offers/reject_lo", requestData)
    .then((res) => res)
    .catch((err) => err);
};

const uploadFile = (requestData, id) => {
  return axios
    .post(`/api/v1/jobs/${id}/attach_file`, requestData)
    .then((response) => response)
    .catch((err) => err);
};

const downloadFile = (url) => {
  return axios
    .get(url, { responseType: "blob" })
    .then((response) => {
      const blog = new Blob([response.data]);
      const contentDisposition = response.headers["content-disposition"];
      let filename = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          filename = fileNameMatch[1];
        }
      }
      saveAs(blog, filename);
    })
    .catch((err) => err);
};

const getJob = (id, type = "indents") => {
  if (type === "dispatches") {
    return axios
      .get(`/api/v1/dispatches/${id}/get_dispatch_info`)
      .then((response) => response)
      .catch((err) => err);
  }
  return axios
    .get(`/api/v1/${type}/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const getPOJob = (id) => {
  return axios
    .get(`/api/v1/purchase_orders/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

export const jobService = {
  saveJob,
  routeJob,
  moveLoJob,
  rejectLoJob,
  uploadFile,
  downloadFile,
  getJob,
  getPOJob,
};
