import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Css";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const StepTopography = ({
  body1,
  body2,
  reverse,
  wordBreak,
  isBig,
  isMedium,
  showBlink,
  blinkClass,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.CTasks}>
      {reverse && (
        <Typography
          className={clsx(
            classes.body2,
            { "text-break": wordBreak.body2 },
            isBig && classes.stBig2,
            isMedium && classes.stMedium2
          )}
          variant="body2"
        >
          {body2}
        </Typography>
      )}
      <Typography
        className={clsx(
          classes.body1,
          { "text-break": wordBreak.body1 },
          isBig && classes.stBig1,
          isMedium && classes.stMedium1
        )}
        variant="body1"
      >
        {body1}{" "}
        {showBlink ? <Box className={blinkClass} component="span" /> : null}
      </Typography>
      {!reverse && (
        <Typography
          className={clsx(
            classes.body2,
            { "text-break": wordBreak.body2 },
            isBig && classes.stBig2,
            isMedium && classes.stMedium2
          )}
          variant="body2"
        >
          {body2}
        </Typography>
      )}
    </div>
  );
};

StepTopography.defaultProps = {
  reverse: false,
  wordBreak: {
    body1: false,
    body2: false,
  },
  isBig: false,
  isMedium: false,
  showBlink: false,
};

StepTopography.propTypes = {
  body1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  body2: PropTypes.string,
  reverse: PropTypes.bool,
  wordBreak: PropTypes.object,
  isBig: PropTypes.bool,
};

export default StepTopography;
