import React, { useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import ListboxComponent from "../Cards/ListboxComponent";
import { gridsActions, indentFormActions, vendorActions } from "../../actions";
import { connect } from "react-redux";
import FormDatePicker from "../Cards/FormDatePicker";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    background: "#ffffff",
    margin: [[theme.spacing(4), 0]],
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
  formControl: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
  },
  whiteBG: {
    background: "#ffffff",
  },
  itemOption: {
    "&:after": {
      content: '""',
      display: "block",
      height: 1,
      background: theme.palette.divider,
      width: "calc(100% - 32px)",
      position: "absolute",
      bottom: 0,
    },
  },
  truncate: {
    display: "-webkit-box",
    "-moz-box-orient": "vertical",
    "-webkit-box-orient": "vertical",
    "box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
  },
}));

const initialState = {
  search_term: "",
  item: null,
  vendor: null,
  department: null,
  search_date: null
};

const SearchIndent = ({
  items,
  vendors,
  departments,
  fetchItems,
  fetchDepartments,
  fetchVendors,
  setSearch,
  resetSearch,
  indentSearch,
  isPO = false,
}) => {
  const [queries, setQueries] = useState(initialState);
  const classes = useStyles();

  const handleSearch = (e) => {
    e.preventDefault();
    const { search_term, item, vendor, department, search_date } = queries;
    if (!search_term && !item && !vendor && !department && !search_date) {
      resetSearch();
      return;
    }
    setSearch({
      search_term,
      department_id: department?.id,
      item_id: item?.id,
      vendor_id: vendor?.id,
      isPO,
      search_date,
    });
  };

  return (
    <form className={classes.formRoot} onSubmit={handleSearch}>
      <Box marginRight={4}>
        <TextField
          className={classes.formControl}
          variant="outlined"
          size="small"
          label="Requisition Slip Number"
          value={queries.search_term}
          disabled={indentSearch.isVisible}
          onChange={(event) => {
            event.persist();
            setQueries((ps) => ({
              ...ps,
              search_term: event.target.value,
            }));
          }}
        />
      </Box>
      {!isPO && (
        <Box marginRight={4}>
          <Autocomplete
            id="item"
            options={items}
            value={queries.item}
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            className="d-inline-block"
            disabled={indentSearch.isVisible}
            classes={{
              option: classes.itemOption,
            }}
            onChange={(event, newValue) =>
              setQueries((ps) => ({ ...ps, item: newValue }))
            }
            closeIcon={<CloseIcon size="small" id="item" />}
            onOpen={async () => items.length < 1 && (await fetchItems())}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item Name"
                variant="outlined"
                className={classes.formControl}
                size="small"
              />
            )}
            renderOption={(option) => (
              <Tooltip title={option.name} position="bottom">
                <Typography id="item" className={classes.truncate}>
                  {option.name}
                </Typography>
              </Tooltip>
            )}
          />
        </Box>
      )}
      {!isPO && (<Box marginRight={4}>
        <Autocomplete
          id="department"
          options={departments}
          value={queries.department}
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          className="d-inline-block"
          disabled={indentSearch.isVisible}
          classes={{
            option: classes.itemOption,
          }}
          onChange={(event, newValue) =>
            setQueries((ps) => ({ ...ps, department: newValue }))
          }
          closeIcon={<CloseIcon size="small" id="department" />}
          onOpen={async () => departments.length < 1 && (await fetchDepartments())}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Department Name"
              variant="outlined"
              className={classes.formControl}
              size="small"
            />
          )}
          renderOption={(option) => (
            <Tooltip title={option.name} position="bottom">
              <Typography id="department" className={classes.truncate}>
                {option.name}
              </Typography>
            </Tooltip>
          )}
        />
      </Box>
      )}
      <Box marginRight={4}>
        <Autocomplete
          id="vendor"
          options={vendors}
          value={queries.vendor}
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          className="d-inline-block"
          disabled={indentSearch.isVisible}
          classes={{
            option: classes.itemOption,
          }}
          onChange={(event, newValue) =>
            setQueries((ps) => ({ ...ps, vendor: newValue }))
          }
          closeIcon={<CloseIcon size="small" id="vendor" />}
          onOpen={async () => vendors.length < 1 && (await fetchVendors())}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Vendor Name"
              variant="outlined"
              className={classes.formControl}
              size="small"
            />
          )}
          renderOption={(option) => (
            <Tooltip title={option.name} position="bottom">
              <Typography id="vendor" className={classes.truncate}>
                {option.name}
              </Typography>
            </Tooltip>
          )}
        />
      </Box>
      <Box marginRight={4}>
      <FormDatePicker
                selectedDate={queries.search_date}
                name="searchDate"
                label="Date"
                readOnly={true}
                size="small"
                disabled={indentSearch.isVisible}
                handleChange={
                  (event)=>{
                   setQueries( (ps) => ({
                    ...ps,
                    search_date: event.format("YYYY-MM-DD"),
                   }));
                  }
                }
              />
      </Box>
      <Box marginRight={4} display="flex" alignItems="center">
        <Button
          disabled={indentSearch.isVisible}
          variant="contained"
          color="primary"
          onClick={handleSearch}
          type="submit"
        >
          Search
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setQueries(initialState);
            resetSearch();
          }}
        >
          Reset
        </Button>
      </Box>
    </form>
  );
};

const mapState = (state) => {
  const { items, departments } = state.indentForm;
  const { indentSearch } = state.grids;
  const vendors = state.allVendors.allVendors;
  return {
    items,
    departments,
    vendors,
    indentSearch,
  };
};

const dispatchAction = {
  fetchItems: indentFormActions.fetchItems,
  fetchDepartments: indentFormActions.fetchDepartments,
  fetchVendors: vendorActions.fetchVendor,
  setSearch: gridsActions.setSearch,
  resetSearch: gridsActions.resetSearch,
};

export default connect(mapState, dispatchAction)(SearchIndent);
