import React from "react";
import { useLocation, Link } from "react-router-dom";

import Profile from "./Profile";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));
export default function DisabledTabs({ value, handleChange }) {
  const location = useLocation();
  const classes = useStyles();

  const showTab = ["/flowview", "/myview"].includes(location.pathname);

  return (
    <Grid item className="CNavigationTabs" xs={4} md={6} xl={10}>
      <Grid container justify="space-between">
        {showTab && (
          <Grid item>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="tabs"
              className={classes.root}
            >
              <Tab label="Complete View" component={Link} to="/flowview" />
              <Tab label="My View" component={Link} to="/myview" />
            </Tabs>
          </Grid>
        )}
        <Grid item className="d-flex align-items-center ml-auto">
          <div className="NavigationBell">
            <img src={require("./Notification.png")} alt="notifiction bell" />
          </div>
          <div className="NavigationProfile">
            <Profile />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
