import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import FlowViewMenu from "./FlowViewMenu";
import Tracker from "./Tracker";
import MyViewTracker from "./MyViewTracker";
import { connect } from "react-redux";
import { gridsActions, errorAndLoadingActions } from "../../actions";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "reactstrap";
import AddIndentForm from "../Cards/CreateIndent/AddIndentForm";
import AddPo from "../Cards/PO/AddPo";
import DialogAlert from "./DialogAlert";
import BackdropLoader from "../Cards/BackdropLoader";
import SearchIndent from "./SearchIndent";
import { withStyles } from "@material-ui/core";
import { flowConstant, GRIDS } from "../../utils";

const FlowPaper = withStyles({
  root: {
    padding: 20,
    background: "transparent",
  },
})(Paper);

const Flow = ({
  getGrids,
  dragAlert,
  detailedGrid,
  allAlert,
  value,
  popUp,
  loadCount,
  openAlert,
  closeAlert,
  resetSearch,
  clearGrid,
}) => {
  const [selectedFlow, setSelectedFlow] = useState("");

  const handleChange = (flowId) => {
    setSelectedFlow(flowId);
    resetSearch();
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getGrids(GRIDS.INDENT);
      } catch (err) {
        openAlert(`Error loading Flows: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    fetchData();
    return () => {
      clearGrid();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlowPaper elevation={0}>
      <div>
        <FlowViewMenu selectedFlow={selectedFlow} handleChange={handleChange} />
      </div>
      {selectedFlow &&
      detailedGrid.id &&
      [flowConstant.INDENT_PROCESS_TRACKER, flowConstant.PO_ARCHIVE].includes(
        detailedGrid.id
      ) ? (
        <SearchIndent />
      ) : (
        [
          flowConstant.PO_GENERATOR,
          flowConstant.IMPORT_PROCESS_TRACKER,
        ].includes(detailedGrid.id) && <SearchIndent isPO={true} />
      )}
      <Switch>
        <Route path="/myview/:flowId">
          <MyViewTracker view={value} />
        </Route>
        <Route path="/flowview/:flowId">
          <Tracker view={value} />
        </Route>
      </Switch>
      <Snackbar open={dragAlert.isOpen}>
        <Alert color="danger">{dragAlert.message}</Alert>
      </Snackbar>
      {detailedGrid.id ? (
        <>
          {[
            flowConstant.PO_GENERATOR,
            flowConstant.IMPORT_PROCESS_TRACKER,
          ].includes(selectedFlow) && (
            <AddPo
              order_type={
                selectedFlow === flowConstant.PO_GENERATOR ? "local" : "import"
              }
            />
          )}
          {selectedFlow === flowConstant.INDENT_PROCESS_TRACKER && (
            <AddIndentForm />
          )}
        </>
      ) : (
        ""
      )}
      {allAlert && allAlert.isOpen ? (
        <Snackbar
          open={allAlert.isOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert color={allAlert.type}>{allAlert.message}</Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {popUp.isOpen && <DialogAlert popUP={popUp} />}
      {loadCount > 0 && <BackdropLoader />}
    </FlowPaper>
  );
};

const mapState = (state) => {
  const { dragAlert, detailedGrid } = state.grids;
  const allAlert = state.errorAndLoading.alert;
  const { popUp, loadCount } = state.errorAndLoading;
  return {
    dragAlert,
    detailedGrid,
    allAlert,
    popUp,
    loadCount,
  };
};

const dispatchAction = {
  getGrids: gridsActions.getGrids,
  resetSearch: gridsActions.resetSearch,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
  clearGrid: gridsActions.clearGrid,
};

export default connect(mapState, dispatchAction)(Flow);
