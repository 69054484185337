import { poFormConstants } from "../constants";

export function poForm(
  state = {
    vendors: [],
    vendorAllIndent: [],
    vendorIndents: [],
    busy_purchase_types: [],
    busy_itc_eligibilities: [],
    busy_currencies: [],
    material_centres: [],
    po_users: [],
  },
  action
) {
  function compareDataOnBusyGst(a, b) {
    const bandA = +a?.item?.busy_item?.busy_gst ?? 0;
    const bandB = +b?.item?.busy_item?.busy_gst ?? 0;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  function getPOTableLength() {
    let vendorTempAllIndent = action.data;
    let vendorIndentItem = [];
    let temp1 = [],
      temp2 = [];
    vendorTempAllIndent.sort(compareDataOnBusyGst);
    for (let i = 0; i < vendorTempAllIndent.length; i++) {
      let value1 = vendorTempAllIndent[i].item?.busy_item?.busy_gst ?? "no_gst";
      if (value1 !== null || value1 !== undefined) {
        if (!vendorIndentItem.includes(value1)) {
          if (i === 0) {
            vendorIndentItem.push(value1);
            temp1.push(vendorTempAllIndent[i]);
            if (vendorTempAllIndent.length === 1) temp2.push(temp1);
          } else if (i < vendorTempAllIndent.length - 1) {
            vendorIndentItem.push(value1);
            temp2.push(temp1);
            temp1 = [];
            temp1.length = 0;
            temp1.push(vendorTempAllIndent[i]);
          } else {
            vendorIndentItem.push(value1);
            temp2.push(temp1);
            temp1 = [];
            temp1.length = 0;
            temp1.push(vendorTempAllIndent[i]);
            temp2.push(temp1);
            temp1 = [];
            temp1.length = 0;
          }
        } else {
          if (i < vendorTempAllIndent.length - 1) {
            temp1.push(vendorTempAllIndent[i]);
          } else {
            temp1.push(vendorTempAllIndent[i]);
            temp2.push(temp1);
            temp1 = [];
            temp1.length = 0;
          }
        }
      }
    }
    return temp2;
  }

  switch (action.type) {
    case poFormConstants.GET_VENDOR_REQUEST:
      return {
        ...state,
        vendors: [],
      };

    case poFormConstants.GET_VENDOR_SUCCESS:
      return {
        ...state,
        vendors: action.data,
      };

    case poFormConstants.GET_VENDOR_INDENTS_REQUEST:
      return {
        ...state,
        vendorIndents: [],
      };
    case poFormConstants.GET_PURCHASE_TYPE_SUCCESS:
      return {
        ...state,
        busy_purchase_types: action.data,
      };
    case poFormConstants.GET_ITC_ELIGIBILITIES_SUCCESS:
      return {
        ...state,
        busy_itc_eligibilities: action.data,
      };
    case poFormConstants.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        busy_currencies: action.data,
      };
    case poFormConstants.GET_MATERIAL_CENTER_SUCCESS:
      return {
        ...state,
        material_centres: action.data,
      };
    case poFormConstants.GET_PO_USERS_SUCCESS:
      return {
        ...state,
        po_users: action.data,
      };
    case poFormConstants.GET_VENDOR_INDENTS_SUCCESS:
      let vendorIndentData = getPOTableLength();
      return {
        ...state,
        vendorIndents: vendorIndentData,
      };
    case poFormConstants.GET_DEPARTMENT_INDENTS_SUCCESS:
      let departmentIndentData = getPOTableLength();
      return {
        ...state,
        vendorIndents: departmentIndentData,
      };
      case poFormConstants.GET_DEPARTMENT_INDENTS_FAILURE:
        return {
          ...state,
          vendorIndents: [],
        };
    default:
      return state;
  }
}
