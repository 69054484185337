import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
// import { BasePaths, ROUTES } from "./RoutesConst";

const useRedirect = () => {
  const user = useSelector((state) => state?.user?.user ?? {});
  const redirectPath = useRef("/");

  useMemo(() => {
    if (!user?.id) return;
    if (!user?.role) {
      redirectPath.current = "/404";
      return;
    }
    const access = user?.permission?.find((_) => _.page_route === "/OEM/stock-out/");
    if (user.role && user.role.name === "oem_dispatch" && access?.view_page) {
      redirectPath.current = "/OEM/stock-out";
      return;
    }
    if (user.role) {
      redirectPath.current = "/flowview";
      return;
    }
    // if (
    //   ["/flowview", `${BasePaths.PMS}/d/`, `${BasePaths.FINAL}/jar/`].forEach(
    //     (route) => {
    //       if (!ROUTES[route]) return false;
    //     }
    //   )
    // )
    //   return;
    // if (user.roles.some((role) => ROUTES["/flowview"].includes(role.name)))
    //   redirectPath.current = "/flowview";
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.PMS}/d/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.PMS}/d/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.SALESDISPATCH}/d/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.SALESDISPATCH}/d/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.FINAL}/jar/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.FINAL}/jar/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.JAR}/jar/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.JAR}/jar/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.MOULDING}/mould/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.MOULDING}/mould/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[
    //       `${BasePaths.QUALITY}/reports/incoming-quality-report/`
    //     ].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.QUALITY}/reports/incoming-quality-report/`;
    // else if (
    //   user.roles.some((role) =>
    //     ROUTES[`${BasePaths.WAREHOUSE}/d/`].includes(role.name)
    //   )
    // )
    //   redirectPath.current = `${BasePaths.QUALITY}/d/`;
  }, [user]);

  return redirectPath.current;
};

export default useRedirect;
