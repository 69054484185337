export const Defects = [
    {id: 1 , name: "Armature defect", acronym: "(A.D.)" },
    {id: 2 , name: "Armature Earth", acronym: "(A.E.)" },
    {id: 3 , name: "Armature short", acronym: "(A.S.)" },
    {id: 4 , name: "Armature Unbalance", acronym: "(A.U.)" },
    {id: 5 , name: "Ball bearing loose in motor bottom", acronym: "(B.L.B.)" },
    {id: 6 , name: "Ball bearing loose in motor top", acronym: "(B.L.T.)" },
    {id: 7 , name: "Bearing coupling loose in motor top", acronym: "(B.C.L.T.)" },
    {id: 8 , name: "Bearing Size loose in motor top", acronym: "(B.S.L.T.)" },
    {id: 9 , name: "Bearing Size loose in shaft", acronym: "(B.S.L.)" },
    {id: 10 , name: "Carbon brush cutting commutator", acronym: "(C.C.C.)" },
    {id: 11 , name: "Coupling comes out from bottom", acronym: "(C.O.B.)" },
    {id: 12 , name: "Chimta Sound", acronym: "(C.H.B.S.)" },
    {id: 13 , name: "Delrin Coupler Unbalance", acronym: "(C.U.)" },
    {id: 14 , name: "Fan loose in shaft", acronym: "(F.L.)" },
    {id: 15 , name: "Field connection break", acronym: "(F.C.B.)" },
    {id: 16 , name: "Field Connection Wrong", acronym: "(F.C.W.)" },
    {id: 17 , name: "Field Earth", acronym: "(A.F.)" },
    {id: 18 , name: "Field patti Less (Double ball bearing)", acronym: "(F.P.L.)" },
    {id: 19 , name: "Field Patti Over", acronym: "(F.P.O)" },
    {id: 20 , name: "Motor Jam", acronym: "(M.J.)" },
    {id: 21 , name: "Polish Cut / Recut required", acronym: "(P.C.)" },
    {id: 22 , name: "Thread damage of armature shaft", acronym: "(T.D.A.S.)" },
    {id: 23 , name: "Thread damage of motor top", acronym: "(T.D.M.T.)" },
    {id: 24 , name: "Top Sound", acronym: "(T.B.B.S.)" },
    {id: 25 , name: "Wattage High / Low (Motor)", acronym: "(W.H/L.)" },
]