import { errorAndLoadingConstants } from "../constants";
import { downloadFile as download } from "../services";

const downloadFile = (url, params = null) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await download(url, params).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatch({
          type: errorAndLoadingConstants.OPEN_ALERT,
          message: err,
          alertType: "danger",
        });
      }
    );
  };
};

export const downloadAction = {
  downloadFile,
};
