export const errorAndLoadingConstants = {
  OPEN_ALERT: "OPEN_ALERT",
  CLOSE_ALERT: "CLOSE_ALERT",

  OPEN_POPUP: "OPEN_POPUP",
  CLOSE_POPUP: "CLOSE_POPUP",

  API_CALL_START: "API_CALL_START",
  API_CALL_STOP: "API_CALL_STOP",

  SHOW_SUCCESS: "SHOW_SUCCESS",
  CLOSE_SUCCESS: "CLOSE_SUCCESS",
};
