import { auditToolConstant } from "../constants";

export function auditTool(
  state = {
    auditList: [],
    auditDetails: {},
    store: [],
    scannedSerials: [],
    subSerials: {},
    dispatchStations: [],
    brList: [],
  },
  action
) {
  switch (action.type) {

    case auditToolConstant.RESET_AUDIT_DATA:
      return {
        auditList: [],
        auditDetails: {},
        store: [],
        scannedSerials: [],
        subSerials: {},
        dispatchStations: [],
        brList: [],
      };

    case auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_REQUEST:
    case auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_REQUEST:
    case auditToolConstant.COMPLETE_AUDIT_SCANNING_REQUEST:
    case auditToolConstant.GET_WAREHOUSE_AUDIT_REQUEST:
    case auditToolConstant.GET_DISPATCH_STATION_REQUEST:
    case auditToolConstant.GET_BR_LIST_REQUEST:
      return {
        ...state,
      };

    case auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_SUCCESS:
      return {
        ...state,
        auditList: action.data
      };

    case auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_SUCCESS:
      return auditDetails(state, action.data);
    
    case auditToolConstant.COMPLETE_AUDIT_SCANNING_SUCCESS:
      return auditDetails(state, action.data);

    case auditToolConstant.GET_WAREHOUSE_AUDIT_SUCCESS:
      return warehouseAudit(state, action.data);

    case auditToolConstant.GET_DISPATCH_STATION_SUCCESS:
      return {
        ...state,
        dispatchStations: action.data,
      };

    case auditToolConstant.GET_BR_LIST_SUCCESS:
      return {
        ...state,
        brList: action.data,
      };

    case auditToolConstant.GET_WAREHOUSE_AUDIT_LIST_FAILURE:
    case auditToolConstant.GET_WAREHOUSE_AUDIT_DETAILS_FAILURE:
    case auditToolConstant.COMPLETE_AUDIT_SCANNING_FAILURE:
    case auditToolConstant.GET_WAREHOUSE_AUDIT_FAILURE:
      return {
        ...state,
      };
    case auditToolConstant.GET_DISPATCH_STATION_FAILURE:
      return{
        ...state,
        dispatchStations: [],
      }
    case auditToolConstant.GET_BR_LIST_FAILURE:
      return {
        ...state,
        brList: [],
      }

    default:
      return state;
  }
}

/************************ utility functions for audit tool reducers ****************** */

const auditDetails = (state, auditData) => {
  const { audit_tool_logs, ...newData } = auditData;

  if(newData?.store_type === "master_carton_store" && newData?.sub_store_type === "single"){

    const serialsObject = {}

    audit_tool_logs.forEach(log => {
      const scannedSerial = log.scanned_serial;
      const subSerial = log.box_data.sub_serial;

      if (!serialsObject[scannedSerial]) {
        serialsObject[scannedSerial] = [];
      }
      serialsObject[scannedSerial].push(subSerial);
    });
  
    const resultMap = new Map();

    audit_tool_logs.forEach(log => {
      const scannedSerial = log.scanned_serial;
      const subSerialData = {
        sub_serial: log.box_data.sub_serial,
        match: log.box_data.match
      };
  
      if (!resultMap.has(scannedSerial)) {
        resultMap.set(scannedSerial, {
          match: log.match,
          stations: log.stations,
          product_name: log.product_name,
          scanned_serial: scannedSerial,
          production_flow: log.production_flow,
          master_carton_code: log.master_carton_code,
          all_serials: [subSerialData]
        });
      } else {
        resultMap.get(scannedSerial).all_serials.push(subSerialData);
      }
    });

    return {
      ...state,
      store: Array.from(resultMap.values()),
      subSerials: serialsObject,
      scannedSerials: auditData.audit_tool_logs.map((data) => data.scanned_serial),
      auditDetails: newData,
    };
  }

  return {
    ...state,
    store: auditData.audit_tool_logs,
    scannedSerials: auditData.audit_tool_logs.map((data) => data.scanned_serial),
    auditDetails: newData,
  };
}

const warehouseAudit = (state, auditData) => {
  const { audit_tool, data } = auditData;
  if(audit_tool?.store_type === "master_carton_store" && audit_tool?.sub_store_type === "single"){

    const boxCode = data.scanned_serial;
    const subSerial = data.box_data.sub_serial;
    const storeArray = state.store;
    const serialsObject = state.subSerials
  
    if (serialsObject.hasOwnProperty(boxCode)){
      if (!serialsObject[boxCode].includes(subSerial))
        serialsObject[boxCode].push(subSerial);
    }
    else{
      serialsObject[boxCode] = [subSerial];
    }
    let existingBoxCodeIndex = storeArray.findIndex(store => store.scanned_serial === data.scanned_serial)
    if (existingBoxCodeIndex !== -1) {
      storeArray[existingBoxCodeIndex].all_serials.push({sub_serial: data.box_data.sub_serial, match: data.box_data.match})
    }
    else {
      let newScanData = {
        all_serials: [{
          sub_serial: data.box_data.sub_serial,
          match: data.box_data.match
        }],
        master_carton_code: data.master_carton_code,
        product_name: data.product_name,
        production_flow: data.production_flow,
        scanned_serial: data.scanned_serial
      }
      storeArray.unshift(newScanData)
    }
    return {
      ...state,
      store: storeArray,
      subSerials: serialsObject,
      scannedSerials: [...state.scannedSerials, data.scanned_serial],
      auditDetails: audit_tool,
    }
  }

  return {
    ...state,
    store: [data, ...state.store],
    scannedSerials: [...state.scannedSerials, data.scanned_serial],
    auditDetails: audit_tool,
  };
}

