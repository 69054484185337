export const productTypesConstants = {
  PRODUCT_TYPE_REQUEST: "PRODUCT_TYPE_REQUEST",
  PRODUCT_TYPE_SUCCESS: "PRODUCT_TYPE_SUCCESS",
  PRODUCT_TYPE_FAILURE: "PRODUCT_TYPE_FAILURE",

  GET_ALL_PRODUCT_TYPE_SUCCESS: "GET_ALL_PRODUCT_TYPE_SUCCESS",
  GET_ALL_PRODUCT_TYPE_FAILURE: "GET_ALL_PRODUCT_TYPE_FAILURE",

  GET_COLOR_CODES_SUCCESS: "GET_COLOR_CODES_SUCCESS",
  GET_COLOR_CODES_FAILURE: "GET_COLOR_CODES_FAILURE",
};
