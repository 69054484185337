import { errorAndLoadingConstants, assemblyListConstant } from "../constants";
import { assemblyListService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getAssemblyList = (type) => {
  return async (dispatch) => {
    dispatch({
      type: assemblyListConstant.GET_ASSEMBLYLIST_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await assemblyListService.getAssemblyList(type).then(
      (assemblyList) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (assemblyList.status === 200) {
          dispatch({
            type: assemblyListConstant.GET_ASSEMBLYLIST_SUCCESS,
            data: assemblyList.data,
          });
        } else {
          const errMsg = requestFailError(assemblyList);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyListConstant.GET_ASSEMBLYLIST_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyListConstant.GET_ASSEMBLYLIST_FAILURE,
        });
      }
    );
  };
};

const editAssemblyList = (requestData) => {
  return async (dispatch) => {
    dispatch({
      type: assemblyListConstant.EDIT_ASSEMBLYLIST_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await assemblyListService.editAssemblyList(requestData).then(
      (assembly) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (assembly.status === 200) {
          dispatch({
            type: assemblyListConstant.EDIT_ASSEMBLYLIST_SUCCESS,
            data: assembly.data,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: `The ${requestData.type} list has been successfully updated.`,
            },
          });
        } else {
          const errMsg = requestFailError(assembly);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyListConstant.EDIT_ASSEMBLYLIST_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyListConstant.EDIT_ASSEMBLYLIST_FAILURE,
        });
      }
    );
  };
};

export const assemblyListActions = {
  getAssemblyList,
  editAssemblyList,
};
