export const jobConstant = {
  JOB_ACTION_REQUEST: "JOB_ACTION_REQUEST",
  JOB_ACTION_SUCCESS: "JOB_ACTION_SUCCESS",
  JOB_ACTION_FAILURE: "JOB_ACTION_FAILURE",

  JOB_ACTION_SUCCESS_NO_WC: "JOB_ACTION_SUCCESS_NO_WC",

  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",

  GET_JOB_REQUEST: "GET_JOB_REQUEST",
  GET_JOB_SUCCESS: "GET_JOB_SUCCESS",
  GET_JOB_FAILURE: "GET_JOB_FAILURE",

  GET_PO_JOB_REQUEST: "GET_PO_JOB_REQUEST",
  GET_PO_JOB_SUCCESS: "GET_PO_JOB_SUCCESS",
  GET_PO_JOB_FAILURE: "GET_PO_JOB_FAILURE",
};
