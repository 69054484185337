export const assemblyTestingConstants = {
  SEND_ASSEMBLY_TESTING_SUCCESS: "SEND_ASSEMBLY_TESTING_SUCCESS",
  SEND_ASSEMBLY_TESTING_FAILURE: "SEND_ASSEMBLY_TESTING_FAILURE",

  GET_ASSEMBLY_PLAN_SUCCESS: "GET_ASSEMBLY_PLAN_SUCCESS",
  GET_ASSEMBLY_PLAN_FAILURE: "GET_ASSEMBLY_PLAN_FAILURE",

  GET_DETAILED_PLAN_SUCCESS: "GET_DETAILED_PLAN_SUCCESS",
  GET_DETAILED_PLAN_FAILURE: "GET_DETAILED_PLAN_FAILURE",
  GET_FAILURE_REASON_BY_TYPE: "GET_FAILURE_REASON_BY_TYPE",
  GET_FAILURE_REASON_BY_TYPE_SUCCESS: "GET_FAILURE_REASON_BY_TYPE_SUCCESS",
  GET_FAILURE_REASON_BY_TYPE_FAILURE: "GET_FAILURE_REASON_BY_TYPE_FAILURE",
};
