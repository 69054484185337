import { productTypesConstants } from "../constants";

export function productType(
  state = {
    type: "",
    product_type: [],
    color_codes: [],
  },
  action
) {
  switch (action.type) {
    case productTypesConstants.PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        type: action.data.type,
        product_type: action.data.product_type,
      };

    case productTypesConstants.GET_ALL_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        product_type: action.data,
      };

    case productTypesConstants.GET_COLOR_CODES_SUCCESS:
      return {
        ...state,
        color_codes: action.data,
      };

    case productTypesConstants.PRODUCT_TYPE_FAILURE:
    case productTypesConstants.PRODUCT_TYPE_REQUEST:
    case productTypesConstants.GET_ALL_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        type: "",
        product_type: [],
      };
    case productTypesConstants.GET_COLOR_CODES_FAILURE:
      return {
        ...state,
        color_codes: [],
      };

    default:
      return state;
  }
}
