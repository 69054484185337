import { salesOrderConstants } from "../constants";

export function salesOrder(
  state = {
    dashboard: {},
    detailed_orders: {},
    br_list: [],
    detailed_br: {},
    dispatch_stations: [],
    warehouses: [],
    detailedMDN: {},
    canceled_orders: [],
    detailed_canceled_order: {},
  },
  action
) {
  switch (action.type) {
    case salesOrderConstants.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        detailed_orders: {
          ...state.detailed_orders,
          [action.data?.box_code]: action.data,
        },
      };

    case salesOrderConstants.GET_BR_LIST_SUCCESS:
      return {
        ...state,
        br_list: action.data,
      };

    case salesOrderConstants.GET_BR_DETAILS_SUCCESS:
      return {
        ...state,
        detailed_br: action.data,
      };

    case salesOrderConstants.GET_DISPATCH_STATIONS_LIST_SUCCESS:
      return {
        ...state,
        dispatch_stations: action.data,
      };

    case salesOrderConstants.GET_DISPATCH_STATIONS_LIST_REQUEST:
    case salesOrderConstants.GET_DISPATCH_STATIONS_LIST_FAILURE:
      return {
        ...state,
        dispatch_stations: [],
      };

    case salesOrderConstants.GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        dashboard: action.data,
      };

    case salesOrderConstants.GET_DASHBOARD_LIST_REQUEST:
    case salesOrderConstants.GET_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        dashboard: {},
      };

    case salesOrderConstants.GET_WAREHOUSE_LIST_SUCCESS:
      return {
        ...state,
        warehouses: action.data,
      };

    case salesOrderConstants.GET_MDN_SUCCESS:
      return {
        ...state,
        detailedMDN: action.data,
      };

    case salesOrderConstants.GET_MDN_FAILURE:
      return {
        ...state,
        detailedMDN: {},
      };

    case salesOrderConstants.DELETE_SO_SUCCESS:
    case salesOrderConstants.REVIVE_SO_SUCCESS:
      return {
        ...state,
        canceled_orders: state.canceled_orders.filter(
          (so) => so.id !== action.id
        ),
        canceled_detailed_order: {},
      };

    case salesOrderConstants.CANCELED_ORDER_LIST_SUCCESS:
      return {
        ...state,
        canceled_orders: action.data,
      };

    case salesOrderConstants.CANCELED_ORDER_LIST_FAILURE:
      return {
        ...state,
        canceled_orders: [],
      };

    case salesOrderConstants.DETAILED_CANCELED_ORDER_SUCCESS:
      return {
        ...state,
        canceled_detailed_order: action.data,
      };

    case salesOrderConstants.DETAILED_CANCELED_ORDER_FAILURE:
      return {
        ...state,
        canceled_detailed_order: {},
      };

    case salesOrderConstants.GET_WAREHOUSE_LIST_FAILURE:
    case salesOrderConstants.GET_WAREHOUSE_LIST_REQUEST:
    case salesOrderConstants.GET_BR_DETAILS_REQUEST:
    case salesOrderConstants.GET_BR_DETAILS_FAILURE:
    case salesOrderConstants.GET_BR_LIST_REQUEST:
    case salesOrderConstants.GET_BR_LIST_FAILURE:
    case salesOrderConstants.GET_ORDER_DETAILS_REQUEST:
    case salesOrderConstants.GET_ORDER_DETAILS_FAILURE:
    case salesOrderConstants.DELETE_SO_FAILURE:
    case salesOrderConstants.REVIVE_SO_FAILURE:
    default:
      return state;
  }
}
