import React from "react";
import { NavLink } from "react-router-dom";
import {
  ButtonBase,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ViewDay from "@material-ui/icons/ViewDay";
import "./Navigation.css";

const useStyles = makeStyles(() => ({
  listLi: {
    "& li": {
      paddingLeft: 60,
    },
  },
  NavigationIcon: {
    color: "#ff6464",
  },
}));

const AppNavDrawerItem = ({ children, href, name }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen((oldOpen) => !oldOpen);
  };
  if (href) {
    return (
      <li className={classes.listLi}>
        <NavLink
          to={href ? href : ""}
          className="LinkDecoration"
          activeClassName="LinkDecoration-active"
        >
          <ListItem button className="for-active">
            <ListItemIcon>
              <ViewDay className={classes.NavigationIcon} />
            </ListItemIcon>
            <ListItemText
              className="NavigationText"
              primary={name}
              primaryTypographyProps={{ className: "text-truncate" }}
            />
          </ListItem>
        </NavLink>
      </li>
    );
  }

  return (
    children.props.children.length > 0 && (
      <li className={classes.listLi} data-link>
        <ButtonBase disableRipple onClick={handleClick} className="w-100">
          <ListItem button>
            <ListItemIcon>
              <ViewDay className={classes.NavigationIcon} />
            </ListItemIcon>
            <ListItemText
              className="NavigationText"
              primary={name}
              primaryTypographyProps={{ className: "text-truncate" }}
            />
          </ListItem>
        </ButtonBase>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </li>
    )
  );
};

export default AppNavDrawerItem;
