import { qualityConstants } from "../constants";

const dateMap = {
  quality_control: "consignment_last_delivered_date",
  inward_inventory: "date",
  outward_inventory: "issue_date",
};

const inititalState = {
  reports: {
    qualitiy_control: [],
    inward_inventory: [],
    outward_inventory: [],
  },
  filterApplied: false,
};

export const quality = (state = inititalState, action) => {
  const { report_name } = action;
  switch (action.type) {
    case qualityConstants.GET_QUALITY_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [report_name]: action.data,
        },
      };

    case qualityConstants.SEARCH:
      return {
        ...state,
        filterApplied: true,
        reports: {
          ...state.reports,
          [report_name]: search(
            state.reports[report_name],
            action.payload,
            report_name
          ),
        },
      };

    case qualityConstants.CLEAR_SEARCH:
      return {
        ...state,
        filterApplied: false,
      };

    case qualityConstants.GET_QUALITY_REPORT_REQUEST:
    case qualityConstants.GET_QUALITY_REPORT_FAILURE:
      return inititalState;

    default:
      return state;
  }
};

const search = (state, query, report_name) => {
  let tempData = [...state];
  const dateField = dateMap[report_name];
  tempData = tempData.map((row) => {
    let tempRow = row;
    if (
      (query?.formData?.search_item &&
        row.item_name &&
        row.item_name
          .toLowerCase()
          .includes(query.formData.search_item.toLowerCase())) ||
      (query?.formData?.select_vendor &&
        row.vendor_name &&
        row.vendor_name === (query?.formData?.select_vendor?.trim() ?? "")) ||
      (query.formData.select_status &&
        row.status &&
        row.status === query?.formData?.select_status) ||
      (query.selectedDate.from_date &&
        new Date(row[dateField]) > new Date(query.selectedDate.from_date) &&
        new Date(row[dateField]) < new Date(query.selectedDate.to_date))
    ) {
      tempRow = { ...tempRow, isVisible: true };
    } else {
      tempRow = { ...tempRow, isVisible: false };
    }
    return tempRow;
  });
  return tempData;
};
