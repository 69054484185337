export const gridsConstants = {
  GRIDS_REQUEST: "GRIDS_REQUEST",
  GRIDS_SUCCESS: "GRIDS_SUCCESS",
  GRIDS_FAILURE: "GRIDS_FALIURE",

  SINGLE_GRID_REQUEST: "SINGLE_GRID_REQUEST",
  SINGLE_GRID_SUCCESS: "SINGLE_GRID_SUCCESS",
  SINGLE_GRID_FAILURE: "SINGLE_GRID_FAILURE",

  DROP_JOB: "DROP_JOB",
  DROP_ALERT_STOP: "DROP_ALERT_STOP",
  DROP_FAIL: "DROP_FAIL",

  SAVE_JOB_REQUEST: "SAVE_JOB_REQUEST",
  SAVE_JOB_SUCCESS: "SAVE_JOB_SUCCESS",
  SAVE_JOB_FAILURE: "SAVE_JOB_FAILURE",

  STOP_ERROR: "STOP_ERROR",
  CLOSE_POPUP: "CLOSE_POPUP",

  SET_INDENT_SEARCH: "SET_INDENT_SEARCH",
  RESET_INDENT_SEARCH: "RESET_INDENT_SEARCH",

  CLEAR_DETAILED_GRID: "CLEAR_DETAILED_GRID",

  SET_SEARCH: "SET_SEARCH",
  RESET_SEARCH: "RESET_SEARCH",
};
