import { stoConstants } from "../constants";

export const sto = (
  state = {
    products: [],
    productMasterDetails: {},
    canceled_stos: [],
    detailed_sto: {},
    stn_detail: {},
    scan_serial_data: {},
    stn_scans: [],
    cr_code: "",
    dashboard: null,
    box_detail: null,
    reports: [],
    stn_list:[],
    oem_stn_list: [],
    inventory_stores: [],
  },
  action
) => {
  let total = action?.data?.meta?.total
  switch (action.type) {
    case stoConstants.GET_PRODUCT_WITH_MASTER_CARTON_SUCCESS:
      return {
        ...state,
        products: action.data,
        productMasterDetails: action.data.reduce(
          (acc, prod) => ({
            ...acc,
            [prod.id]: prod.master_details,
          }),
          {}
        ),
      };

    case stoConstants.DELETE_STO_SUCCESS:
    case stoConstants.REVIVE_STO_SUCCESS:
      return {
        ...state,
        canceled_stos: state.canceled_stos.filter(
          (sto) => sto.id !== action.id
        ),
        canceled_detailed_sto: {},
      };

    case stoConstants.GET_CANCELLED_STO_SUCCESS:
      return {
        ...state,
        canceled_stos: action.data,
      };

    case stoConstants.GET_CANCELLED_STO_FAILURE:
      return {
        ...state,
        canceled_stos: [],
      };

    case stoConstants.DETAILED_STO_SUCCESS:
      return {
        ...state,
        canceled_detailed_sto: action.data,
      };

    case stoConstants.DETAILED_STO_FAILURE:
      return {
        ...state,
        canceled_detailed_sto: {},
      };

    case stoConstants.STO_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboard: action.data,
      };

    case stoConstants.STO_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        dashboard: null,
      };

    case stoConstants.GET_PRODUCT_WITH_MASTER_CARTON_FAILURE:
      return [];

    case stoConstants.GET_BOX_DETAIL_SUCCESS:
      return {
        ...state,
        box_detail: action.data,
      };

    case stoConstants.GET_BOX_DETAIL_FAILURE:
    case stoConstants.GET_BOX_DETAIL_INITIAL:
      return {
        ...state,
        box_detail: null,
      };
    case stoConstants.GET_STN_DETAIL_SUCCESS:
      return {
        ...state,
        scan_serial_data: {},
        stn_detail: action.data,
      };

    case stoConstants.GET_STN_DETAIL_REQUEST:
    case stoConstants.GET_STN_DETAIL_FAILURE:
      return {
        ...state,
        scan_serial_data: {},
        stn_detail: {},
      };

    case stoConstants.SCAN_SERIAL_SUCCESS:
      return {
        ...state,
        scan_serial_data: action.data,
      };

    case stoConstants.SCAN_SERIAL_REQUEST:
    case stoConstants.SCAN_SERIAL_FAILURE:
      return {
        ...state,
        total: 0,
        stn_scans: {},
      };

    case stoConstants.GET_STN_SCANS_SUCCESS:
      return {
        ...state,
        total: total,
        stn_scans: action.data.stock_scanning_log,
      };

    case stoConstants.GET_STN_SCANS_REQUEST:
    case stoConstants.GET_STN_SCANS_FAILURE:
      return {
        ...state,
        total: 0,
        stn_scans: [],
      };

    case stoConstants.VEHICLE_LOADED_SUCCESS:
      return {
        ...state,
        cr_code: action.data,
      };

    case stoConstants.VEHICLE_LOADED_REQUEST:
    case stoConstants.VEHICLE_LOADED_FAILURE:
      return {
        ...state,
        cr_code: "",
      };  

    case stoConstants.GET_STN_LIST_SUCCESS:
      return {
        ...state,
        stn_list: action.data,
      };

    case stoConstants.GET_STN_LIST_REQUEST:
    case stoConstants.GET_STN_LIST_FAILURE:
      return {
        ...state,
        stn_list: [],
      };
    case stoConstants.GET_OEM_STN_LIST_SUCCESS:
      return {
        ...state,
        oem_stn_list: action.data,
      };

    case stoConstants.GET_OEM_STN_LIST_REQUEST:
    case stoConstants.GET_OEM_STN_LIST_FAILURE:
      return {
        ...state,
        oem_stn_list: [],
      };
    case stoConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.data,
      };

    case stoConstants.GET_REPORT_FAILURE:
    case stoConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        reports: [],
      };
    
    case stoConstants.GET_SO_PRODUCT_REPORT_SUCCESS:
    return {
      ...state,
      reports: action.data,
    };

    case stoConstants.GET_SO_PRODUCT_REPORT_FAILURE:
    case stoConstants.GET_SO_PRODUCT_REPORT_REQUEST:
      return {
        ...state,
        reports: [],
      };

    case stoConstants.GET_INVENTORY_STORE_SUCCESS:
      return {
        ...state,
        inventory_stores: action.data,
      };
  
      case stoConstants.GET_INVENTORY_STORE_FAILURE:
      case stoConstants.GET_INVENTORY_STORE_REQUEST:
        return {
          ...state,
          inventory_stores: [],
        };
    default:
      return state;
  }
};
