import React from "react";
import { useSelector } from "react-redux";
import FormDatePicker from "../FormDatePicker";
import ListboxComponent from "../ListboxComponent";
import ItemAccordian from "../TaskDetails/ItemAccordian";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const MultipleItems = ({
  items,
  indentSearchable,
  classes,
  multiItems,
  handleAddMultiItem,
  handleMultiItemChange,
  handleMultiItemAutoCompleteChange,
  removeItem,
  selected_items,
  multiItemErrors,
  isDuplicate,
}) => {
  const itemDetails = useSelector((state) => state.indentForm.itemDetail);
  return (
    <Grid container>
      {isDuplicate && (
        <Grid item xs={12}>
          <Alert severity="error">No Duplicate Items</Alert>
        </Grid>
      )}
      {multiItems.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Grid container>
            <Grid item xs={12}>
              <Autocomplete
                id={`selected_item-${index}`}
                options={items}
                value={selected_items[index]}
                disabled={!indentSearchable.selected_department}
                getOptionLabel={(option) => option.name}
                ListboxComponent={ListboxComponent}
                className="d-inline-block"
                classes={{
                  option: classes.itemOption,
                }}
                onChange={(event, newValue) =>
                  handleMultiItemAutoCompleteChange(event, newValue)
                }
                closeIcon={
                  <CloseIcon size="small" id={`selected_item-${index}`} />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      multiItemErrors[index].item_id &&
                      !multiItems[index].item_id
                    }
                    label="Item"
                    variant="outlined"
                    className={classes.itemControl}
                    size="small"
                  />
                )}
                renderOption={(option) => (
                  <Tooltip title={option.name} position="bottom">
                    <Typography
                      id={`selected_item-${index}`}
                      className={classes.truncate}
                    >
                      {option.name}
                    </Typography>
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                selectedDate={multiItems[index].required_date}
                name={`required_date-${index}`}
                handleChange={handleMultiItemChange}
                label="Required Date"
                readOnly={true}
                error={
                  multiItemErrors[index].required_date &&
                  !multiItems[index].required_date
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name={`requested_quantity-${index}`}
                label="Requested Quantity"
                type="number"
                variant="outlined"
                error={
                  multiItemErrors[index].requested_quantity &&
                  !multiItems[index].requested_quantity
                }
                value={multiItems[index].requested_quantity}
                onChange={handleMultiItemChange}
                className={classes.formControl}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selected_items[index]
                        ? itemDetails[selected_items[index].id]?.unit?.name ??
                          ""
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name={`approved_quantity-${index}`}
                label="Approved Quantity"
                type="number"
                variant="outlined"
                error={
                  multiItemErrors[index].approved_quantity &&
                  !multiItems[index].approved_quantity
                }
                value={multiItems[index].approved_quantity}
                className={classes.formControl}
                onChange={handleMultiItemChange}
                margin="none"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selected_items[index]
                        ? itemDetails[selected_items[index].id]?.unit?.name ??
                          ""
                        : ""}
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name={`additional_item_description-${index}`}
                label="Additional Item Description"
                variant="outlined"
                error={
                  multiItemErrors[index].additional_item_description &&
                  !multiItems[index].additional_item_description
                }
                value={multiItems[index].additional_item_description}
                className={classes.formControl}
                onChange={handleMultiItemChange}
                margin="none"
                size="small"
              />
            </Grid>
            {selected_items[index] && itemDetails[selected_items[index].id] && (
              <Grid item xs={12}>
                <ItemAccordian item={itemDetails[selected_items[index].id]} />
              </Grid>
            )}
          </Grid>
          {index > 0 && (
            <Grid container justify="flex-end">
              <Grid item xs="auto">
                <Button color="primary" onClick={() => removeItem(index)}>
                  Remove
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item xs={12} className="text-right">
        <Button
          color="primary"
          onClick={handleAddMultiItem}
          disabled={!indentSearchable.selected_department}
        >
          Add New Item
        </Button>
        {!indentSearchable.selected_department && (
          <Tooltip title="Select department first">
            <InfoIcon size="small" color="primary" />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default MultipleItems;
