import { errorAndLoadingConstants, employeeListConstant } from "../constants";
import { employeeListService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getEmployeeList = (type) => {
  return async (dispatch) => {
    dispatch({
      type: employeeListConstant.GET_EMPLOYEE_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await employeeListService.getEmployeeList(type).then(
      (employeeList) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (employeeList.status === 200) {
          dispatch({
            type: employeeListConstant.GET_EMPLOYEE_SUCCESS,
            data: employeeList.data.workers,
          });
        } else {
          const errMsg = requestFailError(employeeList);
          dispatchFailure(dispatch, errMsg, {
            type: employeeListConstant.GET_EMPLOYEE_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: employeeListConstant.GET_EMPLOYEE_FAILURE,
        });
      }
    );
  };
};

export const employeeListActions = {
  getEmployeeList,
};
