import { errorAndLoadingConstants, stockConstants } from "../constants";
import { stockService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getStocks = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stockService.getStocks().then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stockConstants.GET_STOCK_SUCCESS,
            data: response.data.stocks,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: stockConstants.GET_STOCK_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stockConstants.GET_STOCK_FAILURE,
        });
      }
    );
  };
};

export const stocksActions = {
  getStocks,
};
