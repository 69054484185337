import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  checklistHeading: {
    fontSize: 15,
  },
  checklistLabel: {
    fontSize: 13,
  },
}));

const CheckboxSelect = ({ options, selectedItem, name, handleChange }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardHeader
        className="pb-0"
        subheader={name}
        classes={{ subheader: classes.checklistHeading }}
      ></CardHeader>
      <CardContent className="d-flex flex-column py-0">
        {options.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={selectedItem.indexOf(option.id) > -1}
                value={option.id}
                color="primary"
                size="small"
              />
            }
            label={option.name}
            key={option.id}
            classes={{ label: classes.checklistLabel }}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default CheckboxSelect;
