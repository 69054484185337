import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Divider,
  Grid,
  IconButton,
  Box,
  Typography,
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  List,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppNavDrawerItem from "./AppNavDrawerItem";
import Tabs from "./Tabs";
import useStyles from "./styles";
import { navMenu, ROUTES } from "../../utils";
import "./Navigation.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

const renderNavItems = (navMenu, user, activePage) => {
  if (!navMenu) return;

  return (
    <List disablePadding className="pt-3">
      {navMenu.reduce((items, page) => {
        return reduceChildRoutes({ items, page, user, activePage });
      }, [])}
    </List>
  );
};

const reduceChildRoutes = (context) => {
  const { items, activePage, user } = context;
  let { page } = context;
  const name = page.name;

  if (page.child && page.child.length > 1) {
    items.push(
      <AppNavDrawerItem key={name} title={name} name={name}>
        {renderNavItems(page.child, user, activePage)}
      </AppNavDrawerItem>
    );
  } else {
    page = page.child && page.child.length === 1 ? page.child[0] : page;
    // const isVisible = user?.roles?.some((role) =>
    //   ROUTES[
    //     page.url
    //       ? ["/flowview", "/reports", "/myview"].includes(page.url)
    //         ? page.url
    //         : page.url + "/"
    //       : ""
    //   ]?.includes(role.name)
    // );
    const access = user?.permission?.find((_) => _.page_route === page.url + "/" || _.page_route === page.url)
    const isVisible = access?.view_page;
    isVisible &&
      items.push(
        <AppNavDrawerItem key={name} name={name} title={name} href={page.url} />
      );
  }
  return items;
};
function Navigation(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("xl"));
  const location = useLocation();
  const {pathname} = useLocation();
  const user = useSelector((state) => state?.user?.user ?? {});
  const initialTab = location.pathname === "/flowview" ? 0 : 1;
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value, setValue] = React.useState(initialTab);
  const navMenus = React.useRef(navMenu);

  const handleWebDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const drawer = React.useMemo(() => {
    const test = renderNavItems(navMenus.current, user);
    return <>{test}</>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navMenus.current, user]);

  useEffect(() => {
    if(!isWeb)
      setMobileOpen(false);
  }, [ pathname ]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Grid container>
          <Grid item className="CNavigationLogoContainer" xs={8} md={6} xl={2}>
            <IconButton
              className={classes.menuButton}
              onClick={isWeb ? handleWebDrawerToggle : handleDrawerToggle}
            >
              <MenuRoundedIcon />
            </IconButton>
            <img
              alt="logo"
              className="CNavigationLogo"
              src={require("../../assets/sujatalogo.png")}
            />
          </Grid>
          <Tabs value={value} handleChange={handleChange} />
        </Grid>
      </AppBar>
      <nav className={clsx(classes.drawer, { [classes.drawerShift]: open })}>
        <Hidden xlUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: clsx(classes.drawerPaper, classes.drawerMobile),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className="CNavigationLogoContainerMobile">
              <IconButton
                className={classes.backButton}
                onClick={isWeb ? handleWebDrawerToggle : handleDrawerToggle}
              >
                <ArrowBackIcon />
              </IconButton>
              <img
                alt="logo"
                className="CNavigationLogo"
                src={require("../../assets/sujatalogo.png")}
              />
            </div>
            <Divider />
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation="css">
          <Drawer
            variant="permanent"
            anchor="left"
            open={open}
            classes={{
              paper: clsx(classes.drawerPaper, classes.drawerDesktop, {
                [classes.drawerShift]: open,
              }),
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {props.showChild ? (
          props.children
        ) : (
          <TabPanel>
            <props.children value={value} />
          </TabPanel>
        )}
      </main>
    </div>
  );
}

Navigation.propTypes = {
  container: PropTypes.any,
};

export default Navigation;
