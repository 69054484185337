export const alphNumberCheck = (oldVal, newVal) => {
  const regex = new RegExp(`^[a-zA-Z0-9/-]+$`);
  if (!newVal) return "";
  if (!regex.test(newVal)) {
    return oldVal;
  }
  return newVal;
};

export const numCheck = (oldVal, newVal, acceptZero = false) => {
  const regex = new RegExp(`^${!acceptZero ? "[1-9]" : ""}[0-9]*$`);
  if (!newVal) return "";
  if (!regex.test(newVal) || newVal > 999999) {
    return oldVal;
  }
  return newVal;
};
