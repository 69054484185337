import axios from "axios";
import { saveAs } from "file-saver";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const createSalesOrder = (body) => {
  return axios
    .post("/api/v1/sales_orders/create_sales_order", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const updateSalesOrder = (body) => {
  return axios
    .put("/api/v1/sales_orders/update_sales_order", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getOrderDetail = (body) => {
  return axios
    .post("/api/v1/master_boxes/scan_box", body)
    .then((response) => response)
    .catch((err) => err);
};

const createBR = (body) => {
  return axios
    .post("/api/v1/dispatches", body)
    .then((response) => response)
    .catch((err) => err);
};

const getBRList = () => {
  return axios
    .get("/api/v1/dispatches")
    .then((response) => response)
    .catch((err) => err);
};

const getBRDetail = (id) => {
  return axios
    .get(`/api/v1/dispatches/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const printBR = (code) => {
  return axios
    .get(`/api/v1/dispatches/print/${code}`, { responseType: "blob" })
    .then((response) => {
      const blog = new Blob([response.data]);
      const contentDisposition = response.headers["content-disposition"];
      let filename = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          filename = fileNameMatch[1];
        }
      }
      saveAs(blog, filename);
    })
    .catch((err) => err);
};

const getDispatchStations = () => {
  return axios
    .get("/api/v1/dispatch_stations")
    .then((response) => response)
    .catch((err) => err);
};

const getWarehouse = () => {
  return axios
    .get("/api/v1/warehouses")
    .then((response) => response)
    .catch((err) => err);
};

const confirmPayment = (formBody) => {
  return axios
    .post("/api/v1/sales_order_payments", formBody)
    .then((response) => response)
    .catch((err) => err);
};

const orderApproval = (formBody) => {
  return axios
    .put(`/api/v1/sales_order_payments/${formBody.sales_order_id}`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const createMDN = (formBody) => {
  return axios
    .post(`/api/v1/material_dispatch_notes`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const updateMDN = (body) => {
  return axios
    .put("/api/v1/material_dispatch_notes/update_mdn", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const createBilti = (formBody, id) => {
  return axios
    .post(`/api/v1/bilti_and_couriers/bilti/${id}`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const createCourier = (formBody, id) => {
  return axios
    .post(`/api/v1/bilti_and_couriers/courier/${id}`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const acknowledgeMDN = (formBody, id) => {
  return axios
    .put(`/api/v1/material_dispatch_notes/${id}`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const createEwayBill = (formBody, mdn_id) => {
  return axios
    .post(`/api/v1/invoices`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const mdnReceive = (formBody, id) => {
  return axios
    .post(`/api/v1/bilti_and_couriers/receive/${id}`, formBody)
    .then((response) => response)
    .catch((err) => err);
};

const getMDN = (id) => {
  return axios
    .get(`/api/v1/material_dispatch_notes/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const getCanceledOrdersList = () => {
  return axios
    .get("/api/v1/sales_orders/cancelled_sales_order")
    .then((res) => res)
    .catch((err) => err);
};

const detailedCanceledOrder = (id) => {
  return axios
    .get(`/api/v1/sales_orders/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

const rejectSalesOrder = (id, body) => {
  return axios
    .put(`/api/v1/sales_orders/${id}/reject_so`, body)
    .then((response) => response)
    .catch((err) => err);
};

const rejectMDN = (id, body) => {
  return axios
    .put(`/api/v1/material_dispatch_notes/${id}/reject_mdn`, body)
    .then((response) => response)
    .catch((err) => err);
};

const forceCloseMDN = (id) => {
  return axios
    .put(`/api/v1/material_dispatch_notes/${id}/force_close_mdn`)
    .then((response) => response)
    .catch((err) => err);
};

const deleteSO = (id) => {
  return axios
    .put(`/api/v1/sales_orders/${id}/delete_so`)
    .then((response) => response)
    .catch((err) => err);
};

const reviveSO = (id) => {
  return axios
    .put(`/api/v1/sales_orders/${id}/revive_so`)
    .then((response) => response)
    .catch((err) => err);
};

const editMDN = (formBody, id) => {
  return axios
    .put(
      `/api/v1/material_dispatch_notes/${id}/update_quantity_and_type`,
      formBody
    )
    .then((response) => response)
    .catch((err) => err);
};

const editCustomer = (id, customer_id) => {
  return axios
    .put(`/api/v1/sales_orders/${id}`, { sales_order: { customer_id } })
    .then((res) => res)
    .catch((err) => err);
};

const vehicleLoaded = (body) => {
  return axios
    .post("/api/v1/dispatches", body)
    .then((res) => res)
    .catch((err) => err);
};

export const salesOrderService = {
  createSalesOrder,
  updateSalesOrder,
  getOrderDetail,
  createBR,
  getBRList,
  getBRDetail,
  printBR,
  getDispatchStations,
  getWarehouse,
  confirmPayment,
  orderApproval,
  createMDN,
  updateMDN,
  createBilti,
  createCourier,
  acknowledgeMDN,
  createEwayBill,
  mdnReceive,
  getMDN,
  getCanceledOrdersList,
  detailedCanceledOrder,
  rejectSalesOrder,
  rejectMDN,
  forceCloseMDN,
  deleteSO,
  reviveSO,
  editMDN,
  editCustomer,
  vehicleLoaded,
};
