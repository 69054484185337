export const removeUnderscoreFromString = (str) => {
  if (typeof str !== "string") return "";
  const strArr = str.split("_");
  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
  }
  let newStr = strArr.join(" ");
  return newStr;
};

export const addUnderscore = (str) => {
  if (!str) return "";
  if (typeof str !== "string") return "";
  return str
    .split(" ")
    .map((_) => _[0] + _.slice(1))
    .join("_")
    .toLowerCase();
};
