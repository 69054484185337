import { errorAndLoadingConstants, lotOfferConstants } from "../constants";
import { lotOfferService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";


const createLO = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.createLO(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: lotOfferConstants.CREATE_LO_SUCCESS,
            data: response?.data?.lot_offers ?? [],
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Lot Offer created successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error Creating Lot Offer");
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.CREATE_LO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.CREATE_STO_FAILURE,
        });
      }
    );
  };
};

const editLO = (id, body, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.editLO(id, body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: lotOfferConstants.EDIT_LO_SUCCESS,
            data: response?.data?.lot_offer ?? {},
            extraPayload: {
              job_id,
              workcentre_id,
            },
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "LO edited successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error Editing LO");
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.EDIT_LO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.EDIT_LO_FAILURE,
        });
      }
    );
  };
};

const approveLoDispatch = (body, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.approveLoDispatch(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: lotOfferConstants.APPROVE_LO_DISPATCH_SUCCESS,
            data: response?.data?.lot_offer ?? {},
            extraPayload: {
              job_id,
              workcentre_id,
            },
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Master Box in this Lot is ready to dispatch.",
          });
        } else {
          const errMsg = requestFailError(response, "Error LO Dispatching.");
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.APPROVE_LO_DISPATCH_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.APPROVE_LO_DISPATCH_FAILURE,
        });
      }
    );
  };
};

const scanLOBoxCode = (body, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.scanLOBoxCode(body).then(
      (lo_scanning_log) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (lo_scanning_log.status === 200) {
          dispatch({
            type: lotOfferConstants.SCAN_LO_BOX_CODE_SUCCESS,
            data: lo_scanning_log?.data?.lot_offer ?? {},
            extraPayload: {
              job_id,
              workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(lo_scanning_log);
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.SCAN_LO_BOX_CODE_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.SCAN_LO_BOX_CODE_FAILURE,
        });
      }
    );
  };
};

const deleteLoScaningLog = (id, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.deleteLoScaningLog(id).then(
      (lo_scanning_log) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (lo_scanning_log.status === 200) {
          dispatch({
            type: lotOfferConstants.DELETE_LO_SCANNING_LOG_SUCCESS,
            data: lo_scanning_log?.data?.lo_scanning_log ?? {},
            extraPayload: {
              lo_scanning_log_id: id,
              job_id: job_id,
              workcentre_id: workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(lo_scanning_log);
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.DELETE_LO_SCANNING_LOG_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.DELETE_LO_SCANNING_LOG_FAILURE,
        });
      }
    );
  };
};

const updateLoScaningLog = (id, body, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await lotOfferService.updateLoScaningLog(id, body).then(
      (lo_scanning_log) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (lo_scanning_log.status === 200) {
          dispatch({
            type: lotOfferConstants.UPDATE_LO_SCANNING_LOG_SUCCESS,
            data: lo_scanning_log?.data?.lo_scanning_log ?? {},
            extraPayload: {
              lo_scanning_log_id: id,
              job_id: job_id,
              workcentre_id: workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(lo_scanning_log);
          dispatchFailure(dispatch, errMsg, {
            type: lotOfferConstants.UPDATE_LO_SCANNING_LOG_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: lotOfferConstants.UPDATE_LO_SCANNING_LOG_FAILURE,
        });
      }
    );
  };
};


export const lotOfferActions = {
  createLO,
  editLO,
  approveLoDispatch,
  scanLOBoxCode,
  deleteLoScaningLog,
  updateLoScaningLog,
};
