import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const updateMachineStatus = (id, requestData) => {
  return axios
    .put(`/api/v1/machines/${id}`, requestData)
    .then((response) => response)
    .catch((err) => err);
};

const mouldingDashboard = (body) => {
  return axios
    .get("/api/v1/plans", {
      params: {
        ...body,
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getMachineCount = () => {
  return axios
    .get("/api/v1/machines")
    .then((response) => response)
    .catch((err) => err);
};

export const mouldingService = {
  updateMachineStatus,
  mouldingDashboard,
  getMachineCount,
};
