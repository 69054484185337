import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getCountries = () => {
  return axios
    .get("/api/v1/addresses/countries")
    .then((response) => response)
    .catch((err) => err);
};

const getStates = (country_id) => {
  return axios
    .get(`/api/v1/addresses/${country_id}/states`)
    .then((response) => response)
    .catch((err) => err);
};

const getCities = (state_id) => {
  return axios
    .get(`/api/v1/addresses/${state_id}/cities`)
    .then((response) => response)
    .catch((err) => err);
};

const getDistricts = (state_id) => {
  return axios
    .get(`/api/v1/addresses/${state_id}/districts`)
    .then((response) => response)
    .catch((err) => err);
};

export const countryService = {
  getCountries,
  getStates,
  getCities,
  getDistricts,
};
