import { makeStyles } from "@material-ui/core";

const drawerWidth = 425;
const defaultOpen = 60;
const navHeight = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: defaultOpen,
    [theme.breakpoints.down("lg")]: {
      width: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: "row",
    background: "#ffffff",
    padding: [[0, 24]],
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 14]],
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    marginTop: navHeight,
    background: "#535353",
    border: "none",
    color: "white",
    height: `calc(100vh - ${navHeight}px)`,
  },
  drawerDesktop: {
    overflowX: "hidden",
    width: defaultOpen,
    "&:hover": {
      width: drawerWidth,
    },
  },
  drawerMobile: {
    width: drawerWidth,
    height: "100vh",
    marginTop: "0 !important",
    "& $toolbar": {
      padding: theme.spacing(2.5),
      textAlign: "center",
      background: "#ffffff",
    },
    paddingBottom: navHeight + 50,
  },
  content: {
    flexGrow: 1,
    marginTop: navHeight,
    height: `calc(100vh - ${navHeight}px)`,
    overflowX: "hidden",
    background: theme.palette.blue.main,
  },
  fixedTabPanel: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: "auto",
    },
  },
  submenu: {
    marginBottom: "10px",
  },
  submenu_links: {
    color: "#FFF",
    "&:hover": {
      color: "#FFF !important",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerShift: {
    [theme.breakpoints.up("xl")]: {
      width: drawerWidth
    },
  },
}));

export default useStyles;
