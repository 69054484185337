import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { indentFormActions, errorAndLoadingActions } from "../../../actions";
import AddIndentFields from "./AddIndentFields";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

const elementGap = {
  primary: "30px",
  secondary: "16px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    "& .MuiTextField-root": {
      margin: 10,
      width: "25ch",
    },
  },
  DialogMinWidth: {
    minWidth: "90%",
    padding: [
      [theme.spacing(5), theme.spacing(6), theme.spacing(4), theme.spacing(6)],
    ],
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "95vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "80vh",
    },
  },
  Icon: {
    position: "fixed",
    right: 50,
    bottom: 50,
  },
  formContainer: {
    width: "90%",
  },
  DTitle: {
    padding: 0,
    flex: 1,
  },
  DHead: {
    display: "flex",
    alignItems: "baseline",
    paddingBottom: elementGap.secondary,
    borderBottom: "1px solid #F1F1F3",
    marginBottom: elementGap.primary,
  },
  DbodyContain: {
    paddingTop: 0,
  },
}));

const initialIndent = {
  requisition_slip_number: "",
  requisition_filled_time: null,
  requisition_approved_time: null,
  requisition_final_approved_time: null,
  requisition_received_time: null,
  remarks: "",
};

const initialAutocompleteIndent = {
  selected_department: null,
  requisition_filled_by: null,
  requisition_approved_by: null,
  requisition_final_approved_by: null,
  requisition_received_by: null,
};

const initialErrors = {
  requisition_slip_number: false,
  required_date: false,
  requisition_approved_time: false,
  requisition_filled_time: false,
  requisition_final_approved_time: false,
  requisition_received_time: false,
  selected_department: false,
  requisition_filled_by: false,
  requisition_approved_by: false,
  requisition_final_approved_by: false,
  requisition_received_by: false,
  approved_quantity: false,
  requested_quantity: false,
  selected_item: false,
};

const initialMultiItem = {
  approved_quantity: "",
  required_date: null,
  requested_quantity: "",
  item_id: null,
  additional_item_description: "",
};

const initialMultiItemError = {
  approved_quantity: false,
  required_date: false,
  requested_quantity: false,
  item_id: false,
  additional_item_description: false,
};

function AddIndentFrom({
  saveIndent,
  departments,
  items,
  fetchItemDetails,
  itemDetail,
  fetchItems,
  openAlert,
  closeAlert,
  fetchDepartments,
}) {
  const [open, setOpen] = useState(false);
  const [indent, setIndent] = useState(initialIndent);
  const [indentSearchable, setIndentSearchable] = useState(
    initialAutocompleteIndent
  );
  const [selected_items, setSelectedItems] = useState([null]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [multiItems, setMultiItems] = useState([initialMultiItem]);
  const [cachedItemDetails, setCachedItemDetails] = useState([]);
  const [multiItemErrors, setMultiItemErrors] = useState([
    initialMultiItemError,
  ]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  let existingItems = useRef([]);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (open) {
        await fetchDepartments();
      }
    })();
  }, [open, fetchDepartments]);

  useEffect(() => {
    setSelectedItems([null]);
    async function fetchData() {
      await fetchItems();
    }
    if (indentSearchable.selected_department) fetchData();
  }, [indentSearchable.selected_department, fetchItems]);

  useEffect(() => {
    existingItems.current = selected_items
      .filter((item) => item?.id)
      .map((item) => item.id);
    existingItems.current.forEach((item) => {
      if (!cachedItemDetails.includes(item)) {
        (async () => {
          await fetchItemDetails(item);
          setCachedItemDetails((prevState) => [...prevState, item]);
        })();
      }
    });
  }, [selected_items, fetchItemDetails, cachedItemDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIndent(initialIndent);
    setIndentSearchable(initialAutocompleteIndent);
    setFilesToUpload([]);
    setErrors(initialErrors);
    setMultiItems([initialMultiItem]);
    setMultiItemErrors([initialMultiItemError]);
    setSelectedItems([null]);
  };

  const handleAddMultiItem = () => {
    setMultiItems([...multiItems, initialMultiItem]);
    setSelectedItems([...selected_items, null]);
    setMultiItemErrors([...multiItemErrors, initialMultiItemError]);
  };

  const removeItem = (index) => {
    setMultiItems((prevState) =>
      prevState.filter((item, idx) => idx !== index)
    );
    setSelectedItems(selected_items.filter((item, idx) => idx !== index));
    setMultiItemErrors((prevState) =>
      prevState.filter((item, idx) => idx !== index)
    );
  };

  const fetchItemsList = async () => {
    await fetchItems();
  };

  const validation = () => {
    let errorCount = 0;

    const errorIndentFields = [
      "requisition_slip_number",
      "requisition_approved_time",
      "requisition_filled_time",
      "requisition_final_approved_time",
      "requisition_received_time",
    ];
    const errorIndentSearchableFields = [
      "selected_department",
      "requisition_filled_by",
      "requisition_approved_by",
      "requisition_final_approved_by",
      "requisition_received_by",
    ];

    errorIndentFields.forEach((field) => {
      if (!indent[field]) {
        ++errorCount;
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
    errorIndentSearchableFields.forEach((field) => {
      if (!indentSearchable[field]) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
    const itemValid = validateItems();
    const fileValid = filesToUpload[0] ? validateFile() : false;
    if (errorCount === 0 && !itemValid && !fileValid) return false;
    else return true;
  };

  const validateItems = () => {
    let hasError = false;
    multiItems.forEach((item, index) => {
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          if (!item[key]) {
            hasError = true;
            setMultiItemErrors((prevState) =>
              prevState.map((err, idx) =>
                idx === index ? { ...err, [key]: true } : err
              )
            );
          }
        }
      }
    });
    return hasError;
  };

  const validateFile = () => {
    let hasError = false;
    const MAX_FILE_SIZE = 8192 // 8MB
    const fileSizeKiloBytes = filesToUpload[0].size / 1024
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      hasError = true;
      setErrorMsg("File size is greater than maximum limit(8 MB)");
      setIsSuccess(false);
    }
    else{
      setErrorMsg("")
      setIsSuccess(true)
    }
    return hasError;
  };

  const handleSave = async () => {
    // todo add validation for selectem_item
    const hasErrors = validation();
    var formData = new FormData();

    const data = {
      ...indent,
      requisition_filled_by_id: indentSearchable.requisition_filled_by
        ? indentSearchable.requisition_filled_by.id
        : "",
      requisition_approved_by_id: indentSearchable.requisition_approved_by
        ? indentSearchable.requisition_approved_by.id
        : "",
      requisition_final_approved_by_id:
        indentSearchable.requisition_final_approved_by
          ? indentSearchable.requisition_final_approved_by.id
          : "",
      requisition_received_by_id: indentSearchable.requisition_received_by
        ? indentSearchable.requisition_received_by.id
        : "",
      department_id: indentSearchable.selected_department
        ? indentSearchable.selected_department.id
        : "",
      requisition_filled_time: indent.requisition_filled_time
        ? new Date(indent.requisition_filled_time).toISOString()
        : "",
      requisition_approved_time: indent.requisition_approved_time
        ? new Date(indent.requisition_approved_time).toISOString()
        : "",
      requisition_final_approved_time: indent.requisition_final_approved_time
        ? new Date(indent.requisition_final_approved_time).toISOString()
        : "",
      requisition_received_time: indent.requisition_received_time
        ? new Date(indent.requisition_received_time).toISOString()
        : "",
      items: JSON.stringify(multiItems),
    };
    for (const key of Object.keys(data)) {
      formData.append(`indents[${key}]`, data[key]);
    }
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("indents[files]", filesToUpload[i]);
    }

    if (!hasErrors) {
      try {
        await saveIndent(formData);
        setIndent(initialIndent);
        setIndentSearchable(initialAutocompleteIndent);
        setErrors(initialErrors);
        setFilesToUpload([]);
        setMultiItems([initialMultiItem]);
        setMultiItemErrors([initialMultiItemError]);
        setSelectedItems([null]);
      } catch (err) {
        openAlert(`Error saving indent: ${err.message}`, "danger");
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
  };

  const handleAutoCompleteChange = (event, newValue) => {
    event.persist();
    let name = event.target.id.split("-")[0];

    if (!name) {
      name = event.target.nearestViewportElement.id;
    }
    setIndentSearchable({
      ...indentSearchable,
      [name]: newValue,
    });
  };

  const handleFileUploadChange = (event) => {
    event.persist();
    setErrorMsg("")
    const filesLength = event.target.files.length;
    let files = [];
    for (let i = 0; i < filesLength; i++) {
      files.push(event.target.files[i]);
    }
    setFilesToUpload(files);
  };

  // not a good way of handleing change of input
  // will change after demo
  const handleChange = (event, name = "") => {
    if (
      [
        "requisition_filled_time",
        "requisition_approved_time",
        "requisition_final_approved_time",
        "requisition_received_time",
      ].includes(name)
    ) {
      setIndent({
        ...indent,
        [name]: event._d,
      });
    } else if (event.target.type === "number") {
      event.persist();
      setIndent({
        ...indent,
        [event.target.name]:
          event.target.value && event.target.value < 0 ? 0 : event.target.value,
      });
    } else {
      event.persist();
      setIndent({
        ...indent,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleMultiItemChange = (event, name = "") => {
    if (["required_date"].includes(name.split("-")[0])) {
      const dateIndex = parseInt(name.split("-")[1]);
      setMultiItems((prevState) =>
        prevState.map((item, index) =>
          index === dateIndex
            ? {
                ...item,
                required_date: event.format("YYYY-MM-DD"),
              }
            : item
        )
      );
      return;
    }

    const inputName = event.target.name.split("-");
    const value = event.target.value;
    if (inputName[0] === "requested_quantity") {
      event.persist();

      setMultiItems((prevState) =>
        prevState.map((item, index) =>
          index === parseInt(inputName[1])
            ? {
                ...item,
                [inputName[0]]: value && value < 1 ? 1 : value,
                // eslint-disable-next-line
                ["approved_quantity"]: value && value < 1 ? 1 : value,
              }
            : item
        )
      );
    } else {
      setMultiItems((prevState) =>
        prevState.map((item, index) =>
          index === parseInt(inputName[1])
            ? {
                ...item,
                [inputName[0]]:
                  value && value < 0
                    ? 0
                    : value > parseInt(item.requested_quantity)
                    ? parseInt(item.requested_quantity)
                    : value,
              }
            : item
        )
      );
    }
  };

  const handleMultiItemAutoCompleteChange = (event, newValue) => {
    let itemIndex = parseInt(event.target.id.split("-")[1]);
    if (!itemIndex && event?.target?.nearestViewportElement) {
      itemIndex = parseInt(
        event.target.nearestViewportElement.id.split("-")[1]
      );
      console.log(itemIndex);
    }
    if (newValue && existingItems.current.includes(newValue.id)) {
      setIsDuplicate(true);
      return;
    } else {
      setIsDuplicate(false);
    }
    setSelectedItems(
      selected_items.map((item, index) =>
        index === itemIndex ? newValue : item
      )
    );
    setMultiItems((prevState) =>
      prevState.map((item, index) =>
        index === itemIndex
          ? { ...item, item_id: newValue ? newValue.id : null }
          : item
      )
    );
  };

  return (
    <div>
      <Fab
        className={classes.Icon}
        color="primary"
        onClick={handleClickOpen}
        aria-label="add indent"
      >
        <AddIcon />
      </Fab>
      <Dialog
        open={open}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.DialogMinWidth }}
      >
        <div className={classes.DHead}>
          <DialogTitle className={classes.DTitle} id="form-dialog-title">
            Add New Indent
          </DialogTitle>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>

        <DialogContent className="p-0">
          <form className={classes.root} noValidate autoComplete="off">
            <AddIndentFields
              indent={indent}
              indentSearchable={indentSearchable}
              departments={departments}
              items={items}
              itemDetail={itemDetail}
              handleChange={handleChange}
              handleAutoCompleteChange={handleAutoCompleteChange}
              handleFileUploadChange={handleFileUploadChange}
              errors={errors}
              uploadedFiles={filesToUpload}
              isSuccess={isSuccess}
              errorMsg={errorMsg}
              handleAddMultiItem={handleAddMultiItem}
              multiItems={multiItems}
              handleMultiItemChange={handleMultiItemChange}
              handleMultiItemAutoCompleteChange={
                handleMultiItemAutoCompleteChange
              }
              removeItem={removeItem}
              selected_items={selected_items}
              multiItemErrors={multiItemErrors}
              isDuplicate={isDuplicate}
              fetchItemsList={fetchItemsList}
            />
          </form>
        </DialogContent>
        <DialogActions className="px-2 pt-3">
          <Button
            className="mr-3"
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapState = (state) => {
  const { departments, items, itemDetail } = state.indentForm;
  return {
    departments,
    items,
    itemDetail,
  };
};

const dispatchAction = {
  saveIndent: indentFormActions.saveIndent,
  fetchItems: indentFormActions.fetchItems,
  fetchItemDetails: indentFormActions.fetchItemDetails,
  fetchDepartments: indentFormActions.fetchDepartments,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};

export default connect(mapState, dispatchAction)(AddIndentFrom);
