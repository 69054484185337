import React, { Fragment } from "react";
import moment from "moment";
import { useDrag } from "react-dnd";
import { indentNumberWithPart } from "../../utils";

import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useStyles } from "./Css";

import TasksDetails from "./TaskDetails/TasksDetails";
import CustomChip from "./CustomChip";
import SubTaskTopography from "./SubTaskTopography";
import TaskDetailsPO from "./PO/TaskDetailsPO";

export default function Tasks({
  job,
  step_id,
  step_index,
  workcentre_id,
  workcentre_index,
  workcentre_name,
  stage_id,
  stage_index,
  checklists,
}) {
  const classes = useStyles();
  const [{ opacity }, dragRef] = useDrag({
    item: {
      type: "JOB",
      job_id: job.id,
      from_step_id: step_id,
      from_step_index: step_index,
      from_workcentre_id: workcentre_id,
      from_workcentre_index: workcentre_index,
      from_workcentre_name: workcentre_name,
      from_stage_index: stage_index,
      from_stage_id: stage_id,
      noVendor: !job?.core_objectable?.vendor ? true : false,
      noConsignments:
        !job?.core_objectable?.consignments?.length > 0 ? true : false,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  });

  let vendor_name = "",
    idn_po_no = "";
  if (job.core_objectable.vendor) vendor_name = job.core_objectable.vendor.name;
  if (job.core_objectable_type === "Indent") {
    if (job.core_objectable)
      idn_po_no = indentNumberWithPart(job.core_objectable);
  } else if (job.core_objectable_type === "PurchaseOrder") {
    if (job.core_objectable.number) idn_po_no = job.core_objectable.number;
    if (job.core_objectable.po_not_required)
      idn_po_no += " (Not Required by Vendor)";
  }

  return (
    <div className={classes.CardContainer} ref={dragRef} style={{ opacity }}>
      <CardContent className={classes.indentCardPadding}>
        <Grid container justify="space-between">
          <Grid item xs={7}>
            <SubTaskTopography
              subtitle1={idn_po_no}
              subtitle2={
                job.core_objectable_type === "Indent"
                  ? "IDN NUMBER"
                  : "PO NUMBER"
              }
            />
            {job.core_objectable_type === "Indent" && (
              <SubTaskTopography
                subtitle1={job?.core_objectable?.item?.name}
                subtitle2="Item Name"
              />
            )}
            <SubTaskTopography
              subtitle1={vendor_name}
              subtitle2="VENDOR NAME"
            />
            <SubTaskTopography
              subtitle1={moment(job?.created_at ?? "").format(
                "DD/MM/YYYY hh:mm:ss a"
              )}
              subtitle2="Created Date"
            />
            {workcentre_name === "wc11" && job?.consignment_detail?.length > 0 && (
              <>
                {job?.consignment_detail?.map((cons_detail) => (
                  <Fragment key={cons_detail[1]}>
                    <Box width="100%" borderTop="2px solid" />
                    <SubTaskTopography
                      subtitle1={cons_detail?.[3] ?? ""}
                      subtitle2="IDN NUMBER"
                    />
                    <SubTaskTopography
                      subtitle1={cons_detail?.[1] ?? ""}
                      subtitle2="CONSIGNMENT NUMBER"
                    />
                    <SubTaskTopography
                      subtitle1={
                        cons_detail?.[0] &&
                        moment(cons_detail?.[0]).format("DD/MM/YYYY hh:mm:ss a")
                      }
                      subtitle2="SCHEDULED DATE"
                    />
                    <SubTaskTopography
                      subtitle1={cons_detail?.[2] ?? ""}
                      subtitle2="BALANCE QUANTITY"
                    />
                  </Fragment>
                ))}
              </>
            )}
          </Grid>
          <Grid className={classes.TaskStatusContainer}>
            <div className={classes.TaskStatus}>
              <CustomChip
                color={
                  job.status === "delayed"
                    ? "error"
                    : job.status === "on_time"
                    ? "success"
                    : "warning"
                }
                label={
                  job.status === "delayed"
                    ? "Delayed"
                    : job.status === "on_time"
                    ? "On Time"
                    : "Approaching Delay"
                }
                className={classes.cardChip}
              />
            </div>
            {job.core_objectable_type === "Indent" && (
              <TasksDetails
                job={job}
                stage_id={stage_id}
                step_id={step_id}
                workcentre_id={workcentre_id}
                checklists={checklists}
                workcentre_name={workcentre_name}
              />
            )}
            {job.core_objectable_type === "PurchaseOrder" && (
              <TaskDetailsPO
                job={job}
                stage_id={stage_id}
                step_id={step_id}
                workcentre_id={workcentre_id}
                checklists={checklists}
                workcentre_name={workcentre_name}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}
