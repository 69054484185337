import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { gridsConstants } from "../../constants";

const DialogAlert = ({ popUP }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: gridsConstants.CLOSE_POPUP });
  };
  return (
    <Dialog
      open={popUP.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {popUP.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlert;
