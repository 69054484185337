import { mouldingConstants, planListConstant } from "../constants";

export function moulding(
  state = {
    mouldingData: {},
    machineUpdate: {},
    mouldingDashboard: {},
    machineCount: {},
  },
  action
) {
  switch (action.type) {
    case planListConstant.GET_MOULDING_DATA_SUCCESS:
      return {
        ...state,
        mouldingData: action.data,
      };
    case mouldingConstants.SAVE_MACHINE_STATUS_SUCCESS:
      return {
        ...state,
        machineUpdate: action.data,
      };

    case mouldingConstants.GET_MOULDING_DASHBOARD_SUCCESS:
      return {
        ...state,
        mouldingDashboard: action.data,
      };

    case mouldingConstants.GET_MACHINE_COUNT_SUCCESS:
      return {
        ...state,
        machineCount: action.data,
      };

    case mouldingConstants.GET_MACHINE_COUNT_FAILURE:
    case mouldingConstants.GET_MOULDING_DASHBOARD_FAILURE:
    case mouldingConstants.SAVE_MACHINE_STATUS_FAILURE:
    case planListConstant.GET_MOULDING_DATA_FAILURE:
    default:
      return state;
  }
}
