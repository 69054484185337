import { errorAndLoadingConstants } from "../constants";

const openAlert = (message, alertType) => {
  return (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.OPEN_ALERT,
      message,
      alertType,
    });
  };
};

const closeAlert = () => {
  return (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  };
};

const openPopup = (message) => {
  return (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.OPEN_POPUP,
      message,
    });
  };
};

const closePopup = () => {
  return (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_POPUP,
    });
  };
};

const closeSuccessAlert = () => {
  return (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_SUCCESS,
    });
  };
};

export const errorAndLoadingActions = {
  openAlert,
  closeAlert,
  openPopup,
  closePopup,
  closeSuccessAlert,
};
