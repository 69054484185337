import React from "react";
import moment from "moment";

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Typography,
} from "@material-ui/core";

import { consignmentGross, formattedNumber } from "../../../utils";

export const ViewConsignmentTable = ({ consignments }) => {
  const totalQty = consignments.reduce(
    (accumulator, consignment) => accumulator + consignment.quantity,
    0
  );

  const totalAmount = consignments.reduce((accumulator, consignment) => {
    if (!+consignment.amount) return accumulator;
    return accumulator + consignmentGross(consignment, true);
  }, 0);

  const getRound = (num) => {
    return Math.round(num * 100)/100;
  }

  const DISCOUNT_TYPE = {
    percentage: 0,
    amount: 1,
  };
  
  const getDiscountType = (discount_type, isName) => {
    if ([null, undefined, ""].includes(discount_type)) return null;
    if (isName) {
      return DISCOUNT_TYPE[discount_type];
    }
    return +discount_type;
  };
  
  const calculateDiscount = (consignment, isName) => {
    let discountedPrice = 0;
    const type = getDiscountType(consignment?.discount_type, isName);
    if (type === 1) {
      discountedPrice = getRound(+(consignment?.amount)) - getRound(+(consignment?.discount ?? 0));
    }
  
    if (type === 0) {
      discountedPrice = getRound(+(consignment?.amount)) - getRound(getRound(+(consignment?.amount)) * (getRound(+(consignment?.discount ?? 0)) / 100));
    }
  
    return getRound(discountedPrice);
  }

  return (
    <Paper>
      <Typography className="m-3" variant="h6" id="tableTitle" component="div">
        Consignments
      </Typography>

      <TableContainer>
        <Table aria-label="Consignment Table">
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Scheduled Date</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Discount Type</TableCell>
              <TableCell>List Price</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consignments.map((consignment) => {
              const price = calculateDiscount(consignment, true);
              return (
                <TableRow key={consignment.id}>
                <TableCell>{consignment.number}</TableCell>
                <TableCell>
                  {moment(consignment.scheduled_date)
                    .local()
                    .format("DD/MM/YYYY hh:mm:ss a")}
                </TableCell>
                <TableCell>{formattedNumber(consignment.quantity, true)}</TableCell>
                <TableCell>{consignment?.discount_type ?? ""}</TableCell>
                <TableCell>
                  {formattedNumber(consignment?.amount, true, true) ?? ""}
                </TableCell>
                <TableCell>
                {formattedNumber(price, true, true) ?? ""}
                </TableCell>
                <TableCell>
                  {formattedNumber(
                    consignment?.discounted_amount,
                    true,
                    true
                  ) || ""}
                </TableCell>
                <TableCell>{consignment.remarks}</TableCell>
                <TableCell>{consignment.state}</TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography className="p-3 text-right" variant="body1" component="div">
        Total Quantity: {formattedNumber(totalQty, true)}
      </Typography>
      <Typography
        className="p-3 pt-0 text-right"
        variant="body1"
        component="div"
      >
        Total Amount: {formattedNumber(totalAmount, true, true)}
      </Typography>
    </Paper>
  );
};

export default ViewConsignmentTable;
