import { planListConstant } from "../constants";

export function planList(state = [], action) {
  let total = action?.data?.meta?.total
  switch (action.type) {
    case planListConstant.GET_PLANLIST_REQUEST:
    case planListConstant.GET_PLANLIST_FAILURE:
      return {
        ...state,
        total: 0,
        plans: [],
      };

    case planListConstant.GET_PLANLIST_SUCCESS:
      return {
        ...state,
        total: total,
        plans: action.data.plans,
      }
    case planListConstant.GET_MOTOR_TYPE_SUCCESS:
      return {
        ...state,
        motor_type: action.data.motor_serial
      }
    case planListConstant.GET_PRODUCTION_FLOW_SUCCESS:
      return {
        ...state,
        production_flows: action.data.production_flows
      }
    case planListConstant.CREATE_PLAN_SUCCESS:
      const data = action.data.plans
        ? Array.isArray(action.data.plans)
          ? [...action.data.plans]
          : [action.data]
        : [action.data];
      return {
        ...state,
        plans: [...data, ...state.plans].sort((a, b) => {
        return new Date(b.plan_date) - new Date(a.plan_date);
        }),
        total: state.total + 1 
      };

    case planListConstant.EDIT_PLAN_SUCCESS:
      return {...state,
        plans: state.plans.map((item) =>
        item.id === action.data.id ? action.data : item
        )
      };

    case planListConstant.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        total: state.total -1 ,
        plans: state.plans.filter((item) => item.id !== action.data)
      }

    case planListConstant.CREATE_PLAN_REQUEST:
    case planListConstant.CREATE_PLAN_FAILURE:
    case planListConstant.EDIT_PLAN_REQUEST:
    case planListConstant.EDIT_PLAN_FAILURE:
    case planListConstant.DELETE_PLAN_REQUEST:
    case planListConstant.DELETE_PLAN_FAILURE:
    case planListConstant.GET_MOTOR_TYPE_FAILURE:
    case planListConstant.GET_PRODUCTION_FLOW_FAILURE:
    default:
      return state;
  }
}
