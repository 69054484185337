import { jobService } from "../services";
import { jobConstant, errorAndLoadingConstants } from "../constants";
import { requestFailError } from "../utils";

const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

// reducer for this action is in grids.reducer.js

const routeJob = (requestData, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.routeJob(requestData).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if ([200, 204].includes(job.status)) {
          if (job.data.message === "System has moved the job successfully") {
            dispatch({
              type: jobConstant.JOB_ACTION_SUCCESS_NO_WC,
              extraPayload,
            });
            dispatch({
              type: errorAndLoadingConstants.OPEN_POPUP,
              message: job.data.message,
            });
          } else {
            dispatch({
              type: jobConstant.JOB_ACTION_SUCCESS,
              data: job.data.job,
              extraPayload,
            });
          }
        } else {
          const errMsg = requestFailError(job);
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.JOB_ACTION_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: jobConstant.JOB_ACTION_FAILURE,
        });
      }
    );
  };
};

const moveLoJob = (requestData, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.moveLoJob(requestData).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if ([200, 204].includes(job.status)) {
          dispatch({
            type: jobConstant.JOB_ACTION_SUCCESS,
            data: job.data.job,
            extraPayload,
          });
        } else {
          const errMsg = requestFailError(job);
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.JOB_ACTION_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: jobConstant.JOB_ACTION_FAILURE,
        });
      }
    );
  };
};

const rejectLoJob = (requestData, extraPayload) => {  
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.rejectLoJob(requestData).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if ([200, 204].includes(job.status)) {
          dispatch({
            type: jobConstant.JOB_ACTION_SUCCESS,
            data: job.data.job,
            extraPayload,
          });
        } else {
          const errMsg = requestFailError(job);
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.JOB_ACTION_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: jobConstant.JOB_ACTION_FAILURE,
        });
      }
    );
  };
};

const uploadFile = (requestBody, id, step_id, stage_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.uploadFile(requestBody, id).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (job.status === 200) {
          dispatch({
            type: jobConstant.UPLOAD_FILE_SUCCESS,
            data: job.data.jobs[0],
            extraPayload: {
              job_id: id,
              step_id,
              stage_id,
              workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(job);
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.UPLOAD_FILE_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: jobConstant.UPLOAD_FILE_FAILURE,
        });
      }
    );
  };
};

const getJob = (id, job_id, workcentre_id, type = "indents") => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.getJob(id, type).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (job.status === 200) {
          dispatch({
            type: jobConstant.GET_JOB_SUCCESS,
            job: job.data[
              type === "dispatches" ? "custom_dispatch" : type.slice(0, -1)
            ],
            extraPayload: {
              job_id,
              workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(job, "Job not loaded.");
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.GET_JOB_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: jobConstant.GET_JOB_FAILURE,
        });
      }
    );
  };
};

const getPOJob = (id, job_id, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await jobService.getPOJob(id).then(
      (job) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (job.status === 200) {
          dispatch({
            type: jobConstant.GET_PO_JOB_SUCCESS,
            purchase_order: job.data.purchase_order,
            extraPayload: {
              job_id,
              workcentre_id,
            },
          });
        } else {
          const errMsg = requestFailError(job, "Job not loaded.");
          dispatchFailure(dispatch, errMsg, {
            type: jobConstant.GET_PO_JOB_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: jobConstant.GET_PO_JOB_FAILURE,
        });
      }
    );
  };
};

export const jobActions = {
  routeJob,
  moveLoJob,
  rejectLoJob,
  uploadFile,
  getJob,
  getPOJob,
};
