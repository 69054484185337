import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";
const StoreEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    StoreEnhancers(applyMiddleware(thunkMiddleware))
  );
