import React, { useState } from "react";
import { jobService } from "../../services";
import { fileName } from "../../utils";

import FilePreviewer from "react-file-previewer";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  DialogMinWidth: {
    minWidth: "80%",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "90vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "80vh",
    },
  },
  closeButton: {
    position: "absolute",
    right: 24,
    top: 16,
    color: theme.palette.grey[500],
  },
  rotateAttachFile: {
    transform: "rotate(45deg)",
  },
}));

const supportedFormat = ["jpg", "pdf", "jpeg", "gif", "png", "svg", "bmp"];

export default function UploadedFiles({ file }) {
  const [open, setOpen] = useState(false);
  const file_name = fileName(file.url);
  const file_name_split = file_name.split(".");

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickDownload = (event, url) => {
    event.preventDefault();
    jobService.downloadFile(url);
  };

  return (
    <>
      <div className="fileLinks">
        <Link
          component="button"
          variant="body1"
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen();
          }}
        >
          <AttachFileIcon className={classes.rotateAttachFile} />
          {file_name}
        </Link>
      </div>
      <Dialog
        aria-labelledby={`file-preview-${file.id}`}
        open={open}
        maxWidth="md"
        classes={{ paper: classes.DialogMinWidth }}
      >
        <DialogTitle id={`file-preview-${file.id}`}>
          <IconButton
            aria-label="download"
            onClick={(e) => handleClickDownload(e, file.url)}
          >
            <GetAppIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {supportedFormat.includes(file_name_split.pop()) ? (
            <FilePreviewer
              file={{
                url: file.url,
              }}
              hideControls={true}
            />
          ) : (
            <div className="text-center">
              File not Supported You Can Download
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
