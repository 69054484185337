import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { gridsActions, errorAndLoadingActions } from "../../actions";

// component import
import DropComment from "./DropComment";
import Grid from "./Grid";

// design import
import "./Flowview.css";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyle = makeStyles(() => ({
  dInline: {
    display: "inline-block",
    marginRight: 5,
  },
  linkEllipises: {
    width: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stageContainer: {
    display: "flex",
    overflowX: "scroll",
    height: "calc(100vh - 140px)",
    padding: 0,
  },
  StgTitle: {
    fontSize: 18,
    color: "#4D4F5C",
    textTransform: "uppercase",
  },
  stageBox: {
    padding: 25,
  },
  darkBG: {
    background: "#F8F8F8",
  },
  lightBG: {
    background: "#fff",
  },
}));

const Tracker = ({
  getSingleGrid,
  detailedGrid,
  onDrop,
  stopDropAlert,
  invalidDrop,
  openAlert,
  closeAlert,
  indentSearch,
}) => {
  const classes = useStyle();

  /* Drop comment related state and function */
  const [commentOpen, setCommentOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [dropData, setDropData] = useState({});
  const [commentError, setCommentError] = useState(false);
  const { flowId } = useParams();
  const textRef = useRef();

  const handleCommentClose = () => {
    setCommentOpen(false);
    setCommentError(false);
    setCommentText("");
  };

  const handleCommentSubmit = async () => {
    if (commentText) {
      try {
        await onDrop(dropData, commentText);
        setCommentOpen(false);
        setCommentText("");
        setCommentError(false);
      } catch {
        stopDropAlert();
      }
    } else {
      setCommentError(true);
    }
  };

  const handleCommentTextChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleDrop = (dropDataInfo) => {
    setCommentOpen(true);
    textRef.current.focus();
    setDropData(dropDataInfo);
  };

  /* Drop comment related state and function end here */

  useEffect(() => {
    async function fetchData() {
      try {
        if (flowId) await getSingleGrid(flowId);
      } catch (err) {
        openAlert("Error loading flow: ", err.message);
        setTimeout(() => {
          closeAlert();
        }, 5000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId]);

  return detailedGrid.id ? (
    <Container maxWidth={false} className={classes.stageContainer}>
      <Grid
        grid={detailedGrid}
        handleDrop={handleDrop}
        invalidDrop={invalidDrop}
        stopDropAlert={stopDropAlert}
        indentSearch={indentSearch}
      />
      <DropComment
        commentOpen={commentOpen}
        commentText={commentText}
        handleCommentClose={handleCommentClose}
        handleCommentTextChange={handleCommentTextChange}
        handleCommentSubmit={handleCommentSubmit}
        error={commentError}
        ref={textRef}
      />
    </Container>
  ) : (
    ""
  );
};

const mapState = (state) => {
  const { detailedGrid, indentSearch } = state.grids;
  const { user } = state.user;
  return {
    detailedGrid,
    indentSearch,
    user,
  };
};

const dispatchAction = {
  getSingleGrid: gridsActions.getSingleGrid,
  onDrop: gridsActions.onDrop,
  stopDropAlert: gridsActions.stopDropAlert,
  invalidDrop: gridsActions.invalidDrop,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
};

export default connect(mapState, dispatchAction)(Tracker);
