const wcToSubheadingMap = {
  so_wc1: "Allocate Warehouse & Set Delivery Date",
  so_wc2: "Re-check Order Details",
  so_wc3: "Create MDN",
  mdn_wc1: "",
  mdn_wc2: "",
  mdn_wc3: "Enter Invoice and E-way bill Details",
  inv_wc1: "Enter Bilti Details",
  inv_wc2: "Enter Courier Details",
  inv_wc3: "Enter Material Receiving Date",
};

export const getSubheadingByWC = (workcentre_name) => {
  if (!wcToSubheadingMap.hasOwnProperty(workcentre_name)) return "";
  if (!workcentre_name) return "";
  if (!wcToSubheadingMap[workcentre_name]) return "";
  return wcToSubheadingMap[workcentre_name];
};
