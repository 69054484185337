import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  ReportHeader: {
    display: "flex",
    padding: 30,
  },
  date: {
    flex: 8,
  },
  exportbtn: {
    flex: 1,
    textAlign: "right",
  },
  tableContainer: {
    height: "calc(100vh - 160px)",
    overflow: "scroll",
  },
  reportBackground: {
    background: "#F5F6FA",
  },
});
