import { poFormServices } from "../services";
import { poFormConstants, errorAndLoadingConstants } from "../constants";
import { requestFailError } from "../utils";

const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

const fetchPOVendors = (order_type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: poFormConstants.GET_VENDOR_REQUEST,
    });
    await poFormServices.fetchPOVendors(order_type).then(
      (result) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (result.status === 200) {
          dispatch({
            type: poFormConstants.GET_VENDOR_SUCCESS,
            data: result.data.vendors,
          });
        } else {
          const errMsg = requestFailError(result);
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_VENDOR_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: poFormConstants.GET_VENDOR_FAILURE,
        });
      }
    );
  };
};

const fetchIndentVendors = (vendor_id, order_type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: poFormConstants.GET_VENDOR_INDENTS_REQUEST,
    });
    await poFormServices.fetchIndentVendors(vendor_id, order_type).then(
      (result) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (result.status === 200) {
          dispatch({
            type: poFormConstants.GET_VENDOR_INDENTS_SUCCESS,
            data: result.data.indents,
          });
        } else {
          const errMsg = requestFailError(result);
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_VENDOR_INDENTS_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: poFormConstants.GET_VENDOR_INDENTS_FAILURE,
        });
      }
    );
  };
};

const fetchIndentDepartments = (vendor_id, department_id, order_type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: poFormConstants.GET_DEPARTMENT_INDENTS_REQUEST,
    });
    await poFormServices.fetchIndentDepartments(vendor_id, department_id, order_type).then(
      (result) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (result.status === 200) {
          dispatch({
            type: poFormConstants.GET_DEPARTMENT_INDENTS_SUCCESS,
            data: result.data.indents,
          });
        } else {
          const errMsg = requestFailError(result);
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_DEPARTMENT_INDENTS_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: poFormConstants.GET_DEPARTMENT_INDENTS_FAILURE,
        });
      }
    );
  };
};

const createPO = (requestData, setOpen, setPO, initialPO) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.createPO(requestData).then(
      (po) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (po.status === 200) {
          setOpen(false);
          setPO(initialPO);
          dispatch({
            type: poFormConstants.CREATE_PO_SUCCESS,
            data: po.data.purchase_orders,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "PO successfully created.",
          });
        } else {
          setOpen(true);
          const errMsg = requestFailError(po, "PO cannot be created");
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.CREATE_PO_FAILURE,
          });
        }
      },
      (err) => {
        setOpen(true);
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.CREATE_PO_FAILURE,
        });
      }
    );
  };
};

const rejectPO = (id, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.rejectPO(id).then(
      (po) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (po.status === 200) {
          dispatch({
            type: poFormConstants.REJECT_PO_SUCCESS,
            data: po.data.purchase_orders,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "PO successfully rejected.",
          });
        } else {
          const errMsg = requestFailError(po);
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.REJECT_PO_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.REJECT_PO_FAILURE,
        });
      }
    );
  };
};

const fetchBusyPurchaseTypes = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.fetchBusyPurchaseTypes().then(
      (busy_purchase_types) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (busy_purchase_types.status === 200) {
          dispatch({
            type: poFormConstants.GET_PURCHASE_TYPE_SUCCESS,
            data: busy_purchase_types.data.busy_purchase_types,
          });
        } else {
          const errMsg = requestFailError(
            busy_purchase_types,
            "Error getting purchase types"
          );
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_PURCHASE_TYPE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.GET_PURCHASE_TYPE_FAILURE,
        });
      }
    );
  };
};

const fetchBusyITCEligibilities = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.fetchBusyITCEligibilities().then(
      (busy_itc_eligibilities) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (busy_itc_eligibilities.status === 200) {
          dispatch({
            type: poFormConstants.GET_ITC_ELIGIBILITIES_SUCCESS,
            data: busy_itc_eligibilities.data.busy_itc_eligibilities,
          });
        } else {
          const errMsg = requestFailError(
            busy_itc_eligibilities,
            "Error getting itc eligibility"
          );
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_ITC_ELIGIBILITIES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.GET_ITC_ELIGIBILITIES_FAILURE,
        });
      }
    );
  };
};

const fetchBusyCurrencies = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.fetchBusyCurrencies().then(
      (currencies) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (currencies.status === 200) {
          dispatch({
            type: poFormConstants.GET_CURRENCIES_SUCCESS,
            data: currencies.data.currencies,
          });
        } else {
          const errMsg = requestFailError(
            currencies,
            "Error getting currencies"
          );
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_CURRENCIES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.GET_CURRENCIES_FAILURE,
        });
      }
    );
  };
};

const fetchMaterialCentres = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.fetchMaterialCentres().then(
      (material_centres) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (material_centres.status === 200) {
          dispatch({
            type: poFormConstants.GET_MATERIAL_CENTER_SUCCESS,
            data: material_centres.data.material_centres,
          });
        } else {
          const errMsg = requestFailError(
            material_centres,
            "Error getting material centres"
          );
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_MATERIAL_CENTER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.GET_MATERIAL_CENTER_FAILURE,
        });
      }
    );
  };
};

const fetchPoEmployeeByRole = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await poFormServices.fetchPoEmployeeByRole().then(
      (po_users) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (po_users.status === 200) {
          dispatch({
            type: poFormConstants.GET_PO_USERS_SUCCESS,
            data: po_users.data.employees,
          });
        } else {
          const errMsg = requestFailError(po_users, "Error getting po users");
          dispatchFailure(dispatch, errMsg, {
            type: poFormConstants.GET_PO_USERS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: poFormConstants.GET_PO_USERS_FAILURE,
        });
      }
    );
  };
};

export const poActions = {
  fetchPOVendors,
  fetchIndentVendors,
  fetchIndentDepartments,
  createPO,
  rejectPO,
  fetchBusyPurchaseTypes,
  fetchBusyITCEligibilities,
  fetchBusyCurrencies,
  fetchMaterialCentres,
  fetchPoEmployeeByRole,
};
