import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { connect } from "react-redux";
import BackdropLoader from "../../../containers/Cards/BackdropLoader";
import DialogAlert from "../../../containers/FlowView/DialogAlert";
import SuccessAlert from "../SuccessAlert/SuccessAlert";

const PMSAlerts = ({ allAlert, popUp, loadCount }) => {
  return (
    <>
      {allAlert && allAlert.isOpen ? (
        <Snackbar
          open={allAlert.isOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={allAlert.type}>{allAlert.message}</Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {popUp.isOpen && <DialogAlert popUP={popUp} />}
      {!!loadCount && <BackdropLoader />}
      <SuccessAlert />
    </>
  );
};

const mapState = (state) => {
  const allAlert = state.errorAndLoading.alert;
  const { popUp, loadCount } = state.errorAndLoading;
  return {
    allAlert,
    popUp,
    loadCount,
  };
};

export default connect(mapState, null)(PMSAlerts);
