export const oemDashboardConstant = {
  GET_OEM_DASHBOARD_SUCCESS: "GET_OEM_DASHBORAD_SUCCESS",
  GET_OEM_DASHBOARD_FAILURE: "GET_OEM_DASHBOARD_FAILURE",

  GET_OEM_PRODUCTION_SUCCESS: "GET_OEM_PRODUCTION_SUCCESS",
  GET_OEM_PRODUCTION_FAILURE: "GET_OEM_PRODUCTION_FAILURE",

  GET_OEM_DISPATCH_SUCCESS: "GET_OEM_DISPATCH_SUCCESS",
  GET_OEM_DISPATCH_FAILURE: "GET_OEM_DISPATCH_FAILURE",
};
