import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";

export default function UploadButton({
  handleChange,
  handleFileUpload,
  uploadedFiles,
  multiple = false,
  disable,
  isSuccess,
  errorMsg,
}) {
  return (
    <div className="mt-2">
      <input
        id="upload-file"
        className="d-none"
        type="file"
        onChange={handleChange}
        multiple={multiple}
        disabled={disable}
      />
      {isSuccess ? <p className="success-message"></p> : null}
        <p className="error-message">{errorMsg}</p>

      <label htmlFor="upload-file" className="mr-3">
        <Button variant="outlined" color="primary" component="span">
          Select File <AttachFileIcon />
        </Button>
      </label>
      {handleFileUpload ? (
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={handleFileUpload}
          disabled={disable}
        >
          Upload File
        </Button>
      ) : (
        ""
      )}

      {handleFileUpload ? (
        <Typography>{uploadedFiles && uploadedFiles.name}</Typography>
      ) : (
      <Typography>
        {uploadedFiles.length > 0 && uploadedFiles[0].name}
      </Typography>
      )}
      
    </div>
  );
}

UploadButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func,
  multiple: PropTypes.bool,
  uploadedFiles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
