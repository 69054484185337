import { dateConstants, errorAndLoadingConstants } from "../constants";
import { dateServices } from "../services";
import { requestFailError } from "../utils";

const getDate = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: dateConstants.GET_DATE_REQUEST,
    });
    await dateServices.getDate().then(
      (date) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (date.status === 200) {
          dispatch({
            type: dateConstants.GET_DATE_SUCCESS,
            data: date.data,
          });
        } else {
          const errMsg = requestFailError(date, "Error getting date");
          dispatch({
            type: errorAndLoadingConstants.OPEN_ALERT,
            message: errMsg,
            alertType: "danger",
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatch({
          type: errorAndLoadingConstants.OPEN_ALERT,
          message: err,
          alertType: "danger",
        });
      }
    );
  };
};

export const dateAction = {
  getDate,
};
