import {
  errorAndLoadingConstants,
  assemblyTestingConstants,
} from "../constants";
import { assemblyTestingService, pmsDBService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const sendStatorTest = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await assemblyTestingService.sendAssemblyTest(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: assemblyTestingConstants.SEND_ASSEMBLY_TESTING_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyTestingConstants.SEND_ASSEMBLY_TESTING_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyTestingConstants.SEND_ASSEMBLY_TESTING_FAILURE,
        });
      }
    );
  };
};

const getPlanByType = (type, date) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await assemblyTestingService.getPlanByType(type, date).then(
      (planList) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (planList.status === 200) {
          dispatch({
            type: assemblyTestingConstants.GET_ASSEMBLY_PLAN_SUCCESS,
            data: planList.data,
            extra: type,
          });
        } else {
          const errMsg = requestFailError(planList);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyTestingConstants.GET_ASSEMBLY_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyTestingConstants.GET_ASSEMBLY_PLAN_FAILURE,
        });
      }
    );
  };
};

const getPlanData = (type, date) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await pmsDBService.getDBAssembly(date, type).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: assemblyTestingConstants.GET_DETAILED_PLAN_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyTestingConstants.GET_DETAILED_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyTestingConstants.GET_DETAILED_PLAN_FAILURE,
        });
      }
    );
  };
};

const getFailureReasonByType = (type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await assemblyTestingService.getFailureReasonType(type).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: assemblyTestingConstants.GET_FAILURE_REASON_BY_TYPE_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: assemblyTestingConstants.GET_FAILURE_REASON_BY_TYPE_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: assemblyTestingConstants.GET_FAILURE_REASON_BY_TYPE_FAILURE,
        });
      }
    );
  };
};

export const assemblyTestingActions = {
  sendStatorTest,
  getPlanByType,
  getPlanData,
  getFailureReasonByType,
};
