import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PMSAlert from "../PMS/components/PMSAlerts";
import { BasePaths } from "../utils";

const SummaryReport = lazy(() => import("./pages/SummaryReport"));
const DetailedReport = lazy(() => import("./pages/DetailedReport"));
const baseRoute = BasePaths.SERIALNUMBERTRACEABILITY;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SerialNumberTraceabilityRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/summary-report/`}>
            <SummaryReport />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/detailed-report/`}>
            <DetailedReport />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlert />
    </>
  );
};

export default SerialNumberTraceabilityRoutes;
