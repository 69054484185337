import { errorAndLoadingConstants, stoConstants } from "../constants";
import { stoService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getProductsWithPackType = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.getProductsWithPackType().then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_PRODUCT_WITH_MASTER_CARTON_SUCCESS,
            data: response?.data?.stock_transfer_orders ?? [],
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_PRODUCT_WITH_MASTER_CARTON_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.GET_PRODUCT_WITH_MASTER_CARTON_FAILURE,
        });
      }
    );
  };
};

const createSTO = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.createSTO(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.CREATE_STO_SUCCESS,
            data: response?.data?.stock_transfer_orders ?? [],
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "STO created successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error Creating STO");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.CREATE_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.CREATE_STO_FAILURE,
        });
      }
    );
  };
};

const editSTO = (id, body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.editSTO(id, body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.EDIT_STO_SUCCESS,
            data: response?.data?.stock_transfer_orders ?? [],
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "STO edited successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error Editing STO");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.EDIT_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.EDIT_STO_FAILURE,
        });
      }
    );
  };
};

const rejectSTO = (id, reject_reason, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.rejectSTO(id, { reject_reason }).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.REJECT_STO_SUCCESS,
            data: response?.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "STO rejected successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error rejected STO");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.REJECT_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.REJECT_STO_FAILURE,
        });
      }
    );
  };
};

const deleteSTO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.deleteSTO(id).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.DELETE_STO_SUCCESS,
            data: response?.data,
            id,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "STO deleted successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error deleting STO");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.DELETE_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.DELETE_STO_FAILURE,
        });
      }
    );
  };
};

const reviveSTO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.reviveSTO(id).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.DELETE_STO_SUCCESS,
            data: response?.data,
            id,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "STO Revived successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error deleting STO");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.DELETE_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.DELETE_STO_FAILURE,
        });
      }
    );
  };
};

const getCancelledSTO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.getCancelledSTO().then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_CANCELLED_STO_SUCCESS,
            data: response?.data?.stock_transfer_orders ?? [],
          });
        } else {
          const errMsg = requestFailError(
            response,
            "Error loading cancelled STO"
          );
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_CANCELLED_STO_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.GET_CANCELLED_STO_FAILURE,
        });
      }
    );
  };
};

const detailedSTO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await stoService.detailedSTO(id).then(
      (order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (order.status === 200) {
          dispatch({
            type: stoConstants.DETAILED_STO_SUCCESS,
            data: order.data.stock_transfer_order,
          });
        } else {
          const errMsg = requestFailError(order, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.DETAILED_STO_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.DETAILED_STO_FAILURE,
        });
      }
    );
  };
};

const getStnDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await stoService.getStnDetails(id).then(
      (note) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (note.status === 200) {
          dispatch({
            type: stoConstants.GET_STN_DETAIL_SUCCESS,
            data: note.data.stock_transfer_note,
          });
        } else {
          const errMsg = requestFailError(note, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_STN_DETAIL_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_STN_DETAIL_FAILURE,
        });
      }
    );
  };
};

const scanOut = (body, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.scanOut(body, id).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.SCAN_SERIAL_SUCCESS,
            data: response?.data,
          });
          // dispatch({
          //   type: errorAndLoadingConstants.OPEN_POPUP,
          //   message: "STO created successfully",
          // });
        } else {
          const errMsg = requestFailError(response, "Error Scanning Serial");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.SCAN_SERIAL_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.SCAN_SERIAL_FAILURE,
        });
      }
    );
  };
};

const getStnScans = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    try {
      const response = await stoService.getStnScans(body);

      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });

      if (response.status === 200) {
        dispatch({
          type: stoConstants.GET_STN_SCANS_SUCCESS,
          data: response?.data,
        });
        // Dispatch other actions if needed
      } else {
        const errMsg = requestFailError(response, "Error Getting STN Scans");
        dispatchFailure(dispatch, errMsg, {
          type: stoConstants.GET_STN_SCANS_FAILURE,
        });
      }
    } catch (error) {
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      dispatchFailure(dispatch, error, {
        type: stoConstants.GET_STN_SCANS_FAILURE,
      });
    }
  };
};


const getDashboardData = (date, warehouse_id, inventory_store_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await stoService.getDashboardData(date, warehouse_id, inventory_store_id).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.STO_DASHBOARD_DATA_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.STO_DASHBOARD_DATA_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.STO_DASHBOARD_DATA_FAILURE,
        });
      }
    );
  };
};

const getBoxDetails = (box_code, type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await stoService.getBoxDetails(box_code, type).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_BOX_DETAIL_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response, "No Box Found");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_BOX_DETAIL_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_BOX_DETAIL_FAILURE,
        });
      }
    );
  };
};

const getStnNumberList = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: stoConstants.GET_STN_LIST_REQUEST,
    });
    await stoService.getStnNumberList(body).then(
      (stn_list) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (stn_list.status === 200) {
          dispatch({
            type: stoConstants.GET_STN_LIST_SUCCESS,
            data: stn_list.data.stn_list,
          });
        } else {
          const errMsg = requestFailError(
            stn_list,
            "Error getting STN list"
          );
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_STN_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_STN_LIST_FAILURE,
        });
      }
    );
  };
};

const getOEMStnList = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: stoConstants.GET_OEM_STN_LIST_REQUEST,
    });
    await stoService.getOEMStnList(body).then(
      (oem_stn_list) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (oem_stn_list.status === 200) {
          dispatch({
            type: stoConstants.GET_OEM_STN_LIST_SUCCESS,
            data: oem_stn_list.data.stock_transfer_notes,
          });
        } else {
          const errMsg = requestFailError(
            oem_stn_list,
            "Error getting OEM STN list"
          );
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_OEM_STN_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_OEM_STN_LIST_FAILURE,
        });
      }
    );
  };
};

const getReport = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: stoConstants.GET_REPORT_REQUEST,
    });
    await stoService.getReport(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_REPORT_SUCCESS,
            data: response.data.sales_orders,
          });
        } else {
          const errMsg = requestFailError(response, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_REPORT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_REPORT_FAILURE,
        });
      }
    );
  };
};

const getSOProductReport = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: stoConstants.GET_SO_PRODUCT_REPORT_REQUEST,
    });
    await stoService.getSOProductReport(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_SO_PRODUCT_REPORT_SUCCESS,
            data: response.data.sales_orders,
          });
        } else {
          const errMsg = requestFailError(response, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_SO_PRODUCT_REPORT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.GET_SO_PRODUCT_REPORT_FAILURE,
        });
      }
    );
  };
};

const vehicleLoaded = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await stoService.vehicleLoaded(body).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          // window.location.reload();
          dispatch({
            type: stoConstants.VEHICLE_LOADED_SUCCESS,
            data: response.data.stn_scan.cr_code,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Vehicle Loaded Successfully",
          });
        } else {
          const errMsg = requestFailError(response, "Error processing request");
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.VEHICLE_LOADED_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: stoConstants.VEHICLE_LOADED_FAILURE,
        });
      }
    );
  };
};

const getInventoryStores = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await stoService.getInventoryStores().then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: stoConstants.GET_INVENTORY_STORE_SUCCESS,
            data: response.data.all_inventories,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: stoConstants.GET_INVENTORY_STORE_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: stoConstants.GET_INVENTORY_STORE_FAILURE,
        });
      }
    );
  };
};

export const stosActions = {
  getProductsWithPackType,
  createSTO,
  editSTO,
  rejectSTO,
  deleteSTO,
  reviveSTO,
  getCancelledSTO,
  detailedSTO,
  getStnDetails,
  scanOut,
  getStnScans,
  getDashboardData,
  getBoxDetails,
  getStnNumberList,
  getOEMStnList,
  getReport,
  getSOProductReport,
  vehicleLoaded,
  getInventoryStores,
};
