import React, { useState } from "react";
import CustomAlert from "../CustomAlert";
import { removeUnderscoreFromString } from "../../../utils";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Radio from "@material-ui/core/Radio";
import Link from "@material-ui/core/Link";

export const SKUTable = ({ skus, selectedSKU, handleChange, unblockSKU }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [skuId, setSkuId] = useState(null);

  const handleUnblockSku = async (event, item_sku_id) => {
    event.preventDefault();
    setSkuId(item_sku_id);
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleSubmit = async () => {
    setOpenAlert(false);
    await unblockSKU(skuId);
  };
  return (
    <>
      <TableContainer>
        <Table aria-labelledby="SKUTitle" aria-label="SKU table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>SKU Code</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Manufacturing Company</TableCell>
              <TableCell>State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((sku) => (
              <TableRow key={sku.id}>
                <TableCell>
                  <Radio
                    color="primary"
                    checked={parseInt(selectedSKU) === sku.id}
                    value={sku.id}
                    onChange={handleChange}
                    size="small"
                  />
                </TableCell>
                <TableCell>{sku.sku_code}</TableCell>
                <TableCell>{(sku.unit_price &&
                  sku.unit_price) ||
                  "-"}</TableCell>
                <TableCell>
                  {(sku.manufacturing_company &&
                    sku.manufacturing_company.name) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {sku.state === "blocked" ? (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={(event) => handleUnblockSku(event, sku.id)}
                    >
                      Request Unblock
                    </Link>
                  ) : (
                    removeUnderscoreFromString(sku.state)
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomAlert
        isOpen={openAlert}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default SKUTable;
