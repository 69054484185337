import { vendorConstants, errorAndLoadingConstants } from "../constants";
import { vendorService } from "../services";
import { requestFailError } from "../utils";

const dispatchFailure = (dispatch, err, nativeAction) => {
  dispatch(nativeAction);
  dispatch({
    type: errorAndLoadingConstants.OPEN_ALERT,
    message: err,
    alertType: "danger",
  });
  setTimeout(() => {
    dispatch({
      type: errorAndLoadingConstants.CLOSE_ALERT,
    });
  }, 5000);
};

const fetchVendor = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await vendorService.fetchVendor().then(
      (vendors) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vendors.status === 200) {
          dispatch({
            type: vendorConstants.GET_ALL_VENDOR_SUCCESS,
            data: vendors.data,
          });
        } else {
          const errMsg = requestFailError(vendors, "Error getting vendors");
          dispatchFailure(dispatch, errMsg, {
            type: vendorConstants.GET_ALL_VENDOR_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vendorConstants.GET_ALL_VENDOR_FAILURE,
        });
      }
    );
  };
};

const fetchOEMVendor = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await vendorService.fetchOEMVendor().then(
      (vendors) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (vendors.status === 200) {
          dispatch({
            type: vendorConstants.GET_OEM_VENDOR_SUCCESS,
            data: vendors.data,
          });
        } else {
          const errMsg = requestFailError(vendors, "Error getting vendors");
          dispatchFailure(dispatch, errMsg, {
            type: vendorConstants.GET_OEM_VENDOR_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: vendorConstants.GET_OEM_VENDOR_FAILURE,
        });
      }
    );
  };
};

export const vendorActions = {
  fetchVendor,
  fetchOEMVendor,
};
