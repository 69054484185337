export const salesOrderConstants = {
  CREATE_SALES_ORDER_REQUEST: "CREATE_SALES_ORDER_REQUEST",
  CREATE_SALES_ORDER_SUCCESS: "CREATE_SALES_ORDER_SUCCESS",
  CREATE_SALES_ORDER_FAILURE: "CREATE_SALES_ORDER_FAILURE",

  UPDATE_SALES_ORDER_REQUEST: "UPDATE_SALES_ORDER_REQUEST",
  UPDATE_SALES_ORDER_SUCCESS: "UPDATE_SALES_ORDER_SUCCESS",
  UPDATE_SALES_ORDER_FAILURE: "UDPATE_SALES_ORDER_FAILURE",

  GET_ORDER_DETAILS_REQUEST: "GET_ORDER_DETAILS_REQUEST",
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  GET_ORDER_DETAILS_FAILURE: "GET_ORDER_DETAILS_FAILURE",

  CREATE_BR_REQUEST: "CREATE_BR_REQUEST",
  CREATE_BR_SUCCESS: "CREATE_BR_SUCCESS",
  CREATE_BR_FAILURE: "CREATE_BR_FAILURE",

  GET_BR_LIST_REQUEST: "GET_BR_LIST_REQUEST",
  GET_BR_LIST_SUCCESS: "GET_BR_LIST_SUCCESS",
  GET_BR_LIST_FAILURE: "GET_BR_LIST_FAILURE",

  GET_BR_DETAILS_REQUEST: "GET_BR_DETAILS_REQUEST",
  GET_BR_DETAILS_SUCCESS: "GET_BR_DETAILS_SUCCESS",
  GET_BR_DETAILS_FAILURE: "GET_BR_DETAILS_FAILURE",

  GET_DISPATCH_STATIONS_LIST_REQUEST: "GET_DISPATCH_STATIONS_LIST_REQUEST",
  GET_DISPATCH_STATIONS_LIST_SUCCESS: "GET_DISPATCH_STATIONS_LIST_SUCCESS",
  GET_DISPATCH_STATIONS_LIST_FAILURE: "GET_DISPATCH_STATIONS_LIST_FAILURE",

  GET_DASHBOARD_LIST_REQUEST: "GET_DASHBOARD_LIST_REQUEST",
  GET_DASHBOARD_LIST_SUCCESS: "GET_DASHBOARD_LIST_SUCCESS",
  GET_DASHBOARD_LIST_FAILURE: "GET_DASHBOARD_LIST_FAILURE",

  GET_WAREHOUSE_LIST_REQUEST: "GET_WAREHOUSE_LIST_REQUEST",
  GET_WAREHOUSE_LIST_SUCCESS: "GET_WAREHOUSE_LIST_SUCCESS",
  GET_WAREHOUSE_LIST_FAILURE: "GET_WAREHOUSE_LIST_FAILURE",

  CONFIRM_PAYMENT_REQUEST: "CONFIRM_PAYMENT_REQUEST",
  CONFIRM_PAYMENT_SUCCESS: "CONFIRM_PAYMENT_SUCCESS",
  CONFIRM_PAYMENT_FAILURE: "CONFIRM_PAYMENT_FAILURE",

  ORDER_APPROVAL_REQUEST: "ORDER_APPROVAL_REQUEST",
  ORDER_APPROVAL_SUCCESS: "ORDER_APPROVAL_SUCCESS",
  ORDER_APPROVAL_FAILURE: "ORDER_APPROVAL_FAILURE",

  CREATE_MDN_SUCCESS: "CREATE_MDN_SUCCESS",
  CREATE_MDN_FAILURE: "CREATE_MDN_FAILURE",

  UPDATE_MDN_REQUEST: "UPDATE_MDN_REQUEST",
  UPDATE_MDN_SUCCESS: "UPDATE_MDN_SUCCESS",
  UPDATE_MDN_FAILURE: "UDPATE_MDN_FAILURE",

  CREATE_BILTI_SUCCESS: "CREATE_BILTI_SUCCESS",
  CREATE_BILTI_FAILURE: "CREATE_BILTI_FAILURE",

  CREATE_COURIER_SUCCESS: "CREATE_COURIER_SUCCESS",
  CREATE_COURIER_FAILURE: "CREATE_COURIER_FAILURE",

  ACKNOWLEDGE_MDN_SUCCESS: "ACKNOWLEDGE_MDN_SUCCESS",
  ACKNOWLEDGE_MDN_FAILURE: "ACKNOWLEDGE_MDN_FAILURE",

  CREATE_EWAY_BILL_SUCCESS: "CREATE_EWAY_BILL_SUCCESS",
  CREATE_EWAY_BILL_FAILURE: "CREATE_EWAY_BILL_FAILURE",

  MDN_RECEIVE_SUCCESS: "MDN_RECEIVE_SUCCESS",
  MDN_RECEIVE_FAILURE: "MDN_RECEIVE_FAILURE",

  GET_MDN_SUCCESS: "GET_MDN_SUCCESS",
  GET_MDN_FAILURE: "GET_MDN_FAILURE",

  CANCELED_ORDER_LIST_SUCCESS: "CANCELED_ORDER_LIST_SUCCESS",
  CANCELED_ORDER_LIST_FAILURE: "CANCELED_ORDER_LIST_FAILURE",

  DETAILED_CANCELED_ORDER_SUCCESS: "DETAILED_CANCELED_ORDER_SUCCESS",
  DETAILED_CANCELED_ORDER_FAILURE: "DETAILED_CANCELED_ORDER_FAILURE",

  REJECT_SALES_ORDER_SUCCESS: "REJECT_SALES_ORDER_SUCCESS",
  REJECT_SALES_ORDER_FAILURE: "REJECT_SALES_ORDER_FAILURE",

  REJECT_MDN_SUCCESS: "REJECT_MDN_SUCCESS",
  REJECT_MDN_FAILURE: "REJECT_MDN_FAILURE",

  FORCE_CLOSE_MDN_SUCCESS: "FORCE_CLOSE_MDN_SUCCESS",
  FORCE_CLOSE_MDN_FAILURE: "FORCE_CLOSE_MDN_FAILURE",

  DELETE_SO_SUCCESS: "DELETE_SO_SUCCESS",
  DELETE_SO_FAILURE: "DELETE_SO_FAILURE",

  REVIVE_SO_SUCCESS: "REVIVE_SO_SUCCESS",
  REVIVE_SO_FAILURE: "REVIVE_SO_FAILURE",

  EDIT_MDN_SUCCESS: "EDIT_MDN_SUCCESS",
  EDIT_MDN_FAILURE: "EDIT_MDN_FAILURE",

  EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
  EDIT_CUSTOMER_FAILURE: "EDIT_CUSTOMER_FAILURE",

  VEHICLE_LOADED_FAILURE: "VEHICLE_LOADED_FAILURE",
};
