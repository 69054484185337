import moment from "moment";
import "moment-precise-range-plugin";

const STRINGS = {
  nodiff: "",
  year: "year",
  years: "years",
  month: "month",
  months: "months",
  day: "day",
  days: "days",
  hour: "hour",
  hours: "hours",
  minute: "minute",
  minutes: "minutes",
  second: "second",
  seconds: "seconds",
  delimiter: " ",
};

function pluralize(num, word) {
  return num + " " + STRINGS[word + (num === 1 ? "" : "s")];
}
export const humanizeFormat = (time_in_ms) => {
  const { years, months, days, hours, minutes } = moment.preciseDiff(
    0,
    time_in_ms,
    true
  );
  var result = [];

  if (years) {
    result.push(pluralize(years, "year"));
  }
  if (months) {
    result.push(pluralize(months, "month"));
  }
  if (days) {
    result.push(pluralize(days, "day"));
  }
  if (hours) {
    result.push(pluralize(hours, "hour"));
  }
  if (minutes) {
    result.push(pluralize(minutes, "minute"));
  }
  return result.join(STRINGS.delimiter);
};
