
const DISCOUNT_TYPE = {
  percentage: 0,
  amount: 1,
};

const getDiscountType = (discount_type, isName) => {
  if ([null, undefined, ""].includes(discount_type)) return null;
  if (isName) {
    return DISCOUNT_TYPE[discount_type];
  }
  return +discount_type;
};

const getRound = (num) => {
  return Math.round(num * 100)/100;
}

export const consignmentGross = (consignment, isName = false) => {
  let gross = 0;
  const type = getDiscountType(consignment?.discount_type, isName);
  if (type === 1) {
    const discountedPrice = getRound(+(consignment?.amount)) - getRound(+(consignment?.discount ?? 0));
    gross += getRound(getRound(+(consignment?.quantity)) * discountedPrice);
  }

  if (type === 0) {
    const discountedPrice = getRound(+(consignment?.amount)) - getRound(getRound(+(consignment?.amount)) * (getRound(+(consignment?.discount ?? 0)) / 100));
    gross += getRound(getRound(+(consignment?.quantity)) * discountedPrice);
  }

  return getRound(gross);
};
