import { pmsDBConstants } from "../constants";

export function pmsDB(state = { stations:[], planData:{}, scanTime:{} }, action) {
  switch (action.type) {
    case pmsDBConstants.RESET_PMS_DB:
      return {
        stations:[],
        planData:{},
        scanTime:{},
        pmsDB: {}
      };
    case pmsDBConstants.DASHBOARD_DATA_FAILURE:
    case pmsDBConstants.PMS_DB_DATA_FAILURE:
    case pmsDBConstants.GET_MOTOR_SCAN_TIME_FAILURE:
      return {
        ...state,
        scanTime: {}
      }
    case pmsDBConstants.DASHBOARD_DATA_REQUEST:
    case pmsDBConstants.GET_PLAN_DATA_FAILURE:
      return {
        ...state,
        planData: {}
      }
    case pmsDBConstants.GET_MOTOR_STATION_FAILURE:
    case pmsDBConstants.MOTOR_STATION_REQUEST:

    case pmsDBConstants.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        ...action.data
      }

    case pmsDBConstants.GET_MOTOR_STATION_SUCCESS:
      return {
        ...state,
        stations: action.data
      }
    case pmsDBConstants.GET_PLAN_DATA_SUCCESS:
      return {
        ...state,
        planData: action.data
      }
    case  pmsDBConstants.PMS_DB_DATA_SUCCESS:
      return{
        ...state,
        motor: action.data
      }
    case pmsDBConstants.GET_MOTOR_SCAN_TIME_SUCCESS:
      return{
        ...state,
        scanTime: action.data
      }
    default:
      return state;
  }
}
