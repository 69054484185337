import React from "react";
import "./App.css";
import theme from "./theme";
import Routes from "./Routes";
import "typeface-roboto";
import { ThemeProvider } from "@material-ui/core";
import qs from "query-string";

if (process.env.NODE_ENV !== "production") {
  window.theme = theme;
  window.qs = qs;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
