import { errorAndLoadingConstants, pmsDBConstants } from "../constants";
import { pmsDBService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const buildData = (d) => {
  let data = [];
  data[0] = {
    name: "Accepted",
    value: +d.accepted,
    total: d.total,
  };
  data[1] = {
    name: "Rejected",
    value: +d.rejected,
    total: d.total,
  };
  return {
    ...d,
    data,
  };
};

const getPmsDB = (date, motorType, stationsArray) => {
  return async (dispatch) => {
    dispatch({
      type: pmsDBConstants.DASHBOARD_DATA_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    let motor = null,
      stator = null,
      rotor = null;

    try {
      motor = await pmsDBService.getDBMotor(date, motorType, stationsArray);
      motor = buildData(motor.data);
    } catch (error) {
      const errMsg = requestFailError(error);
      dispatchFailure(dispatch, errMsg, {
        type: pmsDBConstants.DASHBOARD_DATA_FAILURE,
      });
    }

    try {
      stator = await pmsDBService.getDBAssembly(date, "stator", stationsArray);
      stator = buildData(stator.data);
    } catch (error) {
      const errMsg = requestFailError(error);
      dispatchFailure(dispatch, errMsg, {
        type: pmsDBConstants.DASHBOARD_DATA_FAILURE,
      });
    }

    try {
      rotor = await pmsDBService.getDBAssembly(date, "rotor", stationsArray);
      rotor = buildData(rotor.data);
    } catch (error) {
      const errMsg = requestFailError(error);
      dispatchFailure(dispatch, errMsg, {
        type: pmsDBConstants.DASHBOARD_DATA_FAILURE,
      });
    }

    dispatch({
      type: errorAndLoadingConstants.API_CALL_STOP,
    });

    dispatch({
      type: pmsDBConstants.DASHBOARD_DATA_SUCCESS,
      data: { motor, stator, rotor },
    });
  };
};

const getMotorStations = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await pmsDBService.getMotorStations().then(
      (stations) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (stations.status === 200) {
          dispatch({
            type: pmsDBConstants.GET_MOTOR_STATION_SUCCESS,
            data: stations.data.motor_testings,
          });
        } else {
          const errMsg = requestFailError(
            "Error getting Motor Stations"
          );
          dispatchFailure(dispatch, errMsg, {
            type: pmsDBConstants.GET_MOTOR_STATION_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: pmsDBConstants.GET_MOTOR_STATION_FAILURE,
        });
      }
    );
  };
};

const getPlanData = (date, motor_type, flow_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await pmsDBService.getPlanData(date, motor_type, flow_id).then(
      (plans) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (plans.status === 200) {
          dispatch({
            type: pmsDBConstants.GET_PLAN_DATA_SUCCESS,
            data: plans.data,
          });
        } else {
          const errMsg = requestFailError(plans);
          dispatchFailure(dispatch, errMsg, {
            type: pmsDBConstants.GET_PLAN_DATA_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: pmsDBConstants.GET_PLAN_DATA_FAILURE,
        });
      }
    );
  };
};

const getDBSearchedData = (date, station_id, motor_type) => {
  return async (dispatch) => {
    let motor = null
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await pmsDBService.getDBSearchedData(date, station_id, motor_type).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          motor = buildData(response.data);
          dispatch({
            type: pmsDBConstants.PMS_DB_DATA_SUCCESS,
            data: motor,
          });
        } else {
          const errMsg = requestFailError(response, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: pmsDBConstants.PMS_DB_DATA_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: pmsDBConstants.PMS_DB_DATA_FAILURE,
        });
      }
    );
  };
};

const getMotorScannedTime = (date, station_id, motor_type) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await pmsDBService.getMotorScannedTime(date, station_id, motor_type).then(
      (response) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (response.status === 200) {
          dispatch({
            type: pmsDBConstants.GET_MOTOR_SCAN_TIME_SUCCESS,
            data: response.data,
          });
        } else {
          const errMsg = requestFailError(response);
          dispatchFailure(dispatch, errMsg, {
            type: pmsDBConstants.GET_MOTOR_SCAN_TIME_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: pmsDBConstants.GET_MOTOR_SCAN_TIME_FAILURE,
        });
      }
    );
  };
};

const resetPmsDB = () => {
  return async (dispatch) => {
    dispatch({
      type: pmsDBConstants.RESET_PMS_DB,
    });
  }
};

export const pmsDBActions = {
  getPmsDB,
  getMotorStations,
  getPlanData,
  resetPmsDB,
  getDBSearchedData,
  getMotorScannedTime,
};
