import { checklistService } from "../services";
import { checklistConstants, errorAndLoadingConstants } from "../constants";
import { requestFailError } from "../utils";

const saveChecklist = (requestBody, extraPayload) => {
  return async (dispatch) => {
    const dispatchFailure = (err) => {
      dispatch({
        type: checklistConstants.CHECKLIST_FAILURE,
      });
      dispatch({
        type: errorAndLoadingConstants.OPEN_ALERT,
        message: err,
        alertType: "danger",
      });
      setTimeout(() => {
        dispatch({
          type: errorAndLoadingConstants.CLOSE_ALERT,
        });
      }, 5000);
    };

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await checklistService.saveChecklist(requestBody).then(
      (checklist) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (checklist.status === 200) {
          dispatch({
            type: checklistConstants.CHECKLIST_SUCCESS,
            data: checklist.data.workcentre,
            extraPayload,
          });
        } else {
          const errMsg = requestFailError(checklist);
          throw Error(errMsg);
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(err);
      }
    );
  };
};

export const checklistActions = {
  saveChecklist,
};
