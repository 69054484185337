import React from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30ch !important",
    margin: [[10, 0, 10, 0], "!important"],
    [theme.breakpoints.up(1640)]: {
      width: "38ch !important",
    },
    background: "#ffffff",
  },
}));

export default function FormDatePicker({
  handleChange,
  name,
  selectedDate,
  label,
  id,
  error,
  size,
  readOnly,
  disabled = false,
  KeyboardButtonProps,
  fixWidth = true,
  ...rest
}) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
      <KeyboardDatePicker
        error={error}
        inputVariant="outlined"
        id={id}
        value={selectedDate}
        label={label}
        onChange={(moment) => handleChange(moment, name, id)}
        format="DD/MM/YYYY"
        className={!fixWidth ? "" : classes.root}
        size={size}
        inputProps={{ readOnly }}
        disabled={disabled}
        KeyboardButtonProps={KeyboardButtonProps}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
