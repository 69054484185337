import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export const CustomAlert = ({
  isOpen,
  title,
  message,
  handleClose,
  handleSubmit,
  hideCancelButton,
  submitButtonText,
  cancelButtonText,
  cancelButtonVariant,
  submitButtonVariant,
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancelButton && (
          <Button color={cancelButtonVariant} onClick={handleClose}>
            {cancelButtonText}
          </Button>
        )}
        <Button color={submitButtonVariant} onClick={handleSubmit}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomAlert.defaultProps = {
  isOpen: false,
  hideCancelButton: false,
  cancelButtonText: "Cancel",
  submitButtonText: "OK",
  cancelButtonVariant: "primary",
  submitButtonVariant: "primary",
  title: "Alert",
  message: "Are you sure you want to continue?",
};

CustomAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonVariant: PropTypes.string,
  submitButtonVariant: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CustomAlert;
