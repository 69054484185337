import { countryConstants, errorAndLoadingConstants } from "../constants";
import { countryService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getCountries = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: countryConstants.GET_COUNTRIES_REQUEST,
    });
    await countryService.getCountries().then(
      (countries) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (countries.status === 200) {
          dispatch({
            type: countryConstants.GET_COUNTRIES_SUCCESS,
            data: countries.data.addresses,
          });
        } else {
          const errMsg = requestFailError(countries, "Error getting countries");
          dispatchFailure(dispatch, errMsg, {
            type: countryConstants.GET_COUNTRIES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: countryConstants.GET_COUNTRIES_FAILURE,
        });
      }
    );
  };
};

const getStates = (country_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: countryConstants.GET_STATES_REQUEST,
    });
    await countryService.getStates(country_id).then(
      (states) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (states.status === 200) {
          dispatch({
            type: countryConstants.GET_STATES_SUCCESS,
            data: states.data.addresses,
          });
        } else {
          const errMsg = requestFailError(states, "Error getting states");
          dispatchFailure(dispatch, errMsg, {
            type: countryConstants.GET_STATES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: countryConstants.GET_STATES_FAILURE,
        });
      }
    );
  };
};

const getCities = (state_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: countryConstants.GET_CITIES_REQUEST,
    });
    await countryService.getCities(state_id).then(
      (cities) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (cities.status === 200) {
          dispatch({
            type: countryConstants.GET_CITIES_SUCCESS,
            data: cities.data.addresses,
          });
        } else {
          const errMsg = requestFailError(cities, "Error getting cities");
          dispatchFailure(dispatch, errMsg, {
            type: countryConstants.GET_CITIES_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: countryConstants.GET_CITIES_FAILURE,
        });
      }
    );
  };
};

const getDiscticts = (state_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: countryConstants.GET_DISTRICTS_REQUEST,
    });
    await countryService.getDistricts(state_id).then(
      (districts) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (districts.status === 200) {
          dispatch({
            type: countryConstants.GET_DISTRICTS_SUCCESS,
            data: districts.data.addresses,
          });
        } else {
          const errMsg = requestFailError(districts, "Error getting districts");
          dispatchFailure(dispatch, errMsg, {
            type: countryConstants.GET_DISTRICTS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: countryConstants.GET_DISTRICTS_FAILURE,
        });
      }
    );
  };
};

export const countryAction = {
  getCountries,
  getStates,
  getCities,
  getDiscticts,
};
