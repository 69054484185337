import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isStringValid } from "../../../utils";
import { useStyles } from "../Css";
import StepTopography from "../StepTopography";
import UploadedFileLink from "../UploadedFileLink";
import { indentFormActions } from "../../../actions";

import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

export const ItemAccordion = ({
  item,
  item_id = null,
  fetchItemDetails = {},
}) => {
  const classes = useStyles();
  return (
    <Accordion
      className="mt-2 mb-3"
      TransitionProps={{ unmountOnExit: true }}
      onChange={async (e, expanded) => {
        if (!item_id) return;
        if (!expanded) return;
        if (item) return;
        try {
          await fetchItemDetails(item_id);
        } catch (err) {
          console.log("Error Getting Item: ", err);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="item-content"
        id={`item-header-${item?.id ?? item_id}`}
      >
        <Typography>Item Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {item && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={classes.body2} variant="body2">
                Item Name
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(item.name) ? item.name : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className={classes.body2} variant="body2">
                Code
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(item.code) ? item.code : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Typography className={classes.body2} variant="body2">
                Description
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(item.description) ? item.description : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Files
              </Typography>
              {item.files &&
                item.files.map((file) => (
                  <UploadedFileLink key={file.id} file={file} />
                ))}
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Color
              </Typography>
              <StepTopography
                body1={
                  item.color && isStringValid(item.color.name)
                    ? item.color.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.color && isStringValid(item.color.code)
                    ? item.color.code
                    : "-"
                }
                body2="Code"
              />
              <StepTopography
                body1={
                  item.color && isStringValid(item.color.description)
                    ? item.color.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Unit
              </Typography>
              <StepTopography
                body1={
                  item.unit && isStringValid(item.unit.name)
                    ? item.unit.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.unit && isStringValid(item.unit.description)
                    ? item.unit.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Component Description
              </Typography>
              <StepTopography
                body1={
                  item.component_description &&
                  isStringValid(item.component_description.name)
                    ? item.component_description.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.component_description &&
                  isStringValid(item.component_description.description)
                    ? item.component_description.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Dimension Specification
              </Typography>
              <StepTopography
                body1={
                  item.dimension_specification &&
                  isStringValid(item.dimension_specification.name)
                    ? item.dimension_specification.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.dimension_specification &&
                  isStringValid(item.dimension_specification.description)
                    ? item.dimension_specification.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Drawing
              </Typography>
              <StepTopography
                body1={
                  item.drawing && isStringValid(item.drawing.name)
                    ? item.drawing.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.drawing && isStringValid(item.drawing.description)
                    ? item.drawing.description
                    : "-"
                }
                body2="Description"
              />
              {item.drawing &&
                item.drawing.files &&
                item.drawing.files.map((file) => (
                  <UploadedFileLink key={file.id} file={file} />
                ))}
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Grade
              </Typography>
              <StepTopography
                body1={
                  item.grade && isStringValid(item.grade.name)
                    ? item.grade.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.grade && isStringValid(item.grade.description)
                    ? item.grade.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Material Type
              </Typography>
              <StepTopography
                body1={
                  item.material_type && isStringValid(item.material_type.name)
                    ? item.material_type.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.material_type &&
                  isStringValid(item.material_type.description)
                    ? item.material_type.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Product Vertical
              </Typography>
              <StepTopography
                body1={
                  item.product_vertical &&
                  isStringValid(item.product_vertical.name)
                    ? item.product_vertical.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.product_vertical &&
                  isStringValid(item.product_vertical.description)
                    ? item.product_vertical.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Raw Material Type
              </Typography>
              <StepTopography
                body1={
                  item.raw_material_type &&
                  isStringValid(item.raw_material_type.name)
                    ? item.raw_material_type.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.raw_material_type &&
                  isStringValid(item.raw_material_type.description)
                    ? item.raw_material_type.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Type of Consumption
              </Typography>
              <StepTopography
                body1={
                  item.type_of_consumption &&
                  isStringValid(item.type_of_consumption.name)
                    ? item.type_of_consumption.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.type_of_consumption &&
                  isStringValid(item.type_of_consumption.description)
                    ? item.type_of_consumption.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <Typography className="mb-3" variant="body2">
                Type of Purchase
              </Typography>
              <StepTopography
                body1={
                  item.type_of_purchase &&
                  isStringValid(item.type_of_purchase.name)
                    ? item.type_of_purchase.name
                    : "-"
                }
                body2="Name"
              />
              <StepTopography
                body1={
                  item.type_of_purchase &&
                  isStringValid(item.type_of_purchase.description)
                    ? item.type_of_purchase.description
                    : "-"
                }
                body2="Description"
              />
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ItemAccordion.propTypes = {
  item: PropTypes.object,
  item_id: PropTypes.number,
  fetchItemDetails: PropTypes.func,
};

const dispatchAction = {
  fetchItemDetails: indentFormActions.fetchItemDetails,
};

export default connect(null, dispatchAction)(ItemAccordion);
