import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  jobActions,
  checklistActions,
  indentFormActions,
  errorAndLoadingActions,
  poActions,
  dateAction,
} from "../../../actions";
import { jobService } from "../../../services";
import { getLastJobLog, getStepNameByWC } from "../../../utils";
import Checklist from "../Checklist";
import CustomAlert from "../CustomAlert";
import UploadedFiles from "../UploadedFiles";
import IndentDescription from "./IndentDescription";
import VendorAccordian from "../TaskDetails/VendorAccordion";
import CardsCommentDialogue from "../CardsDialogue/CardsCommentDialogue";

import { useStyles } from "../Css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DialogActions from "@material-ui/core/DialogActions";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import Tooltip from "@material-ui/core/Tooltip";
import BackdropLoader from "../BackdropLoader";

const TaskDetailsStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #66707070",
  },
  DialogMinWidth: {
    minWidth: "90%",
    padding: [
      [theme.spacing(6), theme.spacing(5), theme.spacing(4), theme.spacing(7)],
    ],
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "95vh",
    },
    [theme.breakpoints.up(1440)]: {
      height: "80vh",
      minWidth: "70%",
    },
  },
  poDetailSection: {
    minWidth: "100%",
    maxWidth: "100%",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    boxShadow: "0px 2px 6px #0000000A",
    maxHeight: "100%",
    overflowY: "scroll",
    [theme.breakpoints.up(1440)]: {
      width: "100%",
      maxWidth: "unset",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 764,
      maxWidth: 764,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  checklistSection: {
    minWidth: "100%",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    overflowY: "scroll",
    boxShadow: "0px 2px 6px #0000000A",
    "& h4": {
      fontSize: 15,
      borderBottom: "1px solid #F1F1F3",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 398,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  poDetailHeader: {
    borderBottom: "3px solid #F1F1F3",
    marginBottom: 10,
  },
}));

export function TasksDetailsPO({
  job,
  routeJob,
  stage_id,
  step_id,
  workcentre_id,
  workcentre_name,
  checklists,
  saveChecklist,
  uploadFile,
  openAlert,
  closeAlert,
  rejectPO,
  getDate,
  currDate,
  vendorDetail,
  getPOJob,
  poIndent,
}) {
  const classes = useStyles();
  const classes2 = TaskDetailsStyles();
  const [open, setOpen] = useState(false);
  const [checklistSelect, setChecklistSelect] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [rejectAlert, setRejectAlert] = useState(false);
  const [withoutChecklistAlert, setWithoutChecklistAlert] = useState(false);
  const [printLoader, setPrintLoader] = useState(false);
  const co = job.core_objectable;

  const handleChecklistChange = (event) => {
    event.persist();
    setChecklistSelect((prevState) => {
      let newState = [...prevState];
      if (event.target.checked) newState.push(+event.target.value);
      else {
        let index = newState.indexOf(+event.target.value);
        newState.splice(index, 1);
      }
      return newState;
    });
  };

  useEffect(() => {
    if (open) getDate();
  }, [open, getDate]);

  const handleClickOpen = () => {
    async function fetchJob(co_id, job_id, workcentre_id) {
      try {
        await getPOJob(co_id, job_id, workcentre_id);
      } catch (err) {
        console.log("Get PO Job error: ", err);
      }
    }
    fetchJob(job.core_objectable.id, job.id, workcentre_id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChecklistSelect([]);
    setFilesToUpload(null);
  };

  const withoutChecklistConfirmAlertClose = () => {
    setWithoutChecklistAlert(false);
  };

  const handleFileUploadChange = (event) => {
    event.persist();
    setErrorMsg("")
    setFilesToUpload(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if(filesToUpload){
      const MAX_FILE_SIZE = 8192 // 8MB

      const fileSizeKiloBytes = filesToUpload.size / 1024

      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        setErrorMsg("File size is greater than maximum limit(8 MB)");
        setIsSuccess(false);
        return;
      }
      setErrorMsg("")
      setIsSuccess(true)

      var formData = new FormData();
      formData.append("file", filesToUpload);
      try {
        await uploadFile(formData, job.id, step_id, stage_id, workcentre_id);
        setFilesToUpload(null);
      } catch (error) {
        console.log("File Upload error: ", error);
      }
    }
  };

  const printPO = async () => {
    setPrintLoader(true);
    await jobService.downloadFile(`/api/v1/purchase_orders/${co.id}/print`);
    setPrintLoader(false);
  };

  const handleJobDone = async () => {
    const job_id = job.id;
    const last_job_log = getLastJobLog(job.job_logs);
    const last_job_log_id = last_job_log.id;
    const job_action = "yes";
    const last_job_log_state = last_job_log.state;

    const extraPayload = {
      stage_id,
      step_id,
      workcentre_id,
      job_id: job.id,
    };

    const routeJobRequestBody = {
      job_id,
      last_job_log_id,
      job_action,
      last_job_log_state,
    };

    const checklistRequestBody = {
      job_id,
      last_job_log_id,
      last_job_log_state,
      option_ids: checklistSelect,
    };

    if (checklists.length > 0 && checklistSelect.length === 0) {
      setWithoutChecklistAlert(true);
    } else if (checklists.length === 0) {
      if (last_job_log_state) {
        try {
          await routeJob(routeJobRequestBody, extraPayload);
        } catch (error) {
          openAlert(`${error.message}`, "danger");
          setTimeout(() => {
            closeAlert();
          }, 5000);
        }
      }
    } else {
      if (last_job_log_state) {
        try {
          await saveChecklist(checklistRequestBody);
          await routeJob(routeJobRequestBody, extraPayload);
        } catch (error) {
          openAlert(`${error.message}`, "danger");
          setTimeout(() => {
            closeAlert();
          }, 5000);
        }
      }
    }
  };

  const withoutChecklistConfirmAlertSubmit = async () => {
    const job_id = job.id;
    const last_job_log_id = getLastJobLog(job.job_logs);
    const job_action = "yes";
    const last_job_log_state = job.job_logs.find(
      (jl) => jl.id === last_job_log_id
    ).state;

    const routeJobRequestBody = {
      job_id,
      last_job_log_id,
      job_action,
      last_job_log_state,
    };

    const extraPayload = {
      stage_id,
      step_id,
      workcentre_id,
      job_id,
    };

    if (last_job_log_state) {
      await routeJob(routeJobRequestBody, extraPayload);
      setWithoutChecklistAlert(false);
    }
  };

  const handleReject = () => {
    setRejectAlert(true);
  };

  const rejectAlertClose = () => {
    setRejectAlert(false);
  };

  const rejectAlertSubmit = async () => {
    try {
      await rejectPO(co.id, {
        stage_id,
        step_id,
        workcentre_id,
        job_id: job.id,
      });
    } catch (err) {
      openAlert(`${err.message}`, "danger");
      setTimeout(() => {
        closeAlert();
      }, 5000);
    }
  };

  const submitBtnText = getStepNameByWC(workcentre_name);
  return (
    <div>
      <Button
        className={classes.VdBtn}
        size="small"
        onClick={handleClickOpen}
        color="primary"
      >
        <VisibilityOutlinedIcon fontSize="small" className="pr-1" /> View
        Details
      </Button>
      <Dialog
        open={open}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        hideBackdrop={true}
        classes={{ paper: classes2.DialogMinWidth }}
      >
        <DialogTitle
          className={`p-0 d-flex justify-content-between align-items-center ${classes2.root}`}
          id="form-dialog-title"
          disableTypography={true}
        >
          <Typography variant="h5">PO Details</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="p-0 d-md-flex">
          <div className={classes2.poDetailSection}>
            <div
              className={`${classes2.poDetailHeader} d-md-flex justify-content-md-between pb-3 px-2 px-md-0`}
            >
              <div className="vendor-details d-flex d-md-block justify-content-between">
                <strong className="pr-4">Vendor</strong>
                <span>{co.vendor && co.vendor.name}</span>
              </div>
              <div className="indent-details d-flex d-md-block justify-content-between">
                <div>
                  <strong className="pr-4">PO Number</strong>
                  <span>
                    {co.number}
                    {co.po_not_required && " (Not Required by Vendor)"}
                  </span>
                </div>
                <div>
                  <strong>Created in Busy: </strong>
                  <span
                    className={
                      co && co.is_busy_created ? "text-success" : "text-danger"
                    }
                  >
                    {co && co.is_busy_created ? "Yes" : "No"}
                  </span>
                </div>
              </div>
            </div>
            {co.vendor && (
              <VendorAccordian
                vendor={vendorDetail[co.vendor.id]}
                vendor_id={co.vendor.id}
              />
            )}
            {co.indents && 
              co.indents.map((indent) => (
                <IndentDescription
                  key={indent.id}
                  indent_id={indent.id}
                  indent={poIndent[indent.id]}
                  currDate={currDate}
                  itemName={indent?.item?.name ?? ""}
                  workcentre_name = {workcentre_name}
                />
              ))}
          </div>
          <div className={classes2.checklistSection}>
            <h4 className="pb-3 mb-4">Checklist</h4>
            <div className="mb-2">
              <div className={classes.CTasks}>
                {checklists.length > 0 ? (
                  <Checklist
                    checklists={checklists}
                    selectedItem={checklistSelect}
                    handleChange={handleChecklistChange}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="mb-2">
              <div className={classes.CTasks}>
                <UploadedFiles
                  files={job?.checklist_po_files|| []}
                  handleChange={handleFileUploadChange}
                  handleFileUpload={handleFileUpload}
                  isSuccess={isSuccess}
                  errorMsg={errorMsg}
                  uploadedFiles={filesToUpload}
                />
              </div>
            </div>

            <div className="mb-2">
              <div className={classes.CTasks}>
                <CardsCommentDialogue
                  // comments={job.comments}
                  comments={co?.comments || []}
                  job_id={job.id}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {submitBtnText && workcentre_name !== "wc11" && (
            <Button onClick={handleJobDone} variant="contained" color="primary">
              {submitBtnText}
            </Button>
          )}
          {submitBtnText && workcentre_name !== "wc11" && (
            <Button
              onClick={handleReject}
              variant="contained"
              color="secondary"
              className="ml-3"
            >
              Reject
            </Button>
          )}
          <Tooltip title="Print PO">
            <IconButton
              className="ml-3"
              onClick={printPO}
              disabled={printLoader}
            >
              <PrintRoundedIcon size="small" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <CustomAlert
          isOpen={withoutChecklistAlert}
          message="Are you sure you want to continue without filling checklist?"
          handleClose={withoutChecklistConfirmAlertClose}
          handleSubmit={withoutChecklistConfirmAlertSubmit}
        />
        <CustomAlert
          isOpen={rejectAlert}
          message="Are you sure you want to reject this PO?"
          handleClose={rejectAlertClose}
          handleSubmit={rejectAlertSubmit}
        />
        {printLoader && <BackdropLoader />}
      </Dialog>
    </div>
  );
}

const mapState = (state) => {
  const { vendors, vendorDetail, poIndent } = state.indentForm;
  const currDate = state.date;
  return {
    vendors,
    currDate,
    vendorDetail,
    poIndent,
  };
};

const dispatchAction = {
  routeJob: jobActions.routeJob,
  saveChecklist: checklistActions.saveChecklist,
  uploadFile: jobActions.uploadFile,
  getPOJob: jobActions.getPOJob,
  updateIndent: indentFormActions.updateIndent,
  fetchVendors: indentFormActions.fetchVendors,
  rejectPO: poActions.rejectPO,
  openAlert: errorAndLoadingActions.openAlert,
  closeAlert: errorAndLoadingActions.closeAlert,
  getDate: dateAction.getDate,
};

export default connect(mapState, dispatchAction)(TasksDetailsPO);
