import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const saveVehicle = (requestData) => {
  return axios
    .post("/api/v1/vehicle_informations", requestData, {})
    .then((response) => response)
    .catch((err) => err);
};

const getAllVehicle = () => {
  return axios
    .get("/api/v1/vehicle_informations")
    .then((response) => response)
    .catch((err) => err);
};

const getDetailedVehicle = (id) => {
  return axios
    .get(`/api/v1/vehicle_informations/${id}`)
    .then((response) => response)
    .catch((err) => err);
};

const getStations = () => {
  return axios
    .get("/api/v1/station_ids")
    .then((res) => res)
    .catch((err) => err);
};

export const vehicleService = {
  saveVehicle,
  getAllVehicle,
  getDetailedVehicle,
  getStations,
};
