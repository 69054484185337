// Library, middleware related import
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FlowViewDropDown from "./FlowViewDropDown";

// Style related Components import
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
  dropdownSkeleton: {
    marginBottom: "10px",
    width: "100px",
  },
}));

const FlowViewMenu = ({ grids, handleChange, selectedFlow }) => {
  const classes = useStyles();

  return grids.length > 0 ? (
    <FlowViewDropDown
      grids={grids}
      handleChange={handleChange}
      selectedFlow={selectedFlow}
    />
  ) : (
    <Skeleton variant="text" className={classes.dropdownSkeleton} />
  );
};

const mapState = (state) => {
  const { grids } = state.grids;
  return {
    grids,
  };
};

export default connect(mapState, null)(FlowViewMenu);
