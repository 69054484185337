import { vendorConstants } from "../constants";

export function allVendors(
  state = {
    allVendors: [],
    oemVendors: [],
  },
  action
) {
  switch (action.type) {
    case vendorConstants.GET_ALL_VENDOR_REQUEST:
      return state;

    case vendorConstants.GET_ALL_VENDOR_SUCCESS:
      return {
        ...state,
        allVendors: action.data.vendors,
      };

    case vendorConstants.GET_ALL_VENDOR_FAILURE:
      return state;

    case vendorConstants.GET_OEM_VENDOR_REQUEST:
      return state;

    case vendorConstants.GET_OEM_VENDOR_SUCCESS:
      return {
        ...state,
        oemVendors: action.data.oem_vendor,
      };
  
    case vendorConstants.GET_OEM_VENDOR_FAILURE:
      return state;
  
    default:
      return state;
  }
}
