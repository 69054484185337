export * from "./indentNumberWithPart";
export * from "./isStringValid";
export * from "./removeUnderscoreFromString";
export * from "./requestFailError";
export * from "./getStepNameByWC";
export * from "./dispatchFailure";
export * from "./RoutesConst";
export * from "./navMenu";
export * from "./getLastJobLog";
export * from "./FlowConstant";
export * from "./useDebounce";
export * from "./GridTypes";
export * from "./getSubheadingByWC";
export * from "./numberCheck";
export * from "./getISODate";
export * from "./consignmentGross";
export * from "./constants";
export * from "./formattedNumber";
export * from "./DefectTypes";
export * from "./Timeline";

export const fileName = (url) => {
  return decodeURI(url.substring(url.lastIndexOf("/") + 1));
};
