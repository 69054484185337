import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getGrids = (type) => {
  return axios
    .get(`/api/v1/grids?grid_type=${type}`)
    .then((response) => response)
    .catch((err) => err);
};

const getSingleGrid = (id, date_range) => {
  return axios
    .get(`/api/v1/grids/${id}`, { params: date_range })
    .then((response) => response)
    .catch((err) => err);
};

const onDrop = (requestData) => {
  return axios
    .post("/api/v1/job_logs", JSON.stringify(requestData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const gridsService = {
  getGrids,
  getSingleGrid,
  onDrop,
};
