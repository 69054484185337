import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";

const login = (username, password) => {
  const success = (user) => {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user,
    };
  };

  const failure = (error) => {
    return {
      type: userConstants.LOGIN_FAILURE,
      error,
    };
  };
  return async (dispatch) => {
    await userService.login(username, password).then(
      (user) => {
        const message = user?.response?.data?.errors[0]?.message
        if (user?.data?.message === "Sign in successful") {
          dispatch(alertActions.clear());
        } else {
          dispatch(failure(message));
          dispatch(alertActions.error(message));
          throw new Error(message);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
};

const logout = () => {
  const success = () => {
    return {
      type: userConstants.LOGOUT,
    };
  };

  return async (dispatch) => {
    await userService.logout().then(
      (user) => {
        dispatch(success());
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const isLoggedIn = () => {
  const success = (user) => {
    return {
      type: userConstants.LOGGED_IN_SUCCESS,
      user,
    };
  };

  const failure = (error) => {
    return {
      type: userConstants.LOGGED_IN_FAILURE,
      error,
    };
  };
  return async (dispatch) => {
    await userService.isLoggedIn().then(
      (user) => {
        if (user.data.message === "Nobody logged In") {
          dispatch(failure(user.data.message));
          throw new Error({
            message: user.body.message,
          });
        } else {
          dispatch(success(user.data));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
};

export const userActions = {
  login,
  logout,
  isLoggedIn,
};
