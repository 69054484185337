export const auditToolConstant = {
  RESET_AUDIT_DATA: "RESET_AUDIT_DATA",

  GET_WAREHOUSE_AUDIT_LIST_REQUEST: "GET_WAREHOUSE_AUDIT_LIST_REQUEST",
  GET_WAREHOUSE_AUDIT_LIST_SUCCESS: "GET_WAREHOUSE_AUDIT_LIST_SUCCESS",
  GET_WAREHOUSE_AUDIT_LIST_FAILURE: "GET_WAREHOUSE_AUDIT_LIST_FAILURE",

  GET_WAREHOUSE_AUDIT_DETAILS_REQUEST: "GET_WAREHOUSE_AUDIT_DETAILS_REQUEST",
  GET_WAREHOUSE_AUDIT_DETAILS_SUCCESS: "GET_WAREHOUSE_AUDIT_DETAILS_SUCCESS",
  GET_WAREHOUSE_AUDIT_DETAILS_FAILURE: "GET_WAREHOUSE_AUDIT_DETAILS_FAILURE",

  COMPLETE_AUDIT_SCANNING_REQUEST: "COMPLETE_AUDIT_SCANNING_REQUEST",
  COMPLETE_AUDIT_SCANNING_SUCCESS: "COMPLETE_AUDIT_SCANNING_SUCCESS",
  COMPLETE_AUDIT_SCANNING_FAILURE: "COMPLETE_AUDIT_SCANNING_FAILURE",

  GET_WAREHOUSE_AUDIT_REQUEST: "GET_WAREHOUSE_AUDIT_REQUEST",
  GET_WAREHOUSE_AUDIT_SUCCESS: "GET_WAREHOUSE_AUDIT_SUCCESS",
  GET_WAREHOUSE_AUDIT_FAILURE: "GET_WAREHOUSE_AUDIT_FAILURE",
  
  GET_DISPATCH_STATION_REQUEST: "GET_DISPATCH_STATION_REQUEST",
  GET_DISPATCH_STATION_SUCCESS: "GET_DISPATCH_STATION_SUCCESS",
  GET_DISPATCH_STATION_FAILURE: "GET_DISPATCH_STATION_FAILURE",

  GET_BR_LIST_REQUEST: "GET_BR_LIST_REQUEST",
  GET_BR_LIST_SUCCESS: "GET_BR_LIST_SUCCESS",
  GET_BR_LIST_FAILURE: "GET_BR_LIST_FAILURE",
};
