import { errorAndLoadingConstants, salesOrderConstants } from "../constants";
import { salesOrderService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const createSalesOrder = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.CREATE_SALES_ORDER_REQUEST,
    });
    await salesOrderService.createSalesOrder(body).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.CREATE_SALES_ORDER_SUCCESS,
            data: sales_order.data.sales_orders,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Sales Order created successfully",
          });
        } else {
          const errMsg = requestFailError(
            sales_order,
            "Error Creating Sales Order"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CREATE_SALES_ORDER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CREATE_SALES_ORDER_FAILURE,
        });
      }
    );
  };
};

const updateSalesOrder = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    })
    dispatch({
      type: salesOrderConstants.UPDATE_SALES_ORDER_REQUEST,
    });
    await salesOrderService.updateSalesOrder(body).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.UPDATE_SALES_ORDER_SUCCESS,
            data: sales_order.data.sales_orders,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Sales Order updated successfully",
          });
        } else {
          const errMsg = requestFailError(
            sales_order,
            "Error Updating Sales Order"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.UPDATE_SALES_ORDER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.UPDATE_SALES_ORDER_FAILURE,
        });
      }
    );
  }
}

const updateMDN = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    })
    dispatch({
      type: salesOrderConstants.UPDATE_MDN_REQUEST,
    });
    await salesOrderService.updateMDN(body).then(
      (mdn) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (mdn.status === 200) {
          dispatch({
            type: salesOrderConstants.UPDATE_MDN_SUCCESS,
            data: mdn.data.material_dispatch_note,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN updated successfully",
          });
        } else {
          const errMsg = requestFailError(
            mdn,
            "Error Updating MDN"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.UPDATE_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.UPDATE_MDN_FAILURE,
        });
      }
    );
  }
}

const getOrderDetails = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_ORDER_DETAILS_REQUEST,
    });
    const awaitOrder = await salesOrderService.getOrderDetail(body).then(
      (order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (order.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_ORDER_DETAILS_SUCCESS,
            data: order.data.master_box,
          });
          return order;
        } else {
          const errMsg = requestFailError(order, "Error getting Order Details");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_ORDER_DETAILS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_ORDER_DETAILS_FAILURE,
        });
      }
    );
    return awaitOrder;
  };
};

const createBR = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.CREATE_BR_REQUEST,
    });
    try {
      const br = await salesOrderService.createBR(body);
      dispatch({
        type: errorAndLoadingConstants.API_CALL_STOP,
      });
      if (br.status === 200) {
        dispatch({
          type: salesOrderConstants.CREATE_BR_SUCCESS,
          data: br.data.dispatch,
        });
      } else {
        const errMsg = requestFailError(br, "Error Creating BR");
        dispatchFailure(dispatch, errMsg, {
          type: salesOrderConstants.CREATE_BR_FAILURE,
        });
        throw new Error(errMsg);
      }
    } catch (error) {
      throw Error(error.message);
    }
  };
};

const getBRList = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_BR_LIST_REQUEST,
    });
    await salesOrderService.getBRList().then(
      (br_list) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (br_list.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_BR_LIST_SUCCESS,
            data: br_list.data.dispatch_list,
          });
        } else {
          const errMsg = requestFailError(br_list, "Error getting BR List");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_BR_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_BR_LIST_FAILURE,
        });
      }
    );
  };
};

const getBRDetails = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_BR_DETAILS_REQUEST,
    });
    await salesOrderService.getBRDetail(body).then(
      (br) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (br.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_BR_DETAILS_SUCCESS,
            data: br.data.dispatch,
          });
        } else {
          const errMsg = requestFailError(br, "Error getting BR Details");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_BR_DETAILS_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_BR_DETAILS_FAILURE,
        });
      }
    );
  };
};

const getDispatchStations = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_DISPATCH_STATIONS_LIST_REQUEST,
    });
    await salesOrderService.getDispatchStations().then(
      (ds) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (ds.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_DISPATCH_STATIONS_LIST_SUCCESS,
            data: ds.data.dispatch_stations,
          });
        } else {
          const errMsg = requestFailError(
            ds,
            "Error getting Dispatch Stations List"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_DISPATCH_STATIONS_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_DISPATCH_STATIONS_LIST_FAILURE,
        });
      }
    );
  };
};

const getDashboardList = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_DASHBOARD_LIST_REQUEST,
    });
    await salesOrderService.getDashboardList().then(
      (dashboard) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (dashboard.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_DASHBOARD_LIST_SUCCESS,
            data: dashboard.data,
          });
        } else {
          const errMsg = requestFailError(
            dashboard,
            "Error getting Dashboard Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_DASHBOARD_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_DASHBOARD_LIST_FAILURE,
        });
      }
    );
  };
};

const getWarehouse = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.GET_WAREHOUSE_LIST_REQUEST,
    });
    await salesOrderService.getWarehouse().then(
      (warehouses) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (warehouses.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_WAREHOUSE_LIST_SUCCESS,
            data: warehouses.data.warehouses,
          });
        } else {
          const errMsg = requestFailError(
            warehouses,
            "Error getting Warehouse Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_WAREHOUSE_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_WAREHOUSE_LIST_FAILURE,
        });
      }
    );
  };
};

const confirmPayment = (formBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.CONFIRM_PAYMENT_REQUEST,
    });
    await salesOrderService.confirmPayment(formBody).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.CONFIRM_PAYMENT_SUCCESS,
            data: sales_order.data.sales_order_payments,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Warehouse Allocated successfully.",
          });
        } else {
          const errMsg = requestFailError(
            sales_order,
            "Error Confirming Payment"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CONFIRM_PAYMENT_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CONFIRM_PAYMENT_FAILURE,
        });
      }
    );
  };
};

const orderApproval = (formBody) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    dispatch({
      type: salesOrderConstants.ORDER_APPROVAL_REQUEST,
    });
    await salesOrderService.orderApproval(formBody).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.ORDER_APPROVAL_SUCCESS,
            data: sales_order.data.sales_order_payments,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Order successfully approved.",
          });
        } else {
          const errMsg = requestFailError(sales_order, "Error Approving Order");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.ORDER_APPROVAL_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.ORDER_APPROVAL_FAILURE,
        });
      }
    );
  };
};

const createMDN = (formBody, workcentre_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.createMDN(formBody).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.CREATE_MDN_SUCCESS,
            data: sales_order.data.material_dispatch_notes,
            so: formBody.material_dispatch_note.sales_order_id,
            workcentre_id,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN successfully created.",
          });
        } else {
          const errMsg = requestFailError(sales_order, "Error Creating MDN");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CREATE_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CREATE_MDN_FAILURE,
        });
      }
    );
  };
};

const acknowledgeMDN = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.acknowledgeMDN(formBody, id).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.ACKNOWLEDGE_MDN_SUCCESS,
            data: sales_order.data.material_dispatch_notes,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN successfully updated.",
          });
        } else {
          const errMsg = requestFailError(sales_order, "Error Updating MDN");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.ACKNOWLEDGE_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.ACKNOWLEDGE_MDN_FAILURE,
        });
      }
    );
  };
};

const createBilti = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.createBilti(formBody, id).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.CREATE_BILTI_SUCCESS,
            data: sales_order.data.bilti_and_couriers,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Bilti information saved successfully.",
          });
        } else {
          const errMsg = requestFailError(sales_order, "Error Creating Bilti");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CREATE_BILTI_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CREATE_BILTI_FAILURE,
        });
      }
    );
  };
};
const createCourier = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.createCourier(formBody, id).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.CREATE_COURIER_SUCCESS,
            data: sales_order.data.bilti_and_couriers,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Courier information saved successfully.",
          });
        } else {
          const errMsg = requestFailError(
            sales_order,
            "Error Creating Courier"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CREATE_COURIER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CREATE_COURIER_FAILURE,
        });
      }
    );
  };
};

const createEwayBill = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.createEwayBill(formBody, id).then(
      (mdn) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (mdn.status === 200) {
          dispatch({
            type: salesOrderConstants.CREATE_EWAY_BILL_SUCCESS,
            data: mdn.data.invoices,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Invoice and E-Way bill information saved successfully.",
          });
        } else {
          const errMsg = requestFailError(mdn, "Error Creating E-Way Bill");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CREATE_EWAY_BILL_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CREATE_EWAY_BILL_FAILURE,
        });
      }
    );
  };
};

const mdnReceive = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.mdnReceive(formBody, id).then(
      (mdn) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (mdn.status === 200) {
          dispatch({
            type: salesOrderConstants.MDN_RECEIVE_SUCCESS,
            data: mdn.data.bilti_and_couriers,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN Received successfully.",
          });
        } else {
          const errMsg = requestFailError(
            mdn,
            "Error Updating MDN Receive Data"
          );
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.MDN_RECEIVE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.MDN_RECEIVE_FAILURE,
        });
      }
    );
  };
};

const getMDN = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.getMDN(id).then(
      (mdn) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (mdn.status === 200) {
          dispatch({
            type: salesOrderConstants.GET_MDN_SUCCESS,
            data: mdn.data.material_dispatch_note,
          });
        } else {
          const errMsg = requestFailError(mdn, "Error getting MDN");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.GET_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.GET_MDN_FAILURE,
        });
      }
    );
  };
};

const getCanceledOrdersList = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.getCanceledOrdersList().then(
      (orders) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (orders.status === 200) {
          dispatch({
            type: salesOrderConstants.CANCELED_ORDER_LIST_SUCCESS,
            data: orders.data.sales_orders,
          });
        } else {
          const errMsg = requestFailError(orders, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.CANCELED_ORDER_LIST_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.CANCELED_ORDER_LIST_FAILURE,
        });
      }
    );
  };
};

const detailedCanceledOrder = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.detailedCanceledOrder(id).then(
      (order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (order.status === 200) {
          dispatch({
            type: salesOrderConstants.DETAILED_CANCELED_ORDER_SUCCESS,
            data: order.data.sales_order,
          });
        } else {
          const errMsg = requestFailError(order, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.DETAILED_CANCELED_ORDER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.DETAILED_CANCELED_ORDER_FAILURE,
        });
      }
    );
  };
};

const rejectSalesOrder = (id, reject_reason, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.rejectSalesOrder(id, { reject_reason }).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.REJECT_SALES_ORDER_SUCCESS,
            data: res.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Sales Order successfully rejected.",
          });
        } else {
          const errMsg = requestFailError(res, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.REJECT_SALES_ORDER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.REJECT_SALES_ORDER_FAILURE,
        });
      }
    );
  };
};

const rejectMDN = (id, reject_reason, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.rejectMDN(id, { reject_reason }).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.REJECT_MDN_SUCCESS,
            data: res.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN successfully rejected.",
          });
        } else {
          const errMsg = requestFailError(res, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.REJECT_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.REJECT_MDN_FAILURE,
        });
      }
    );
  };
};

const forceCloseMDN = (id, extraPayload) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.forceCloseMDN(id).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.FORCE_CLOSE_MDN_SUCCESS,
            data: res.data,
            extraPayload,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN successfully force closed.",
          });
        } else {
          const errMsg = requestFailError(res, "Error getting data");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.FORCE_CLOSE_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.FORCE_CLOSE_MDN_FAILURE,
        });
      }
    );
  };
};

const deleteSO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.deleteSO(id).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.DELETE_SO_SUCCESS,
            data: res.data,
            id,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Sales Order successfully Deleted.",
          });
        } else {
          const errMsg = requestFailError(res, "Error Deleting Sales Order");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.DELETE_SO_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.DELETE_SO_FAILURE,
        });
      }
    );
  };
};

const reviveSO = (id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.reviveSO(id).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.REVIVE_SO_SUCCESS,
            data: res.data,
            id,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Sales Order successfully Revived.",
          });
        } else {
          const errMsg = requestFailError(res, "Error Reviving Sales Order");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.REVIVE_SO_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.REVIVE_SO_FAILURE,
        });
      }
    );
  };
};

const editMDN = (formBody, id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.editMDN(formBody, id).then(
      (sales_order) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (sales_order.status === 200) {
          dispatch({
            type: salesOrderConstants.EDIT_MDN_SUCCESS,
            data: sales_order.data.material_dispatch_notes,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "MDN successfully updated.",
          });
        } else {
          const errMsg = requestFailError(sales_order, "Error Updating MDN");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.EDIT_MDN_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.EDIT_MDN_FAILURE,
        });
      }
    );
  };
};

const editCustomer = (id, customer_id) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.editCustomer(id, customer_id).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          dispatch({
            type: salesOrderConstants.EDIT_CUSTOMER_SUCCESS,
            data: res.data.sales_orders,
          });
          dispatch({
            type: errorAndLoadingConstants.OPEN_POPUP,
            message: "Customer successfully updated.",
          });
        } else {
          const errMsg = requestFailError(res, "Error Updating Customer");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.EDIT_CUSTOMER_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.EDIT_CUSTOMER_FAILURE,
        });
      }
    );
  };
};

const vehicleLoaded = (body) => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await salesOrderService.vehicleLoaded(body).then(
      (res) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (res.status === 200) {
          window.location.reload();
        } else {
          const errMsg = requestFailError(res, "Error Processing Request");
          dispatchFailure(dispatch, errMsg, {
            type: salesOrderConstants.VEHICLE_LOADED_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: salesOrderConstants.VEHICLE_LOADED_FAILURE,
        });
      }
    );
  };
};

export const salesOrderActions = {
  createSalesOrder,
  updateSalesOrder,
  getOrderDetails,
  createBR,
  getBRList,
  getBRDetails,
  getDispatchStations,
  getDashboardList,
  getWarehouse,
  confirmPayment,
  orderApproval,
  createMDN,
  updateMDN,
  createBilti,
  createCourier,
  acknowledgeMDN,
  createEwayBill,
  mdnReceive,
  getMDN,
  getCanceledOrdersList,
  detailedCanceledOrder,
  rejectSalesOrder,
  rejectMDN,
  forceCloseMDN,
  deleteSO,
  reviveSO,
  editMDN,
  editCustomer,
  vehicleLoaded,
};
