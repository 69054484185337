import { employeeListConstant } from "../constants";

export function employeeList(state = [], action) {
  switch (action.type) {
    case employeeListConstant.GET_EMPLOYEE_REQUEST:
    case employeeListConstant.GET_EMPLOYEE_FAILURE:
      return [];

    case employeeListConstant.GET_EMPLOYEE_SUCCESS:
      return action.data;

    default:
      return state;
    
  }
}
