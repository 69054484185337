import React, { useState, forwardRef } from "react";
import { connect } from "react-redux";
import { indentFormActions } from "../../../actions";
import {
  consignmentGross,
  DISCOUNT_TYPE,
  formattedNumber,
} from "../../../utils";

import Button from "@material-ui/core/Button";
import MaterialTable, { MTableEditField } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
};

const AddConsignmentTable = ({
  indent_id,
  balance,
  unit,
  createConsignments,
}) => {
  const [tableData, setTableData] = useState({
    columns: [
      {
        title: "Quantity",
        field: "quantity",
        type: "numeric",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (row) => formattedNumber(row.quantity, true),
        editComponent: (props) => (
          <MTableEditField
            columnDef={{
              type: "numeric",
              editPlaceholder: "Qty.",
            }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e);
            }}
            placeholder=""
          />
        ),
      },
      {
        title: "Scheduled Date",
        field: "scheduled_date",
        type: "datetime",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
        editComponent: (props) => (
          <MTableEditField
            columnDef={{
              type: "datetime",
              editPlaceholder: "",
            }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e);
            }}
            // disablePast={true}
          />
        ),
      },
      {
        title: "Amount",
        field: "amount",
        type: "numeric",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
        validate: (rowData) =>
          (+rowData.amount !== 0 && !+rowData.amount) || +rowData.amount < 0
            ? "Amount is Required"
            : "",
        render: (row) => formattedNumber(row.amount, true, true),
        editComponent: (props) => (
          <MTableEditField
            columnDef={{
              type: "numeric",
              editPlaceholder: "Amount",
            }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e);
            }}
            placeholder=""
            error={props.error}
          />
        ),
      },
      {
        title: "Discount",
        field: "discount",
        type: "numeric",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (row) => formattedNumber(row.discount, true),
        editComponent: (props) => (
          <MTableEditField
            columnDef={{
              type: "numeric",
              editPlaceholder: "Discount",
            }}
            value={isNaN(props.value) || props.value < 0 ? "" : props.value}
            onChange={(e) => {
              let total = props.rowData.quantity * props.rowData.amount;
              if (+props?.rowData?.discount_type === 0 && +e > 100) return;
              if (+props?.rowData?.discount_type === 1 && +e > props.rowData.amount) return;
              props.onChange(e);
            }}
            placeholder=""
          />
        ),
      },
      {
        title: "Discount Type",
        field: "discount_type",
        type: "numeric",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (rowData) => {
          return DISCOUNT_TYPE?.[rowData.discount_type] ?? "";
        },
        initialEditValue: 0,
        editComponent: (props) => (
          <MTableEditField
            columnDef={{
              lookup: DISCOUNT_TYPE,
            }}
            value={props.value}
            onChange={(e) => {
              props.onRowDataChange({
                ...props.rowData,
                discount: "",
                discount_type: e,
              });
            }}
            placeholder=""
          />
        ),
      },
      {
        title: "Total",
        field: "total",
        editable: "never",
        render: (rowData) =>
          formattedNumber(consignmentGross(rowData), true, true),
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      { title: "Remark", field: "remarks" },
    ],
    consignments: [],
    total_qty: 0,
  });

  const [openRejectableAlert, setOpenRejectableAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [error, setError] = useState({
    message: [],
  });

  const [createSubIndent, setCreateSubIndent] = useState(false);

  let consignmentCount = tableData.consignments.length;

  let remainingQty = formattedNumber(balance - tableData.total_qty, true);

  const handleRejectableSubmit = () => {
    if (tableData.total_qty < balance) {
      setOpenRejectableAlert(true);
      return;
    }
    setOpenAlert(true);
  };

  const handleRejectableClose = () => {
    setOpenRejectableAlert(false);
    setCreateSubIndent(false);
  };

  const handleReject = () => {
    setIsReject(true);
    setCreateSubIndent(false);
    setOpenAlert(true);
  };

  const handleCreate = () => {
    setCreateSubIndent(true);
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
    if (isReject) setIsReject(false);
  };

  const handleSubmit = () => {
    setOpenAlert(false);
    setOpenRejectableAlert(false);
    handleCreateConsignment();
  };

  const validate = (quantity, scheduled_date, total_qty, balance) => {
    const message = [];
    if (!quantity || !scheduled_date) {
      message.push("Quantity and scheduled date are required.");
    }
    // if (new Date(scheduled_date) < Date.now()) {
    //   message.push("Scheduled Date can't be from past.");
    // }
    if (total_qty > balance) {
      message.push("Total quantity should be less than balance quantity.");
    }

    setError({
      message,
    });
    return message.length > 0 ? false : true;
  };

  const getRound = (num) => {
    return Math.round(num * 100)/100;
  }

  const handleCreateConsignment = async () => {
    const reqConsignment = tableData.consignments.map((consignment) => ({
      quantity: getRound(+consignment.quantity),
      gst: +consignment.gst,
      amount: getRound(+consignment.amount),
      remarks: consignment.remarks,
      scheduled_date: consignment.scheduled_date,
      discount_type: +consignment.discount_type,
      discount: getRound(+(consignment.discount ? consignment.discount : 0)),
      discounted_amount: getRound(+consignmentGross(consignment)),
    }));

    const requestBody = {
      indent_id,
      create_sub_indent: createSubIndent,
      consignments: reqConsignment,
    };
    await createConsignments(requestBody);
    setIsReject(false);
  };

  const calculateTotal = () => {
    let total = 0;
    tableData.consignments.forEach((consignment) => {
      total += consignmentGross(consignment);
    });

    return total;
  };

  return (
    <>
      {error.message.map((er, index) => (
        <Box mb={2} key={index}>
          <Alert severity="error">{er}</Alert>
        </Box>
      ))}
      <MaterialTable
        title="Consignments"
        columns={tableData.columns}
        data={tableData.consignments}
        icons={tableIcons}
        className="w-100"
        localization={{
          header: {
            actions: "Actions",
          },
          body: {
            addTooltip: "Add Consignment",
            deleteTooltip: "Delete Consignment",
            editTooltip: "Edit Consignment",
          },
        }}
        options={{
          search: false,
          sorting: false,
          actionsColumnIndex: -1,
          paging: false,
          draggable: false,
          headerStyle: {
            color: "#A3A6B4",
            background: "#F5F6FA",
            fontSize: 10,
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const { amount } = newData;
                const quantity = +newData.quantity;
                if (
                  !validate(
                    quantity,
                    newData.scheduled_date,
                    tableData.total_qty + quantity,
                    balance,
                    newData.amount,
                    newData.gst
                  )
                )
                  return;
                setTableData((prevState) => {
                  const consignments = [...prevState.consignments];
                  consignments.push(newData);
                  return {
                    ...prevState,
                    consignments,
                    total_qty: prevState.total_qty + quantity,
                    total_amount:
                      prevState.total_amount +
                      (amount ? +amount : 0) * quantity,
                  };
                });
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
                const dataUpdate = [...tableData.consignments];
                const index = oldData.tableData.id;
                const newQuantity = +newData.quantity;
                const oldQuantity = +oldData.quantity;
                const newAmount = newData.amount ? +newData.amount : 0;
                const oldAmount = oldData.amount ? +oldData.amount : 0;
                const oldTotal = oldQuantity * oldAmount;
                const newTotal = newQuantity * newAmount;
                dataUpdate[index] = newData;
                if (
                  !validate(
                    newQuantity,
                    newData.scheduled_date,
                    tableData.total_qty - oldQuantity + newQuantity,
                    balance,
                    newData.amount,
                    newData.gst
                  )
                )
                  return;
                setTableData((prevState) => ({
                  ...prevState,
                  consignments: [...dataUpdate],
                  total_qty: prevState.total_qty - oldQuantity + newQuantity,
                  total_amount: prevState.total_amount - oldTotal + newTotal,
                }));
              }, 100);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...tableData.consignments];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setError({ message: [] });
                setTableData((prevState) => ({
                  ...prevState,
                  consignments: [...dataDelete],
                  total_qty: prevState.total_qty - +oldData.quantity,
                  total_amount:
                    prevState.total_amount -
                    (oldData.amount ? +oldData.amount : 0) * +oldData.quantity,
                }));
                resolve();
              }, 100);
            }),
        }}
      />
      {tableData.consignments.length > 0 && (
        <>
          <Typography
            variant="body1"
            className="p-3 text-right"
            component="div"
          >
            Total Quantity: {formattedNumber(tableData.total_qty, true, false)}
          </Typography>
          <Typography
            variant="body1"
            className="p-3 pt-0 text-right"
            component="div"
          >
            Total Amount: {formattedNumber(calculateTotal(), true, true)}
          </Typography>
          <div className="text-right my-3">
            <Button
              color="primary"
              variant="contained"
              onClick={handleRejectableSubmit}
            >
              Create Consignment
            </Button>
          </div>
        </>
      )}
      <Dialog open={openRejectableAlert}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Total quantity to order is {`${formattedNumber(balance)} ${unit}`}.
            You have created{" "}
            {`${consignmentCount}
            ${consignmentCount > 1 ? "Consignments" : "Consignment"}`}{" "}
            with total {`${formattedNumber(tableData.total_qty, true)} ${unit}`}{" "}
            quantity. <br />
            What do you want to do with the remainig {`${remainingQty}`}{" "}
            quantity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={`This action will reject the remaining ${remainingQty} quantity.`}
            position="bottom"
          >
            <Button color="primary" variant="contained" onClick={handleReject}>
              Reject
            </Button>
          </Tooltip>
          <Tooltip
            title={`This action will create a new sub indent from ${remainingQty} quantity.`}
            position="bottom"
          >
            <Button color="primary" variant="contained" onClick={handleCreate}>
              Create New Indent
            </Button>
          </Tooltip>
          <Tooltip
            title="This action will do nothing and take you to the previous screen"
            position="bottom"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleRejectableClose}
            >
              Cancel
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlert}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isReject
              ? "This action will reject the remaining quantities. Are you sure you want to continue?"
              : "Are you sure you want to continue?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const dispatchAction = {
  createConsignments: indentFormActions.createConsignments,
};

export default connect(null, dispatchAction)(AddConsignmentTable);
