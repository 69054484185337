import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isStringValid } from "../../../utils";
import { indentFormActions } from "../../../actions";

import { useStyles } from "../Css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export const ManufacturingCompanyAccordian = ({
  manufacturingCompany,
  manufacturingCompany_id = null,
  fetchManufacturingCompany = {},
}) => {
  const classes = useStyles();
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className="my-3"
      onChange={async (e, expanded) => {
        if (!manufacturingCompany_id) return;
        if (!expanded) return;
        if (manufacturingCompany) return;
        try {
          await fetchManufacturingCompany(manufacturingCompany_id);
        } catch (err) {
          console.log("Error getting Manufacturing Vendor: ", err);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="manufacturing-company-content"
        id={`manufacturing-company-header-${
          manufacturingCompany?.id ?? manufacturingCompany_id
        }`}
      >
        <Typography>Manufacturing Company Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {manufacturingCompany && (
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className={classes.body2} variant="body2">
                Name
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(manufacturingCompany.name)
                  ? manufacturingCompany.name
                  : "-"}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Typography className={classes.body2} variant="body2">
                GSTIN
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(manufacturingCompany.gstin)
                  ? manufacturingCompany.gstin
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography className={classes.body2} variant="body2">
                Description
              </Typography>
              <Typography className={classes.body1} variant="body1">
                {isStringValid(manufacturingCompany.description)
                  ? manufacturingCompany.description
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="mb-3" variant="body2">
                Contacts
              </Typography>
              {manufacturingCompany.contacts &&
                manufacturingCompany.contacts.map((contact) => (
                  <Box key={contact.id} className="my-2">
                    <Typography className={classes.body2} variant="body2">
                      Name
                    </Typography>
                    <Typography className={classes.body1} variant="body1">
                      {isStringValid(contact.name) ? contact.name : "-"}
                    </Typography>
                    <Typography className={classes.body2} variant="body2">
                      Email
                    </Typography>
                    <Typography
                      className={`${classes.body1} text-break`}
                      variant="body1"
                    >
                      {isStringValid(contact.email) ? contact.email : "-"}
                    </Typography>
                    <Typography
                      key={contact.id}
                      className={classes.body1}
                      variant="body1"
                    >
                      {isStringValid(contact.phone) ? contact.phone : ""}
                    </Typography>
                  </Box>
                ))}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="mb-3" variant="body2">
                Address
              </Typography>
              <Grid container spacing={3}>
                {manufacturingCompany.addresses &&
                  manufacturingCompany.addresses.map((address) => (
                    <Grid item xs={6} key={address.id}>
                      <Typography className={classes.body1} variant="body1">
                        {isStringValid(address.area) ? address.area : "-"}{" "}
                        {(address.state && address.state.name) || ""}{" "}
                        {(address.country && address.country.name) || ""}{" "}
                        {address.zipcode}{" "}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ManufacturingCompanyAccordian.propTypes = {
  manufacturingCompany: PropTypes.object,
  manufacturingCompany_id: PropTypes.number,
  fetchManufacturingCompany: PropTypes.func,
};

const dispatchAction = {
  fetchManufacturingCompany: indentFormActions.fetchManufacturingCompany,
};

export default connect(null, dispatchAction)(ManufacturingCompanyAccordian);
