const wcToStepMap = {
  wc1: "Indent Acknowledged",
  wc2: "Vendor Finalized",
  wc3: "Indent on hold",
  wc4: "Indent rejected",
  wc5: "Payment terms fixed",
  wc6: "Delivery schedule obtained",
  wc7: "",
  wc8: "PO scanned and sent",
  wc9: "PO acknowledged by vendor",
  wc10: "Communicated with concerned person",
  wc11: "",
  wc12: "PO scanned and sent",
  wc13: "PO acknowledged by vendor",
  wc14: "Communicated with concerned person",
  wc15: "PI received and submitted",
  wc16: "Payment type marked",
  wc17: "Payment made and document send and confirmed",
  wc18: "Document Forwarded",
  wc19: "Checked for L/C status",
  wc20: "LC opened and document sent",
  wc21: "Documents received on email",
  wc22: "Forwarded shipment document",
  wc23: "Tracking number filled",
  wc24: "Import document received",
  wc25: "Status Tracked",
  wc26: "Formalities completed",
  wc27: "",
};

export const getStepNameByWC = (workcentre_name) => {
  if (!wcToStepMap.hasOwnProperty(workcentre_name)) return "";
  if (!workcentre_name) return "Done";
  if (!wcToStepMap[workcentre_name]) return "Done";
  return wcToStepMap[workcentre_name];
};
