import React from "react";
import PropTypes from "prop-types";
import UploadedFileLink from "./UploadedFileLink";
import UploadButton from "./UploadButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollFiles : {
    height : "100px",
    overflow : "auto",
  }
}));

export default function UploadedFiles({
  files,
  handleChange,
  handleFileUpload,
  uploadedFiles,
  disable,
  isSuccess,
  errorMsg,
}) {
  const classes = useStyles();
  return (
    <div>
      <UploadButton
        handleChange={handleChange}
        handleFileUpload={handleFileUpload}
        uploadedFiles={uploadedFiles}
        disable={disable}
        isSuccess={isSuccess}
        errorMsg={errorMsg}
      />
      <div className={classes.scrollFiles}>
      {files.map((file) => (
        <UploadedFileLink key={file.id} file={file} />
      ))}
      </div>
    </div>
  );
}

UploadedFiles.propTypes = {
  files: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
