import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Label, Form, Alert } from "reactstrap";
import {
  TextField as MaterialTextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import "./Login.css";
import logo from "../../assets/sujatalogo.png";
import { userActions } from "../../actions";
import { connect } from "react-redux";

function Login({ login, loggedIn, alert }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_hidden, toggleHiddenPasswd] = useState(true);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (email && password) {
        await login(email, password);
        await loggedIn();
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <div className="Gcontainer">
      <div className="CLogin container">
        <div className="CLoginForm">
          <div className="CLoginLogo">
            <Link to="/login">
              <img
                src={logo}
                className="img-fluid CLoginLoginLogo"
                alt="logo"
              />
            </Link>
          </div>
          {alert.message && (
            <Alert color={`${alert.type}`}>{alert.message}</Alert>
          )}
          <Label className="CLoginLabel"> Log In</Label>
          <Form onSubmit={handleSubmit}>
            <div className="CLoginEmailDiv">
              <MaterialTextField
                className="CLoginFormgroup"
                label="Email ID/Username*"
                variant="outlined"
                placeholder="Email ID/Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="CLoginPassDiv">
              <MaterialTextField
                className="CLoginFormgroup"
                type={password_hidden ? "password" : "text"}
                placeholder="Password"
                label="Password*"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={(e) => toggleHiddenPasswd(!password_hidden)}
                        size="small"
                      >
                        {password_hidden ? (
                          <VisibilityOffOutlinedIcon size="small" />
                        ) : (
                          <VisibilityOutlinedIcon size="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <div className="CLoginResetPasswordLink">
              <Link to="/request_reset_passwd">Change Password</Link>
            </div> */}
            <Button
              block
              disabled={!validateForm()}
              color="primary"
              type="submit"
              className="mt-4"
            >
              LOGIN
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => {
  const { alert } = state;
  return {
    alert,
  };
};

const actionCreators = {
  login: userActions.login,
  loggedIn: userActions.isLoggedIn,
};
export default connect(mapState, actionCreators)(Login);
