export const lotOfferConstants = {

  CREATE_LO_SUCCESS: "CREATE_LO_SUCCESS",
  CREATE_LO_FAILURE: "CREATE_LO_FAILURE",

  EDIT_LO_SUCCESS: "EDIT_LO_SUCCESS",
  EDIT_LO_FAILURE: "EDIT_LO_FAILURE",

  APPROVE_LO_DISPATCH_SUCCESS: "APPROVE_LO_DISPATCH_SUCCESS",
  APPROVE_LO_DISPATCH_FAILURE: "APPROVE_LO_DISPATCH_FAILURE",

  SCAN_LO_BOX_CODE_SUCCESS: "SCAN_LO_BOX_CODE_SUCCESS",
  SCAN_LO_BOX_CODE_FAILURE: "SCAN_LO_BOX_CODE_FAILURE",

  DELETE_LO_SCANNING_LOG_SUCCESS: "DELETE_LO_SCANNING_LOG_SUCCESS",
  DELETE_LO_SCANNING_LOG_FAILURE: "DELETE_LO_SCANNING_LOG_FAILURE",

  UPDATE_LO_SCANNING_LOG_SUCCESS: "UPDATE_LO_SCANNING_LOG_SUCCESS",
  UPDATE_LO_SCANNING_LOG_FAILURE: "UPDATE_LO_SCANNING_LOG_FAILURE",

  REJECT_LO_SUCCESS: "REJECT_LO_SUCCESS",
  REJECT_LO_FAILURE: "REJECT_LO_FAILURE",

  DELETE_LO_SUCCESS: "DELETE_LO_SUCCESS",
  DELETE_LO_FAILURE: "DELETE_LO_FAILURE",

  REVIVE_LO_SUCCESS: "REVIVE_LO_SUCCESS",
  REVIVE_LO_FAILURE: "REVIVE_LO_FAILURE",

  GET_LO_DETAIL_REQUEST: "GET_LO_DETAIL_REQUEST",
  GET_LO_DETAIL_SUCCESS: "GET_LO_DETAIL_SUCCESS",
  GET_LO_DETAIL_FAILURE: "GET_LO_DETAIL_FAILURE",

};
