import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { errorAndLoadingActions } from "../../../actions";

const SuccessAlert = ({
  open = false,
  title = "Success",
  message = "Action Successfull",
  closeSuccessAlert,
}) => {
  return (
    <Dialog open={open} onClose={closeSuccessAlert}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={closeSuccessAlert}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SuccessAlert.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  closeSuccessAlert: PropTypes.func,
};

const mapState = (state) => {
  const { open, title, message } = state.errorAndLoading.successAlert;
  return {
    open,
    title,
    message,
  };
};

const dispatchAction = {
  closeSuccessAlert: errorAndLoadingActions.closeSuccessAlert,
};

export default connect(mapState, dispatchAction)(SuccessAlert);
