import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getOemDashboard = (body) => {
  return axios
    .post("/api/v1/oems/get_oem_dashboard", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getOemProduction = (body) => {
  return axios
    .post("/api/v1/oems/oem_production_by_date_and_type", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getOemDispatch = (body) => {
  return axios
    .post("/api/v1/oems/oem_product_code_wise_count", JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const oemDashboardService = {
  getOemDashboard,
  getOemProduction,
  getOemDispatch,
};
