export default {
  root: {
    "&$expanded": {
      borderRadius: [[5, 5, 13, 13]],
      boxShadow: "0px 4px 6px #00000029",
    },
    "&::before": {
      height: 0,
    },
  },
  rounded: {
    borderRadius: [[5, 5, 0, 0]],
  },
};
