import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getDBMotor = (date, motor_type, station_id) => {
  return axios
    .get(`/api/v1/motor_testings/get_testing_record_by_type`, {
      params: {
        testing_type: "motor",
        testing_date: date,
        motor_type: motor_type,
        station_id: station_id,
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getMotorStations = () => {
  return axios
    .get("/api/v1/motor_testings/motor_scanning_station")
    .then((response) => response)
    .catch((err) => err);
};

const getPlanData = (date, motor_type, flow_id) => {
  return axios
    .get(`/api/v1/motor_testings/get_plan_data`, {
      params: {
        date: date,
        motor_type: motor_type,
        flow_id: flow_id
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getDBAssembly = (date, type, stationsArray) => {
  return axios
    .get(`/api/v1/rotor_stator_testings/get_record_by_type`, {
      params: {
        testing_type: type,
        testing_date: date,
        station_id: stationsArray
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getDBSearchedData = (date, station_id, motor_type) => {
  return axios
    .get(`/api/v1/motor_testings/get_motor_testing_record_by_station`,{
        params: {
          testing_type: "motor",
          testing_date: date,
          station_id: station_id,
          motor_type: motor_type
        },
    })
    .then((res) => res)
    .catch((err) => err);
};

const getMotorScannedTime = (date, station_id, motor_type) => {
  return axios
    .get(`/api/v1/motor_testings/get_motor_testing_record_by_date`,{
        params: {
          testing_type: "motor",
          testing_date: date,
          station_id: station_id,
          motor_type: motor_type
        },
    })
    .then((res) => res)
    .catch((err) => err);
};

export const pmsDBService = {
  getDBMotor,
  getDBAssembly,
  getMotorStations,
  getPlanData,
  getDBSearchedData,
  getMotorScannedTime,
};
