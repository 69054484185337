import axios from "axios";
import { saveAs } from "file-saver";

export const downloadFile = (url, params) => {
  return axios
    .get(url, { responseType: "blob", params })
    .then((response) => {
      const blog = new Blob([response.data]);
      const contentDisposition = response.headers["content-disposition"];
      let filename = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          filename = fileNameMatch[1];
        }
      }
      saveAs(blog, filename);
    })
    .catch((err) => {
      throw err.message;
    });
};
