import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const getQualityReport = (report_name) => {
  return axios
    .get(`/api/v1/reports/${report_name}`)
    .then((response) => response)
    .catch((err) => err);
};

export const qualityService = {
  getQualityReport,
};
