import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PMSAlerts from "./components/PMSAlerts";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Plan = lazy(() => import("./pages/Plan"));
const Rotor = lazy(() => import("./pages/Rotor"));
const Motor = lazy(() => import("./pages/Motor"));
const Stator = lazy(() => import("./pages/Stator"));
const StatorTest = lazy(() => import("./pages/StatorTesting"));
const RotorTest = lazy(() => import("./pages/RotorTesting"));
const MotorTestingHistory = lazy(() => import("./pages/MotorTestingHistory"));
const Report = lazy(() => import("./pages/Report"));

const baseRoute = "/pms";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PMSRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <ProtectedRoutes path={`${baseRoute}/d/`}>
            <Dashboard />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/plan/`}>
            <Plan />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/rotor/`}>
            <Rotor />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/motor/`}>
            <Motor />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/stator/`}>
            <Stator />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/stator-test/`}>
            <StatorTest />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/rotor-test/`}>
            <RotorTest />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/motor-testing-report/`}>
            <MotorTestingHistory />
          </ProtectedRoutes>
          <ProtectedRoutes path={`${baseRoute}/report/`}>
            <Report />
          </ProtectedRoutes>
        </Suspense>
      </Switch>
      <PMSAlerts />
    </>
  );
};

export default PMSRoutes;
