import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import useRedirect from "./utils/useRedirect";
import useRoles from "./utils/useRoles";

const Auth = ({ user: { isLoggedIn }, path, children }) => {
  const redirectPath = useRedirect();
  return (
    <Route
      path={path}
      render={() => (isLoggedIn ? <Redirect to={redirectPath} /> : children)}
    />
  );
};

const Protected = ({ path, children }) => {
  const authorized = useRoles(path);
  const redirectPath = useRedirect();
  return (
    <Route
      path={path}
      render={() =>
        authorized ? children : <Redirect to={redirectPath.current} />
      }
    />
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export const AuthRoutes = connect(mapStateToProps, null)(Auth);
export const ProtectedRoutes = Protected;
