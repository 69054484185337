import { commentConstants } from "../constants";

export function comments(
  state = {
    comments: {},
  },
  action
) {
  const { job_id } = action;
  switch (action.type) {
    case commentConstants.SUBMIT_COMMENT_FAILURE:
      return state;

    case commentConstants.SUBMIT_COMMENT_SUCCESS:
      const { comment } = action;
      return {
        ...state,
        comments: {
          ...state.comments,
          [job_id]: [comment, ...state.comments[job_id]],
        },
      };

    case commentConstants.STORE_COMMENTS:
      const { comments } = action;
      comments.sort((a, b) => b.id - a.id);
      return {
        ...state,
        comments: {
          ...state.comments,
          [job_id]: comments,
        },
      };

    default:
      return state;
  }
}
