import { combineReducers } from "redux";
import { user } from "./user.reducer";
import { alert } from "./alert.reducer";
import { grids } from "./grids.reducer";
import { comments } from "./comment.reducer";
import { allVendors } from "./vendor.reducer";
import { reports } from "./report.reducer";
import { indentForm } from "./indentForm.reducer";
import { poForm } from "./poForm.reducer";
import { errorAndLoading } from "./errorAndLoading.reducer";
import { date } from "./date.reducer";
import { planList } from "./planList.reducer";
import { historyReport } from "./historyReport.reducer";
import { pmsDB } from "./pmsDashboard.reducer";
import { assemblyList } from "./assemblyList.reducer";
import { employeeList } from "./employeeList.reducer";
import { currPlan } from "./currPlan.reducer";
import { oemDashboard } from "./oemDashboard.reducer";
import { assemblyTesting } from "./assemblyTesting.reducer";
import { moulding } from "./moulding.reducer";
import { productType } from "./productType.reducer";
import { countryData } from "./country.reducer";
import { customer } from "./customer.reducer";
import { vehicle } from "./vechile.reducer";
import { salesOrder } from "./salesOrder.reducer";
import { quality } from "./quality.reducer";
import { stock } from "./stock.reducer";
import { sto } from "./sto.reducer";
import { auditTool } from "./auditTool.reducer";

const rootReducer = combineReducers({
  user,
  alert,
  grids,
  comments,
  allVendors,
  reports,
  indentForm,
  poForm,
  errorAndLoading,
  date,
  planList,
  historyReport,
  pmsDB,
  assemblyList,
  employeeList,
  currPlan,
  oemDashboard,
  assemblyTesting,
  moulding,
  productType,
  countryData,
  customer,
  vehicle,
  salesOrder,
  quality,
  stock,
  sto,
  auditTool,
});

export default rootReducer;
