import { errorAndLoadingConstants, planListConstant } from "../constants";
import { planListService } from "../services";
import { dispatchFailure, requestFailError } from "../utils";

const getPlanList = (plan_type, page) => {
  return async (dispatch) => {
    dispatch({
      type: planListConstant.GET_PLANLIST_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.getPlanList({ plan_type, page }).then(
      (planList) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (planList.status === 200) {
          dispatch({
            type: planListConstant.GET_PLANLIST_SUCCESS,
            data: planList.data,
          });
        } else {
          const errMsg = requestFailError(planList);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.GET_PLANLIST_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.GET_PLANLIST_FAILURE,
        });
      }
    );
  };
};

const createPlan = (data) => {
  return async (dispatch) => {
    dispatch({
      type: planListConstant.CREATE_PLAN_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.createPlan(data).then(
      (plan) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (plan.status === 200) {
          dispatch({
            type: planListConstant.CREATE_PLAN_SUCCESS,
            data: plan.data,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: "The plan has been Successfully created.",
            },
          });
        } else {
          const errMsg = requestFailError(plan);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.CREATE_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.CREATE_PLAN_FAILURE,
        });
      }
    );
  };
};

const editPlan = (requestData, id) => {
  return async (dispatch) => {
    dispatch({
      type: planListConstant.EDIT_PLAN_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.editPlan(requestData, id).then(
      (plan) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (plan.status === 200) {
          dispatch({
            type: planListConstant.EDIT_PLAN_SUCCESS,
            data: plan.data,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: "The plan has been Successfully edited.",
            },
          });
        } else {
          const errMsg = requestFailError(plan);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.EDIT_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.EDIT_PLAN_FAILURE,
        });
      }
    );
  };
};

const deletePlan = (id) => {
  return async (dispatch) => {
    dispatch({
      type: planListConstant.DELETE_PLAN_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.deletePlan(id).then(
      (plan) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (plan.status === 200) {
          dispatch({
            type: planListConstant.DELETE_PLAN_SUCCESS,
            data: id,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: "The plan has been Successfully deleted.",
            },
          });
        } else {
          const errMsg = requestFailError(plan);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.DELETE_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.DELETE_PLAN_FAILURE,
        });
      }
    );
  };
};

const getMoudlingData = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.getMoudlingData().then(
      (data) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (data.status === 200) {
          dispatch({
            type: planListConstant.GET_MOULDING_DATA_SUCCESS,
            data: data.data,
          });
        } else {
          const errMsg = requestFailError(data);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.GET_MOULDING_DATA_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.GET_MOULDING_DATA_FAILURE,
        });
      }
    );
  };
};

const createPlanJarFinal = (type, data) => {
  return async (dispatch) => {
    dispatch({
      type: planListConstant.CREATE_PLAN_REQUEST,
    });

    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });

    await planListService.createPlanJarFinal(type, data).then(
      (plan) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (plan.status === 200) {
          dispatch({
            type: planListConstant.CREATE_PLAN_SUCCESS,
            data: plan.data,
          });
          dispatch({
            type: errorAndLoadingConstants.SHOW_SUCCESS,
            data: {
              title: "Success",
              message: "The plan has been Successfully created.",
            },
          });
        } else {
          const errMsg = requestFailError(plan);
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.CREATE_PLAN_FAILURE,
          });
        }
      },
      (error) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, error, {
          type: planListConstant.CREATE_PLAN_FAILURE,
        });
      }
    );
  };
};

const getMotorType = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await planListService.getMotorType().then(
      (motor_type) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (motor_type.status === 200) {
          dispatch({
            type: planListConstant.GET_MOTOR_TYPE_SUCCESS,
            data: motor_type.data,
          });
        } else {
          const errMsg = requestFailError(
            "Error getting Motor Type"
          );
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.GET_MOTOR_TYPE_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: planListConstant.GET_MOTOR_TYPE_FAILURE,
        });
      }
    );
  };
};

const getProductionFlow = () => {
  return async (dispatch) => {
    dispatch({
      type: errorAndLoadingConstants.API_CALL_START,
    });
    await planListService.getProductionFlow().then(
      (production_flow) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        if (production_flow.status === 200) {
          dispatch({
            type: planListConstant.GET_PRODUCTION_FLOW_SUCCESS,
            data: production_flow.data,
          });
        } else {
          const errMsg = requestFailError(
            "Error getting Production Flow"
          );
          dispatchFailure(dispatch, errMsg, {
            type: planListConstant.GET_PRODUCTION_FLOW_FAILURE,
          });
        }
      },
      (err) => {
        dispatch({
          type: errorAndLoadingConstants.API_CALL_STOP,
        });
        dispatchFailure(dispatch, err, {
          type: planListConstant.GET_PRODUCTION_FLOW_FAILURE,
        });
      }
    );
  };
};

export const planListActions = {
  getPlanList,
  createPlan,
  editPlan,
  deletePlan,
  getMoudlingData,
  createPlanJarFinal,
  getMotorType,
  getProductionFlow,
};
